import { ResponseEnlacesInteres } from './../transparencia/transparencia.component.interface';
import { ActivatedRoute, Router } from '@angular/router';
import {
  queryallEnlacesInteres,
  queryallEnlacesInteres2,
  queryAllVideos,
  queryEventos,
  queryallCulturalEventBanners,
  getQueryPaginaPrincipal,
  AddViewToPage,
} from './../../../operations/query';
import {
  CulturalEventBanner,
  EnlaceInteres,
  InfographicBanner,
  LinkClass,
  New,
  Notice,
  PaginaPrincipaldata,
  Video,
  Event,
  Relevante,
  DireccionEvento,
} from './homepage.interface';

/* eslint-disable @typescript-eslint/explicit-function-return-type */
// KYBC.Imports for Angular components.
import {
  Component,
  OnInit,
  ViewChildren,
  Inject,
  ElementRef,
  OnDestroy,
} from '@angular/core';
import { DOCUMENT } from '@angular/common';

// KYBC.Imports for queries and mutations with GraphQL and Apollo.
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';

// JACH.https://www.npmjs.com/package/ngx-gallery
import {
  NgxGalleryOptions,
  NgxGalleryImage,
  NgxGalleryAnimation,
} from 'ngx-gallery-9';

// KYBC.https://swiperjs.com/get-started/
import Swiper from 'swiper';
import { CapacitacionTransparencia } from 'src/app/interfaces/capacitacion-transparencia';
import { queryVeryImportantNotices } from 'src/app/operations/query';
import { EnlacesInteres } from '../transparencia/transparencia.component.interface';
import { ApiHomeService } from '../../../services/api-home.service';
import { VideosDialogComponent } from './videosModal/videosModal.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ComentariosModalComponent } from './comentarios-modal/comentarios-modal.component';
import { AvisosComponent } from './avisos/avisos.component';
import * as internal from 'stream';
import {MatSnackBar} from '@angular/material/snack-bar';
import { DireccionEventoComponent } from './modal/direccion-evento/direccion-evento.component';
import { GraphqlService } from '../../../services/graphql.service';

export interface DialogData {
  modalInfographic: string;
  youtubeUrl: string;
}

export type Response = {
  allCulturalEventBanners: CulturalEventBanner[];
  getDatosPaginaPrincipal: [PaginaPrincipaldata];
  allInfographicBanners: InfographicBanner[];
  allNews: New[];
  allVeryImportantNotices: Notice[];
  allImportantNotices: Notice[];
  allNotices: Notice[];
  allRelevantes: Relevante[];
  // hayNoticias: Notice[];
  // hayNoticias: contador;
  allEvents: Event[];
  // allEnlacesParlamentarios: EnlaceParlamentario[];
  allVideosFromDB: Video[];
  allEnlacesInteres: EnlaceInteres[];
  allEnlacesInteres2: EnlaceInteres[];
  allLinkClasses: LinkClass[];
  // allLinks: Link[];
};

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.scss'],
})
export class HomepageComponent implements OnInit, OnDestroy {
  @ViewChildren('sliderProximosEventosChildren')
  proximosEventosSliderViewChildren: any;
  @ViewChildren('sliderVideosChildren') videosSliderViewChildren: any;
  @ViewChildren('sliderEnlacesInteresChildren')
  enlacesInteresSliderViewChildren: any;
  @ViewChildren('sliderEnlacesInteresChildren2')
  enlacesInteresSliderViewChildren2: any;
  paginaPrincipal: any;
  citatorio: any;
  video = 0;
  nombrePresidentae: string;
  fechaUltimaSesion: string;
  urlUltimaSesion: string;
  ordenDelDia: string;
  urlBaseSinopsis: string;
  urlBaseResumenSesion: string;
  urlBaseDatosRelevantes: string;
  urlBaseMinutoMinuto: string;
  urlBaseIntervenciones: string;
  urlBaseVotaciones: string;
  proximosEventosSwiper: any;
  enlacesInteresSwiper: any;
  enlacesInteresSwiper2: any;
  videosSwiper: any;
  galleryOptions: NgxGalleryOptions[] = [];
  galleryImages: NgxGalleryImage[] = [];
  culturalBanners: any;
  infographicBanners: any;
  news: any[] = [];
  contadorAvisos = true;
  veryImportantNotices: any[] = [];
  importantNotices: any[] = [];
  notices: any[] = [];
  contador: any[] = [];
  // contador:  number;
  // enlacesParlamentariosFromDB: any[];
  videosFromDB: any[] = [];
  enlacesInteres: any[] = [];
  enlacesInteres2: any[] = [];
  linkClasses: any[];
  links: any[];
  loading = true;
  loadingVideos = true;
  loadingEnlacesInteres = true;
  currentChannel = 'canal' + (0 + 2);
  error: any;
  errorEnlacesInteres: any;
  public avisos: any[] = [];
  public avisosB: any[] = [];
  public relevantes: any[] = [];
  public eventos: any[] = [];
  public eventosC: any[] = [];
  sub: any;
  public avisosImportantes: any[] = [];
  public avisosRelevantes: any[] = [];
  proximosEventos: any[] = [];
  subAvisos: any;
  subRelevantes: any;
  subEventos: any;
  EnlacesInteresCA2: any[][];
  EnlacesInteresCA: any[][];
  enlint: EnlacesInteres[];
  subEnlacesInteres: any;
  videosDB: any[] = [];
  videosDBb: any[] = [];
  videosDBY: any[] = [];
  date = Date.now();

  // direccionEvento: DireccionEvento[];
  // private _direccionEvento: DireccionEvento[] = [];
  // private _direccionEventoHoy: DireccionEvento[] = [];
  direccionEventoHoy: DireccionEvento;
  fechaHora = new Date().toISOString();
  fecha = this.fechaHora.split('T')[0];
  getMes: string;
  getDay: number;
  durationInSeconds = 5;
  getYear: number;
  visitas: any;

  constructor(
    private api: ApiHomeService,
    private graphApi: GraphqlService,
    private apollo: Apollo,
    @Inject(DOCUMENT) document,
    private elementRef: ElementRef,
    private route: ActivatedRoute,
    private router: Router,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar
  ) {}

  ngOnInit() {
    // KYBC.Call function for fetch all cultural events banners from DB.
    this.addViewToPage('c4c0cdad-e9ae-454b-9e70-25d2da460c84');
    this.setNotices();
    this.setNoticesB();
    this.setRelevantes();
    this.setProximosEventos();
    this.fetchCulturalEventBanner();
    this.api.getDatosPaginaPrincipal().subscribe((result) => {
      this.paginaPrincipal = result;
      this.nombrePresidentae = result.NombreCompleto;
      this.citatorio = result.Citatorio;
      this.fechaUltimaSesion = result.FechaUltimaSesion;
      this.urlUltimaSesion = result.UrlUltimaSesion;
      this.urlBaseSinopsis = result.UrlBaseSinopsis;
      this.urlBaseResumenSesion = result.UrlBaseResumenSesion;
      this.urlBaseDatosRelevantes = result.UrlBaseDatosRelevantes;
      this.urlBaseVotaciones = result.UrlBaseVotaciones;
      this.urlBaseMinutoMinuto = result.UrlBaseMinutoMinuto;
      this.urlBaseIntervenciones = result.UrlBaseIntervenciones;
      this.ordenDelDia = result.OrdenDelDia;
    });
    // KYBC.Call function for fetch all infographic banners from DB.
    this.fetchInfographicBanner();
    // KYBC.Call function for fetch all News from DB.
    this.fetchAllNews();
    // KYBC.Call function for fetch all Very Important Notices from DB.
    // this.fetchAllVeryImportantNotices();
    // KYBC.Call function for fetch all Important Notices from DB.
    // this.fetchAllImportantNotices();
    // KYBC.Call function for fetch all Notices from DB.
    // this.fetchAllNotices();
    // GFVS  este método es para ver si hay o no avisos
    // this.fetchhayNoticias();
    // KYBC.Call function for Fetch all Enlaces Parlamentarios from DB.
    // this.fetchAllEnlacesParlamentarios();
    // GFVS.Call function for fetch all videos from DB.
    this.fetchAllVideos();
    this.fetchAllVideosB();
    // KYBC.Call function for fetch all enlaces de interés from DB.
    this.fetchAllEnlacesInteres();
    // this.fetchAllEnlacesInteres2();
    // KYBC.Call function for fetch all Link Classes from DB.
    // this.fetchAllLinkClasses();
    // KYBC.Call function for fetch all Links from DB.
    // this.fetchAllLinks();
    // this.setcapacitacionesTransparencia();
    // this.subEnlacesInteres = this.apollo
    //   .watchQuery<ResponseEnlacesInteres>({
    //     query: gql`
    //       {
    //         allEnlacesInteresTransparencia {
    //           Nombre
    //           URL
    //           ImageBase64
    //         }
    //       }
    //     `,
    //   })
    //   .valueChanges.subscribe((result) => {
    //     this.enlint = result.data && result.data.allEnlacesInteresTransparencia;
    //     this.loading = result.loading;
    //   });
    this.getDireccionEventoHoy();
    this.obtenerFecha();

  }
  //
  openSnackBar() {
    this._snackBar.open("No existen eventos Hoy", "Ok");
  }

  // Joey Morán. Modal Avisos
  openAvisosDialog() {
    const dialogRef = this.dialog.open(AvisosComponent, {
      data: { relevantes: this.avisosRelevantes},
    });
  }

  onNavegar() {
    window.open('/leyess/#marco', '_blank');
  }

  goOrdinaria() {
    this.router.navigate(['/inicio/comisiones'], {
      queryParams: { Tipo: 'Ordinaria' },
    });
  }

  goEspecial() {
    this.router.navigate(['/inicio/comisiones'], {
      queryParams: { Tipo: 'Especial' },
    });
  }

  goBicamaral() {
    this.router.navigate(['/inicio/comisiones'], {
      queryParams: { Tipo: 'Bicamaral' },
    });
  }

  goInvestigacion() {
    this.router.navigate(['/inicio/comisiones'], {
      queryParams: { Tipo: 'Investigación' },
    });
  }

  goGAmistad() {
    this.router.navigate(['/inicio/comisiones'], {
      queryParams: { Tipo: 'Grupo de Amistad' },
    });
  }

  goPInternacionales() {
    this.router.navigate(['/inicio/comisiones'], {
      queryParams: { Tipo: 'Parlamentos Internacionales' },
    });
  }

  goGTrabajo() {
    this.router.navigate(['/inicio/comisiones'], {
      queryParams: { Tipo: 'Trabajo' },
    });
  }

  //Fecha de Hoy
  obtenerFecha(){
    const d = new Date();
    const months = [
      'Enero',
      'Febrero',
      'Marzo',
      'Abril',
      'Mayo',
      'Junio',
      'Julio',
      'Agosto',
      'Septiembre',
      'Octubre',
      'Noviembre',
      'Diciembre',
    ];
    this.getMes = months[d.getMonth()];
    this.getDay = d.getDate();
    this.getYear=d.getFullYear();
  }

  getDireccionEventoHoy(): void {
    this.api
      .getDireccionEventoHoy(this.fecha)
      .valueChanges.subscribe((result) => {
        this.direccionEventoHoy = result.data.getMesDireccionEvento;
      });
  }
  
  openModalDireccionEvento(): any {
    // tslint:disable-next-line: no-use-before-declare
    //const dialogRef = this.dialog.open(DireccionEventoComponent, {});
  }

  setNotices(): void {
    this.subAvisos = this.api
      .getAvisosMuyImportantes()
      .valueChanges.subscribe((result) => {
        this.avisosImportantes = result.data && result.data.allNotices;
        this.avisos = this.chunkArray([...this.avisosImportantes], 3); // Joel, modificar si quieres que se vean 3 anuncios o mas.

      });
  }

  setNoticesB(): void {
    this.subAvisos = this.api
      .getAvisosMuyImportantes()
      .valueChanges.subscribe((result) => {
        this.avisosImportantes = result.data && result.data.allNotices;
        this.avisosB = this.avisosImportantes;
         console.log("AvisosImportantes: ", this.avisosImportantes);
      });
  }

  setRelevantes(): void {
    this.subRelevantes = this.api
      .getRelevantes()
      .valueChanges.subscribe((result) => {
        // console.log('result: ', result);
        this.avisosRelevantes = result.data && result.data.allRelevantes;
        // console.log('AVISOS RELEVANTES: ', this.avisosRelevantes);
        this.relevantes = this.chunkArray([...this.avisosRelevantes], 4); // Joel, modificar si quieres que se vean 3 anuncios o mas.
      });
  }

  setProximosEventos(): void {
    this.subEventos = this.api.getEventos().valueChanges.subscribe((result) => {
      this.proximosEventos = result.data && result.data.allEvents;
      // console.log({ arrEvenos: this.proximosEventos });
      this.eventosC = this.chunkArray([...this.proximosEventos], 3); // Joel, modificar si quieres que se vean 3 anuncios o mas.
      // console.log("Eventos: ", this.eventosC);
    });
  }
  addViewToPage(Oid: any): any {
    this.graphApi.addViewToPage(Oid)
      .subscribe(
        ({ data }: any) => {
          this.visitas = data.addView.Visitas;
        },
        (error) => {
          console.log( ':Error addView: ',  error);
        }
      );
  }
  chunkArray(arr: Array<any>, n: number) {
    return arr
      .slice(0, (arr.length + n - 1) / n || 0)
      .map((c, i) => arr.slice(n * i, n * i + n));
  }
  // YCOSIO
  // Metodo para recuperar archivos base 64 y descargarlos
  /*setcapacitacionesTransparencia() {
    this._capacitacionesTransparencia = this._capacitacionTransparenciaService
      .getCapacitacionesTransparencia()
      .valueChanges.subscribe((result) => {
        this.capacitacionesTransparencia = result.data && result.data.allCapacitacionesTransparencia;
        this.capacitacionesTransparencia.forEach((element) => {
          element['Tipo'] = element['NombreCompleto'] ? (element['NombreCompleto'].split('.'))[1] : element['NombreCompleto'];
        });
      });
  }

  downloadBase64File(base64: string, fileName: string, fileType: string) {
    const linkSource = `data:application/${fileType};base64,${base64}`;
    const downloadLink = document.createElement("a");
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
    downloadLink.remove();
  }*/

  // tslint:disable-next-line: use-lifecycle-interface
  // ngAfterViewInit(): void {
  // this.proximosEventosSliderViewChildren.changes.subscribe(() => {
  //   this.proximosEventosSwiper = new Swiper('.swiperProximosEventos', {
  //     updateOnWindowResize: true, // KYBC.Swiper will recalculate slides position on window resize (orientationchange).
  //     initialSlide: 0, // KYBC.	Index number of initial slide.
  //     direction: 'horizontal', // KYBC.Could be 'horizontal' or 'vertical' (for vertical slider).
  //     speed: 300, // KYBC.Duration of transition between slides (in ms).
  //     effect: 'slide', // KYBC.Tranisition effect. Could be "slide", "fade", "cube", "coverflow" or "flip".
  //     spaceBetween: 10, // KYBC.Distance between slides in px.
  //     slidesPerView: 5, // KYBC.Number of slides per view (slides visible at the same time on slider's container).
  //     loop: true, // KYBC.Set to true to enable continuous loop mode.
  //     autoplay: {
  //       delay: 2500,
  //       disableOnInteraction: false,
  //     },
  //   });
  // });
  // this.videosSliderViewChildren.changes.subscribe(() => {
  //   this.videosSwiper = new Swiper('.swiperVideos', {
  //     updateOnWindowResize: true, // KYBC.Swiper will recalculate slides position on window resize (orientationchange).
  //     initialSlide: 0, // KYBC.	Index number of initial slide.
  //     direction: 'horizontal', // KYBC.Could be 'horizontal' or 'vertical' (for vertical slider).
  //     speed: 300, // KYBC.Duration of transition between slides (in ms).
  //     effect: 'slide', // KYBC.Tranisition effect. Could be "slide", "fade", "cube", "coverflow" or "flip".
  //     spaceBetween: 10, // KYBC.Distance between slides in px.
  //     slidesPerView: 4, // KYBC.Number of slides per view (slides visible at the same time on slider's container).
  //     loop: false, // KYBC.Set to true to enable continuous loop mode.
  //   });
  //   //console.log("VIDEOS SWIPER", this.videosSwiper);
  // });
  // this.enlacesInteresSliderViewChildren.changes.subscribe(() => {
  //   this.enlacesInteresSwiper = new Swiper('.swiperEnlacesInteres', {
  //     updateOnWindowResize: true, // KYBC.Swiper will recalculate slides position on window resize (orientationchange).
  //     initialSlide: 0, // KYBC.	Index number of initial slide.
  //     direction: 'horizontal', // KYBC.Could be 'horizontal' or 'vertical' (for vertical slider).
  //     speed: 300, // KYBC.Duration of transition between slides (in ms).
  //     effect: 'slide', // KYBC.Tranisition effect. Could be "slide", "fade", "cube", "coverflow" or "flip".
  //     spaceBetween: 10, // KYBC.Distance between slides in px.
  //     slidesPerView: 5, // KYBC.Number of slides per view (slides visible at the same time on slider's container).
  //     loop: true, // KYBC.Set to true to enable continuous loop mode.
  //     autoplay: {
  //       delay: 2500,
  //       disableOnInteraction: false,
  //     },
  //   });
  // });
  // this.enlacesInteresSliderViewChildren2.changes.subscribe(() => {
  //   this.enlacesInteresSwiper2 = new Swiper('.swiperEnlacesInteres2', {
  //     updateOnWindowResize: true, // KYBC.Swiper will recalculate slides position on window resize (orientationchange).
  //     initialSlide: 0, // KYBC.	Index number of initial slide.
  //     direction: 'horizontal', // KYBC.Could be 'horizontal' or 'vertical' (for vertical slider).
  //     speed: 300, // KYBC.Duration of transition between slides (in ms).
  //     effect: 'slide', // KYBC.Tranisition effect. Could be "slide", "fade", "cube", "coverflow" or "flip".
  //     spaceBetween: 10, // KYBC.Distance between slides in px.
  //     slidesPerView: 5, // KYBC.Number of slides per view (slides visible at the same time on slider's container).
  //     loop: true, // KYBC.Set to true to enable continuous loop mode.
  //     autoplay: {
  //       delay: 2500,
  //       disableOnInteraction: false,
  //     },
  //   });
  // });
  // KYBC.Applies script tag for KalturaPlayer for 'Sesión en Vivo' functionality.
  // var scriptKalturaPlayer = document.createElement('script');
  // scriptKalturaPlayer.type = 'text/javascript';
  // scriptKalturaPlayer.text =
  //   'kWidget.embed({ "targetId": "kaltura_player_1565720477", "wid": "_1808371", "uiconf_id": 44425072, "flashvars": {},
  // "cache_st": 1565720477, "entry_id": "1_3ekobsj8" });';
  // this.elementRef.nativeElement.appendChild(scriptKalturaPlayer);
  // KYBC.Applies script tag for 'Twitter Time-line' functionality. https://publish.twitter.com/#
  // const scriptTwitterTimeline = document.createElement('script');
  // scriptTwitterTimeline.type = 'text/javascript';
  // scriptTwitterTimeline.src = 'https://platform.twitter.com/widgets.js';
  // this.elementRef.nativeElement.appendChild(scriptTwitterTimeline);
  // }

  ngOnDestroy(): void {
    // console.log('onDestroy'); //KYBC. Borrar
    this.sub.unsubscribe();
  }

  // KYBC.Fetch all cultural events banners from DB.
  fetchCulturalEventBanner() {
    this.sub = this.apollo
      .watchQuery<Response>({
        query: queryallCulturalEventBanners,
      })
      .valueChanges.subscribe((result) => {
        this.culturalBanners =
          result.data && result.data.allCulturalEventBanners[0];
          console.log(result.data);
        this.loading = result.loading;
        this.error = result.errors;
      });
  }
  // KYBC.Fetch all cultural events banners from DB.
  fetchDatosPaginaPrincipal() {
    this.sub = this.apollo
      .watchQuery<Response>({
        query: getQueryPaginaPrincipal,
      })
      .valueChanges.subscribe((result) => {
        this.paginaPrincipal =
          result.data && result.data.getDatosPaginaPrincipal;
        this.loading = result.loading;
        this.error = result.errors;
      });
  }
  // KYBC.Fetch all infographic banners from DB.
  fetchInfographicBanner() {
    this.sub = this.apollo
      .watchQuery<Response>({
        query: gql`
          {
            allInfographicBanners {
              Oid
              Titulo
              Descripcion
              Tooltip
              Link
              BannerBase64
            }
          }
        `,
      })
      .valueChanges.subscribe((result) => {
        // console.log('::::::::::allInfographicBanners Result from DB: \n', result); // KYBC.Borrar.
        this.infographicBanners =
          result.data && result.data.allInfographicBanners[0];
        // console.log( 'allInfographicBanners: \n', this.infographicBanners ); // KYBC.Borrar.
        this.loading = result.loading;
        this.error = result.errors;
      });
  }

  // // KYBC.Fetch all News from DB.
  fetchAllNews() {
    const UrlNoticias =
      'https://portalhcd.diputados.gob.mx/PortalWeb/PaginaPrincipal/Noticias/2021/';
    this.sub = this.apollo
      .watchQuery<Response>({
        query: gql`
          {
            allNews {
              Oid
              Titulo
              #ImageBase64
              linkSitio
              Descripcion
              Numero
              SFTPFotografia
              Url
            }
          }
        `,
      })
      .valueChanges.subscribe((result) => {
        // console.log('::::::::::allNews Result from DB: \n', result); // KYBC.Borrar.
        this.news = result.data && result.data.allNews;
        // console.log('::::::::::allNews from DB: \n', this.news); // KYBC.Borrar.
        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < this.news.length; i++) {
          this.galleryImages.push({
            small:
              this.news[i].SFTPFotografia != null
                ? UrlNoticias + this.news[i].SFTPFotografia
                : '/assets/images/preloader.webp', // this.news[i].ImageBase64,
            medium:
              this.news[i].SFTPFotografia != null
                ? UrlNoticias + this.news[i].SFTPFotografia
                : '/assets/images/preloader.webp',
            big:
              this.news[i].SFTPFotografia != null
                ? UrlNoticias + this.news[i].SFTPFotografia
                : '/assets/images/preloader.webp',
            description: this.news[i].Descripcion,
            url: this.news[i].linkSitio,
          });
        }
        // console.log('::::::::::this.galleryImages: \n', this.galleryImages); // KYBC.Borrar.
        this.formatNewsSlider();
      });
  }

  formatNewsSlider() {
    // JACH.Options for Ngx slider.
    this.galleryOptions = [
      {
        imageAutoPlay: true,
        imageAutoPlayPauseOnHover: true,
        previewAutoPlay: true,
        previewAutoPlayPauseOnHover: true,
        imageAutoPlayInterval: 5000,
        width: '100%',
        height: '500px',
        thumbnailsColumns: 4,
        imageAnimation: NgxGalleryAnimation.Fade,
        imageDescription: true,
        preview: true,
        linkTarget: '_blank',
        thumbnailsAsLinks: false,
        previewCloseOnEsc: true,
        previewCloseOnClick: true,
        lazyLoading: true,
      },
      // max-width 800
      {
        breakpoint: 800,
        width: '100%',
        height: '400px',
        imagePercent: 80,
        thumbnailsPercent: 20,
        thumbnailsMargin: 20,
        thumbnailMargin: 20,
      },
      // max-width 400
      {
        breakpoint: 400,
        preview: false,
      },
    ];
  }

  // KYBC.Fetch all notices from DB.
  /*  fetchAllNotices() {
     this.sub = this.apollo
       .watchQuery<Response>({
         query: queryVeryImportantNotices,
       })
       .valueChanges.subscribe((result) => {
         //'::::::::::allNotices Result from DB: \n', result); // KYBC.Borrar.
         const allNotices = result.data && result.data.allNotices;
         this.veryImportantNotices = allNotices.filter(
           (notice) => notice.Tipo === 0
         );
         this.importantNotices = allNotices.filter(
           (notice) => notice.Tipo === 1
         );
         this.notices = allNotices.filter((notice) => notice.Tipo === 2);
         //console.log('::::::::::allNotices from DB: \n', this.notices); // KYBC.Borrar.
         this.loading = result.loading;
         this.error = result.errors;
       });
   } */

  // fetchhayNoticias() {
  //   this.sub = this.apollo.watchQuery<Response>({
  //     query: gql`
  //     {
  //       hayNoticias {
  //         ContadorNoticias
  //       }
  //     }
  //   `,
  //   }).valueChanges.subscribe(result => {
  //     this.contador = result.data && result.data.hayNoticias;
  //     console.log('::::::::::contador from DB: \n', this.contador);
  //     this.loading = result.loading;
  //     this.error = result.errors;
  //   });
  // }

  // KYBC.Fetch all next events from DB.
  fetchAllNextEvents() {
    this.sub = this.apollo
      .watchQuery<Response>({
        query: queryEventos,
      })
      .valueChanges.subscribe((result) => {
        this.eventos = result.data && result.data.allEvents;
        this.eventosC = this.chunkArray([...this.proximosEventos], 3);
        this.loading = result.loading;
        this.error = result.errors;
      });
  }

  // GFVS.Fetch all video links from DB.
  fetchAllVideos() {
    this.sub = this.apollo
      .watchQuery<Response>({
        query: queryAllVideos,
      })
      .valueChanges.subscribe((result) => {
        // console.log('::::::::::allVideosFromDB Result from DB: \n', result); // KYBC.Borrar.
        this.videosFromDB = result.data && result.data.allVideosFromDB;
        this.videosDB = this.chunkArray([...this.videosFromDB], 3);
        // console.log('::::::::::allVideosFromDB from DB: \n', this.videosFromDB); // KYBC.Borrar.
        this.loadingVideos = result.loading;
        this.error = result.errors;
      });
  }

   // GFVS.Fetch all video links from DB.
   fetchAllVideosB() {
    this.sub = this.apollo
      .watchQuery<Response>({
        query: queryAllVideos,
      })
      .valueChanges.subscribe((result) => {
        // console.log('::::::::::allVideosFromDB Result from DB: \n', result); // KYBC.Borrar.
        this.videosFromDB = result.data && result.data.allVideosFromDB;
        this.videosDBb = this.videosFromDB;
        // console.log('::::::::::allVideosFromDB from DB: \n', this.videosFromDB); // KYBC.Borrar.
        this.loadingVideos = result.loading;
        this.error = result.errors;
      });
  }

  // KYBC.Opens modal with infographic.
  openDialog(youtubeUrl?: any): void {
    // console.log('YOUTUBE MODAL PARAMETRO', youtubeUrl);
    let dialogRef;
    if (youtubeUrl) {
      dialogRef = this.dialog.open(VideosDialogComponent, {
        width: '75%',
        height: '70%',
        data: { youtubeUrl },
      });
    } else {
      dialogRef = this.dialog.open(VideosDialogComponent, {
        width: '65%',
        data: { youtubeUrl },
      });
    }
    dialogRef.afterClosed().subscribe((result) => {
      // console.log('::::::::::Modal closed! :' + result);
    });
  }

  // JG. Modal de Comentarios
  openComentarios(): void {
    const dialogRef = this.dialog.open(ComentariosModalComponent, {});
    dialogRef.afterClosed().subscribe((result) => {
      // console.log('::::::::::Modal closed! :' + result);
    });
  }

  // KYBC.Fetch all enlaces de interés from DB.
  fetchAllEnlacesInteres() {
    this.sub = this.apollo
      .watchQuery<Response>({
        query: queryallEnlacesInteres,
      })
      .valueChanges.subscribe((result) => {
        this.enlacesInteres = result.data && result.data.allEnlacesInteres;
        this.EnlacesInteresCA = this.chunkArray([...this.enlacesInteres], 6);
        this.loadingEnlacesInteres = result.loading;
        this.errorEnlacesInteres = result.errors;
      });
    // console.log('Datos: ', this.EnlacesInteresCA);
  }
  fetchAllEnlacesInteres2() {
    this.sub = this.apollo
      .watchQuery<Response>({
        query: queryallEnlacesInteres2,
      })
      .valueChanges.subscribe((result) => {
        this.enlacesInteres2 = result.data && result.data.allEnlacesInteres2;
        this.EnlacesInteresCA2 = this.chunkArray([...this.enlacesInteres2], 3);
        this.loadingEnlacesInteres = result.loading;
        this.errorEnlacesInteres = result.errors;
      });
  }
  // KYBC.Fetch all Link Classes from DB.
  fetchAllLinkClasses() {
    this.sub = this.apollo
      .watchQuery<Response>({
        query: gql`
          {
            allLinkClasses {
              Oid
              Nombre
              Descripcion
            }
          }
        `,
      })
      .valueChanges.subscribe((result) => {
        // console.log('::::::::::allLinkClasses Result from DB: \n', result); // KYBC.Borrar.
        this.linkClasses = result.data && result.data.allLinkClasses;
        // console.log('::::::::::allLinkClasses from DB: \n', this.linkClasses); // KYBC.Borrar.
        this.loading = result.loading;
        this.error = result.errors;
      });
  }

  // KYBC.Fetch all Links from DB.
  // fetchAllLinks() {
  //   this.sub = this.apollo.watchQuery<Response>({
  //     query: gql`
  //     {
  //       allLinks {
  //         Oid
  //         Nombre
  //         Descripcion
  //         Clase
  //         linkSitio
  //       }
  //     }
  //   `,
  //   }).valueChanges.subscribe(result => {
  //     console.log('::::::::::allLinks Result from DB: \n', result); // KYBC.Borrar.
  //     this.links = result.data && result.data.allLinks;
  //     console.log('::::::::::allLinks from DB: \n', this.links); // KYBC.Borrar.
  //     this.loading = result.loading;
  //     this.error = result.errors;
  //   });
  // }

  // KYBC.Change the Channel in 'Sesion en Vivo' functionality.
  changeChannel(channelName: string, tablinkNumber: string) {
    let divs;
    let tablinks;

    divs = document.getElementsByClassName('channel');
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < divs.length; i++) {
      divs[i].style.display = 'none';
    }

    tablinks = document.getElementsByClassName('tablink');
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace(
        ' sesionEnVivoButtonActive',
        ''
      );
    }

    document.getElementById(channelName).style.display = 'block';
    tablinks = document.getElementsByClassName(tablinkNumber);
    tablinks[0].className = tablinks[0].className.replace(
      ' tablink',
      ' tablink sesionEnVivoButtonActive'
    );
  }

  sliderPrevNext(swiper: string, navigationOption: string) {
    switch (swiper) {
      case 'swiperProximosEventos':
        if (navigationOption === 'prev') {
          this.proximosEventosSwiper.slidePrev();
        }
        if (navigationOption === 'next') {
          this.proximosEventosSwiper.slideNext();
        }
        break;
      case 'swiperVideos':
        if (navigationOption === 'prev') {
          this.videosSwiper.slidePrev();
        }
        if (navigationOption === 'next') {
          this.videosSwiper.slideNext();
        }
        break;
      case 'swiperEnlacesInteres':
        if (navigationOption === 'prev') {
          this.enlacesInteresSwiper.slidePrev();
        }
        if (navigationOption === 'next') {
          this.enlacesInteresSwiper.slideNext();
        }
        break;
      case 'swiperEnlacesInteres2':
        if (navigationOption === 'prev') {
          this.enlacesInteresSwiper.slidePrev();
        }
        if (navigationOption === 'next') {
          this.enlacesInteresSwiper.slideNext();
        }
        break;
      default:
      // code block
    }
  }
}