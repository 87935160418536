import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-avisos',
  templateUrl: './avisos.component.html',
  styleUrls: ['./avisos.component.scss']
})
export class AvisosComponent implements OnInit {
  relevantes: any[]=[];

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
  public dialog: MatDialog,
  public dialogRef: MatDialogRef<AvisosComponent>) { }

  ngOnInit(): void {
    console.log('los datos del modal son x: ', this.data);
    this.relevantes = this.data.relevantes;
  }
  onNoClick():void{
    this.dialogRef.close();
  }
}
