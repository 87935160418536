import { Injectable } from '@angular/core';
import { AvisoPrivacidadRespuesta } from '../interfaces/avisos-privacidad-respuesta';
import gql from 'graphql-tag';
import { Apollo } from 'apollo-angular';
import { GraphqlService } from './graphql.service';
@Injectable({
  providedIn: 'root',
})
export class AvisosPrivacidadService {
  constructor(
    private apollo: Apollo,
    private _graphqlService: GraphqlService
  ) {}
  getSubAvisosPrivacidad(visitanteEmail: string) {
    return this.apollo.watchQuery<AvisoPrivacidadRespuesta>({
      query: gql`
          {
            allFavoriteAvisosPrivacidad(EmailVisitante: "${visitanteEmail}") {
              Oid
              Nombre
              NombreCompleto
              URLDocumento
              FileName
              size
              NombreCorto
              Content
              NombreArea
              Area
            }
          }
        `,
    });
  }
  deleteFavoriteAvisoPrivacidad(OidAviso: string, emailVisitante: string): any {
    return this.apollo.mutate({
      mutation: this._graphqlService.mutationDeleteFavoriteAviso(
        OidAviso,
        emailVisitante
      ),
    });
  }
}
