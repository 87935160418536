// KYBC.Imports for Angular components.
import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

// KYBC.Imports for queries and mutations with GraphQL and Apollo.
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';

// KYBC.Imports for services.
import { BuscarDiputadosINEService } from '../../../../services/buscar-diputados-ine.service';
import { AsientoDiputado } from 'src/app/interfaces/asiento-diputado';
import { BuscarDiputadosService } from 'src/app/services/buscar-diputados.service';
import { DiputadoEntidad } from 'src/app/interfaces/diputado-entidad';
import { filter } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { DiputadosGpComponent } from '../../infopal/modal/diputados-gp/diputados-gp.component';
import { EndidadFederativaComponent } from '../../infopal/modal/endidad-federativa/endidad-federativa.component';
import { GeneroComponent } from '../../infopal/modal/genero/genero.component';
import { TipoEleccionComponent } from '../../infopal/modal/tipo-eleccion/tipo-eleccion.component';
import { GraphqlService } from '../../../../services/graphql.service';

type Partido = {
  Oid: string;
  Nombre: string;
  NombreCompleto: string;
  IntegrantesActivos: number;
  SitioDelGP: string;
};

type State = {
  Oid: string;
  Nombre: string;
};

type Response = {
  allPartidos: Partido[];
  allStates: State[];
};

@Component({
  selector: 'app-tusdiputados',
  templateUrl: './tusdiputados.component.html',
  styleUrls: ['./tusdiputados.component.scss'],
})
export class TusdiputadosComponent implements OnInit, OnDestroy {
  partidos: Partido[];
  states: any[];
  loading = true;
  error: any;
  sub: any;

  buscarDiputadoINEData: any;

  // Para la sección de Diputados por Curul
  public general: string;
  public morena: string;
  public pan: string;
  public pri: string;
  public pt: string;
  public mc: string;
  public es: string;
  public pv: string;
  public prd: string;
  public sp: string;
  public generalD: string;
  public morenaD: string;
  public panD: string;
  public priD: string;
  public ptD: string;
  public mcD: string;
  public esD: string;
  public pvD: string;
  public prdD: string;
  public spD: string;
  public ocultarGeneral: boolean;
  public ocultarMorena: boolean;
  public ocultarPan: boolean;
  public ocultarPri: boolean;
  public ocultarPt: boolean;
  public ocultarMc: boolean;
  public ocultarEs: boolean;
  public ocultarPv: boolean;
  public ocultarPrd: boolean;
  public ocultarSp: boolean;
  public ocultarGeneralD: boolean;
  public ocultarMorenaD: boolean;
  public ocultarPanD: boolean;
  public ocultarPriD: boolean;
  public ocultarPtD: boolean;
  public ocultarMcD: boolean;
  public ocultarEsD: boolean;
  public ocultarPvD: boolean;
  public ocultarPrdD: boolean;
  hidden = false;
  public ocultarSpD: boolean;
  public asientosDiputados: AsientoDiputado[];
  public diputadosEntidad: DiputadoEntidad[];
  public diputadosEntidadData: any = {};
  public diputadosEntidadCargados: boolean;
  // public itemChartData: any = { items: [], title: 'Diputados por Curul' };
  public itemChartData: any = { items: [] };

  buscaDiputadosFormGroup = new FormGroup({
    nombreDiputadoForm: new FormControl(''),
    estadoDiputadoForm: new FormControl(''),
    cabeceraMunicipalForm: new FormControl(''),
    grupoParlamentarioForm: new FormControl(''),
    mail: new FormControl(''),
  });

  buscaDiputadosINEFormGroup = new FormGroup({
    estadoINEDiputadoForm: new FormControl('', [Validators.required]),
    seccionINEDiputadoForm: new FormControl('', [
      Validators.required,
      Validators.pattern(/^[0-9]{1,4}$/),
    ]),
  });
  querySub: any;
  partido: any;
  visitas: any;

  constructor(
    private apollo: Apollo,
    private _router: Router,
    private route: ActivatedRoute,
    private _buscarDiputadosINEService: BuscarDiputadosINEService,
    private _buscarDiputadosService: BuscarDiputadosService,
    public dialog: MatDialog,
    private api:GraphqlService
  ) {
    this.general = '../../../../assets/images/tusDiputados/partidos/todos.png';
    this.generalD =
      '../../../../assets/images/tusDiputados/partidos/totalDatos.png';
    this.ocultarGeneral = false;
    this.ocultarGeneralD = false;
  }

  ngOnInit(): any {
    this.addViewToPage('1ec0c9fb-d230-458b-977e-bc4988ba83de');
    this.querySub = this.route.queryParams.subscribe((params) => {
      this.partido = params.partido || '';
      console.log({ partido: this.partido });
    });
    // KYBC.Recuperar todos los partidos políticos de la BD.
    this.fetchAllPartidosFromDB();
    // KYBC.Recuperar todos los estados de la BD.
    this.fetchAllEstadosFromDB();
    this.fetchAllDiputadosEntidadPartido();
  }

  ngOnDestroy(): any {
    console.log('onDestroy'); // KYBC. Borrar
    this.sub.unsubscribe();
  }

  toggleBadgeVisibility() {
    this.hidden = !this.hidden;
  }
  openDialogAnteriores() {
    this.dialog.open(DialogElementsAnterioresDialog);
  }
  abrirComponenteGP(obj?: any) {
    this.dialog.open(DiputadosGpComponent, {
      data: { obj },
    });
  }
  mapChartLoaded(loaded) {
    if (loaded) {
      this.fetchAllAsientosDiputados();
    }
  }
  mostrarIntegrantes(oid?: any) {
    this.dialog.open(DiputadosGpComponent, {
      data: { oid },
    });
  }
  openModalXentidad() {
    this.dialog.open(EndidadFederativaComponent, {});
  }
  openModalXgenero() {
    this.dialog.open(GeneroComponent, {});
  }
  openModalXeleccion() {
    this.dialog.open(TipoEleccionComponent, {});
  }
  openModDocumentos() {
    // this.dialog.open(documen, {});
  }
  openAlfabeticoGrupo() {
    this.dialog.open(DiputadosGpComponent, {});
  }
  fetchAllDiputadosEntidadPartido() {
    this._buscarDiputadosService
      .getDiputadosEntidadPartido()
      .valueChanges.subscribe((result) => {
        this.diputadosEntidad =
          result.data && result.data.allDiputadosEntidadPartido;
        this.setMapCharData();
        this.loading = result.loading;
        this.error = result.errors;
      });
  }

  fetchAllAsientosDiputados() {
    this._buscarDiputadosService
      .getAsientosDiputados()
      .valueChanges.subscribe((result) => {
        this.asientosDiputados = result.data && result.data.allAsientoDiputados;
        this.setItemCharData();
        this.loading = result.loading;
        this.error = result.errors;
      });
  }

  setMapCharData() {
    this.diputadosEntidad.forEach((diputado) => {
      const key = diputado.Estado;
      if (!this.diputadosEntidadData[key]) {
        this.diputadosEntidadData[key] = [
          {
            Estado: diputado.Estado,
            Partido: diputado.Partido,
            Conteo: 1,
          },
        ];
      } else {
        let estado = this.diputadosEntidadData[key];
        let partido = estado.find((item) => item.Partido === diputado.Partido);
        if (!partido) {
          estado.push({
            Estado: diputado.Estado,
            Partido: diputado.Partido,
            Conteo: 1,
          });
        } else {
          partido.Conteo++;
        }
      }
    });
    this.diputadosEntidadCargados = true;
  }

  setItemCharData() {
    let conteo = {};
    let sortItems = [];
    this.asientosDiputados.forEach((asiento) => {
      const key = asiento.Partido ? asiento.Partido : 'SP';
      if (!conteo[key]) {
        conteo[key] = [
          asiento.NombreCompletoPartido
            ? asiento.NombreCompletoPartido
            : 'Sin partido',
          1,
          asiento.Partido ? asiento.ColorHexadecimal : '#000000',
          key,
        ];
      } else {
        conteo[key][1]++;
      }
    });
    Object.keys(conteo).forEach((key) => sortItems.push(conteo[key]));
    sortItems.sort((a, b) => (a[1] < b[1] ? 1 : -1));
    this.itemChartData.items = sortItems;
  }
  addViewToPage(Oid: any): any {
    this.api.addViewToPage(Oid).subscribe(
      ({ data }: any) => {
        this.visitas = data.addView.Visitas;
      },
      (error) => {
        console.log(
          '::::::::::Error al aumentar las visualizaciones de la infografía: ',
          error
        );
      }
    );
  }
  // KYBC.Recuperar todos los partidos políticos de la BD.
  fetchAllPartidosFromDB(): any {
    this.sub = this.apollo

      .watchQuery<Response>({
        query: gql`
          {
            allPartidos {
              Oid
              OID
              Nombre
              IntegrantesActivos
              LogoBase64
              ColorHexadecimal
              Twitter
              Facebook
              ColorHexadecimal
              Instagram
              NombreCompleto
              SitioDelGP
              Diputados {
                NombreCompleto
                NombreCompletoPartido
                Estado
                Distrito
                CabeceraMunicipal
                Correo
                Ubicacion
                Extension
              }
            }
          }
        `,
      })
      .valueChanges.subscribe((result) => {
        // console.log('Resultado:::::::::::::::::::::'); // KYBC:Borrar.
        // console.log(result); // KYBC.Borrar.
        this.partidos = result.data && result.data.allPartidos;
        console.log({ partidos: this.partidos }); // KYBC.Borrar.
        this.loading = result.loading;
        this.error = result.errors;
      });
  }

  // KYBC.Recuperar todos los estados de la BD.
  fetchAllEstadosFromDB(): any {
    this.sub = this.apollo

      .watchQuery<Response>({
        query: gql`
          {
            allStates {
              Nombre
            }
          }
        `,
      })
      .valueChanges.subscribe((result) => {
        // console.log('Resultado:::::::::::::::::::::'); // KYBC:Borrar.
        // console.log(result); // KYBC.Borrar.
        this.states = result.data && result.data.allStates;
        // console.log('::::::::::All Estados from DB:'); // KYBC:Borrar.
        // console.log(this.states); // KYBC.Borrar.
        this.loading = result.loading;
        this.error = result.errors;
      });
  }

  // KYBC.Go to 'listadoDiputadosBuscador' and pass params.
  buscarDiputados(): any {
    // console.log(this.buscaDiputadosFormGroup.value); // KYBC:Borrar.
    this._router
      .navigate([
        '/inicio/tusDiputados/listadoDiputadosBuscador',
        {
          nombre: this.buscaDiputadosFormGroup.value.nombreDiputadoForm,
          estado: this.buscaDiputadosFormGroup.value.estadoDiputadoForm,
          cabeceraMunicipal: this.buscaDiputadosFormGroup.value
            .cabeceraMunicipalForm,
          grupoParlamentario: this.buscaDiputadosFormGroup.value
            .grupoParlamentarioForm,
          mail: this.buscaDiputadosFormGroup.value.mailForm
            ? this.buscaDiputadosFormGroup.value.mailForm
            : '',
        },
      ])
      .then((e) => {
        if (e) {
          //  console.log('Navegación Exitosa', e);
        } else {
          //  console.log('Navegación Fallida', e);
          // tslint:disable-next-line: no-unused-expression
          console.error;
        }
      });
  }

  // KYBC.Go to 'listadoDiputadosBuscador' and pass params.
  buscarDiputadosINE(): any {
    this.querySub = this.route.queryParams.subscribe((params) => {
      this.partido = params.partido || '';
      console.log({ partido: this.partido });
    });
    console.log(this.buscaDiputadosINEFormGroup.value); // KYBC:Borrar.
    const diputadoINEJSONData: any = {
      estado: this.buscaDiputadosINEFormGroup.value.estadoINEDiputadoForm,
      seccion: this.buscaDiputadosINEFormGroup.value.seccionINEDiputadoForm,
    };
    // console.log(diputadoINEJSONData); // KYBC.Borrar.

    this._buscarDiputadosINEService
      .obtenerDiputadosINE(diputadoINEJSONData)
      .then((response) => {
        console.log({ response }); // KYBC.Borrar.
        if (this.partido) {
          response.items.filter((obj) =>
            Object.keys(obj).some((key) => {
              if (key !== 'Oid') {
                return obj[key]
                  ?.toLowerCase()
                  .includes(this.partido.toLowerCase());
              }
            })
          );
        } else {
          this.buscarDiputadoINEData = response;
        }
        // console.log('::::::::::Diputado info from INE:'); // KYBC.Borrar.
        console.log({ IneData: this.buscarDiputadoINEData }); // KYBC.Borrar.
        // console.log(this.buscarDiputadoINEData.items.length); // KYBC.Borrar.
        if (this.buscarDiputadoINEData.items.length > 0) {
          // console.log('::::::::::Resultado Buscar Diputado INE:');
          // console.log(this.buscarDiputadoINEData.items[0].id_dip);
          this.linkCvDiputado(this.buscarDiputadoINEData.items[0].id_dip);
        } else {
          alert(
            'No se encontrarón diputados para el estado: ' +
              this.buscaDiputadosINEFormGroup.value.estadoINEDiputadoForm +
              ' y sección: ' +
              this.buscaDiputadosINEFormGroup.value.seccionINEDiputadoForm
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  // KYBC.Go to 'cvDiputados' and pass params.
  // eslint-disable-next-line @typescript-eslint/camelcase
  linkCvDiputado(id_dip: any): any {
    this._router
      .navigate([
        '/inicio/tusDiputados/cvDiputado',
        {
          // eslint-disable-next-line @typescript-eslint/camelcase
          id_dip,
        },
      ])
      .then((e) => {
        if (e) {
          //  console.log('Navegación Fallida: ', e);
        } else {
          //  console.log('Navegación Exitosa: ', e);
        }
      });
  }

  cambiarGeneral(): any {
    this.general = '../../../../assets/images/tusDiputados/partidos/todos.png';
    this.ocultarGeneral = false;
    this.ocultarMorena = true;
    this.ocultarPan = true;
    this.ocultarPri = true;
    this.ocultarPt = true;
    this.ocultarMc = true;
    this.ocultarEs = true;
    this.ocultarPv = true;
    this.ocultarPrd = true;
    this.ocultarSp = true;

    this.ocultarGeneralD = false;
    this.generalD =
      '../../../../assets/images/tusDiputados/partidos/totalDatos.png';
    this.ocultarMorenaD = true;
    this.ocultarPanD = true;
    this.ocultarPriD = true;
    this.ocultarPtD = true;
    this.ocultarMcD = true;
    this.ocultarEsD = true;
    this.ocultarPvD = true;
    this.ocultarPrdD = true;
    this.ocultarSpD = true;
  }
  cambiarMorena(): any {
    this.ocultarGeneral = true;
    this.morena = '../../../../assets/images/tusDiputados/partidos/morena1.png';
    this.ocultarMorena = false;
    this.ocultarPan = true;
    this.ocultarPri = true;
    this.ocultarPt = true;
    this.ocultarMc = true;
    this.ocultarEs = true;
    this.ocultarPv = true;
    this.ocultarPrd = true;
    this.ocultarSp = true;

    this.ocultarGeneralD = true;
    this.morenaD =
      '../../../../assets/images/tusDiputados/partidos/morena_datos1.png';
    this.ocultarMorenaD = false;
    this.ocultarPanD = true;
    this.ocultarPriD = true;
    this.ocultarPtD = true;
    this.ocultarMcD = true;
    this.ocultarEsD = true;
    this.ocultarPvD = true;
    this.ocultarPrdD = true;
    this.ocultarSpD = true;
  }
  cambiarPan(): any {
    this.pan = '../../../../assets/images/tusDiputados/partidos/pan_curul.png';
    this.ocultarMorena = true;
    this.ocultarPan = false;
    this.ocultarPri = true;
    this.ocultarPt = true;
    this.ocultarMc = true;
    this.ocultarEs = true;
    this.ocultarPv = true;
    this.ocultarPrd = true;
    this.ocultarSp = true;
    this.ocultarGeneral = true;
    this.panD = '../../../../assets/images/tusDiputados/partidos/pan_datos.png';
    this.ocultarMorenaD = true;
    this.ocultarPanD = false;
    this.ocultarPriD = true;
    this.ocultarPtD = true;
    this.ocultarMcD = true;
    this.ocultarEsD = true;
    this.ocultarPvD = true;
    this.ocultarPrdD = true;
    this.ocultarSpD = true;
    this.ocultarGeneralD = true;
  }
  cambiarPri(): any {
    this.pri = '../../../../assets/images/tusDiputados/partidos/pri_curul.png';
    this.ocultarMorena = true;
    this.ocultarPan = true;
    this.ocultarPri = false;
    this.ocultarPt = true;
    this.ocultarMc = true;
    this.ocultarEs = true;
    this.ocultarPv = true;
    this.ocultarPrd = true;
    this.ocultarSp = true;
    this.ocultarGeneral = true;
    this.priD = '../../../../assets/images/tusDiputados/partidos/pri_datos.png';
    this.ocultarMorenaD = true;
    this.ocultarPanD = true;
    this.ocultarPriD = false;
    this.ocultarPtD = true;
    this.ocultarMcD = true;
    this.ocultarEsD = true;
    this.ocultarPvD = true;
    this.ocultarPrdD = true;
    this.ocultarSpD = true;
    this.ocultarGeneralD = true;
  }
  cambiarPt(): any {
    this.pt = '../../../../assets/images/tusDiputados/partidos/pt_curul.png';
    this.ocultarMorena = true;
    this.ocultarPan = true;
    this.ocultarPri = true;
    this.ocultarPt = false;
    this.ocultarMc = true;
    this.ocultarEs = true;
    this.ocultarPv = true;
    this.ocultarPrd = true;
    this.ocultarSp = true;
    this.ocultarGeneral = true;
    this.ptD = '../../../../assets/images/tusDiputados/partidos/pt_datos.png';
    this.ocultarMorenaD = true;
    this.ocultarPanD = true;
    this.ocultarPriD = true;
    this.ocultarPtD = false;
    this.ocultarMcD = true;
    this.ocultarEsD = true;
    this.ocultarPvD = true;
    this.ocultarPrdD = true;
    this.ocultarSpD = true;
    this.ocultarGeneralD = true;
  }
  cambiarMc(): any {
    this.mc = '../../../../assets/images/tusDiputados/partidos/mov_ciu.png';
    this.ocultarMorena = true;
    this.ocultarPan = true;
    this.ocultarPri = true;
    this.ocultarPt = true;
    this.ocultarMc = false;
    this.ocultarEs = true;
    this.ocultarPv = true;
    this.ocultarPrd = true;
    this.ocultarSp = true;
    this.ocultarGeneral = true;
    this.mcD = '../../../../assets/images/tusDiputados/partidos/mc_datos.png';
    this.ocultarMorenaD = true;
    this.ocultarPanD = true;
    this.ocultarPriD = true;
    this.ocultarPtD = true;
    this.ocultarMcD = false;
    this.ocultarEsD = true;
    this.ocultarPvD = true;
    this.ocultarPrdD = true;
    this.ocultarSpD = true;
    this.ocultarGeneralD = true;
  }
  cambiarEs(): any {
    this.es =
      '../../../../assets/images/tusDiputados/partidos/encuentro_social.png';
    this.ocultarMorena = true;
    this.ocultarPan = true;
    this.ocultarPri = true;
    this.ocultarPt = true;
    this.ocultarMc = true;
    this.ocultarEs = false;
    this.ocultarPv = true;
    this.ocultarPrd = true;
    this.ocultarSp = true;
    this.ocultarGeneral = true;
    this.esD = '../../../../assets/images/tusDiputados/partidos/pes_datos.png';
    this.ocultarMorenaD = true;
    this.ocultarPanD = true;
    this.ocultarPriD = true;
    this.ocultarPtD = true;
    this.ocultarMcD = true;
    this.ocultarEsD = false;
    this.ocultarPvD = true;
    this.ocultarPrdD = true;
    this.ocultarSpD = true;
    this.ocultarGeneralD = true;
  }
  cambiarPv(): any {
    this.pv = '../../../../assets/images/tusDiputados/partidos/pv_curul.png';
    this.ocultarMorena = true;
    this.ocultarPan = true;
    this.ocultarPri = true;
    this.ocultarPt = true;
    this.ocultarMc = true;
    this.ocultarEs = true;
    this.ocultarPv = false;
    this.ocultarPrd = true;
    this.ocultarSp = true;
    this.ocultarGeneral = true;
    this.pvD = '../../../../assets/images/tusDiputados/partidos/pv_datos.png';
    this.ocultarMorenaD = true;
    this.ocultarPanD = true;
    this.ocultarPriD = true;
    this.ocultarPtD = true;
    this.ocultarMcD = true;
    this.ocultarEsD = true;
    this.ocultarPvD = false;
    this.ocultarPrdD = true;
    this.ocultarSpD = true;
    this.ocultarGeneralD = true;
  }
  cambiarPrd(): any {
    this.prd =
      '../../../../assets/images/tusDiputados/partidos/revolucion_democratica.png';
    this.ocultarMorena = true;
    this.ocultarPan = true;
    this.ocultarPri = true;
    this.ocultarPt = true;
    this.ocultarMc = true;
    this.ocultarEs = true;
    this.ocultarPv = true;
    this.ocultarPrd = false;
    this.ocultarSp = true;
    this.ocultarGeneral = true;
    this.prdD = '../../../../assets/images/tusDiputados/partidos/prd_datos.png';
    this.ocultarMorenaD = true;
    this.ocultarPanD = true;
    this.ocultarPriD = true;
    this.ocultarPtD = true;
    this.ocultarMcD = true;
    this.ocultarEsD = true;
    this.ocultarPvD = true;
    this.ocultarPrdD = false;
    this.ocultarSpD = true;
    this.ocultarGeneralD = true;
  }
  cambiarSp(): any {
    this.sp = '../../../../assets/images/tusDiputados/partidos/sp_curul.png';
    this.ocultarMorena = true;
    this.ocultarPan = true;
    this.ocultarPri = true;
    this.ocultarPt = true;
    this.ocultarMc = true;
    this.ocultarEs = true;
    this.ocultarPv = true;
    this.ocultarPrd = true;
    this.ocultarSp = false;
    this.ocultarGeneral = true;
    this.spD = '../../../../assets/images/tusDiputados/partidos/sp_datos.png';
    this.ocultarMorenaD = true;
    this.ocultarPanD = true;
    this.ocultarPriD = true;
    this.ocultarPtD = true;
    this.ocultarMcD = true;
    this.ocultarEsD = true;
    this.ocultarPvD = true;
    this.ocultarPrdD = true;
    this.ocultarSpD = false;
    this.ocultarGeneralD = true;
  }
}

@Component({
  selector: 'dialog-elements-example-dialog',
  templateUrl: 'anterioresDialog.html',
})
export class DialogElementsAnterioresDialog {}
