import { Component, Inject, OnInit } from '@angular/core';
import gql from 'graphql-tag';
import { Apollo } from 'apollo-angular';
import { BuscarDiputadosService } from 'src/app/services/buscar-diputados.service';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-tipo-eleccion',
  template: `
    <main>
      <section>
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <h1 style="text-align: center;">
                Composición por tipo de elección y Grupo Parlamentario
              </h1>
            </div>
            <hr
              style="border-top: 1 px; border-width: 2px; width: 100%; border-color: #38572d;"
            />

            <div class="col-md-12 table-responsive">
              <table class="table ">
                <thead class="table-success" style="background-color: #38572d;">
                  <tr>
                    <th scope="col" style="text-align: center;">
                      Grupo Parlamentario
                    </th>
                    <th scope="col" style="text-align: center;">
                      Mayoría Relativa
                    </th>
                    <th scope="col" style="text-align: center;">%</th>
                    <th scope="col" style="text-align: center;">1a.</th>
                    <th scope="col" style="text-align: center;">2a.</th>
                    <th scope="col" style="text-align: center;">3a.</th>
                    <th scope="col" style="text-align: center;">4a.</th>
                    <th scope="col" style="text-align: center;">5a.</th>
                    <th scope="col" style="text-align: center;">
                      Representación
                    </th>
                    <th scope="col" style="text-align: center;">%</th>
                    <th scope="col" style="text-align: center;">Total</th>
                    <th scope="col" style="text-align: center;">%</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    *ngFor="let item of tipoEleccion"
                    style="text-align: center;"
                  >
                    <th scope="row" style="text-align: center;">
                      {{ item.Nombre }}
                    </th>
                    <td style="text-align: center;">{{ item.MR }}</td>
                    <td style="text-align: center;">{{ item.pMR | number: '1.0-1' }}</td>
                    <td style="text-align: center;">{{ item.Primera }}</td>
                    <td style="text-align: center;">{{ item.Segunda }}</td>
                    <td style="text-align: center;">{{ item.Tercera }}</td>
                    <td style="text-align: center;">{{ item.Cuarta }}</td>
                    <td style="text-align: center;">{{ item.Quinta }}</td>
                    <td style="text-align: center;">{{ item.RP }}</td>
                    <td style="text-align: center;">{{ item.pRP }}</td>
                    <td style="text-align: center;">{{ item.Total }}</td>
                    <td style="text-align: center;">{{ item.pTotal }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>
    </main>
  `,
  styles: [],
})
export class TipoEleccionComponent implements OnInit {
  tipoEleccion: any[] = [];
  loading = true;
  table: Number;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private apollo: Apollo,
    public dialog: MatDialog,

    private service: BuscarDiputadosService
  ) {
    this.loading = true;
  }

  ngOnInit(): void {
    this.service
      .getComisionTipoEleccion()
      .valueChanges.subscribe((res: any) => {
        // console.log('data', res.data.allComisionTipoEleccion);
        this.tipoEleccion = res.data.allComisionTipoEleccion;
        this.loading = false;
      });
  }
}
