import { Component, Inject, OnInit } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { FormGroup, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
// import { map } from 'rxjs/operators';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { ConsultasTransparenciaService } from '../../../services/consultas-transparencia.service';
import { CapacitacionTransparencia } from 'src/app/interfaces/capacitacion-transparencia';
import { CapacitacionTransparenciaService } from 'src/app/services/capacitacion-transparencia.service';
import { InfoPreguntasFrecComponent } from './modales/info-preguntas-frec/info-preguntas-frec.component';
import { MatTabChangeEvent } from '@angular/material/tabs';
import {
  ComiteTransparencia,
  // DatosAbiertos,
  EnlacesInteres,
  ExpedienteReservado,
  ObligacionTransparencia,
  // PreguntaFrecuente,
  // TransparenciaProactiva,
  InformeTransparencia,
  // ArchivoTransparencia,
  // ConsultasAtendidas,
  ResponseComiteTransparencia,
  ResponseInformeTransparencia,
  // ResponseExpedienteReservado,
  ResponseEnlacesInteres,
  // ResponseDatosAbiertos,
  // ResponseTransparenciaProactiva,
  // ResponsePreguntaFrecuente,
  // ResponseObligacionTransparencia,
  // ResponseArchivoTransparencia,
  BannerInformativoTransparencia,
  ResponseBannerInformativoTransparencia,
  ResponseConvenioMateriaTransparencia,
  ConvenioMateriaTransparencia,
  EstradoElectronicoTransparencia,
  ObligacionesTransparencia,
} from './transparencia.component.interface';
// import {
//   AvisoPrivacidad,
//   AvisoPrivacidad2,
//   ResponseAvisoPrivacidad,
//   ResponseFavoriteAvisoPrivacidad,
// } from './aviso-privacidad/aviso-privacidad.interface';
// import { DatePipe } from '@angular/common';
// import { NoimagePipe } from '../../../pipes/noimage.pipe';
import {
  MatSnackBar,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
// import { TrabajadorArea } from 'src/app/interfaces/trabajador-area';
import { TrabajadorService } from 'src/app/services/trabajador.service';
// import { LegislacionFederalComponent } from './modales/legislacion-federal/legislacion-federal.component';
// import { CorpusComponent } from './modales/corpus/corpus.component';
import { GraphqlService } from '../../../services/graphql.service';
import { ResponseEstradoElectronicoTransparencia } from './transparencia.component.interface';
import { DisposicionesMateriaService } from 'src/app/services/disposiciones-materia.service';

interface Category {
  Nombre: string;
}
interface Infographic {
  Oid: string;
  Titulo: string;
  UrlImagenCompleta: string;
  UrlPortada: string;
  Usuario: string;
  Nombre: string;
  PrimerApellido: string;
  SegundoApellido: string;
  Categoria: string;
  FechaSubida: string;
  Visualizaciones: number;
}
interface Response {
  allInfographicCategories: Category[];
  allInfographicsThumbnails: Infographic[];
  allInfographicsByViews: Infographic[];
  allInfographicsByVotes: Infographic[];
  allInfographicsByDate: Infographic[];
  infographicPreview: Infographic;
}

// interface Infographic2 {
//   Oid: string;
// }

// interface ResponseCategoriaTrans {
//   allInfographicsTrans: Infographic[];
// }

// interface ResponseInfographics {
//   allFavoriteInfographics: Infographic2[];
// }

// const mutationAddFavoriteAviso = (OidAviso, emailVisitante): any => gql`
// mutation {
//   addFavoriteAviso(OidAviso: "${OidAviso}", EmailVisitante: "${emailVisitante}"){
//     Oid
//   }
// }`;

// const mutationDeleteFavoriteAviso = (OidAviso, emailVisitante): any => gql`
// mutation {
//   deleteFavoriteAviso(OidAviso: "${OidAviso}", EmailVisitante: "${emailVisitante}"){
//     Oid
//   }
// }`;

// const mutationAddFavoriteInfographic = (
//   OidInfografia,
//   emailVisitante
// ): any => gql`
// mutation {
//   addFavoriteInfographic(OidInfographic: "${OidInfografia}", EmailVisitante: "${emailVisitante}"){
//     Oid
//   }
// }`;
// const mutationDeleteFavoriteInfographic = (
//   OidInfografia: any,
//   emailVisitante: string
// ): any => gql`
// mutation {
//   deleteFavoriteInfographic(OidInfographic: "${OidInfografia}", EmailVisitante: "${emailVisitante}"){
//     Oid
//   }
// }`;

// // KYBC.GraphQL Queries definitions.
// const infographicPreviewQuery = gql`
//   query infographicPreview($Oid: ID!) {
//     infographicPreview(Oid: $Oid) {
//       Oid
//       Titulo
//       Descripcion
//       Votos

//       PreviewBase64
//       Usuario
//       Nombre
//       PrimerApellido
//       SegundoApellido
//       FechaSubida
//       Categoria
//       Visualizaciones
//     }
//   }
// `;
// const modalInfographicQuery = gql`
//   query modalInfographic($Oid: ID!) {
//     modalInfographic(Oid: $Oid) {
//       ImageBase64
//     }
//   }
// `;

// const infographicPreviewQueryByCategory = gql`
//   query allInfographicsByCategory($CategoriaOid: ID!) {
//     allInfographicsByCategory(CategoriaOid: $CategoriaOid) {
//       Oid
//       Titulo
//       Descripcion
//       Votos
//       Estrellas
//       ThumbnailBase64
//       Usuario
//       Nombre
//       PrimerApellido
//       SegundoApellido
//       FechaSubida
//       Categoria
//       Visualizaciones
//     }
//   }
// `;

// const AddViewToInfographicMutation = gql`
//   mutation addViewToInfographic($Oid: ID!) {
//     addViewToInfographic(Oid: $Oid) {
//       Oid
//     }
//   }
// `;

// export interface DialogData {
//   modalInfographic: string;
//   modalInfographicBase64: string;
// }
//>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>Página Principal<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
@Component({
  selector: 'app-app-transparencia',
  templateUrl: './transparencia.component.html',
  styleUrls: ['./transparencia.component.scss'],
})
export class TransparenciaComponent implements OnInit {
  // // tslint:disable-next-line: radix
  // // anio: number = parseInt(this.datePipe.transform(Date.now(), 'y'));
  // // anios: number[] = [
  // //   this.anio,
  // //   this.anio - 1,
  // //   this.anio - 2,
  // //   this.anio - 3,
  // //   this.anio - 4,
  // //   this.anio - 5,
  // // ];
  // avisosPriv: AvisoPrivacidad[];
  // infographicCategories: any[];
  // favoriteAvisosPrivacidad: AvisoPrivacidad2[];
  // // visitanteEmail = '';
  // private querySubscription: any;

  // infographics: any;
  // loadingCategories = true;
  // loadingInfographics = true;
  // error: any;
  // sub: any;
  // subFavoriteInfographics: any;
  // subInfographicsTrans: any;
  // cookieValue: any = 'UNKNOWN';

  // previewInfographic: any;
  // votes: any;

  // loggedIn = false;
  // consultasData: any;
  // comtra: ComiteTransparencia[];
  // consultasatendidas: ConsultasAtendidas[];
  // databi: DatosAbiertos[];
  // traproa: TransparenciaProactiva[];
  // traprob: TransparenciaProactiva[];
  // traproc: TransparenciaProactiva[];
  // prefreid: PreguntaFrecuente[];
  // prefrepa: PreguntaFrecuente[];
  // prefrerl: PreguntaFrecuente[];
  // prefretp: PreguntaFrecuente[];
  // oblitra: ObligacionTransparencia[];
  // subAvisoPrivacidad: any;
  // subFavoriteAvisoPrivacidad: any;
  // subComiteTransparencia: any;
  // subDatosAbiertos: any;
  // subTransparenciaProactivaa: any;
  // subTransparenciaProactivab: any;
  // subTransparenciaProactivac: any;
  // subPreguntaFrecuenteID: any;
  // subPreguntaFrecuentePA: any;
  // subPreguntaFrecuenteRL: any;
  // subPreguntaFrecuenteTP: any;
  // subConsultasAtendidas: any;
  // subObligacionTransparencia: any;

  //Rodrigo
  visitas: any;
  public categoria: string;

  private subBannerInformativoTransparencia: any;
  bannerInformativoTransparencia: BannerInformativoTransparencia[];
  obligacionesTransparencia: ObligacionesTransparencia[] = [];
  private subcomiteIntegrantes: any;
  comiteIntegrantes: ComiteTransparencia[];
  private subindiceExpedienteReservado: any;
  indiceExpedienteReservado: ExpedienteReservado[] = [];
  indiceExpedienteReservadoAnio: any[] = [];
  selectedTabUT = 0;
  private subInformeTransparencia: any;
  informestrans: InformeTransparencia[] = [];
  informestransAnio: any[] = [];

  configuracion:any={};
  capacitacionesTransparenciaManual: CapacitacionTransparencia[] = [];
  capacitacionesTransparenciaPresentacion: CapacitacionTransparencia[] = [];
  disposicionMateria: any[] = [];
  convenioMateriaTransparencia: ConvenioMateriaTransparencia[] = [];

  buscaConsultasFormGroup = new FormGroup({
    palabraClaveForm: new FormControl(''),
    anioForm: new FormControl(''),
    mesForm: new FormControl(''),
  });

  private subEstradoElectronicoTransparencia: any;
  estradoElectronicoTransparencia: EstradoElectronicoTransparencia[] = [];
  private subEnlacesInteres: any;
  enlint: EnlacesInteres[];

  selectedTabPdp = 0;
  private querySub: any;
  private oid = 0;
  loading = false;

  infografias: any[] = [];

  // emailVisitante = '';

  // p = 1;
  // collection = [];
  // config: any;
  // PalabraClave: any = '';
  // FechaSolicitud: any = '';
  // public keyword = 'Consulta';
  // data = [
  //   {
  //     id: 1,
  //     name: '¿cuánto gana un diputado?',
  //   },
  //   {
  //     id: 2,
  //     name: 'Inglaterra',
  //   },
  // ];

  // infoTransparencia: any;
  // categ: any[];
  // catego: any[];
  // modalInfographicFromDB: unknown;
  // activeLinkPdp = 'organigrama';
  // hoy = Date.now().toString();

  // public organigramaArea: TrabajadorArea[] = [];
  // public organigramaCargado = false;
  constructor(
    private _snackBar: MatSnackBar,
    public dialog: MatDialog,
    private _router: Router,
    private apollo: Apollo,
    private route: ActivatedRoute,
    private _buscarConsultasService: ConsultasTransparenciaService,
    private router: Router,
    private _capacitacionTransparenciaService: CapacitacionTransparenciaService,
    private disposicionesMateria: DisposicionesMateriaService,
    private _trabajadorServiceOrganigrama: TrabajadorService,
    private api: GraphqlService
  ) {}

  ngOnInit(): void {
    this.addViewToPage('c8276148-4d5e-4bb9-b0b2-b493acfd44df');
    // this.querySub = this.route.queryParams.subscribe((params) => {
    //   // Defaults to 0 if no query param provided.

    //   this.categoria =
    //     params.categoria || '2a4ed0df-388a-4e64-9c32-70fa3ed68dbc';
    //   // this.oidpadre = this.oid;
    //   // console.log('llegó oidCategoria', this.categoria);
    // });
    // if (this.categoria !== '2a4ed0df-388a-4e64-9c32-70fa3ed68dbc') {
    //   this.fetchInfographicsThumbnailsByCategory(this.categoria);
    //   this.verifyVisitantSession();
    //   // console.log('entro al if  que trae infografias transparencia');
    // } else {
    //   this.verifyVisitantSession();
    //   this.fetchAllInfographicCategories();
    //   this.fetchAllInfographicsThumbnailsFromDB();
    // }

    // document.getElementById("defaultOpen").click();
    this.querySub = this.route.queryParams.subscribe((params) => {
      // Defaults to 0 if no query param provided.
      this.oid = params.oid || 0;
      // console.log('llegó oid', this.oid);
    });

    this.CargarBanners();
    this.ObligacionesTransparencia();
    this.ComiteTransparencia();
    this.UnidadTransparencia();
    this.EstadosElectronicos();
    this.SitiosInteres();
    this.InfografiasTransparencia();

    // this.subDatosAbiertos = this.apollo
    //   .watchQuery<ResponseDatosAbiertos>({
    //     query: gql`
    //       {
    //         allDatosAbiertos {
    //           Nombre
    //           URL
    //           ImageBase64
    //         }
    //       }
    //     `,
    //   })
    //   .valueChanges.subscribe((result) => {
    //     this.databi = result.data && result.data.allDatosAbiertos;
    //     this.loading = result.loading;
    //   });
    // this.subTransparenciaProactivaa = this.apollo
    //   .watchQuery<ResponseTransparenciaProactiva>({
    //     query: gql`
    //       {
    //         allTransparenciaProactivaa {
    //           Nombre
    //           URL
    //           Video
    //           ImageBase64
    //           Pagina
    //         }
    //       }
    //     `,
    //   })
    //   .valueChanges.subscribe((result) => {
    //     this.traproa = result.data && result.data.allTransparenciaProactivaa;
    //     this.loading = result.loading;
    //   });
    // this.subTransparenciaProactivab = this.apollo
    //   .watchQuery<ResponseTransparenciaProactiva>({
    //     query: gql`
    //       {
    //         allTransparenciaProactivab {
    //           Nombre
    //           URL
    //           Video
    //           ImageBase64
    //           Pagina
    //         }
    //       }
    //     `,
    //   })
    //   .valueChanges.subscribe((result) => {
    //     this.traprob = result.data && result.data.allTransparenciaProactivab;
    //     this.loading = result.loading;
    //   });
    // this.subTransparenciaProactivac = this.apollo
    //   .watchQuery<ResponseTransparenciaProactiva>({
    //     query: gql`
    //       {
    //         allTransparenciaProactivac {
    //           Nombre
    //           URL
    //           Video
    //           ImageBase64
    //           Pagina
    //         }
    //       }
    //     `,
    //   })
    //   .valueChanges.subscribe((result) => {
    //     this.traproc = result.data && result.data.allTransparenciaProactivac;
    //     this.loading = result.loading;
    //   });
    // this.subPreguntaFrecuenteID = this.apollo
    //   .watchQuery<ResponsePreguntaFrecuente>({
    //     query: gql`
    //       {
    //         allPreguntasFrecuentesID {
    //           Pregunta
    //           Respuesta
    //           Nombre
    //           NombreMostrar
    //           Link
    //         }
    //       }
    //     `,
    //   })
    //   .valueChanges.subscribe((result) => {
    //     this.prefreid = result.data && result.data.allPreguntasFrecuentesID;
    //     this.loading = result.loading;
    //   });
    // this.subPreguntaFrecuentePA = this.apollo
    //   .watchQuery<ResponsePreguntaFrecuente>({
    //     query: gql`
    //       {
    //         allPreguntasFrecuentesPA {
    //           Pregunta
    //           Respuesta
    //           Nombre
    //           NombreMostrar
    //           Link
    //         }
    //       }
    //     `,
    //   })
    //   .valueChanges.subscribe((result) => {
    //     this.prefrepa = result.data && result.data.allPreguntasFrecuentesPA;
    //     this.loading = result.loading;
    //   });
    // this.subPreguntaFrecuenteRL = this.apollo
    //   .watchQuery<ResponsePreguntaFrecuente>({
    //     query: gql`
    //       {
    //         allPreguntasFrecuentesRL {
    //           Pregunta
    //           Respuesta
    //           Nombre
    //           NombreMostrar
    //           Link
    //         }
    //       }
    //     `,
    //   })
    //   .valueChanges.subscribe((result) => {
    //     this.prefrerl = result.data && result.data.allPreguntasFrecuentesRL;
    //     this.loading = result.loading;
    //   });
    // this.subPreguntaFrecuenteTP = this.apollo
    //   .watchQuery<ResponsePreguntaFrecuente>({
    //     query: gql`
    //       {
    //         allPreguntasFrecuentesTP {
    //           Pregunta
    //           Respuesta
    //           Nombre
    //           NombreMostrar
    //           Link
    //         }
    //       }
    //     `,
    //   })
    //   .valueChanges.subscribe((result) => {
    //     this.prefretp = result.data && result.data.allPreguntasFrecuentesTP;
    //     this.loading = result.loading;
    //   });
    // this.subObligacionTransparencia = this.apollo
    //   .watchQuery<ResponseObligacionTransparencia>({
    //     query: gql`
    //       {
    //         allObligacionesTransparencia {
    //           Oid
    //           NombreObligacion
    //           Orden
    //           ImagenB64
    //         }
    //       }
    //     `,
    //   })
    //   .valueChanges.subscribe((result) => {
    //     this.oblitra = result.data && result.data.allObligacionesTransparencia;
    //     this.loading = result.loading;
    //   });

    // // this.verifyVisitantSession();

    // this.subAvisoPrivacidad = this.apollo
    //   .watchQuery<ResponseAvisoPrivacidad>({
    //     query: gql`
    //       {
    //         allAvisosPrivacidad {
    //           Oid
    //           Nombre
    //           NombreCompleto
    //           NombreCorto
    //           URLDocumento
    //           FileName
    //           size
    //           Area
    //         }
    //       }
    //     `,
    //   })
    //   .valueChanges.subscribe((result) => {
    //     this.avisosPriv = result.data && result.data.allAvisosPrivacidad;
    //     this.loading = result.loading;
    //   });
  }
  //Visitas
  //Agregar Visita a la Pagina
  addViewToPage(Oid: any): any {
    this.api.addViewToPage(Oid).subscribe(
      ({ data }: any) => {
        this.visitas = data.addView.Visitas;
      },
      (error) => {
        console.log(
          '::::::::::Error al aumentar las visualizaciones de la infografía: ',
          error
        );
      }
    );
  }

  CargarBanners() {
    this.apollo
      .watchQuery<ResponseBannerInformativoTransparencia>({
        query: gql`
          {
            allBannerInformativoTransparencia {
              Oid
              Orden
              Titulo
              UrlImagen
              UrlDestino
              Mensaje
            }
          }
        `,
      })
      .valueChanges.subscribe((result) => {
        this.bannerInformativoTransparencia =
          result.data && result.data.allBannerInformativoTransparencia;
        this.loading = result.loading;
      });
  }
  //Obligaciones
  ObligacionesTransparencia() {
    this.apollo
      .watchQuery<any>({
        query: gql`
          {
            allObligacionesTransparencia {
              Oid
              Orden
              NombreObligacion
              ImagenB64
              Articulos {
                Oid
                Nombre
                Archivos {
                  OID
                  Nombre
                  Descripcion
                  Fraccion
                  URLDocumento
                }
              }
            }
          }
        `,
      })
      .valueChanges.subscribe((result) => {
        this.obligacionesTransparencia =
          result.data && result.data.allObligacionesTransparencia;
        this.loading = result.loading;
      });
  }

  //Models de Obligaciones

  openDialogObligaciones(tipo: number): any {
    this.dialog.open(ObligacionTransparenciaModalComponent, {
      data: this.obligacionesTransparencia[tipo],
    });
  }

  //Comité de Transparencia
  ComiteTransparencia() {
    //Integrantes
    this.subcomiteIntegrantes = this.apollo
      .watchQuery<ResponseComiteTransparencia>({
        query: gql`
          {
            allComiteTransparencia {
              Nombre
              ImageBase64
              Cargo
              Puesto
              Correo
            }
          }
        `,
      })
      .valueChanges.subscribe((result) => {
        this.comiteIntegrantes =
          result.data && result.data.allComiteTransparencia;
        this.loading = result.loading;
      });
    //Índice de Expedientes Reservados
    this.subindiceExpedienteReservado = this.apollo
      .watchQuery<any>({
        query: gql`
          {
            allExpedientesReservados {
              Anio
              Semestre
              NombreArchivo
              URLDocumento
            }
          }
        `,
      })
      .valueChanges.subscribe((result) => {
        this.indiceExpedienteReservado =
          result.data && result.data.allExpedientesReservados;
        this.indiceExpedienteReservadoAnio = this.indiceExpedienteReservado.filter(
          (thing, i, arr) => arr.findIndex((t) => t.Anio === thing.Anio) === i
        );
        this.indiceExpedienteReservadoAnio.forEach((a) => {
          a.Documentos = this.indiceExpedienteReservado.filter(
            (i) => i.Anio === a.Anio && i.URLDocumento != ''
          );
        });
        this.loading = result.loading;
      });
  }
  //Unidad de Transparencia

  tabChanged = (tabChangeEvent: MatTabChangeEvent): void => {
    // console.log('tabChangeEvent => ', tabChangeEvent);
    // console.log('index => ', tabChangeEvent.index);
    if (tabChangeEvent.index !== 1) {
      this.selectedTabPdp = tabChangeEvent.index;
    } else {
      this.selectedTabPdp = tabChangeEvent.index;
    }
  };

  UnidadTransparencia() {
    //Directorio = app-directorio-transp
    //Datos de Contacto
    this._buscarConsultasService.getConfiguracion().subscribe((data) => {
      this.configuracion = data;
    });
    //Informes de Transparencia
    this.subInformeTransparencia = this.apollo
      .watchQuery<ResponseInformeTransparencia>({
        query: gql`
          {
            allInformesTransparencia {
              Oid
              Anio
              Tipo
              Nombre
              Descripcion
              URLDocumento
            }
          }
        `,
      })
      .valueChanges.subscribe((result) => {
        this.informestrans =
          result.data && result.data.allInformesTransparencia;
        this.informestransAnio = this.informestrans.filter(
          (thing, i, arr) => arr.findIndex((t) => t.Anio === thing.Anio) === i
        );
        this.informestransAnio.forEach((a) => {
          a.Documentos = this.informestrans.filter((i) => i.Anio === a.Anio);
        });
        this.loading = result.loading;
      });

    //Acuerdo de Costos de Reproducción en duro
    //Disposiciones en la Materia
    
    this.disposicionesMateria
      .allDisposicionMateria()
      .valueChanges.subscribe((result) => {
        this.disposicionMateria =
          result.data && result.data.allDisposicionMateria;
      });

    //Días Inhábiles en duro
    //Capacitaciones

    this._capacitacionTransparenciaService
      .getCapacitacionesTransparenciaTipo(0)
      .valueChanges.subscribe((result) => {
        this.capacitacionesTransparenciaManual =
          result.data && result.data.allCapacitacionesTransparenciaTipo;
        this.capacitacionesTransparenciaManual.forEach((element) => {
          element.Extension = element.NombreCompleto
            ? element.NombreCompleto.split('.')[1]
            : element.NombreCompleto;
        });
      });

    this._capacitacionTransparenciaService
      .getCapacitacionesTransparenciaTipo(1)
      .valueChanges.subscribe((result) => {
        this.capacitacionesTransparenciaPresentacion =
          result.data && result.data.allCapacitacionesTransparenciaTipo;
        this.capacitacionesTransparenciaPresentacion.forEach((element) => {
          element.Extension = element.NombreCompleto
            ? element.NombreCompleto.split('.')[1]
            : element.NombreCompleto;
        });
      });

    //Convenios en materia de transparencia
    this.apollo
      .watchQuery<ResponseConvenioMateriaTransparencia>({
        query: gql`
          {
            allMateriaTransparencia {
              Oid
              Orden
              Nombre
              Descripcion
              UrlDocumento
            }
          }
        `,
      })
      .valueChanges.subscribe((result) => {
        this.convenioMateriaTransparencia =
          result.data && result.data.allMateriaTransparencia;
        this.loading = result.loading;
      });
  }

  //Preguntas Frecuentes
  // openDialogPregFrec(): any {
  //   this.dialog.open(InfoPreguntasFrecComponent, {
  //     width: '80%',
  //     height: '100%',
  //   });
  // }

  //Solicitud de Información
  buscarConsultas(): any {
    // localStorage.setItem(
    //   'PalabraClave',
    //   this.buscaConsultasFormGroup.value.palabraClaveForm
    // );
    // localStorage.setItem('anio', this.buscaConsultasFormGroup.value.anioForm);
    // localStorage.setItem('mes', this.buscaConsultasFormGroup.value.mesForm);
    if (
      (this.buscaConsultasFormGroup.value.anioForm !== '' &&
        this.buscaConsultasFormGroup.value.anioForm !== 0 &&
        this.buscaConsultasFormGroup.value.anioForm) ||
      (this.buscaConsultasFormGroup.value.mesForm !== '' &&
        this.buscaConsultasFormGroup.value.mesForm) ||
      (this.buscaConsultasFormGroup.value.palabraClaveForm &&
        this.buscaConsultasFormGroup.value.palabraClaveForm !== '')
    ) {
      this._router
        .navigate([
          '/transparencia/historialConsultas',
          {
            anio: this.buscaConsultasFormGroup.value.anioForm,
            mes: this.buscaConsultasFormGroup.value.mesForm,
            palabraClave: this.buscaConsultasFormGroup.value.palabraClaveForm,
          },
        ])
        .then((e) => {
          if (e) {
            console.log('Navegación Fallida', e);
          } else {
            // console.log('Navegación Exitosa', e);
          }
        });
    } else {
      const verticalPosition: MatSnackBarVerticalPosition = 'top';
      this._snackBar.open(
        'Debe ingresar al menos una opción del formulario.',
        'Aceptar',
        {
          duration: 3000,
          verticalPosition,
        }
      );
    }
  }

  //Estrados Electrónicos
  EstadosElectronicos() {
    this.subEstradoElectronicoTransparencia = this.apollo
      .watchQuery<ResponseEstradoElectronicoTransparencia>({
        query: gql`
          {
            allEstradoElectronicoTransparencia {
              Oid
              Orden
              NP
              FolioSolicitud
              NExperidente
              UrlDocumento
            }
          }
        `,
      })
      .valueChanges.subscribe((result) => {
        this.estradoElectronicoTransparencia =
          result.data && result.data.allEstradoElectronicoTransparencia;
        this.loading = result.loading;
      });
  }

  SitiosInteres() {
    this.subEnlacesInteres = this.apollo
      .watchQuery<ResponseEnlacesInteres>({
        query: gql`
          {
            allEnlacesInteresTransparencia {
              Nombre
              URL
              ImageBase64
            }
          }
        `,
      })
      .valueChanges.subscribe((result) => {
        this.enlint = result.data && result.data.allEnlacesInteresTransparencia;
        this.loading = result.loading;
      });
  }

  InfografiasTransparencia() {
    this.apollo
      .watchQuery<any>({
        query: gql`
          {
            allInfografiasTransparencia {
              Oid
              Nombre
              Descripcion
              UrlArchivo
              UrlImagen
            }
          }
        `,
      })
      .valueChanges.subscribe((result) => {
        this.infografias =
          result.data && result.data.allInfografiasTransparencia;
        this.loading = result.loading;
      });
  }
}
//>>>>>>>Modal de Obligacion Transparencia<<<<<<<<<<<<<<<<//

@Component({
  selector: 'app-obligacion-transparencia-modal',
  templateUrl: 'modales/obligacion-transparencia-modal.html',
  styles: [
    `
      .boton {
        background-color: #183c34;
        color: white;
        padding: 5px 20px;
        font-size: 13px;
        border-radius: 5px;
        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
          0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
      }
    `,
  ],
})
export class ObligacionTransparenciaModalComponent implements OnInit {
  loading = false;
  error: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ObligacionTransparencia,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<ObligacionTransparenciaModalComponent>
  ) {}

  ngOnInit(): any {
    if (this.data) {
      this.loading = true;
    }
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}
//>>>>>>>FIN Modal de Obligacion Transparencia<<<<<<<<<<<<<<<<//
//>>>>>>>>>>INFOGRAFIAS<<<<<<<<<<<<<<<<<<<<<//

const infographicPreviewQuery = gql`
  query infographicPreview($Oid: ID!) {
    infographicPreview(Oid: $Oid) {
      Oid
      Titulo
      Descripcion
      Votos
      UrlImagenCompleta
      Usuario
      Nombre
      PrimerApellido
      SegundoApellido
      FechaSubida
      Categoria
      Visualizaciones
    }
  }
`;
@Component({
  selector: 'app-infografia',
  templateUrl: 'modales/infografia.html',
  styles: [
    `
      .boton {
        background-color: #183c34;
        color: white;
        padding: 5px 20px;
        font-size: 13px;
        border-radius: 5px;
        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
          0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
      }
    `,
  ],
})
export class ModalInfografia {
  sub: any;
  infographics: any;
  catego: Infographic;
  infoTransparencia: any;
  loadingInfographics: boolean;
  error: any;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    private apollo: Apollo,
    // private route: ActivatedRoute,
    // private router: Router,
    public dialogRef: MatDialogRef<ModalInfografia>
  ) {
    this.fetchAllInfographicsThumbnailsFromDB();
  }

  // KYBC.Function for fetch all infographic previews from DB.
  fetchAllInfographicsThumbnailsFromDB(): any {
    const Oid = this.data.legis;
    this.sub = this.apollo
      .watchQuery<Response>({
        query: infographicPreviewQuery,
        variables: {
          Oid,
        },
      })
      .valueChanges.subscribe((result) => {
        this.infographics = result.data.infographicPreview;

        // this.catego = this.infographics.filter((aviso) =>
        //   aviso.Categoria.includes('Datos Personales')
        // );

        // console.log('CATEGO:', this.catego);

        this.loadingInfographics = result.loading;
        this.error = result.errors;
      });
  }

  closeDialog(): any {
    this.dialogRef.close();
  }
}
//>>>>>>>>>>>>>>>>>>>>FIN Infografias<<<<<<<<<<<<<<<<<<<<<<<<<<<<//

//   setStep(index: number) {
//     this.selectedTabPdp = 0;
//   }

//   organigramaTransparencia() {
//     this._trabajadorServiceOrganigrama
//       .selectTrabajadoresArea('562df02c-6eea-490a-b9ac-87f371e5e87e')
//       .refetch()
//       .then((data) => {
//         this.organigramaArea = data.data.selectTrabajadoresArea;
//         this.organigramaCargado = true;
//       });
//   }
//   isFavorite(oid): any {
//     return this.favoriteAvisosPrivacidad.find((aviso) => aviso.Oid === oid);
//   }
//   addFavoriteAviso(OidAviso): any {
//     // .log(`Llego oid ${OidAviso}`);
//     this.apollo
//       .mutate({
//         mutation: mutationAddFavoriteAviso(OidAviso, this.emailVisitante),
//       })
//       .subscribe(
//         ({ data }) => {
//           const newFavAviso: AvisoPrivacidad2 = { Oid: OidAviso };
//           this.favoriteAvisosPrivacidad.push(newFavAviso);

//           alert('se ha guardado el aviso en favoritos de manera exitosa!');
//         },
//         (error) => {
//           alert('hubo un error al guardar en favoritos, intente de nuevo');
//           console.log(error);
//         }
//       );
//   }

//   deleteFavoriteAviso(OidAviso): any {
//     this.apollo
//       .mutate({
//         mutation: mutationDeleteFavoriteAviso(OidAviso, this.emailVisitante),
//       })
//       .subscribe(
//         ({ data }) => {
//           this.favoriteAvisosPrivacidad = this.favoriteAvisosPrivacidad.filter(
//             (aviso) => aviso.Oid !== OidAviso
//           );
//           alert('se ha borrado el aviso en favoritos de manera exitosa!');
//         },
//         (error) => {
//           alert('hubo un error al borrar de favoritos, intente de nuevo');
//           console.log(error);
//         }
//       );
//   }

//   // KYBC.Function for fetch all infographic previews from DB.
//   fetchAllInfographicsThumbnailsFromDB(): any {
//     this.sub = this.apollo
//       .watchQuery<Response>({
//         query: gql`
//           {
//             allInfographicsThumbnails {
//               Oid
//               Titulo
//               Descripcion
//               Votos
//               Estrellas
//               ThumbnailBase64
//               Usuario
//               Nombre
//               PrimerApellido
//               SegundoApellido
//               FechaSubida
//               Categoria
//               Visualizaciones
//             }
//           }
//         `,
//       })
//       .valueChanges.subscribe((result) => {
//         this.infographics =
//           result.data && result.data.allInfographicsThumbnails;

//         this.catego = this.infographics.filter((aviso) =>
//           aviso.Categoria.includes('Transparencia')
//         );
//         this.infoTransparencia = this.chunkArray([...this.catego], 3);
//         this.setPreviewInfographic(this.catego[0].Oid);
//         // console.log('info: ', this.infoTransparencia);
//         this.loadingInfographics = result.loading;
//         this.error = result.errors;
//       });
//   }

//   chunkArray(arr: Array<any>, n: number) {
//     return arr
//       .slice(0, (arr.length + n - 1) / n || 0)
//       .map((c, i) => arr.slice(n * i, n * i + n));
//   }

//   // KYBC.Function for fetch all infographic categories from DB.
//   fetchAllInfographicCategories(): any {
//     this.sub = this.apollo
//       .watchQuery<Response>({
//         query: gql`
//           {
//             allInfographicCategories {
//               Oid
//               Nombre
//               ImageBase64
//             }
//           }
//         `,
//       })
//       .valueChanges.subscribe((result) => {
//         // console.log('::::::::::allInfographicCategories Result \n', result); // KYBC.Borrar.
//         this.infographicCategories =
//           result.data && result.data.allInfographicCategories;
//         // console.log(
//         // '::::::::::allInfographicCategories from DBasdf: \n',
//         // this.infographicCategories
//         // ); // KYBC.Borrar.
//         this.loadingCategories = result.loading;
//         this.error = result.errors;
//       });
//   }

//   setPreviewInfographic(Oid: string): any {
//     this.querySubscription = this.apollo
//       .watchQuery({
//         query: infographicPreviewQuery,
//         variables: {
//           Oid,
//         },
//       })
//       .valueChanges.subscribe(({ data }) => {
//         this.previewInfographic = data;
//         this.previewInfographic = this.previewInfographic.infographicPreview;
//         this.addViewToInfographic(this.previewInfographic.Oid);
//       });
//     window.scrollTo(0, 0);
//   }

//   addViewToInfographic(Oid: any): any {
//     this.apollo
//       .mutate({
//         mutation: AddViewToInfographicMutation,
//         variables: {
//           Oid,
//         },
//       })
//       .subscribe(
//         ({ data }) => {
//           // console.log(
//           //   '::::::::::AddViewToInfographicMutation Data from DB: ',
//           //   data
//           // ); // KYBC.Borrar.
//         },
//         (error) => {
//           console.log(
//             '::::::::::Error al aumentar las visualizaciones de la infografía: ',
//             error
//           );
//         }
//       );
//   }

//   // buscarConsultasInicial() {
//   //   localStorage.setItem(
//   //     'Palabra_clave',
//   //     this.buscaConsultasFormGroup.value.palabraClaveForm
//   //   );
//   //   localStorage.setItem(
//   //     'Fecha_solicitud',
//   //     this.buscaConsultasFormGroup.value.fechaForm
//   //   );
//   //   this.PalabraClave = localStorage.getItem('Palabra_clave');
//   //   this.FechaSolicitud = localStorage.getItem('Fecha_solicitud');
//   //   if (this.PalabraClave === '') {
//   //     this.PalabraClave = null;
//   //   } else if (this.FechaSolicitud === '') {
//   //     console.log(':::::::: la fecha viene vacía');
//   //     this.FechaSolicitud = 'fecha vacía';
//   //   }
//   // this.PalabraClave = localStorage.getItem('Palabra_clave');
//   // this.FechaSolicitud = localStorage.getItem('Fecha_solicitud');
//   // if (this.PalabraClave != null && this.FechaSolicitud == null) {
//   //   this.subConsultasAtendidas = this.apollo
//   //     .watchQuery<ResponseConsultasAtendidas>({
//   //       query: gql`
//   //       {
//   //         allConsultasAtendidas (PalabraClave: "${this.PalabraClave}") {
//   //           Pregunta
//   //           Respuesta
//   //         }
//   //       }
//   //     `,
//   //     })
//   //     .valueChanges.subscribe((result) => {
//   //       this.consultasatendidas =
//   //         result.data && result.data.allConsultasAtendidas;
//   //       this.loading = result.loading;
//   //     });
//   // } else if (this.FechaSolicitud != null && this.PalabraClave == null) {
//   //   console.log(':::::::::construiré la parte de fecha');
//   // }
//   // }

//   openLegislacionFederal(): any {
//     const dialogRef = this.dialog.open(LegislacionFederalComponent, {});
//   }

//   openCorpus(): any {
//     const dialogRef = this.dialog.open(CorpusComponent, {});
//   }

//   openInfografia(Oid: any): void {
//     // console.log('OdiInfografia trae: ', Oid);

//     const dialogRef = this.dialog.open(ModalInfografia, {
//       width: '70%',
//       height: '99%',
//       data: { legis: Oid },
//     });
//   }

//   openTab(tabName): void {
//     // Declare all variables
//     // tslint:disable-next-line: one-variable-per-declaration
//     let i: number, tabcontent, tablinks;

//     // Get all elements with class="tabcontent" and hide them
//     tabcontent = document.getElementsByClassName('tabcontent');
//     for (i = 0; i < tabcontent.length; i++) {
//       tabcontent[i].style.display = 'none';
//     }

//     // Get all elements with class="tablinks" and remove the class "active"
//     tablinks = document.getElementsByClassName('tablinks');
//     for (i = 0; i < tablinks.length; i++) {
//       tablinks[i].className = tablinks[i].className.replace(' active', '');
//     }

//     // Show the current tab, and add an "active" class to the link that opened the tab
//     document.getElementById(tabName).style.display = 'block';
//     // evt.currentTarget.className += " active";
//   }
// }

// @Component({
//   selector: 'app-imagen',
//   templateUrl: 'modales/imagen.html',
//   styles: [
//     `
//       .boton {
//         background-color: #183c34;
//         color: white;
//         padding: 5px 20px;
//         font-size: 13px;
//         border-radius: 5px;
//         box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
//           0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
//       }
//     `,
//   ],
// })
// export class Imagen {
//   constructor(
//     public dialog: MatDialog,
//     private apollo: Apollo,
//     private route: ActivatedRoute,
//     private router: Router,
//     public dialogRef: MatDialogRef<Imagen>
//   ) {}

//   closeDialog(): any {
//     this.dialogRef.close();
//   }
// }

// @Component({
//   selector: 'app-trabajo-parlamentario',
//   templateUrl: 'modales/trabajo-parlamentario.html',
//   styles: [
//     `
//       .boton {
//         background-color: #183c34;
//         color: white;
//         padding: 5px 20px;
//         font-size: 13px;
//         border-radius: 5px;
//         box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
//           0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
//       }
//     `,
//   ],
// })
// export class TrabajoParlamentarioComponent implements OnInit {
//   trapar72: ArchivoTransparencia[];
//   trapar70: ArchivoTransparencia[];

//   subTrabajoParlamentario72: any;
//   subTrabajoParlamentario70: any;
//   loading = false;
//   error: any;

//   constructor(
//     public dialog: MatDialog,
//     private apollo: Apollo,
//     public dialogRef: MatDialogRef<TrabajoParlamentarioComponent>
//   ) {}

//   ngOnInit(): any {
//     // document.getElementById("defaultOpen").click();
//     // console.log('fetching en trabajo parlamentario');
//     this.subTrabajoParlamentario72 = this.apollo
//       .watchQuery<ResponseArchivoTransparencia>({
//         query: gql`
//           {
//             allTrabajoParlamentario72 {
//               OID
//               ObligacionesTransparencia
//               Nombre
//               Descripcion
//               Articulo
//               Fraccion
//               URLDocumento
//             }
//           }
//         `,
//       })
//       .valueChanges.subscribe((result) => {
//         this.trapar72 = result.data && result.data.allTrabajoParlamentario72;
//         this.loading = result.loading;
//       });
//     this.subTrabajoParlamentario70 = this.apollo
//       .watchQuery<ResponseArchivoTransparencia>({
//         query: gql`
//           {
//             allTrabajoParlamentario70 {
//               OID
//               ObligacionesTransparencia
//               Nombre
//               Descripcion
//               Articulo
//               Fraccion
//               URLDocumento
//             }
//           }
//         `,
//       })
//       .valueChanges.subscribe((result) => {
//         this.trapar70 = result.data && result.data.allTrabajoParlamentario70;
//         this.loading = result.loading;
//       });
//   }

//   closeDialog(): void {
//     this.dialogRef.close();
//   }
// }

// @Component({
//   selector: 'app-normativa',
//   templateUrl: 'modales/normativa.html',
//   styles: [
//     `
//       .boton {
//         background-color: #183c34;
//         color: white;
//         padding: 5px 20px;
//         font-size: 13px;
//         border-radius: 5px;
//         box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
//           0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
//       }
//     `,
//   ],
// })
// export class Normativa {
//   normat70: ArchivoTransparencia[];

//   subNormativa70: any;
//   loading = false;
//   error: any;
//   constructor(
//     public dialog: MatDialog,
//     private apollo: Apollo,
//     private route: ActivatedRoute,
//     private router: Router,
//     public dialogRef: MatDialogRef<Normativa>
//   ) {}

//   // tslint:disable-next-line: use-lifecycle-interface
//   ngOnInit(): void {
//     // document.getElementById("defaultOpen").click();
//     this.subNormativa70 = this.apollo
//       .watchQuery<ResponseArchivoTransparencia>({
//         query: gql`
//           {
//             allNormativa70 {
//               OID
//               ObligacionesTransparencia
//               Nombre
//               Descripcion
//               Articulo
//               Fraccion
//               URLDocumento
//             }
//           }
//         `,
//       })
//       .valueChanges.subscribe((result) => {
//         this.normat70 = result.data && result.data.allNormativa70;
//         this.loading = result.loading;
//       });
//   }

//   closeDialog(): any {
//     this.dialogRef.close();
//   }
// }

// @Component({
//   selector: 'app-contrataciones',
//   templateUrl: 'modales/contrataciones.html',
//   styles: [
//     `
//       .boton {
//         background-color: #183c34;
//         color: white;
//         padding: 5px 20px;
//         font-size: 13px;
//         border-radius: 5px;
//         box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
//           0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
//       }
//     `,
//   ],
// })
// export class Contrataciones {
//   conpub70: ArchivoTransparencia[];

//   subContratacionesPublicas70: any;
//   loading = false;
//   error: any;
//   constructor(
//     public dialog: MatDialog,
//     private apollo: Apollo,
//     private route: ActivatedRoute,
//     private router: Router,
//     public dialogRef: MatDialogRef<Contrataciones>
//   ) {}

//   // tslint:disable-next-line: use-lifecycle-interface
//   ngOnInit(): any {
//     // document.getElementById("defaultOpen").click();
//     this.subContratacionesPublicas70 = this.apollo
//       .watchQuery<ResponseArchivoTransparencia>({
//         query: gql`
//           {
//             allContratacionesPublicas70 {
//               OID
//               ObligacionesTransparencia
//               Nombre
//               Descripcion
//               Articulo
//               Fraccion
//               URLDocumento
//             }
//           }
//         `,
//       })
//       .valueChanges.subscribe((result) => {
//         this.conpub70 = result.data && result.data.allContratacionesPublicas70;
//         this.loading = result.loading;
//       });
//   }

//   closeDialog(): void {
//     this.dialogRef.close();
//   }
// }

// @Component({
//   selector: 'app-faq',
//   templateUrl: 'modales/faq.html',
//   styles: [
//     `
//       .boton {
//         background-color: #183c34;
//         color: white;
//         padding: 5px 20px;
//         font-size: 13px;
//         border-radius: 5px;
//         box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
//           0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
//       }
//     `,
//   ],
// })
// export class Faq implements OnInit {
//   recom70: ArchivoTransparencia[];

//   subRecomendaciones70: any;
//   loading = false;
//   error: any;

//   constructor(
//     public dialog: MatDialog,
//     private apollo: Apollo,
//     private route: ActivatedRoute,
//     private router: Router,
//     public dialogRef: MatDialogRef<Faq>
//   ) {}

//   ngOnInit(): void {
//     // document.getElementById("defaultOpen").click();
//     this.subRecomendaciones70 = this.apollo
//       .watchQuery<ResponseArchivoTransparencia>({
//         query: gql`
//           {
//             allRecomendaciones70 {
//               OID
//               ObligacionesTransparencia
//               Nombre
//               Descripcion
//               Articulo
//               Fraccion
//               URLDocumento
//             }
//           }
//         `,
//       })
//       .valueChanges.subscribe((result) => {
//         this.recom70 = result.data && result.data.allRecomendaciones70;
//         this.loading = result.loading;
//       });
//   }

//   closeDialog(): any {
//     this.dialogRef.close();
//   }
// }

// @Component({
//   selector: 'app-indicadores-objetivos',
//   templateUrl: 'modales/indicadores-objetivos.html',
//   styles: [
//     `
//       .boton {
//         background-color: #183c34;
//         color: white;
//         padding: 5px 20px;
//         font-size: 13px;
//         border-radius: 5px;
//         box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
//           0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
//       }
//     `,
//   ],
// })
// export class IndicadoresObjetivos implements OnInit {
//   indobj70: ArchivoTransparencia[];

//   subIndicadoresObjetivos70: any;
//   loading = false;
//   error: any;
//   constructor(
//     public dialog: MatDialog,
//     private apollo: Apollo,
//     private route: ActivatedRoute,
//     private router: Router,
//     public dialogRef: MatDialogRef<IndicadoresObjetivos>
//   ) {}

//   ngOnInit(): any {
//     // document.getElementById("defaultOpen").click();
//     this.subIndicadoresObjetivos70 = this.apollo
//       .watchQuery<ResponseArchivoTransparencia>({
//         query: gql`
//           {
//             allIndicadoresObjetivos70 {
//               OID
//               ObligacionesTransparencia
//               Nombre
//               Descripcion
//               Articulo
//               Fraccion
//               URLDocumento
//             }
//           }
//         `,
//       })
//       .valueChanges.subscribe((result) => {
//         this.indobj70 = result.data && result.data.allIndicadoresObjetivos70;
//         this.loading = result.loading;
//       });
//   }

//   closeDialog(): any {
//     this.dialogRef.close();
//   }
// }

// @Component({
//   selector: 'app-informacion',
//   templateUrl: 'modales/informacion.html',
//   styles: [
//     `
//       .boton {
//         background-color: #183c34;
//         color: white;
//         padding: 5px 20px;
//         font-size: 13px;
//         border-radius: 5px;
//         box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
//           0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
//       }
//     `,
//   ],
// })
// export class Informacion {
//   infadm70: ArchivoTransparencia[];
//   infadm72: ArchivoTransparencia[];

//   subInformacionAdministrativa70: any;
//   subInformacionAdministrativa72: any;
//   loading = false;
//   error: any;
//   constructor(
//     public dialog: MatDialog,
//     private apollo: Apollo,
//     private route: ActivatedRoute,
//     private router: Router,
//     public dialogRef: MatDialogRef<Informacion>
//   ) {}

//   ngOnInit(): any {
//     // document.getElementById("defaultOpen").click();
//     this.subInformacionAdministrativa70 = this.apollo
//       .watchQuery<ResponseArchivoTransparencia>({
//         query: gql`
//           {
//             allInformacionAdministrativa70 {
//               OID
//               ObligacionesTransparencia
//               Nombre
//               Descripcion
//               Articulo
//               Fraccion
//               URLDocumento
//             }
//           }
//         `,
//       })
//       .valueChanges.subscribe((result) => {
//         this.infadm70 =
//           result.data && result.data.allInformacionAdministrativa70;
//         this.loading = result.loading;
//       });
//     this.subInformacionAdministrativa72 = this.apollo
//       .watchQuery<ResponseArchivoTransparencia>({
//         query: gql`
//           {
//             allInformacionAdministrativa72 {
//               OID
//               ObligacionesTransparencia
//               Nombre
//               Descripcion
//               Articulo
//               Fraccion
//               URLDocumento
//             }
//           }
//         `,
//       })
//       .valueChanges.subscribe((result) => {
//         this.infadm72 =
//           result.data && result.data.allInformacionAdministrativa72;
//         this.loading = result.loading;
//       });
//   }

//   closeDialog(): void {
//     this.dialogRef.close();
//   }
// }
// @Component({
//   selector: 'app-interes',
//   templateUrl: 'modales/interes.html',
//   styles: [
//     `
//       .boton {
//         background-color: #183c34;
//         color: white;
//         padding: 5px 20px;
//         font-size: 13px;
//         border-radius: 5px;
//         box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
//           0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
//       }
//     `,
//   ],
// })
// export class Interes {
//   intpub70: ArchivoTransparencia[];

//   subInformacionInteresPublico70: any;
//   loading = false;
//   error: any;
//   constructor(
//     public dialog: MatDialog,
//     private apollo: Apollo,
//     private route: ActivatedRoute,
//     private router: Router,
//     public dialogRef: MatDialogRef<Interes>
//   ) {}

//   ngOnInit(): any {
//     // document.getElementById("defaultOpen").click();
//     this.subInformacionInteresPublico70 = this.apollo
//       .watchQuery<ResponseArchivoTransparencia>({
//         query: gql`
//           {
//             allInformacionInteresPublico70 {
//               OID
//               ObligacionesTransparencia
//               Nombre
//               Descripcion
//               Articulo
//               Fraccion
//               URLDocumento
//             }
//           }
//         `,
//       })
//       .valueChanges.subscribe((result) => {
//         this.intpub70 =
//           result.data && result.data.allInformacionInteresPublico70;
//         this.loading = result.loading;
//       });
//   }

//   closeDialog(): any {
//     this.dialogRef.close();
//   }
// }

// @Component({
//   selector: 'app-presupuesto',
//   templateUrl: 'modales/presupuesto.html',
//   styles: [
//     `
//       .boton {
//         background-color: #183c34;
//         color: white;
//         padding: 5px 20px;
//         font-size: 13px;
//         border-radius: 5px;
//         box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
//           0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
//       }
//     `,
//   ],
// })
// export class Presupuesto {
//   infpre70: ArchivoTransparencia[];

//   subInfoPresupuestariaFinanciera70: any;
//   loading = false;
//   error: any;
//   constructor(
//     public dialog: MatDialog,
//     private apollo: Apollo,
//     private route: ActivatedRoute,
//     private router: Router,
//     public dialogRef: MatDialogRef<Presupuesto>
//   ) {}

//   ngOnInit(): any {
//     // document.getElementById("defaultOpen").click();
//     this.subInfoPresupuestariaFinanciera70 = this.apollo
//       .watchQuery<ResponseArchivoTransparencia>({
//         query: gql`
//           {
//             allInfoPresupuestariaFinanciera70 {
//               OID
//               ObligacionesTransparencia
//               Nombre
//               Descripcion
//               Articulo
//               Fraccion
//               URLDocumento
//             }
//           }
//         `,
//       })
//       .valueChanges.subscribe((result) => {
//         this.infpre70 =
//           result.data && result.data.allInfoPresupuestariaFinanciera70;
//         this.loading = result.loading;
//       });
//   }

//   closeDialog(): any {
//     this.dialogRef.close();
//   }
// }

// @Component({
//   selector: 'app-rendicion-cuentas',
//   templateUrl: 'modales/rendicion-cuentas.html',
//   styles: [
//     `
//       .boton {
//         background-color: #183c34;
//         color: white;
//         padding: 5px 20px;
//         font-size: 13px;
//         border-radius: 5px;
//         box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
//           0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
//       }
//     `,
//   ],
// })
// export class RendicionCuentas {
//   rencue70: ArchivoTransparencia[];
//   rencue72: ArchivoTransparencia[];

//   subRendicionCuentas70: any;
//   subRendicionCuentas72: any;
//   loading = false;
//   error: any;
//   constructor(
//     public dialog: MatDialog,
//     private apollo: Apollo,
//     private route: ActivatedRoute,
//     private router: Router,
//     public dialogRef: MatDialogRef<RendicionCuentas>
//   ) {}

//   ngOnInit(): any {
//     // document.getElementById("defaultOpen").click();
//     this.subRendicionCuentas70 = this.apollo
//       .watchQuery<ResponseArchivoTransparencia>({
//         query: gql`
//           {
//             allRendicionCuentas70 {
//               OID
//               ObligacionesTransparencia
//               Nombre
//               Descripcion
//               Articulo
//               Fraccion
//               URLDocumento
//             }
//           }
//         `,
//       })
//       .valueChanges.subscribe((result) => {
//         this.rencue70 = result.data && result.data.allRendicionCuentas70;
//         this.loading = result.loading;
//       });
//     this.subRendicionCuentas72 = this.apollo
//       .watchQuery<ResponseArchivoTransparencia>({
//         query: gql`
//           {
//             allRendicionCuentas72 {
//               OID
//               ObligacionesTransparencia
//               Nombre
//               Descripcion
//               Articulo
//               Fraccion
//               URLDocumento
//             }
//           }
//         `,
//       })
//       .valueChanges.subscribe((result) => {
//         this.rencue72 = result.data && result.data.allRendicionCuentas72;
//         this.loading = result.loading;
//       });
//   }

//   closeDialog(): any {
//     this.dialogRef.close();
//   }
// }

// @Component({
//   selector: 'app-responsabilidades',
//   templateUrl: 'modales/responsabilidades.html',
//   styles: [
//     `
//       .boton {
//         background-color: #183c34;
//         color: white;
//         padding: 5px 20px;
//         font-size: 13px;
//         border-radius: 5px;
//         box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
//           0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
//       }
//     `,
//   ],
// })
// export class Responsabilidades {
//   resadm70: ArchivoTransparencia[];

//   subResponsabilidadesAdministrativas70: any;
//   loading = false;
//   error: any;
//   constructor(
//     public dialog: MatDialog,
//     private apollo: Apollo,
//     private route: ActivatedRoute,
//     private router: Router,
//     public dialogRef: MatDialogRef<Responsabilidades>
//   ) {}

//   ngOnInit(): any {
//     // document.getElementById("defaultOpen").click();
//     this.subResponsabilidadesAdministrativas70 = this.apollo
//       .watchQuery<ResponseArchivoTransparencia>({
//         query: gql`
//           {
//             allResponsabilidadesAdministrativas70 {
//               OID
//               ObligacionesTransparencia
//               Nombre
//               Descripcion
//               Articulo
//               Fraccion
//               URLDocumento
//             }
//           }
//         `,
//       })
//       .valueChanges.subscribe((result) => {
//         this.resadm70 =
//           result.data && result.data.allResponsabilidadesAdministrativas70;
//         this.loading = result.loading;
//       });
//   }

//   closeDialog(): any {
//     this.dialogRef.close();
//   }
// }

// @Component({
//   selector: 'app-tramites',
//   templateUrl: 'modales/tramites.html',
//   styles: [
//     `
//       .boton {
//         background-color: #183c34;
//         color: white;
//         padding: 5px 20px;
//         font-size: 13px;
//         border-radius: 5px;
//         box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
//           0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
//       }
//     `,
//   ],
// })
// export class Tramites {
//   traser70: ArchivoTransparencia[];

//   subTramitesServicios70: any;
//   loading = false;
//   error: any;
//   constructor(
//     public dialog: MatDialog,
//     private apollo: Apollo,
//     private route: ActivatedRoute,
//     private router: Router,
//     public dialogRef: MatDialogRef<Tramites>
//   ) {}

//   ngOnInit(): any {
//     // document.getElementById("defaultOpen").click();
//     this.subTramitesServicios70 = this.apollo
//       .watchQuery<ResponseArchivoTransparencia>({
//         query: gql`
//           {
//             allTramitesServicios70 {
//               OID
//               ObligacionesTransparencia
//               Nombre
//               Descripcion
//               Articulo
//               Fraccion
//               URLDocumento
//             }
//           }
//         `,
//       })
//       .valueChanges.subscribe((result) => {
//         this.traser70 = result.data && result.data.allTramitesServicios70;
//         this.loading = result.loading;
//       });
//   }

//   closeDialog(): any {
//     this.dialogRef.close();
//   }
// }

// @Component({
//   selector: 'app-unidad',
//   templateUrl: 'modales/unidad.html',
//   styles: [
//     `
//       .boton {
//         background-color: #183c34;
//         color: white;
//         padding: 5px 20px;
//         font-size: 13px;
//         border-radius: 5px;
//         box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
//           0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
//       }
//     `,
//   ],
// })
// export class Unidad {
//   unitra70: ArchivoTransparencia[];
//   unitra80: ArchivoTransparencia[];

//   subUnidadTransparencia70: any;
//   subUnidadTransparencia80: any;
//   loading = false;
//   error: any;
//   constructor(
//     public dialog: MatDialog,
//     private apollo: Apollo,
//     private route: ActivatedRoute,
//     private router: Router,
//     public dialogRef: MatDialogRef<Unidad>
//   ) {}

//   ngOnInit(): any {
//     // document.getElementById("defaultOpen").click();
//     this.subUnidadTransparencia70 = this.apollo
//       .watchQuery<ResponseArchivoTransparencia>({
//         query: gql`
//           {
//             allUnidadTransparencia70 {
//               OID
//               ObligacionesTransparencia
//               Nombre
//               Descripcion
//               Articulo
//               Fraccion
//               URLDocumento
//             }
//           }
//         `,
//       })
//       .valueChanges.subscribe((result) => {
//         this.unitra70 = result.data && result.data.allUnidadTransparencia70;
//         this.loading = result.loading;
//       });
//     this.subUnidadTransparencia80 = this.apollo
//       .watchQuery<ResponseArchivoTransparencia>({
//         query: gql`
//           {
//             allUnidadTransparencia80 {
//               OID
//               ObligacionesTransparencia
//               Nombre
//               Descripcion
//               Articulo
//               Fraccion
//               URLDocumento
//             }
//           }
//         `,
//       })
//       .valueChanges.subscribe((result) => {
//         this.unitra80 = result.data && result.data.allUnidadTransparencia80;
//         this.loading = result.loading;
//       });
//   }

//   closeDialog(): any {
//     this.dialogRef.close();
//   }
// }
