import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { GraphqlService } from './graphql.service';
import { EventoRespuesta } from '../interfaces/evento-respuesta';
import gql from 'graphql-tag';

@Injectable({
  providedIn: 'root',
})
export class EventoService {
  constructor(
    private apollo: Apollo,
    private _graphqlService: GraphqlService
  ) {}

  getSubEvents(visitanteEmail: string): any {
    return this.apollo.watchQuery<EventoRespuesta>({
      query: gql`
          {
            allFavoriteEvents(EmailVisitante: "${visitanteEmail}") {
              Oid
              Nombre
              Descripcion
              FechaInicio
              imagedata
            }
          }
        `,
    });
  }

  deleteFavoriteEvent(Oid: string, emailVisitante: string): any {
    return this.apollo.mutate({
      mutation: this._graphqlService.mutationDeleteFavoriteEvent(
        Oid,
        emailVisitante
      ),
    });
  }
}
