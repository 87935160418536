import {
  Component,
  OnInit,
  Input,
  ElementRef,
  Output,
  EventEmitter,
} from '@angular/core';
import { ShareService } from '../../services/share.service';

@Component({
  selector: 'app-item-chart',
  templateUrl: './item-chart.component.html',
  styleUrls: ['./item-chart.component.scss'],
})
export class ItemChartComponent implements OnInit {
  @Input() data: any;
  @Output() loaded = new EventEmitter<boolean>();

  constructor(
    private _shareService: ShareService,
    private _elementRef: ElementRef
  ) {}

  ngOnInit(): void {
    this.addMainScripts();
  }

  addMainScripts() {
    const scripts = [
      { name: 'highcharts', src: 'https://code.highcharts.com/highcharts.js' },
      {
        name: 'highchartsItemSeries',
        src: 'https://code.highcharts.com/modules/item-series.js',
      },
      {
        name: 'highchartsExporting',
        src: 'https://code.highcharts.com/modules/exporting.js',
      },
      {
        name: 'highchartsExportData',
        src: 'https://code.highcharts.com/modules/export-data.js',
      },
      {
        name: 'highchartsAccessibility',
        src: 'https://code.highcharts.com/modules/accessibility.js',
      },
    ];
    this._shareService
      .addMultiplesLinkScripts(this._elementRef, scripts)
      .then(() => this.crearGrafica());
  }

  /* Formato para los items
     [
       ['Social Democratic Party', 153, '#EB001F', 'SPD'],
     ]

     Documentacion: https://api.highcharts.com/highcharts/labels.items, https://www.highcharts.com/demo/parliament-chart
 */
  crearGrafica() {
    this._shareService
      .addScript(
        this._elementRef,
        `
    Highcharts.chart('idChart', {
      chart: {
        type: 'item'
      },

      title: {
        text: ${JSON.stringify('Grupos Parlamentarios')}
      },

      legend: {
        labelFormat: '{name} <span style="opacity: 0.4">{y}</span>'
      },

      series: [{
        name: 'Integrantes',
        keys: ['name', 'y', 'color', 'label'],
        data: ${JSON.stringify(this.data.items)},
        dataLabels: {
          enabled: true,
          format: '{point.label}'
        },
        // Circular options
        center: ['50%', '88%'],
        size: '100%',
        startAngle: -100,
        endAngle: 100
      }]
    });`
      )
      .then(() => this.loaded.emit(true));
  }
}
