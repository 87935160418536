import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss']
})
export class CarouselComponent implements OnInit {

  public id: string = `demo${Math.floor((Math.random() * 100000) + 1)}`;

  constructor() { }

  ngOnInit(): void {
  }

}
