import { OnInit, Component, Inject } from '@angular/core';
import {
  LegislaturaLeyes,
  Law,
  ResponseLaws,
} from '../leyes-vigentes.interface';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'app-modal-regabrogadas',
  templateUrl: './modal-regabrogadas.component.html',
})
// tslint:disable-next-line: component-class-suffix
export class ModalReglamentosAbrogadasComponent implements OnInit {
  loading = false;
  item = {};
  error: any;
  LeyesFedAbrogadas: Law[];
  Legislatura: LegislaturaLeyes[];
  subLaws: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private apollo: Apollo,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<ModalReglamentosAbrogadasComponent>
  ) {}
  ngOnInit(): any {
    this.subLaws = this.apollo

      .watchQuery<ResponseLaws>({
        query: gql`
          {
            allLeyesFedAbrogadas {
              Oid
              Id
              Nombre
              NombreLey
              Version
              UltimaReforma
              MarcoJuridico
              Nuevo
              UltimaReforma
              Url
              UrlWord
              Notas
              UrlPdf
              UrlAndroid
              TipoLey
              PublicacionOriginal
              DescripcionLey
              LinkLey
            }
          }
        `,
      })
      .valueChanges.subscribe((result) => {
        this.LeyesFedAbrogadas =
          result.data && result.data.allLeyesFedAbrogadas;
        this.loading = result.loading;
        this.error = result.errors;
      });
  }
  closeDialog(): any {
    this.dialogRef.close();
  }
}
