import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ArchivoTransparencia } from '../pages/home/transparencia/transparencia.component.interface';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ConsultasTransparenciaService {
  constructor(private _httpClient: HttpClient, private apollo: Apollo) {}
  getArchivoTransparencia(oid: string = '') {
    return this.apollo
      .watchQuery<ArchivoTransparencia>({
        query: gql`
        query getArchivoTransparencia {
          getArchivoTransparencia(Oid: "${oid}") {
          OID
          Nombre
          ArchivoB64
        }
      }
      `,
        variables: {
          oid,
        },
        fetchPolicy: 'network-only',
      })
      .valueChanges.pipe(
        map((result: any) => {
          //  console.log('result');
          //  console.log(result);
          return result.data.getArchivoTransparencia;
        })
      );
  }
  getConfiguracion() {
    return this.apollo
      .watchQuery<any>({
        query: gql`
        query getConfiguracion {
          getConfiguracion{
          Oid
          Contacto
        }
      }
      `,
        fetchPolicy: 'network-only',
      })
      .valueChanges.pipe(
        map((result: any) => {
          return result.data.getConfiguracion;
        })
      );
  }
  obtenerConsultas(consultaJSONData: JSON): any {
    const año = consultaJSONData['anio'];
    const mes = consultaJSONData['mes'];
    const palabra = consultaJSONData['palabraClave'];
    if (año === '') {
      const anioNuevo = (consultaJSONData['anio'] = 0);
      // console.log('aquí va el código para transformar lo vacío a cero');
      // console.log(':::::el valor de anio nuevo es:', anioNuevo);
    } else {
      // console.log('no entro al if');
    }
    if (mes === '') {
      consultaJSONData['mes'] = 0;
    }
    if (palabra === '') {
      consultaJSONData['palabraClave'] = '-';
    }
    // if (palabra === '' && mes === '' && año === '') {
    //   alert('Debes ingresar al menos un parámetro');
    // }

    // console.log(':::::::variable año', año);
    if (año === '' && mes === '' && palabra === '') {
      alert('debes ingresar al menos un parametro');
    } else if (parseInt(mes, 10) > 12 || parseInt(mes, 10) < 1) {
      // console.log('ingresaste al if de validación de mes');
      alert('debes ingresar un número de mes valido entre 1 y 12');
    } else {
      return this._httpClient
        .get(
          consultaJSONData['url']
          // 'https://comprobaciones.diputados.gob.mx/ords/sitl/dip/?ROWNUM=' +
          // http://172.16.50.16:7001/ords/transparencia/transparencia/sol/2019/5/gastos
          // 'http://172.16.50.16:7001/ords/transparencia/transparencia/sol/{anio}/{mes}/{palabras clave}'
          //'https://hist-transparencia.diputados.gob.mx/ords/transparencia/transparencia/sol/' +
          //  consultaJSONData['anio'] +
          //  '/' +
          //  consultaJSONData['mes'] +
          //  '/' +
          //  consultaJSONData['palabraClave']
        )
        .toPromise();
    }
  }
}
