import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-diario-busqueda',
  templateUrl: './diario-busqueda.component.html',
  styleUrls: ['./diario-busqueda.component.scss'],
})
export class DiarioBusquedaComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
