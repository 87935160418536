import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { EventoService } from 'src/app/services/evento.service';
import { Evento } from 'src/app/interfaces/evento';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-mis-eventos',
  templateUrl: './mis-eventos.component.html',
  styleUrls: ['./mis-eventos.component.scss'],
})
export class MisEventosComponent implements OnInit, OnDestroy {
  @Input() data: any = {};

  public eventos: any;
  public favoriteEvents: Evento[];
  private _subEventos: any;

  constructor(private _leyService: EventoService) {
  }

  ngOnInit(): void {
    this.setSubLaws();
  }

  ngOnDestroy(): any {
    this._subEventos.unsubscribe();
  }

  setSubLaws(): void {
    this._subEventos = this._leyService
      .getSubEvents(this.data.visitanteEmail)
      .valueChanges.subscribe((result) => {
        this.favoriteEvents = result.data && result.data.allFavoriteEvents;
        this.eventos = this.chunkArray([...this.favoriteEvents], 4);
      });
  }

  deleteFavoriteEvent(Oid): any {
    this._leyService
      .deleteFavoriteEvent(Oid, this.data.visitanteEmail)
      .subscribe(
        () => {
          this.eventos = this.eventos.filter((law) => law.Oid !== Oid);
          Swal.fire(
            'Confirmación',
            'Se ha borrado de favoritos de manera exitosa!',
            'success'
          );
        },
        (error) => {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text:
              'Hubo un error al borrar de favoritos, intente de nuevo mas tarde.',
            footer: '<a href>Continuas con problemas?</a>',
          });
          console.log(error);
        }
      );
  }

  chunkArray(arr: Array<any>, n: number) {
    return arr
      .slice(0, ((arr.length + n - 1) / n) | 0)
      .map((c, i) => arr.slice(n * i, n * i + n));
  }
}
