import {
  Component,
  ComponentFactoryResolver,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { AdItem } from '../ad-item/ad-item';

@Component({
  selector: 'dynamic-component',
  template: `<div #container><ng-content></ng-content></div>`,
})
export class DynamicComponent {
  @ViewChild('container', { read: ViewContainerRef, static: true })
  container: ViewContainerRef;

  constructor(private compFactoryResolver: ComponentFactoryResolver) {}

  public addComponent(ngItem: AdItem): any {
    const factory = this.compFactoryResolver.resolveComponentFactory(
      ngItem.component
    );
    const ref = this.container.createComponent(factory);
    const newItem: any = ref.instance;
    newItem.data = ngItem.data;
    return newItem;
  }
}
