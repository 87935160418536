import { Component, OnInit } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { TrabajadorService } from 'src/app/services/trabajador.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  year: number;
  datosFooter: any[] = [];
  columnas: any[] = [];
urlWebDiputados ="https://web.diputados.gob.mx";

  constructor(
    private trabajadorService: TrabajadorService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.year = new Date().getFullYear();
    this.getFooter();
  }

  getFooter() {
    this.trabajadorService.getFooter().subscribe((result) => {
      this.datosFooter = result.data && result.data.allFooter;
      this.columnas.push(this.datosFooter.filter((df) => df.Columna == 0));
      this.columnas.push(this.datosFooter.filter((df) => df.Columna == 1));
      this.columnas.push(this.datosFooter.filter((df) => df.Columna == 2));
    });
  }
  LinkConParametro(ruta: string, param: string) {
    ruta = this.urlWebDiputados+ruta;
    let queryParams: any = {};
    queryParams = JSON.parse(param);

    var url = new URL(ruta, window.location.href);
    for (let key in queryParams) {
      url.searchParams.append(key, queryParams[key]);
    }
    window.open(url.href,'_blank');
  }
}
