import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class BuscarDiputadosINEService {
  constructor(private _httpClient: HttpClient) {}

  obtenerDiputadosINE(diputadoINEJSONData: JSON): any {
    return this._httpClient
      .get(
        'https://chats.diputados.gob.mx/ords/sitl/secc/?ESTADO=' +
          diputadoINEJSONData['estado'] +
          '&SECCION=' +
          diputadoINEJSONData['seccion']
      )
      .toPromise();
  }
}
