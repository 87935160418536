import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Apollo } from 'apollo-angular';
import { ModalComponent } from '../modal/modal.component';

@Component({
  selector: 'app-servicio-social',
  templateUrl: './servicio-social.component.html',
  styleUrls: ['./servicio-social.component.scss'],
})
export class ServicioSocialComponent implements OnInit {
  querySub: any;
  id: any;
  instituciones: any;
  constructor(
    private apollo: Apollo,
    public dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  openModalServicioSocial(): any {
    this.router.navigate(['/inicio/servicioSocial'], {
      queryParams: { Tipo: 'servicio social' },
    });
    this.dialog.open(ModalComponent, {});
  }
  openModalArticulo(): any {
    this.router.navigate(['/inicio/servicioSocial'], {
      queryParams: { Tipo: 'articulo' },
    });
    this.dialog.open(ModalComponent, {});
  }
  openModalPracticas(): any {
    this.router.navigate(['/inicio/servicioSocial'], {
      queryParams: { Tipo: 'practicas' },
    });
    this.dialog.open(ModalComponent, {});
  }
  openModalCompromiso(): any {
    this.router.navigate(['/inicio/servicioSocial'], {
      queryParams: { Tipo: 'compromiso' },
    });
    this.dialog.open(ModalComponent, {});
  }

  openModalRequisitos(): any {
    this.router.navigate(['/inicio/servicioSocial'], {
      queryParams: { Tipo: 'requisitos' },
    });
    this.dialog.open(ModalComponent, {});
  }

  openModalPasos(): any {
    this.router.navigate(['/inicio/servicioSocial'], {
      queryParams: { Tipo: 'pasos' },
    });
    this.dialog.open(ModalComponent, {});
  }

  openModalInstituciones(): any {
    this.router.navigate(['/inicio/servicioSocial'], {
      queryParams: { Tipo: 'instituciones' },
    });
    this.dialog.open(ModalComponent, {});
  }
  openModalDisposiciones(): any {
    this.router.navigate(['/inicio/servicioSocial'], {
      queryParams: { Tipo: 'disposiciones' },
    });
    this.dialog.open(ModalComponent, {});
  }

  ngOnInit(): void {}
}
