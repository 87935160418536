import { Component, OnInit, Inject } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { Apollo } from 'apollo-angular';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'app-modal-marcocodigo',
  templateUrl: './modal-marcocodigo.html',
})
// tslint:disable-next-line: component-class-suffix
export class ModalMarcoCodigo implements OnInit {
  loading = false;
  error: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private apollo: Apollo,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<ModalMarcoCodigo>
  ) {}
  ngOnInit(): any {
    // Rellenar aquí con el query
  }
  closeDialog(): any {
    this.dialogRef.close();
  }
}
