import { Component, OnInit } from '@angular/core';
import { PresupuestoService } from 'src/app/services/presupuesto.service';
import { Presupuesto } from 'src/app/interfaces/presupuesto';
import { PresupuestoAsignado } from 'src/app/interfaces/presupuesto-signado';
import { ShareService } from 'src/app/services/share.service';
import { GastoAsignado } from 'src/app/interfaces/gasto-asignado';
import { BienMaterial } from 'src/app/interfaces/bien-material';

@Component({
  selector: 'app-info-financiera',
  templateUrl: './info-financiera.component.html',
  styleUrls: ['./info-financiera.component.scss'],
})
export class InfoFinancieraComponent implements OnInit {
  public presupuestos: Presupuesto[] = [];
  public presupuestoAsignado: PresupuestoAsignado[] = [];
  public gastoAsignado: GastoAsignado[] = [];
  public gastoDetallado: any[] = [];
  public bienesMateriales: any = {};
  public tiposBienesMateriales: any = [];
  public gastoTotal: number = 0;

  constructor(private _presupuestoService: PresupuestoService, private _shareService: ShareService) { }

  ngOnInit(): void {
    this.fetchAllPresupuestos();
    this.selectBienesMateriales();
  }

  selectBienesMateriales() {
    this._presupuestoService.selectBienesMateriales().refetch().then((data) => {
      const bienesMateriales: BienMaterial[] = data.data.selectBienesMateriales;
      bienesMateriales.forEach(bienMaterial => {
        if (this.bienesMateriales[bienMaterial.Tipo]) {
          this.bienesMateriales[bienMaterial.Tipo].push(bienMaterial);
        } else {
          this.bienesMateriales[bienMaterial.Tipo] = [bienMaterial];
          this.tiposBienesMateriales.push([bienMaterial.Tipo, bienMaterial.Titulo]);
        }
      });
    });
  }

  downloadBienMaterial(oid: string) {
    this._presupuestoService.selectBienMaterial(oid).refetch().then(data => {
      const bienMaterial: BienMaterial = data.data.selectBienMaterial;
      this.downloadPdf(bienMaterial.ArchivoB64, null, bienMaterial.Nombre)
    });
  }

  fetchAllPresupuestos() {
    this._presupuestoService.allPresupuestos().refetch().then((data) => {
      this.presupuestos = data.data.allPresupuestos;
      this.fetchPresupuestoAsignado(this.presupuestos[0]?.Oid);
      this.fetchGastoAsignado(this.presupuestos[0]?.Oid);
    });
  }

  fetchPresupuestoAsignado(presupuestoOid: string) {
    this._presupuestoService.selectPresupuestoAsignado(presupuestoOid).refetch().then((data) => {
      this.presupuestoAsignado = data.data.selectPresupuestoAsignado;
    });
  }

  fetchGastoAsignado(presupuestoOid: string) {
    this.gastoDetallado = [];
    this.gastoTotal = 0;
    this._presupuestoService.selectGastoAsignado(presupuestoOid).refetch().then((data) => {
      this.gastoAsignado = data.data.selectGastosAsignado;
      this.setGastoDetallado();
    });
  }

  fetchDetallePresupuesto(presupuestoAsignado: PresupuestoAsignado) {
    this.fetchPresupuestoAsignado(presupuestoAsignado.Oid);
    this.fetchGastoAsignado(presupuestoAsignado.Oid);
  }

  downloadPdf(archivoB64: string, url: string, nombre: string) {
    archivoB64 ?
      this._shareService.downloadPdf(archivoB64, nombre) :
      window.open(url, "_blank");
  }

  async setGastoDetallado() {
    await Promise.all(this.gastoAsignado.map(gasto => {
      gasto.Tipo = 1;
      this.gastoTotal += parseInt(gasto.Monto);
      return this._presupuestoService.selectCapituloGasto(gasto.Oid).refetch()
        .then((data) => {
          gasto.CapituloGasto = data.data.selectCapituloGasto;
          return true;
        });
    }));
    this.gastoAsignado.forEach(gasto => {
      this.gastoDetallado.push(gasto);
      gasto.CapituloGasto.forEach(capitulo => {
        this.gastoDetallado.push(capitulo);
      });
    })
  }
}
