import { Notice, Event, Relevante,DireccionEvento } from './../pages/home/homepage/homepage.interface';
import {
  getQueryPaginaPrincipal,
  queryEnviarComentario,
  queryEventos,
  queryRelevantes,
  queryVeryImportantNotices,
  queryDireccionEventoMes,
  queryDireccionEventoDiario,
} from './../operations/query';
import { ExecFileOptionsWithStringEncoding } from 'child_process';
import { map } from 'rxjs/operators';
import { Apollo } from 'apollo-angular';
import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { qOlvideContrasena } from '../operations/mutation';
import Observable from 'zen-observable-ts';

@Injectable({
  providedIn: 'root',
})
export class ApiHomeService {
  constructor(private apollo: Apollo) { }
  getDatosPaginaPrincipal() {
    return this.apollo
      .watchQuery({
        query: getQueryPaginaPrincipal,
        fetchPolicy: 'network-only',
      })
      .valueChanges.pipe(
        map((result: any) => {
          return result.data.getDatosPaginaPrincipal[0];
        })
      );
  }
  getAvisosMuyImportantes(): any {
    return this.apollo.watchQuery<Notice>({
      query: queryVeryImportantNotices,
      fetchPolicy: 'network-only',
    });
  }
  getRelevantes(): any {
    return this.apollo.watchQuery<Relevante>({
      query: queryRelevantes,
      fetchPolicy: 'network-only',
    });
  }
  getEventos(): any {
    return this.apollo.watchQuery<Event>({
      query: queryEventos,
      fetchPolicy: 'network-only',
    });
  }
  enviarComentario(email: string, Comentario: String): any {
    return this.apollo.watchQuery<boolean>({
      query: queryEnviarComentario,
      variables: {
        email,
        Comentario
      },
      fetchPolicy: 'network-only',
    });
  }
  getDireccionEventoHoy(Fecha: string): any {
    return this.apollo.watchQuery<DireccionEvento>({
      query: queryDireccionEventoDiario,
      variables: {
        Fecha
      },
      fetchPolicy: 'network-only',
    });
  }
  getDireccionEvento(): any {
    return this.apollo.watchQuery<DireccionEvento>({
      query: queryDireccionEventoMes,
      fetchPolicy: 'network-only',
    });
  }
}
