import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-seccion-menu',
  templateUrl: './seccion-menu.component.html',
  styleUrls: ['./seccion-menu.component.scss']
})
export class SeccionMenuComponent implements OnInit {

  public itemsRow: any;
  
  @Input() data: any[]= [];
  @Input() columns: number=2;

  constructor() { }

  ngOnInit(): void {
      this.itemsRow = this.chunkArray(this.data, this.columns);
      // console.log("dsfsdf",this.itemsRow);
  }

  chunkArray(arr: Array<any>, n: number) {
    return arr
      .slice(0, (arr.length + n - 1) / n || 0)
      .map((c, i) => arr.slice(n * i, n * i + n));
  }
}
