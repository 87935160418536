import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BreadcrumbModule } from 'angular-crumbs';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
// import { GraphQLModule } from './graphql.module';
import { HttpClientModule } from '@angular/common/http';
import { MenuComponent } from './components/menu/menu.component';
import { FooterComponent } from './components/footer/footer.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { HomeModule } from './pages/home/home.module';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatButtonToggleModule } from '@angular/material/button-toggle';

import { NgxGalleryModule } from 'ngx-gallery-9';
import { CustompipesModule } from './components/custompipes/custompipes.module';
import { ComponentsModule } from './components/components.module';
import { GraphQLModule } from './graphql.module';
import { LazyLoadImageModule } from 'ng-lazyload-image';

import { FullCalendarModule } from '@fullcalendar/angular'; // the main connector. must go first
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin
import interactionPlugin from '@fullcalendar/interaction';
import { CarruselHomeComponent } from './pages/carrusel-home/carrusel-home.component';
import { YouTubePlayerModule } from '@angular/youtube-player';
import { RecursosHumanosComponent } from './pages/home/parlamentarias/recursos-humanos/recursos-humanos.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCard } from '@angular/material/card';
import { TransmisionComponent } from './pages/transmision/transmision.component';
import { VideoComponent } from './pages/video/video.component';

import { NgxCaptchaModule } from 'ngx-captcha';
import { MaterialModule } from './shared/material/material.module';

FullCalendarModule.registerPlugins([
  // register FullCalendar plugins
  dayGridPlugin,
  interactionPlugin,
]);

@NgModule({
  declarations: [
    AppComponent,
    MenuComponent,
    FooterComponent,
    NotFoundComponent,
    TransmisionComponent,
    CarruselHomeComponent,
    VideoComponent,
    RecursosHumanosComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BreadcrumbModule,
    GraphQLModule,
    MaterialModule,
    HttpClientModule,
    BrowserAnimationsModule,
    HomeModule,
    MatPaginatorModule,
    NgxGalleryModule,
    CustompipesModule,
    MatButtonToggleModule,
    ComponentsModule,
    LazyLoadImageModule,
    FullCalendarModule,
    YouTubePlayerModule,
    FormsModule,
    ReactiveFormsModule,
    NgxCaptchaModule,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
