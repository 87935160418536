import { Component, OnInit } from '@angular/core';
import { TrabajadorService } from 'src/app/services/trabajador.service';
import { Contacto } from 'src/app/interfaces/contacto';

@Component({
  selector: 'app-directorio',
  templateUrl: './directorio.component.html',
  styleUrls: ['./directorio.component.scss'],
})
export class DirectorioComponent implements OnInit {
  // eslint-disable-next-line @typescript-eslint/no-inferrable-types
  public view: string = 'Listado';
  public area: string = '';
  public keyWord: string = '';

  private _directorio: Contacto[] = [];
  public directorioFiltrado: Contacto[] = [];
  public areas: any[] = [];

  constructor(private _trabajadorService: TrabajadorService) { }

  ngOnInit(): void {
    this.fetchDirectorio();
    this.fetchAreas();
  }

  fetchDirectorio() {
    this._trabajadorService.allDirectorio().refetch().then((data) => {
      this._directorio = data.data.allDirectorio;
      this.directorioFiltrado = [...this._directorio];
    });
  }

  fetchAreas() {
    this._trabajadorService.allAreas().refetch().then((data) => {
      this.areas = data.data.allAreas;
    });
  }

  filtrarDirectorio(todos?: boolean) {
    if (todos) {
      this.directorioFiltrado = [...this._directorio];
    } else {
      this.directorioFiltrado = this._directorio.filter(contacto => {
        return (this.area ? contacto.Area == this.area : true) &&
          (this.keyWord ? Object.keys(contacto).some((key) => {
            if (key !== 'Oid') {
              return contacto[key]
                ?.toString()
                ?.toLowerCase()
                .includes(this.keyWord.toLowerCase());
            }
          }) : true);
      });
    }
  }
}
