import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SanitizeVideoUrlPipe } from './sanitizeVideoUrl/sanitize-video-url.pipe';
import { MatSnackBarModule } from '@angular/material/snack-bar';

@NgModule({
  declarations: [SanitizeVideoUrlPipe],
  imports: [CommonModule],
  exports: [SanitizeVideoUrlPipe, MatSnackBarModule],
})
export class CustompipesModule {}
