import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import copy from 'fast-copy';
import { CapacitacionTransparencia } from '../../../../interfaces/capacitacion-transparencia';
import { CapacitacionTransparenciaService } from '../../../../services/capacitacion-transparencia.service';
import { ArchivoTransparencia } from '../transparencia.component.interface';
import { ConsultasTransparenciaService } from '../../../../services/consultas-transparencia.service';
import Swal from 'sweetalert2';

interface ArticuloFraccion {
  Articulo: string;
  Fraccion: string;
  Padre: number;
  Anio: number;
  Incisos: [Inciso];
}
interface Fraccion {
  Articulo: string;
  Fraccion: string;
  NumeroFraccion: number;
  Inciso: string;
}
interface Inciso {
  Inciso: string;
  Anio: number;
  Fracciones: [Fraccion];
}

interface ResponseIncisos {
  allIncisos: Inciso[];
}
interface ResponseArchivosTransparencia {
  allArchivosTransparencia: ArchivoTransparencia[];
}
interface ResponseFraccion {
  allFracciones: Fraccion[];
}
interface Anio {
  anio: number;
}
interface ResponseAnio {
  allAniosArchTrans: Anio[];
}
interface ResponseArticuloFraccion {
  allArticuloFraccion: ArticuloFraccion[];
}

@Component({
  selector: 'app-art-ix',
  templateUrl: './art-ix.component.html',
  styleUrls: ['./art-ix.component.scss'],
})
export class ArtIxComponent implements OnInit {
  private querySub: any;
  private subArchivosTransparencia: any;
  private subArticuloFraccion: any;
  private subFracciones: any;
  private subAnios: any;
  private subInciso: any;
  private oid = 0;
  loading = false;
  error: any;
  events: Event[];
  archivosTransparencia: ArchivoTransparencia[];
  articuloFraccion: ArticuloFraccion[];
  fraccion: Fraccion[];
  incisos: Inciso[];
  anios: Anio[];

  private subEvents: any;
  oidpadre = 0;
  visitanteEmail = '';
  loggedIn = false;
  archivoTransparencia: any;

  selectedAnio = 0;
  selectedInciso = 0;
  selectedArchivo = 0;

  constructor(
    private apollo: Apollo,
    private router: Router,
    private route: ActivatedRoute,
    private api: ConsultasTransparenciaService
  ) {}

  ngOnInit(): void {
    this.verifyVisitantSession();
    this.visitanteEmail = 'chuycova@yahoo.com.mx';

    this.querySub = this.route.queryParams.subscribe((params: any) => {
      // Defaults to 0 if no query param provided.
      this.oid = params.oid || 0;
      this.oidpadre = this.oid;
      this.selectedArchivo = params.arc || 0;
      // this.anio = params.anio || 0;
      // this.inciso = params.inciso || 0;
      // console.log('llegó oid', this.oid);
    });
    this.subFracciones = this.apollo
      .watchQuery<ResponseFraccion>({
        query: gql`
          {
            allFracciones(OidPadre: ${this.oidpadre}) {
              Articulo
              Fraccion
              NumeroFraccion
              Inciso
            }
          }
        `,
      })
      .valueChanges.subscribe((result) => {
        this.fraccion = result.data && result.data.allFracciones;
        this.loading = result.loading;
      });

    this.subArticuloFraccion = this.apollo
      .watchQuery<ResponseArticuloFraccion>({
        query: gql`
          {
            allArticuloFraccion(OidPadre: ${this.oidpadre}) {
              Oid
              Articulo
              Fraccion
              Padre
              Anio
              Incisos {
                Padre
                OidInciso
                OidFraccion
                Inciso
                Anio
                Archivos {
                  OID
                  ObligacionesTransparencia
                  Nombre
                  NombreCorto
                  Descripcion
                  Articulo
                  Fraccion
                  Inciso
                  URLDocumento
                  Padre
                  Anio
                }
              }
            }
          }
        `,
      })
      .valueChanges.subscribe((result) => {
        this.articuloFraccion = result.data && result.data.allArticuloFraccion;
        if (this.selectedArchivo > 0) {
          this.articuloFraccion.forEach((f: any) => {
            f.Incisos.forEach((i: any) => {
              i.Open = false;
              i.Archivos.forEach((a: any) => {
                if (a.OID == this.selectedArchivo) {
                  this.selectedAnio = this.articuloFraccion.findIndex(
                    (x: any) => x.Anio == f.Anio
                  );
                  i.Open = true;
                }
              });
            });
          });
        }
        this.loading = result.loading;
      });

    // this.subArchivosTransparencia = this.apollo
    //   .watchQuery<ResponseArchivosTransparencia>({
    //     query: gql`
    //       {
    //         allArchivosTransparencia(OidPadre: ${this.oidpadre}) {
    //           OID
    //           ObligacionesTransparencia
    //           Nombre
    //           NombreCorto
    //           Descripcion
    //           Articulo
    //           Fraccion
    //           URLDocumento
    //           Padre
    //           Anio
    //           Inciso
    //         }
    //       }
    //     `,
    //   })
    //   .valueChanges.subscribe((result) => {
    //     this.archivosTransparencia =
    //       result.data && result.data.allArchivosTransparencia;

    //     this.loading = result.loading;
    //   });

    //   this.subInciso = this.apollo
    //     .watchQuery<ResponseIncisos>({
    //       query: gql`
    //   {
    //     allIncisos(OidPadre: ${this.oidpadre}) {
    //       Inciso
    //       Anio
    //     }
    //   }
    // `,
    //     })
    //     .valueChanges.subscribe((result) => {
    //       this.incisos = result.data && result.data.allIncisos;
    //       this.articuloFraccion.forEach((f: any) => {
    //         f.Incisos = this.incisos.filter((i: any) => i.Anio == f.Anio);
    //         f.Incisos.forEach((i: any) => {
    //           let splitInciso = i.Inciso.split(' ');
    //           i.Open = false;
    //           if (splitInciso.length > 1)
    //             i.Open = splitInciso[1] == this.inciso && i.Anio == this.anio;
    //         });
    //       });
    //       this.loading = result.loading;
    //     });
  }

  verifyVisitantSession(): any {
    // this.loggedIn = !!localStorage.getItem('HCD_activeSessionVisitant');
    this.loggedIn = !!localStorage.getItem('HCD_activeSessionVisitant');
  }

  downloadBase64File(objeto: ArchivoTransparencia) {
    this.api.getArchivoTransparencia(objeto.OID).subscribe((data) => {
      // console.log('data', data);
      this.archivoTransparencia = null;
      if (data[0]) {
        this.archivoTransparencia = data[0];
        const myData = copy(this.archivoTransparencia);
        myData.Tipo = myData.NombreArchivo
          ? myData.NombreArchivo.split('.')[1]
          : myData.NombreArchivo;
        this.archivoTransparencia = myData;
        let base64: any = null;
        let fileName: any = null;
        let fileType: any = null;
        if (this.archivoTransparencia) {
          base64 = this.archivoTransparencia.ArchivoB64;
          fileName = this.archivoTransparencia.NombreArchivo;
          fileType = this.archivoTransparencia.Tipo;
        }
        const linkSource = `data:application/${fileType};base64,${base64}`;
        const downloadLink = document.createElement('a');
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
        downloadLink.remove();
      }
      this.loading = false;
    });
  }
  copiarLink(OID: string) {
    const aux = document.createElement('input');
    let url = `https://transparencia.diputados.gob.mx/transparencia/obligaciones?oid=${this.oidpadre}&arc=${OID}`;
    aux.setAttribute('value', url);
    document.body.appendChild(aux);
    aux.select();
    document.execCommand('copy');
    document.body.removeChild(aux);
    Swal.fire(
      'Confirmación',
      'El link se ha copiado correctamente!',
      'success'
    );
  }
}
