import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'timeCustomPipe' })
export class TimeCustomPipeComponent implements PipeTransform {
  transform(timeInSeconds: number): string {
    const hours = Math.floor(timeInSeconds / 3600);
    const minutes = Math.floor(timeInSeconds / 3600 / 60);
    if (hours < 10 && minutes < 10) {
      return '0' + hours + ':0' + minutes;
    } else {
      if (hours < 10 && minutes > 9) {
        return '0' + hours + ':' + minutes;
      }
      if (hours > 9 && minutes < 10) {
        return hours + ':0' + minutes;
      }
    }
    return hours + ':' + minutes;
  }
}
