import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Ley } from 'src/app/interfaces/ley';
import { LeyService } from 'src/app/services/ley.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-mis-leyes',
  templateUrl: './mis-leyes.component.html',
  styleUrls: ['./mis-leyes.component.scss'],
})
export class MisLeyesComponent implements OnInit, OnDestroy {
  @Input() data: any = {};

  public leyes: Ley[];
  private _subLeys: any;

  constructor(private _leyService: LeyService) {}

  ngOnInit(): void {
    this.setSubLaws();
  }

  ngOnDestroy(): any {
    this._subLeys.unsubscribe();
  }

  setSubLaws(): void {
    this._subLeys = this._leyService
      .getSubLaws(this.data.visitanteEmail)
      .valueChanges.subscribe((result) => {
        this.leyes = result.data && result.data.allFavoriteLaws;
        // console.log('LeyesFavoritas: ', this.leyes);
      });
  }

  deleteFavoriteLaw(Oid): any {
    this._leyService.deleteFavoriteLaw(Oid, this.data.visitanteEmail).subscribe(
      () => {
        this.leyes = this.leyes.filter((law) => law.Oid !== Oid);
        Swal.fire(
          'Confirmación',
          'Se ha borrado de favoritos de manera exitosa!',
          'success'
        );
      },
      (error) => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text:
            'Hubo un error al borrar de favoritos, intente de nuevo mas tarde.',
          footer: '<a href>Continuas con problemas?</a>',
        });
        console.log(error);
      }
    );
  }
}
