import { New, Notice } from '../home/homepage/homepage.interface';

import { Component, OnInit, Inject, ElementRef } from '@angular/core';

import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';

// JACH.https://www.npmjs.com/package/ngx-gallery
import {
  NgxGalleryOptions,
  NgxGalleryImage,
  NgxGalleryAnimation,
} from 'ngx-gallery-9';
import { DOCUMENT } from '@angular/common';
import { ApiHomeService } from '../../services/api-home.service';

export type Response = {
  allNews: New[];
  allVeryImportantNotices: Notice[];
  allImportantNotices: Notice[];
  allNotices: Notice[];
};

@Component({
  selector: 'app-carrusel-home',
  templateUrl: './carrusel-home.component.html',
  styleUrls: ['./carrusel-home.component.scss'],
})
export class CarruselHomeComponent implements OnInit {
  galleryOptions: NgxGalleryOptions[] = [];
  galleryImages: NgxGalleryImage[] = [];

  news: any[];

  veryImportantNotices: any[];
  importantNotices: any[];
  notices: any[];

  linkClasses: any[];
  links: any[];
  loading = true;
  loadingVideos = true;
  loadingEnlacesInteres = true;

  error: any;
  errorEnlacesInteres: any;
  public avisos: any;
  public eventos: any;
  public eventosC: any;
  sub: any;
  public avisosImportantes: any = [];
  proximosEventos: any = [];
  subAvisos: any;
  subEventos: any;

  constructor(
    private api: ApiHomeService,
    private apollo: Apollo,
    @Inject(DOCUMENT) document,
    private elementRef: ElementRef
  ) {}

  ngOnInit() {
    // KYBC.Call function for fetch all cultural events banners from DB.
    this.setNotices();
    this.fetchAllNews();
  }

  setNotices(): void {
    this.subAvisos = this.api
      .getAvisosMuyImportantes()
      .valueChanges.subscribe((result) => {
        this.avisosImportantes = result.data && result.data.allNotices;
        this.avisos = this.chunkArray([...this.avisosImportantes], 3); // Joel, modificar si quieres que se vean 3 anuncios o mas.
      });
  }

  chunkArray(arr: Array<any>, n: number) {
    return arr
      .slice(0, (arr.length + n - 1) / n || 0)
      .map((c, i) => arr.slice(n * i, n * i + n));
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  // // KYBC.Fetch all News from DB.
  fetchAllNews() {
    this.sub = this.apollo
      .watchQuery<Response>({
        query: gql`
          {
            allNews {
              Oid
              Titulo
              ImageBase64
              linkSitio
              Descripcion
              Numero
            }
          }
        `,
      })
      .valueChanges.subscribe((result) => {
        // console.log('::::::::::allNews Result from DB: \n', result); // KYBC.Borrar.
        this.news = result.data && result.data.allNews;
        // console.log('::::::::::allNews from DB: \n', this.news); // KYBC.Borrar.
        for (let i = 0; i < this.news.length; i++) {
          this.galleryImages.push({
            small: this.news[i].ImageBase64,
            medium: this.news[i].ImageBase64,
            big: this.news[i].ImageBase64,
            description: this.news[i].Descripcion,
            url: this.news[i].linkSitio,
          });
        }
        // console.log('::::::::::this.galleryImages: \n', this.galleryImages); // KYBC.Borrar.
        this.formatNewsSlider();
      });
  }

  formatNewsSlider() {
    // JACH.Options for Ngx slider.
    this.galleryOptions = [
      {
        imageAutoPlay: true,
        imageAutoPlayPauseOnHover: true,
        previewAutoPlay: true,
        previewAutoPlayPauseOnHover: true,
        imageAutoPlayInterval: 5000,
        width: '360px',
        height: '312px',
        thumbnailsColumns: 4,
        imageAnimation: NgxGalleryAnimation.Fade,
        imageDescription: true,
        preview: true,
        linkTarget: '_blank',
        thumbnailsAsLinks: false,
        lazyLoading: true,
      },
      // max-width 800
      {
        breakpoint: 800,
        width: '100%',
        height: '400px',
        imagePercent: 80,
        thumbnailsPercent: 20,
        thumbnailsMargin: 20,
        thumbnailMargin: 20,
      },
      // max-width 400
      {
        breakpoint: 400,
        preview: false,
      },
    ];
  }
}
