import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-intervenciones',
  templateUrl: './intervenciones.component.html',
  styleUrls: ['./intervenciones.component.scss'],
})
export class IntervencionesComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
