import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';

@Component({
  selector: 'app-votos',
  templateUrl: './votos.component.html',
  styleUrls: ['./votos.component.scss']
})
export class VotosComponent implements OnInit {


  public Oid = 0;
  public querySub: any;



  constructor(private rutaActiva: ActivatedRoute, private route: ActivatedRoute,) { }

  ngOnInit(): void {

    this.querySub = this.route.queryParams.subscribe((params) => {
      this.Oid = params.Id || 0;
      console.log('llegó oid', this.Oid);
    });

  }

}
