import { Injectable } from '@angular/core';
// import { PublicacionRespuesta } from '../interfaces/publicacion-respuesta';
import gql from 'graphql-tag';
import { Apollo } from 'apollo-angular';
import { GraphqlService } from './graphql.service';

@Injectable({
  providedIn: 'root',
})
export class PublicacionesService {
  constructor(
    private apollo: Apollo,
    private _graphqlService: GraphqlService
  ) {}
  getSubPublications(visitanteEmail: string): any {
    console.log('visitanteEmail', visitanteEmail);
    return this.apollo.watchQuery<any>({
      query: gql`
          {
            allFavoritePublications(EmailVisitante: "${visitanteEmail}") {
              Oid
              Titulo
              Fecha
              Autor
              Categoria
              Orden
              ImagenPortadaB64
              Vistas
            }
          }
        `,
    });
  }

  deleteFavoritePublication(
    OidPublication: string,
    emailVisitante: string
  ): any {
    return this.apollo.mutate({
      mutation: this._graphqlService.mutationDeleteFavoritePublication(
        OidPublication,
        emailVisitante
      ),
    });
  }
}
