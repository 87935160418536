import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-art-dos',
  templateUrl: './art-dos.component.html',
  styleUrls: ['./art-dos.component.scss'],
})
export class ArtDosComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
