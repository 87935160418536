import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  FullCalendarModule,
  FullCalendarComponent,
} from '@fullcalendar/angular';
import { CalendarOptions, Calendar } from '@fullcalendar/angular'; // useful for typechecking
import { DireccionEvento } from '../../homepage.interface';
import { ApiHomeService } from '../../../../../services/api-home.service';

@Component({
  selector: 'app-direccionEvento',
  templateUrl: './direccion-evento.component.html',
  styleUrls: ['./direccion-evento.component.scss'],

})
export class DireccionEventoComponent implements OnInit {
  @ViewChild('calendar') calendarComponent: FullCalendarComponent;
  public calendarOptions: CalendarOptions;
  direccionEvento: DireccionEvento[];
  private _direccionEvento: DireccionEvento[] = [];
  public loading = false;
  public error: any;

  constructor(
    private api: ApiHomeService) { }

  ngOnInit(): void {
    this.getAllDireccionEvento();

  }

  someMethod() {
    const calendarApi = this.calendarComponent.getApi();
    calendarApi.next();
  }
  goTo(dia: string) {
    const calendarApi = this.calendarComponent.getApi();
    calendarApi.gotoDate(dia);
  }
  getAllDireccionEvento() {
    this.direccionEvento = this.api
      .getDireccionEvento()
      .valueChanges.subscribe((result) => {
        this._direccionEvento = result.data && result.data.getDireccionEventoMes;
        // console.log('acuerdos??? ', this.acuerdo);
        //   console.log('Direccionde Eventos ', this._direccionEvento);
        this.loading = result.loading;
        this.error = result.errors;
        this.setCalendar();

        return this._direccionEvento;
      },
        (error) => {
          this.error = error;
        }
      );
  }

  setCalendar() {
    let direccionEventoCalendario: any = {};
    let day: string = '1-1-2021';
    if (this._direccionEvento.length > 0) {
      if (this._direccionEvento[0].Fecha) {
        const dia: string[] = this._direccionEvento[0].Fecha.split('-');
        day = `${dia[2]}-${dia[1]}-${dia[0]}`;
        // console.log({ day });
      }
      direccionEventoCalendario = this._direccionEvento.map((evento) => {
        let arrayFecha: string[] = ['1', '1', '2021'];
        if (evento.Fecha) {
          arrayFecha = evento.Fecha.split('-');
        }
        return {
          title: evento.Nombre,
          date: `${arrayFecha[2] || 2000}-${arrayFecha[1] || 1}-${arrayFecha[0] || 1}`,
          color: ['#009CF9'],
          url: evento.SFTPArchivo,
        };
      });
      this.calendarOptions = {
        firstDay: 1,
        initialView: 'dayGridMonth',
        weekends: false,
        initialDate: day,
        dateClick: (dia) => this.handleDateClick(dia),
        events: direccionEventoCalendario,
        locale: 'es',
        height: '550px',
        buttonText: {
          today: 'Hoy',
        },
        dayHeaders: true,
      };
      console.log('Calendario:', this.calendarOptions);
      this.goTo(day);
    }
  }
  toggleWeekends() {
    this.calendarOptions.weekends = !this.calendarOptions.weekends; // toggle the boolean!
  }
  handleDateClick(dia) {
    console.log(dia);
  }
}
