import { Component, OnInit } from '@angular/core';
import { TrabajadorArea } from 'src/app/interfaces/trabajador-area';
import { TrabajadorService } from 'src/app/services/trabajador.service';

@Component({
  selector: 'app-directorio-transp',
  templateUrl: './directorio-transp.component.html',
  styles: [
  ]
})
export class DirectorioTranspComponent implements OnInit {

  public datosEmpleado: TrabajadorArea[] = [];

  constructor(private _trabajadorService: TrabajadorService) { }

  ngOnInit(): void {
    this.getAllDirectorio();
  }
  getAllDirectorio() {
    this._trabajadorService.getAllDirectorio('562df02c-6eea-490a-b9ac-87f371e5e87e').refetch().then((data) => {
      this.datosEmpleado = data.data.selectTrabajadoresArea;
    });
  }
}
