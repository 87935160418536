import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { BuscarDiputadosService } from 'src/app/services/buscar-diputados.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-mis-diputados',
  templateUrl: './mis-diputados.component.html',
  styleUrls: ['./mis-diputados.component.scss'],
})
export class MisDiputadosComponent implements OnInit, OnDestroy {
  @Input() data: any = {};

  public favoriteDiputados: any[];
  public diputados: any;
  private _subDiputados: any;

  constructor(private _buscarDiputadosService: BuscarDiputadosService) {}

  ngOnInit(): void {
    this.setFavirteDiputados();
  }

  ngOnDestroy(): any {
    this._subDiputados.unsubscribe();
  }

  setFavirteDiputados(): void {
    this._subDiputados = this._buscarDiputadosService
      .getFavoriteDeputies(this.data.visitanteEmail)
      .valueChanges.subscribe((result) => {
        this.favoriteDiputados =
          result.data && result.data.allFavoriteDiputados;
        // console.log('Diputados', this.favoriteDiputados);
        this.diputados = this.chunkArray([...this.favoriteDiputados], 4);
        // console.log('Diputados!!!', this.diputados);
      });
  }

  deleteFavoriteDiputado(diputadoFavorito): any {
    this._buscarDiputadosService
      .deleteFavoriteDeputies(
        diputadoFavorito.IdDiputado,
        this.data.visitanteEmail
      )
      .subscribe(
        ({ data }) => {
          const index = this.favoriteDiputados.findIndex(
            (diputado) => diputado.IdDiputado == diputadoFavorito.IdDiputado
          );
          this.favoriteDiputados.splice(index, 1);
          this.diputados = this.chunkArray([...this.favoriteDiputados], 4);
          Swal.fire(
            'Confirmación',
            'Se ha borrado de favoritos de manera exitosa!',
            'success'
          );
        },
        (error) => {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text:
              'Hubo un error al borrar de favoritos, intente de nuevo mas tarde.',
            footer: '<a href>Continuas con problemas?</a>',
          });
          console.log(error);
        }
      );
  }

  chunkArray(arr: Array<any>, n: number) {
    return arr
      .slice(0, ((arr.length + n - 1) / n) | 0)
      .map((c, i) => arr.slice(n * i, n * i + n));
  }
}
