import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { MatDialog } from '@angular/material/dialog';
import * as _ from 'underscore';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { ModalCronologico } from './modal-cronologico/modal-cronologico.component';
import { environment } from 'src/environments/environment';
import { ModalArticuloComponent } from './modal-articulo/modal-articulo.component';
import { ModalPeriodoComponent } from './modal-periodo/modal-periodo.component';
import { ModalLegislaturaComponent } from './modal-legislatura/modal-legislatura.component';
import { ModalLXIVComponent } from './modal-lxiv/modal-lxiv.component';
import { ModalReglamentosVigentesComponent } from './modal-regvigentes/modal-regvigentes.component';
import { ModalMarcoCodigo } from './modal-marcocodigo/modal-marcocodigo.component';
import { ModalReglamentosAbrogadasComponent } from './modal-regabrogadas/modal-regabrogadas.component';
import {
  Norma,
  ResponseallLegislaturasLeyes,
  ResponseNormas,
} from './leyes-vigentes.interface';
import {
  LeyesDOF,
  ResponseLeyesDOF,
  ResponsecaracReformasConst,
} from './leyes-vigentes.interface';
import {
  Law,
  Law2,
  LawType,
  LegislaturaLeyes,
  LeyesEstatales2,
  Link,
  Linkb,
  ReformasConstc,
  ResponseallLinks,
  ResponseCorreoPieLeyes,
  ResponseDescriReformasConst,
  ResponseDescTitMarcoJuridico,
  ResponseFavoriteLaws,
  ResponseLaws,
  ResponseLeyesEstatales2,
  ResponseSumarios,
  ResponseTelPieLeyes,
  ResponseTitReformasConst,
  ResponseTituloMarcoJurdico,
  ResponseTituloPieUno,
  ResponseTitulosReformasConst,
  Sumario,
  Titulos,
} from './leyes-vigentes.interface';
import { ModalNormasComponent } from './modal-normas/modal-normas.component';
import { ModalCompilacionesComponent } from './modal-compilaciones/modal-compilaciones.component';
import { AddViewToPage } from '../../../../operations/query';
import { GraphqlService } from '../../../../services/graphql.service';

// @Pipe({ name: 'reverse' })
// export class ReversePipe implements PipeTransform {
//   transform(value) {
//     return value.slice().reverse();
//   }
// }
export const mutationDeleteFavoriteLaw = (OidLaw, emailVisitante): any => gql`
mutation {
  deleteFavoriteLaw(OidLaw: "${OidLaw}", EmailVisitante: "${emailVisitante}"){
    Oid
  }
}`;
export const mutationAddFavoriteLaw = (OidLaw, emailVisitante): any => gql`
mutation {
  addFavoriteLaw(OidLaw: "${OidLaw}", EmailVisitante: "${emailVisitante}"){
    Oid
  }
}`;
// const AddFavoriteLawMutation = gql`
//   mutation addFavoriteLaw($OidLey: String!, $EmailVisitante: String!) {
//     addFavoriteLaw(OidLey: $OidLey, EmailVisitante: $EmailVisitante) {
//       Oid
//     }
//   }
// `;

// const queryAniosperiodoPresidente = gql`
//   query AniosPeriodoPresi($Presidente: String!) {
//     AniosPeriodoPresi(Presidente: $Presidente) {
//       PublicacionDofstr
//       NombreCompleto
//     }
//   }
// `;

const startYear = 1994;

@Component({
  selector: 'app-leyes-vigentes',
  templateUrl: './leyes-vigentes.component.html',
  styleUrls: ['./leyes-vigentes.component.scss'],
})
export class LeyesVigentesComponent implements OnInit, OnDestroy {
  // agregado public tabla1 Joel
  public tabla1 = 0;

  arrYears = [];
  selectedOptionYear = '';
  yourProperty =
    '<a target="_blank" href="dof/2020/mar/DOF_06mar20.pdf">99</a>'; // day.toString();
  month1: string;
  tableCols1 = ['L', 'M', 'M', 'J', 'V', 'S', 'D'];
  tableData1 = [
    {
      D: '',
      L: '',
      M: '',
      MI: '',
      J: '',
      V: '',
      S: '',
      DL: '',
      LL: '',
      ML: '',
      MIL: '',
      JL: '',
      VL: '',
      SL: '',
      DLdef: '',
      LLdef: '',
      MLdef: '',
      MILdef: '',
      JLdef: '',
      VLdef: '',
      SLdef: '',
    },
    {
      D: '',
      L: '',
      M: '',
      MI: '',
      J: '',
      V: '',
      S: '',
      DL: '',
      LL: '',
      ML: '',
      MIL: '',
      JL: '',
      VL: '',
      SL: '',
      DLdef: '',
      LLdef: '',
      MLdef: '',
      MILdef: '',
      JLdef: '',
      VLdef: '',
      SLdef: '',
    },
    {
      D: '',
      L: '',
      M: '',
      MI: '',
      J: '',
      V: '',
      S: '',
      DL: '',
      LL: '',
      ML: '',
      MIL: '',
      JL: '',
      VL: '',
      SL: '',
      DLdef: '',
      LLdef: '',
      MLdef: '',
      MILdef: '',
      JLdef: '',
      VLdef: '',
      SLdef: '',
    },
    {
      D: '',
      L: '',
      M: '',
      MI: '',
      J: '',
      V: '',
      S: '',
      DL: '',
      LL: '',
      ML: '',
      MIL: '',
      JL: '',
      VL: '',
      SL: '',
      DLdef: '',
      LLdef: '',
      MLdef: '',
      MILdef: '',
      JLdef: '',
      VLdef: '',
      SLdef: '',
    },
    {
      D: '',
      L: '',
      M: '',
      MI: '',
      J: '',
      V: '',
      S: '',
      DL: '',
      LL: '',
      ML: '',
      MIL: '',
      JL: '',
      VL: '',
      SL: '',
      DLdef: '',
      LLdef: '',
      MLdef: '',
      MILdef: '',
      JLdef: '',
      VLdef: '',
      SLdef: '',
    },
    {
      D: '',
      L: '',
      M: '',
      MI: '',
      J: '',
      V: '',
      S: '',
      DL: '',
      LL: '',
      ML: '',
      MIL: '',
      JL: '',
      VL: '',
      SL: '',
      DLdef: '',
      LLdef: '',
      MLdef: '',
      MILdef: '',
      JLdef: '',
      VLdef: '',
      SLdef: '',
    },
  ];
  month2: string;
  tableCols2 = ['L', 'M', 'M', 'J', 'V', 'S', 'D'];
  tableData2 = [
    {
      D: '',
      L: '',
      M: '',
      MI: '',
      J: '',
      V: '',
      S: '',
      DL: '',
      LL: '',
      ML: '',
      MIL: '',
      JL: '',
      VL: '',
      SL: '',
      DLdef: '',
      LLdef: '',
      MLdef: '',
      MILdef: '',
      JLdef: '',
      VLdef: '',
      SLdef: '',
    },
    {
      D: '',
      L: '',
      M: '',
      MI: '',
      J: '',
      V: '',
      S: '',
      DL: '',
      LL: '',
      ML: '',
      MIL: '',
      JL: '',
      VL: '',
      SL: '',
      DLdef: '',
      LLdef: '',
      MLdef: '',
      MILdef: '',
      JLdef: '',
      VLdef: '',
      SLdef: '',
    },
    {
      D: '',
      L: '',
      M: '',
      MI: '',
      J: '',
      V: '',
      S: '',
      DL: '',
      LL: '',
      ML: '',
      MIL: '',
      JL: '',
      VL: '',
      SL: '',
      DLdef: '',
      LLdef: '',
      MLdef: '',
      MILdef: '',
      JLdef: '',
      VLdef: '',
      SLdef: '',
    },
    {
      D: '',
      L: '',
      M: '',
      MI: '',
      J: '',
      V: '',
      S: '',
      DL: '',
      LL: '',
      ML: '',
      MIL: '',
      JL: '',
      VL: '',
      SL: '',
      DLdef: '',
      LLdef: '',
      MLdef: '',
      MILdef: '',
      JLdef: '',
      VLdef: '',
      SLdef: '',
    },
    {
      D: '',
      L: '',
      M: '',
      MI: '',
      J: '',
      V: '',
      S: '',
      DL: '',
      LL: '',
      ML: '',
      MIL: '',
      JL: '',
      VL: '',
      SL: '',
      DLdef: '',
      LLdef: '',
      MLdef: '',
      MILdef: '',
      JLdef: '',
      VLdef: '',
      SLdef: '',
    },
    {
      D: '',
      L: '',
      M: '',
      MI: '',
      J: '',
      V: '',
      S: '',
      DL: '',
      LL: '',
      ML: '',
      MIL: '',
      JL: '',
      VL: '',
      SL: '',
      DLdef: '',
      LLdef: '',
      MLdef: '',
      MILdef: '',
      JLdef: '',
      VLdef: '',
      SLdef: '',
    },
  ];
  month3: string;
  tableCols3 = ['L', 'M', 'M', 'J', 'V', 'S', 'D'];
  tableData3 = [
    {
      D: '',
      L: '',
      M: '',
      MI: '',
      J: '',
      V: '',
      S: '',
      DL: '',
      LL: '',
      ML: '',
      MIL: '',
      JL: '',
      VL: '',
      SL: '',
      DLdef: '',
      LLdef: '',
      MLdef: '',
      MILdef: '',
      JLdef: '',
      VLdef: '',
      SLdef: '',
    },
    {
      D: '',
      L: '',
      M: '',
      MI: '',
      J: '',
      V: '',
      S: '',
      DL: '',
      LL: '',
      ML: '',
      MIL: '',
      JL: '',
      VL: '',
      SL: '',
      DLdef: '',
      LLdef: '',
      MLdef: '',
      MILdef: '',
      JLdef: '',
      VLdef: '',
      SLdef: '',
    },
    {
      D: '',
      L: '',
      M: '',
      MI: '',
      J: '',
      V: '',
      S: '',
      DL: '',
      LL: '',
      ML: '',
      MIL: '',
      JL: '',
      VL: '',
      SL: '',
      DLdef: '',
      LLdef: '',
      MLdef: '',
      MILdef: '',
      JLdef: '',
      VLdef: '',
      SLdef: '',
    },
    {
      D: '',
      L: '',
      M: '',
      MI: '',
      J: '',
      V: '',
      S: '',
      DL: '',
      LL: '',
      ML: '',
      MIL: '',
      JL: '',
      VL: '',
      SL: '',
      DLdef: '',
      LLdef: '',
      MLdef: '',
      MILdef: '',
      JLdef: '',
      VLdef: '',
      SLdef: '',
    },
    {
      D: '',
      L: '',
      M: '',
      MI: '',
      J: '',
      V: '',
      S: '',
      DL: '',
      LL: '',
      ML: '',
      MIL: '',
      JL: '',
      VL: '',
      SL: '',
      DLdef: '',
      LLdef: '',
      MLdef: '',
      MILdef: '',
      JLdef: '',
      VLdef: '',
      SLdef: '',
    },
    {
      D: '',
      L: '',
      M: '',
      MI: '',
      J: '',
      V: '',
      S: '',
      DL: '',
      LL: '',
      ML: '',
      MIL: '',
      JL: '',
      VL: '',
      SL: '',
      DLdef: '',
      LLdef: '',
      MLdef: '',
      MILdef: '',
      JLdef: '',
      VLdef: '',
      SLdef: '',
    },
  ];
  month4: string;
  tableCols4 = ['L', 'M', 'M', 'J', 'V', 'S', 'D'];
  tableData4 = [
    {
      D: '',
      L: '',
      M: '',
      MI: '',
      J: '',
      V: '',
      S: '',
      DL: '',
      LL: '',
      ML: '',
      MIL: '',
      JL: '',
      VL: '',
      SL: '',
      DLdef: '',
      LLdef: '',
      MLdef: '',
      MILdef: '',
      JLdef: '',
      VLdef: '',
      SLdef: '',
    },
    {
      D: '',
      L: '',
      M: '',
      MI: '',
      J: '',
      V: '',
      S: '',
      DL: '',
      LL: '',
      ML: '',
      MIL: '',
      JL: '',
      VL: '',
      SL: '',
      DLdef: '',
      LLdef: '',
      MLdef: '',
      MILdef: '',
      JLdef: '',
      VLdef: '',
      SLdef: '',
    },
    {
      D: '',
      L: '',
      M: '',
      MI: '',
      J: '',
      V: '',
      S: '',
      DL: '',
      LL: '',
      ML: '',
      MIL: '',
      JL: '',
      VL: '',
      SL: '',
      DLdef: '',
      LLdef: '',
      MLdef: '',
      MILdef: '',
      JLdef: '',
      VLdef: '',
      SLdef: '',
    },
    {
      D: '',
      L: '',
      M: '',
      MI: '',
      J: '',
      V: '',
      S: '',
      DL: '',
      LL: '',
      ML: '',
      MIL: '',
      JL: '',
      VL: '',
      SL: '',
      DLdef: '',
      LLdef: '',
      MLdef: '',
      MILdef: '',
      JLdef: '',
      VLdef: '',
      SLdef: '',
    },
    {
      D: '',
      L: '',
      M: '',
      MI: '',
      J: '',
      V: '',
      S: '',
      DL: '',
      LL: '',
      ML: '',
      MIL: '',
      JL: '',
      VL: '',
      SL: '',
      DLdef: '',
      LLdef: '',
      MLdef: '',
      MILdef: '',
      JLdef: '',
      VLdef: '',
      SLdef: '',
    },
    {
      D: '',
      L: '',
      M: '',
      MI: '',
      J: '',
      V: '',
      S: '',
      DL: '',
      LL: '',
      ML: '',
      MIL: '',
      JL: '',
      VL: '',
      SL: '',
      DLdef: '',
      LLdef: '',
      MLdef: '',
      MILdef: '',
      JLdef: '',
      VLdef: '',
      SLdef: '',
    },
  ];
  month5: string;
  tableCols5 = ['L', 'M', 'M', 'J', 'V', 'S', 'D'];
  tableData5 = [
    {
      D: '',
      L: '',
      M: '',
      MI: '',
      J: '',
      V: '',
      S: '',
      DL: '',
      LL: '',
      ML: '',
      MIL: '',
      JL: '',
      VL: '',
      SL: '',
      DLdef: '',
      LLdef: '',
      MLdef: '',
      MILdef: '',
      JLdef: '',
      VLdef: '',
      SLdef: '',
    },
    {
      D: '',
      L: '',
      M: '',
      MI: '',
      J: '',
      V: '',
      S: '',
      DL: '',
      LL: '',
      ML: '',
      MIL: '',
      JL: '',
      VL: '',
      SL: '',
      DLdef: '',
      LLdef: '',
      MLdef: '',
      MILdef: '',
      JLdef: '',
      VLdef: '',
      SLdef: '',
    },
    {
      D: '',
      L: '',
      M: '',
      MI: '',
      J: '',
      V: '',
      S: '',
      DL: '',
      LL: '',
      ML: '',
      MIL: '',
      JL: '',
      VL: '',
      SL: '',
      DLdef: '',
      LLdef: '',
      MLdef: '',
      MILdef: '',
      JLdef: '',
      VLdef: '',
      SLdef: '',
    },
    {
      D: '',
      L: '',
      M: '',
      MI: '',
      J: '',
      V: '',
      S: '',
      DL: '',
      LL: '',
      ML: '',
      MIL: '',
      JL: '',
      VL: '',
      SL: '',
      DLdef: '',
      LLdef: '',
      MLdef: '',
      MILdef: '',
      JLdef: '',
      VLdef: '',
      SLdef: '',
    },
    {
      D: '',
      L: '',
      M: '',
      MI: '',
      J: '',
      V: '',
      S: '',
      DL: '',
      LL: '',
      ML: '',
      MIL: '',
      JL: '',
      VL: '',
      SL: '',
      DLdef: '',
      LLdef: '',
      MLdef: '',
      MILdef: '',
      JLdef: '',
      VLdef: '',
      SLdef: '',
    },
    {
      D: '',
      L: '',
      M: '',
      MI: '',
      J: '',
      V: '',
      S: '',
      DL: '',
      LL: '',
      ML: '',
      MIL: '',
      JL: '',
      VL: '',
      SL: '',
      DLdef: '',
      LLdef: '',
      MLdef: '',
      MILdef: '',
      JLdef: '',
      VLdef: '',
      SLdef: '',
    },
  ];
  month6: string;
  tableCols6 = ['L', 'M', 'M', 'J', 'V', 'S', 'D'];
  tableData6 = [
    {
      D: '',
      L: '',
      M: '',
      MI: '',
      J: '',
      V: '',
      S: '',
      DL: '',
      LL: '',
      ML: '',
      MIL: '',
      JL: '',
      VL: '',
      SL: '',
      DLdef: '',
      LLdef: '',
      MLdef: '',
      MILdef: '',
      JLdef: '',
      VLdef: '',
      SLdef: '',
    },
    {
      D: '',
      L: '',
      M: '',
      MI: '',
      J: '',
      V: '',
      S: '',
      DL: '',
      LL: '',
      ML: '',
      MIL: '',
      JL: '',
      VL: '',
      SL: '',
      DLdef: '',
      LLdef: '',
      MLdef: '',
      MILdef: '',
      JLdef: '',
      VLdef: '',
      SLdef: '',
    },
    {
      D: '',
      L: '',
      M: '',
      MI: '',
      J: '',
      V: '',
      S: '',
      DL: '',
      LL: '',
      ML: '',
      MIL: '',
      JL: '',
      VL: '',
      SL: '',
      DLdef: '',
      LLdef: '',
      MLdef: '',
      MILdef: '',
      JLdef: '',
      VLdef: '',
      SLdef: '',
    },
    {
      D: '',
      L: '',
      M: '',
      MI: '',
      J: '',
      V: '',
      S: '',
      DL: '',
      LL: '',
      ML: '',
      MIL: '',
      JL: '',
      VL: '',
      SL: '',
      DLdef: '',
      LLdef: '',
      MLdef: '',
      MILdef: '',
      JLdef: '',
      VLdef: '',
      SLdef: '',
    },
    {
      D: '',
      L: '',
      M: '',
      MI: '',
      J: '',
      V: '',
      S: '',
      DL: '',
      LL: '',
      ML: '',
      MIL: '',
      JL: '',
      VL: '',
      SL: '',
      DLdef: '',
      LLdef: '',
      MLdef: '',
      MILdef: '',
      JLdef: '',
      VLdef: '',
      SLdef: '',
    },
    {
      D: '',
      L: '',
      M: '',
      MI: '',
      J: '',
      V: '',
      S: '',
      DL: '',
      LL: '',
      ML: '',
      MIL: '',
      JL: '',
      VL: '',
      SL: '',
      DLdef: '',
      LLdef: '',
      MLdef: '',
      MILdef: '',
      JLdef: '',
      VLdef: '',
      SLdef: '',
    },
  ];
  month7: string;
  tableCols7 = ['L', 'M', 'M', 'J', 'V', 'S', 'D'];
  tableData7 = [
    {
      D: '',
      L: '',
      M: '',
      MI: '',
      J: '',
      V: '',
      S: '',
      DL: '',
      LL: '',
      ML: '',
      MIL: '',
      JL: '',
      VL: '',
      SL: '',
      DLdef: '',
      LLdef: '',
      MLdef: '',
      MILdef: '',
      JLdef: '',
      VLdef: '',
      SLdef: '',
    },
    {
      D: '',
      L: '',
      M: '',
      MI: '',
      J: '',
      V: '',
      S: '',
      DL: '',
      LL: '',
      ML: '',
      MIL: '',
      JL: '',
      VL: '',
      SL: '',
      DLdef: '',
      LLdef: '',
      MLdef: '',
      MILdef: '',
      JLdef: '',
      VLdef: '',
      SLdef: '',
    },
    {
      D: '',
      L: '',
      M: '',
      MI: '',
      J: '',
      V: '',
      S: '',
      DL: '',
      LL: '',
      ML: '',
      MIL: '',
      JL: '',
      VL: '',
      SL: '',
      DLdef: '',
      LLdef: '',
      MLdef: '',
      MILdef: '',
      JLdef: '',
      VLdef: '',
      SLdef: '',
    },
    {
      D: '',
      L: '',
      M: '',
      MI: '',
      J: '',
      V: '',
      S: '',
      DL: '',
      LL: '',
      ML: '',
      MIL: '',
      JL: '',
      VL: '',
      SL: '',
      DLdef: '',
      LLdef: '',
      MLdef: '',
      MILdef: '',
      JLdef: '',
      VLdef: '',
      SLdef: '',
    },
    {
      D: '',
      L: '',
      M: '',
      MI: '',
      J: '',
      V: '',
      S: '',
      DL: '',
      LL: '',
      ML: '',
      MIL: '',
      JL: '',
      VL: '',
      SL: '',
      DLdef: '',
      LLdef: '',
      MLdef: '',
      MILdef: '',
      JLdef: '',
      VLdef: '',
      SLdef: '',
    },
    {
      D: '',
      L: '',
      M: '',
      MI: '',
      J: '',
      V: '',
      S: '',
      DL: '',
      LL: '',
      ML: '',
      MIL: '',
      JL: '',
      VL: '',
      SL: '',
      DLdef: '',
      LLdef: '',
      MLdef: '',
      MILdef: '',
      JLdef: '',
      VLdef: '',
      SLdef: '',
    },
  ];
  month8: string;
  tableCols8 = ['L', 'M', 'M', 'J', 'V', 'S', 'D'];
  tableData8 = [
    {
      D: '',
      L: '',
      M: '',
      MI: '',
      J: '',
      V: '',
      S: '',
      DL: '',
      LL: '',
      ML: '',
      MIL: '',
      JL: '',
      VL: '',
      SL: '',
      DLdef: '',
      LLdef: '',
      MLdef: '',
      MILdef: '',
      JLdef: '',
      VLdef: '',
      SLdef: '',
    },
    {
      D: '',
      L: '',
      M: '',
      MI: '',
      J: '',
      V: '',
      S: '',
      DL: '',
      LL: '',
      ML: '',
      MIL: '',
      JL: '',
      VL: '',
      SL: '',
      DLdef: '',
      LLdef: '',
      MLdef: '',
      MILdef: '',
      JLdef: '',
      VLdef: '',
      SLdef: '',
    },
    {
      D: '',
      L: '',
      M: '',
      MI: '',
      J: '',
      V: '',
      S: '',
      DL: '',
      LL: '',
      ML: '',
      MIL: '',
      JL: '',
      VL: '',
      SL: '',
      DLdef: '',
      LLdef: '',
      MLdef: '',
      MILdef: '',
      JLdef: '',
      VLdef: '',
      SLdef: '',
    },
    {
      D: '',
      L: '',
      M: '',
      MI: '',
      J: '',
      V: '',
      S: '',
      DL: '',
      LL: '',
      ML: '',
      MIL: '',
      JL: '',
      VL: '',
      SL: '',
      DLdef: '',
      LLdef: '',
      MLdef: '',
      MILdef: '',
      JLdef: '',
      VLdef: '',
      SLdef: '',
    },
    {
      D: '',
      L: '',
      M: '',
      MI: '',
      J: '',
      V: '',
      S: '',
      DL: '',
      LL: '',
      ML: '',
      MIL: '',
      JL: '',
      VL: '',
      SL: '',
      DLdef: '',
      LLdef: '',
      MLdef: '',
      MILdef: '',
      JLdef: '',
      VLdef: '',
      SLdef: '',
    },
    {
      D: '',
      L: '',
      M: '',
      MI: '',
      J: '',
      V: '',
      S: '',
      DL: '',
      LL: '',
      ML: '',
      MIL: '',
      JL: '',
      VL: '',
      SL: '',
      DLdef: '',
      LLdef: '',
      MLdef: '',
      MILdef: '',
      JLdef: '',
      VLdef: '',
      SLdef: '',
    },
  ];
  month9: string;
  tableCols9 = ['L', 'M', 'M', 'J', 'V', 'S', 'D'];
  tableData9 = [
    {
      D: '',
      L: '',
      M: '',
      MI: '',
      J: '',
      V: '',
      S: '',
      DL: '',
      LL: '',
      ML: '',
      MIL: '',
      JL: '',
      VL: '',
      SL: '',
      DLdef: '',
      LLdef: '',
      MLdef: '',
      MILdef: '',
      JLdef: '',
      VLdef: '',
      SLdef: '',
    },
    {
      D: '',
      L: '',
      M: '',
      MI: '',
      J: '',
      V: '',
      S: '',
      DL: '',
      LL: '',
      ML: '',
      MIL: '',
      JL: '',
      VL: '',
      SL: '',
      DLdef: '',
      LLdef: '',
      MLdef: '',
      MILdef: '',
      JLdef: '',
      VLdef: '',
      SLdef: '',
    },
    {
      D: '',
      L: '',
      M: '',
      MI: '',
      J: '',
      V: '',
      S: '',
      DL: '',
      LL: '',
      ML: '',
      MIL: '',
      JL: '',
      VL: '',
      SL: '',
      DLdef: '',
      LLdef: '',
      MLdef: '',
      MILdef: '',
      JLdef: '',
      VLdef: '',
      SLdef: '',
    },
    {
      D: '',
      L: '',
      M: '',
      MI: '',
      J: '',
      V: '',
      S: '',
      DL: '',
      LL: '',
      ML: '',
      MIL: '',
      JL: '',
      VL: '',
      SL: '',
      DLdef: '',
      LLdef: '',
      MLdef: '',
      MILdef: '',
      JLdef: '',
      VLdef: '',
      SLdef: '',
    },
    {
      D: '',
      L: '',
      M: '',
      MI: '',
      J: '',
      V: '',
      S: '',
      DL: '',
      LL: '',
      ML: '',
      MIL: '',
      JL: '',
      VL: '',
      SL: '',
      DLdef: '',
      LLdef: '',
      MLdef: '',
      MILdef: '',
      JLdef: '',
      VLdef: '',
      SLdef: '',
    },
    {
      D: '',
      L: '',
      M: '',
      MI: '',
      J: '',
      V: '',
      S: '',
      DL: '',
      LL: '',
      ML: '',
      MIL: '',
      JL: '',
      VL: '',
      SL: '',
      DLdef: '',
      LLdef: '',
      MLdef: '',
      MILdef: '',
      JLdef: '',
      VLdef: '',
      SLdef: '',
    },
  ];
  month10: string;
  tableCols10 = ['L', 'M', 'M', 'J', 'V', 'S', 'D'];
  tableData10 = [
    {
      D: '',
      L: '',
      M: '',
      MI: '',
      J: '',
      V: '',
      S: '',
      DL: '',
      LL: '',
      ML: '',
      MIL: '',
      JL: '',
      VL: '',
      SL: '',
      DLdef: '',
      LLdef: '',
      MLdef: '',
      MILdef: '',
      JLdef: '',
      VLdef: '',
      SLdef: '',
    },
    {
      D: '',
      L: '',
      M: '',
      MI: '',
      J: '',
      V: '',
      S: '',
      DL: '',
      LL: '',
      ML: '',
      MIL: '',
      JL: '',
      VL: '',
      SL: '',
      DLdef: '',
      LLdef: '',
      MLdef: '',
      MILdef: '',
      JLdef: '',
      VLdef: '',
      SLdef: '',
    },
    {
      D: '',
      L: '',
      M: '',
      MI: '',
      J: '',
      V: '',
      S: '',
      DL: '',
      LL: '',
      ML: '',
      MIL: '',
      JL: '',
      VL: '',
      SL: '',
      DLdef: '',
      LLdef: '',
      MLdef: '',
      MILdef: '',
      JLdef: '',
      VLdef: '',
      SLdef: '',
    },
    {
      D: '',
      L: '',
      M: '',
      MI: '',
      J: '',
      V: '',
      S: '',
      DL: '',
      LL: '',
      ML: '',
      MIL: '',
      JL: '',
      VL: '',
      SL: '',
      DLdef: '',
      LLdef: '',
      MLdef: '',
      MILdef: '',
      JLdef: '',
      VLdef: '',
      SLdef: '',
    },
    {
      D: '',
      L: '',
      M: '',
      MI: '',
      J: '',
      V: '',
      S: '',
      DL: '',
      LL: '',
      ML: '',
      MIL: '',
      JL: '',
      VL: '',
      SL: '',
      DLdef: '',
      LLdef: '',
      MLdef: '',
      MILdef: '',
      JLdef: '',
      VLdef: '',
      SLdef: '',
    },
    {
      D: '',
      L: '',
      M: '',
      MI: '',
      J: '',
      V: '',
      S: '',
      DL: '',
      LL: '',
      ML: '',
      MIL: '',
      JL: '',
      VL: '',
      SL: '',
      DLdef: '',
      LLdef: '',
      MLdef: '',
      MILdef: '',
      JLdef: '',
      VLdef: '',
      SLdef: '',
    },
  ];
  month11: string;
  tableCols11 = ['L', 'M', 'M', 'J', 'V', 'S', 'D'];
  tableData11 = [
    {
      D: '',
      L: '',
      M: '',
      MI: '',
      J: '',
      V: '',
      S: '',
      DL: '',
      LL: '',
      ML: '',
      MIL: '',
      JL: '',
      VL: '',
      SL: '',
      DLdef: '',
      LLdef: '',
      MLdef: '',
      MILdef: '',
      JLdef: '',
      VLdef: '',
      SLdef: '',
    },
    {
      D: '',
      L: '',
      M: '',
      MI: '',
      J: '',
      V: '',
      S: '',
      DL: '',
      LL: '',
      ML: '',
      MIL: '',
      JL: '',
      VL: '',
      SL: '',
      DLdef: '',
      LLdef: '',
      MLdef: '',
      MILdef: '',
      JLdef: '',
      VLdef: '',
      SLdef: '',
    },
    {
      D: '',
      L: '',
      M: '',
      MI: '',
      J: '',
      V: '',
      S: '',
      DL: '',
      LL: '',
      ML: '',
      MIL: '',
      JL: '',
      VL: '',
      SL: '',
      DLdef: '',
      LLdef: '',
      MLdef: '',
      MILdef: '',
      JLdef: '',
      VLdef: '',
      SLdef: '',
    },
    {
      D: '',
      L: '',
      M: '',
      MI: '',
      J: '',
      V: '',
      S: '',
      DL: '',
      LL: '',
      ML: '',
      MIL: '',
      JL: '',
      VL: '',
      SL: '',
      DLdef: '',
      LLdef: '',
      MLdef: '',
      MILdef: '',
      JLdef: '',
      VLdef: '',
      SLdef: '',
    },
    {
      D: '',
      L: '',
      M: '',
      MI: '',
      J: '',
      V: '',
      S: '',
      DL: '',
      LL: '',
      ML: '',
      MIL: '',
      JL: '',
      VL: '',
      SL: '',
      DLdef: '',
      LLdef: '',
      MLdef: '',
      MILdef: '',
      JLdef: '',
      VLdef: '',
      SLdef: '',
    },
    {
      D: '',
      L: '',
      M: '',
      MI: '',
      J: '',
      V: '',
      S: '',
      DL: '',
      LL: '',
      ML: '',
      MIL: '',
      JL: '',
      VL: '',
      SL: '',
      DLdef: '',
      LLdef: '',
      MLdef: '',
      MILdef: '',
      JLdef: '',
      VLdef: '',
      SLdef: '',
    },
  ];
  month12: string;
  tableCols12 = ['L', 'M', 'M', 'J', 'V', 'S', 'D'];
  tableData12 = [
    {
      D: '',
      L: '',
      M: '',
      MI: '',
      J: '',
      V: '',
      S: '',
      DL: '',
      LL: '',
      ML: '',
      MIL: '',
      JL: '',
      VL: '',
      SL: '',
      DLdef: '',
      LLdef: '',
      MLdef: '',
      MILdef: '',
      JLdef: '',
      VLdef: '',
      SLdef: '',
    },
    {
      D: '',
      L: '',
      M: '',
      MI: '',
      J: '',
      V: '',
      S: '',
      DL: '',
      LL: '',
      ML: '',
      MIL: '',
      JL: '',
      VL: '',
      SL: '',
      DLdef: '',
      LLdef: '',
      MLdef: '',
      MILdef: '',
      JLdef: '',
      VLdef: '',
      SLdef: '',
    },
    {
      D: '',
      L: '',
      M: '',
      MI: '',
      J: '',
      V: '',
      S: '',
      DL: '',
      LL: '',
      ML: '',
      MIL: '',
      JL: '',
      VL: '',
      SL: '',
      DLdef: '',
      LLdef: '',
      MLdef: '',
      MILdef: '',
      JLdef: '',
      VLdef: '',
      SLdef: '',
    },
    {
      D: '',
      L: '',
      M: '',
      MI: '',
      J: '',
      V: '',
      S: '',
      DL: '',
      LL: '',
      ML: '',
      MIL: '',
      JL: '',
      VL: '',
      SL: '',
      DLdef: '',
      LLdef: '',
      MLdef: '',
      MILdef: '',
      JLdef: '',
      VLdef: '',
      SLdef: '',
    },
    {
      D: '',
      L: '',
      M: '',
      MI: '',
      J: '',
      V: '',
      S: '',
      DL: '',
      LL: '',
      ML: '',
      MIL: '',
      JL: '',
      VL: '',
      SL: '',
      DLdef: '',
      LLdef: '',
      MLdef: '',
      MILdef: '',
      JLdef: '',
      VLdef: '',
      SLdef: '',
    },
    {
      D: '',
      L: '',
      M: '',
      MI: '',
      J: '',
      V: '',
      S: '',
      DL: '',
      LL: '',
      ML: '',
      MIL: '',
      JL: '',
      VL: '',
      SL: '',
      DLdef: '',
      LLdef: '',
      MLdef: '',
      MILdef: '',
      JLdef: '',
      VLdef: '',
      SLdef: '',
    },
  ];
  leyesDOF: LeyesDOF[];
  leyesDOFFiltered: LeyesDOF[];
  subLeyesDOF: any;

  tipoLeyes: LawType[];

  leyesAbrogadas: Law[];

  ArtIxComponent;
  favoriteLaws: Law2[];
  leyesEstatales: LeyesEstatales2[];
  sumarios: Sumario[];
  TitReformasConst: Titulos[];
  TitLeyesFederalesar: Titulos[];
  TitMarcoJuridicoar: Titulos[];
  TitPieUnoar: Titulos[];
  DescReformasConst: Titulos[];
  DescTitLeyesFederalesAr: Titulos[];
  DescTitPieUnoAr: Titulos[];
  DescTitMarcoJuridicoAr: Titulos[];
  TelPieLeyesAr: Titulos[];
  CorreoPieLeyesAr: Titulos[];
  TitulosLeyes: Titulos[];
  CarReformasConst: ReformasConstc[];
  loading = false;
  loadingLv = true;
  error: any;
  p = 1;
  collection = [];
  config: any;
  Legislatura: LegislaturaLeyes[];
  Link: Link[];
  Linkb: Linkb[];
  suballLegislaturas: any;
  suballLinks: any;
  suballLinksb: any;
  subTypes: any;
  subLaws: any;
  subNormas: any;
  subMarco: any;
  subFavoriteLaws: any;
  subLeyesEstatales: any;
  subSumarios: any;
  subTitulosReformasConst: any;
  subTituloLeyesFederales: any;
  subTituloMarcoJuridico: any;
  subTituloPieUno: any;
  subDescReformasConst: any;
  subDescTitLeyesFederales: any;
  subDescTitpieUno: any;
  subDescTitMarcoJuridico: any;
  subTelPieLeyes: any;
  subCorreoPieLeyes: any;
  subTitulosLeyes: any;
  subCarReformasConst: any;
  loggedIn = false;
  emailVisitante = '';
  leg: any;
  legis: any;
  noleg: any;
  activeLink: Law;
  querySub: any;
  opcion: any;
  produccion: boolean;
  marco: Law[];
  visitas: any;

  constructor(
    public dialog: MatDialog,
    private apollo: Apollo,
    private route: ActivatedRoute,
    private router: Router,
    private api: GraphqlService
  ) {
    this.config = {
      currentPage: 1,
      itemsPerPage: 2,
    };

    this.route.queryParamMap
      .pipe(map((params) => params.get('page')))
      .subscribe((page) => (this.config.currentPage = page));

    for (let i = 1; i <= 101; i++) {
      this.collection.push(`item ${i}`);
    }
  }

  ngOnInit(): any {
    this.produccion = environment.production;
    this.addViewToPage('c4c0cdad-e9ae-454b-9e70-25d2da460c84');
    this.suballLinks = this.apollo
      .watchQuery<ResponseallLinks>({
        query: gql`
          {
            allLinks {
              Oid
              Titulo
              LinkTitulo
              Tipo
              Descripcion
              Nota
            }
          }
        `,
      })
      .valueChanges.subscribe((result) => {
        this.Link = result.data && result.data.allLinks;
        this.loading = result.loading;
        this.error = result.errors;
      });
    this.suballLinksb = this.apollo
      .watchQuery<ResponseallLinks>({
        query: gql`
          {
            allLinksb {
              Oid
              Titulo
              LinkTitulo
              Tipo
              Descripcion
              Nota
            }
          }
        `,
      })
      .valueChanges.subscribe((result) => {
        this.Linkb = result.data && result.data.allLinksb;
        this.loading = result.loading;
        this.error = result.errors;
      });
    this.querySub = this.route.queryParams.subscribe((params) => {
      // Defaults to 0 if no query param provided.
      this.opcion = params.opcion || 'lVigentes';
      console.log('params', this.opcion);
      if (this.opcion === 'marco') {
        const tabCount = 2;
        this.tabla1 = (this.tabla1 + 1) % tabCount;
      } else if (this.opcion === 'lVigentes') {
        const tabCount = 1;
        this.tabla1 = this.tabla1 % tabCount;
      }
      // this.oidpadre = this.oid;
      // console.log('llegó oidCategoria', this.categoria);
    });

    this.suballLegislaturas = this.apollo
      .watchQuery<ResponseallLegislaturasLeyes>({
        query: gql`
          {
            allLegislaturasLeyes {
              Oid
              Numero
              FechaInicio
              FechaFin
              Descripcion
              Link
              Romano
            }
          }
        `,
      })
      .valueChanges.subscribe((result) => {
        this.Legislatura = result.data && result.data.allLegislaturasLeyes;
        this.loading = result.loading;
        this.error = result.errors;
      });
    this.subCarReformasConst = this.apollo
      .watchQuery<ResponsecaracReformasConst>({
        query: gql`
          {
            ReformasConstCar {
              Link
              FErratatxt
              FerrataImagen
              Imagen
              Publicacion
              Nombre
              Descripcion
              Id
              Pdf
              Word
              Android
              Notas
              PublicacionOriginal
              FechaPublicacionstr
            }
          }
        `,
      })
      .valueChanges.subscribe((result) => {
        this.CarReformasConst = result.data && result.data.ReformasConstCar;
        // console.log('CARREFORMASCONST: !!!', this.CarReformasConst);
        this.loading = result.loading;
        this.error = result.errors;
      });
    this.verifyVisitantSession();

    this.subLaws = this.apollo
      .watchQuery<ResponseLaws>({
        query: gql`
          {
            allLawsAbrogadas {
              Oid
              Id
              Nombre
              Version
              UltimaReforma
              MarcoJuridico
              Nuevo
              UltimaReforma
              Url
              UrlWord
              Notas
              UrlPdf
              UrlAndroid
              TipoLey
              PublicacionOriginal
            }
          }
        `,
      })
      .valueChanges.subscribe((result) => {
        this.leyesAbrogadas = result.data && result.data.allLawsAbrogadas;
        this.loading = result.loading;
        this.error = result.errors;
      });
    this.subMarco = this.apollo
      .watchQuery<ResponseLaws>({
        query: gql`
          {
            allMarco {
              Oid
              Id
              Nombre
              Nuevo
              NuevasReformas
              EnActualizacion
              Version
              PublicacionOriginal
              UltimaReforma
              Url
              UrlWord
              UrlPdf
              UrlAndroid
              TipoLey
            }
          }
        `,
      })
      .valueChanges.subscribe((result) => {
        this.marco = result.data && result.data.allMarco;
        this.activeLink = this.marco[1];
        this.loading = result.loading;
        this.error = result.errors;
      });
    this.subLeyesEstatales = this.apollo
      .watchQuery<ResponseLeyesEstatales2>({
        query: gql`
          {
            allLeyesEstatales2 {
              Numero
              Nombre
              SFTPArchivoImagen
              NombreLegislacion
              LinkOrigen
              TipoLegislacion
              LinkOrigenLegislatura
              Titulo
            }
          }
        `,
      })
      .valueChanges.subscribe((result) => {
        this.leyesEstatales = result.data && result.data.allLeyesEstatales2;
        this.loading = result.loading;
        this.error = result.errors;
      });
    this.subSumarios = this.apollo
      .watchQuery<ResponseSumarios>({
        query: gql`
          {
            allSumariosReforma {
              Oid
              Consecutivo
              Titulo
              FechaActualizacion
              SFTPCronoArchivoWord
              SFTPCronoArchivoPDF
              SFTPArtArchivoWord
              SFTPArtArchivoPDF
              SFTPPresArchivoWord
              SFTPPresArchivoPDF
            }
          }
        `,
      })
      .valueChanges.subscribe((result) => {
        this.sumarios = result.data && result.data.allSumariosReforma;
        console.log('Sumarios Reforma :::', this.sumarios);
        this.loading = result.loading;
        this.error = result.errors;
      });
    this.subTitulosReformasConst = this.apollo
      .watchQuery<ResponseTitulosReformasConst>({
        query: gql`
          {
            TitReformasConst {
              Descripcion
            }
          }
        `,
      })
      .valueChanges.subscribe((result) => {
        this.TitReformasConst = result.data && result.data.TitReformasConst;
        // console.log('***ferTitReformasConst trae:', this.TitReformasConst);
        this.loading = result.loading;
        this.error = result.errors;
      });

    this.subTituloMarcoJuridico = this.apollo
      .watchQuery<ResponseTituloMarcoJurdico>({
        query: gql`
          {
            TitMarcoJuridico {
              Descripcion
            }
          }
        `,
      })
      .valueChanges.subscribe((result) => {
        this.TitMarcoJuridicoar = result.data && result.data.TitMarcoJuridico;
        // // console.log('**fer TitMarcoJuridicoar trae', this.TitMarcoJuridicoar);
        this.loading = result.loading;
        this.error = result.errors;
      });
    this.subTituloPieUno = this.apollo
      .watchQuery<ResponseTituloPieUno>({
        query: gql`
          {
            TitPieUno {
              Titulo
              Descripcion
            }
          }
        `,
      })
      .valueChanges.subscribe((result) => {
        this.TitPieUnoar = result.data && result.data.TitPieUno;
        console.log('**fer TitPieUnoar trae', this.TitPieUnoar);
        this.loading = result.loading;
        this.error = result.errors;
      });
    this.subTitulosLeyes = this.apollo
      .watchQuery<ResponseTitReformasConst>({
        query: gql`
          {
            TitulosLeyes {
              Nombre
              Descripcion
            }
          }
        `,
      })
      .valueChanges.subscribe((result) => {
        this.TitulosLeyes = result.data && result.data.TitulosLeyes;
        // // console.log('**fer TitulosLeyes trae', this.TitulosLeyes);
        this.loading = result.loading;
        this.error = result.errors;
      });
    this.subDescReformasConst = this.apollo
      .watchQuery<ResponseDescriReformasConst>({
        query: gql`
          {
            DescReformasConst {
              Descripcion
            }
          }
        `,
      })
      .valueChanges.subscribe((result) => {
        this.DescReformasConst = result.data && result.data.DescReformasConst;
        this.loading = result.loading;
        this.error = result.errors;
      });

    this.subDescTitMarcoJuridico = this.apollo
      .watchQuery<ResponseDescTitMarcoJuridico>({
        query: gql`
          {
            DescTitMarcoJuridico {
              Descripcion
            }
          }
        `,
      })
      .valueChanges.subscribe((result) => {
        this.DescTitMarcoJuridicoAr =
          result.data && result.data.DescTitMarcoJuridico;
        this.loading = result.loading;
        this.error = result.errors;
      });
    this.subTelPieLeyes = this.apollo
      .watchQuery<ResponseTelPieLeyes>({
        query: gql`
          {
            TelefonoPieLeyes {
              Descripcion
            }
          }
        `,
      })
      .valueChanges.subscribe((result) => {
        this.TelPieLeyesAr = result.data && result.data.TelefonoPieLeyes;
        this.loading = result.loading;
        this.error = result.errors;
      });
    this.subCorreoPieLeyes = this.apollo
      .watchQuery<ResponseCorreoPieLeyes>({
        query: gql`
          {
            CorreoPieLeyes {
              Descripcion
            }
          }
        `,
      })
      .valueChanges.subscribe((result) => {
        this.CorreoPieLeyesAr = result.data && result.data.CorreoPieLeyes;
        // console.log('DescTitMarcoJuridico', this.DescTitMarcoJuridicoAr);

        this.loading = result.loading;
        this.error = result.errors;
      });

    this.fillYears();
    this.fillLeyesDOF(this.arrYears.slice().reverse().slice(0, 6).join(','));
    // this.fillSelectorYears();
  }

  tabChanged = (tabChangeEvent: MatTabChangeEvent): void => {
    console.log('tabChangeEvent => ', tabChangeEvent);
    console.log('index => ', tabChangeEvent.index);
  };

  fillLeyesDOF(Years: string): void {
    this.subLeyesDOF = this.apollo
      .watchQuery<ResponseLeyesDOF>({
        query: gql`
          {
            allLeyesDOF(Years: "${Years}") {
              Fecha
              Link
            }
          }
        `,
      })
      .valueChanges.subscribe((result) => {
        this.leyesDOF = result.data && result.data.allLeyesDOF;
        this.fillSelectorYears();
      });
  }

  fillYears(): any {
    const d = new Date();
    const y = d.getFullYear();
    for (let i = startYear; i <= y; i++) {
      this.arrYears.push(i.toString());
    }
  }

  fillSelectorYears(): any {
    this.selectedOptionYear = this.arrYears[
      this.arrYears.length - 1
    ].toString();
    if (this.selectedOptionYear !== '') {
      this.onChange_selectedOptionYear(this.selectedOptionYear);
    }
  }

  onChange_selectedOptionYear(idselectedOptionYear: string): void {
    this.selectedOptionYear = idselectedOptionYear;

    if (this.selectedOptionYear !== '') {
      this.leyesDOFFiltered = _.filter(this.leyesDOF, function (d) {
        return (
          new Date(
            new Date(d.Fecha).getFullYear(),
            new Date(d.Fecha).getMonth(),
            new Date(d.Fecha).getDate()
          ).setHours(0, 0, 0, 0) >=
            new Date(parseInt(idselectedOptionYear), 0, 1).setHours(
              0,
              0,
              0,
              0
            ) &&
          new Date(
            new Date(d.Fecha).getFullYear(),
            new Date(d.Fecha).getMonth(),
            new Date(d.Fecha).getDate()
          ).setHours(0, 0, 0, 0) <=
            // tslint:disable-next-line: radix
            new Date(parseInt(idselectedOptionYear), 11, 31).setHours(
              0,
              0,
              0,
              0
            )
        );
      });
      // tslint:disable-next-line: radix
      this.fillCalendars(parseInt(this.selectedOptionYear));
    }
  }

  getClass(value: string, def: string): any {
    let styleClass = '';
    if (def === '*') {
      styleClass = 'inactivo';
      return styleClass;
    }
    switch (value) {
      case 'APS': {
        styleClass = 'sistema';
        break;
      }
      case 'APCO': {
        styleClass = 'comision';
        break;
      }
      case 'IJ': {
        styleClass = 'justificada';
        break;
      }
      case 'IV': {
        styleClass = 'votacion';
        break;
      }
      case 'AC': {
        styleClass = 'cedula';
        break;
      }
      case 'PMD': {
        styleClass = 'permiso';
        break;
      }
      case 'I': {
        styleClass = 'inasistencia';
        break;
      }
      case 'V': {
        styleClass = 'valido';
        break;
      }
      // case '*': {styleClass = 'inactivo';break;}
      default: {
        styleClass = '';
        break;
      }
    }
    return styleClass;
  }

  fillCalendars(year: number): any {
    this.month1 = this.DisplayMonth(1);
    this.fillNumbersInMonth(this.tableData1, 1, year);
    this.month2 = this.DisplayMonth(2);
    this.fillNumbersInMonth(this.tableData2, 2, year);
    this.month3 = this.DisplayMonth(3);
    this.fillNumbersInMonth(this.tableData3, 3, year);
    this.month4 = this.DisplayMonth(4);
    this.fillNumbersInMonth(this.tableData4, 4, year);
    this.month5 = this.DisplayMonth(5);
    this.fillNumbersInMonth(this.tableData5, 5, year);
    this.month6 = this.DisplayMonth(6);
    this.fillNumbersInMonth(this.tableData6, 6, year);
    this.month7 = this.DisplayMonth(7);
    this.fillNumbersInMonth(this.tableData7, 7, year);
    this.month8 = this.DisplayMonth(8);
    this.fillNumbersInMonth(this.tableData8, 8, year);
    this.month9 = this.DisplayMonth(9);
    this.fillNumbersInMonth(this.tableData9, 9, year);
    this.month10 = this.DisplayMonth(10);
    this.fillNumbersInMonth(this.tableData10, 10, year);
    this.month11 = this.DisplayMonth(11);
    this.fillNumbersInMonth(this.tableData11, 11, year);
    this.month12 = this.DisplayMonth(12);
    this.fillNumbersInMonth(this.tableData12, 12, year);
  }

  fillNumbersInMonth(tableData, currentMonth, year): any {
    const month = currentMonth;
    const y = year;
    if (currentMonth === 1) {
      year--;
      currentMonth = 13;
    }

    // 0-5 POSICIONES
    const date = new Date();
    const startDayInCurrentMonth = this.dayofStartMonthInt(
      currentMonth - 1,
      year
    );
    const totalDaysInCurrentMonth = this.daysInMonth(currentMonth, year);
    let totalDaysInLastMonth = this.daysInMonth(currentMonth - 1, year);

    let day = 1;
    let col = 0;
    const breakRow = false;
    let row = 0;
    let defValue = '*';

    col = startDayInCurrentMonth - 1;
    if (startDayInCurrentMonth === 0) {
      col = 6;
    }
    for (col; col >= 0; col--) {
      // console.log('col: '+ col);
      switch (col) {
        case 0: {
          tableData[row].D = totalDaysInLastMonth.toString();
          tableData[row].DLdef = defValue;
          break;
        }
        case 1: {
          tableData[row].L = totalDaysInLastMonth.toString();
          tableData[row].LLdef = defValue;
          break;
        }
        case 2: {
          tableData[row].M = totalDaysInLastMonth.toString();
          tableData[row].MLdef = defValue;
          break;
        }
        case 3: {
          tableData[row].MI = totalDaysInLastMonth.toString();
          tableData[row].MILdef = defValue;
          break;
        }
        case 4: {
          tableData[row].J = totalDaysInLastMonth.toString();
          tableData[row].JLdef = defValue;
          break;
        }
        case 5: {
          tableData[row].V = totalDaysInLastMonth.toString();
          tableData[row].VLdef = defValue;
          break;
        }
        case 6: {
          tableData[row].S = totalDaysInLastMonth.toString();
          tableData[row].SLdef = defValue;
          break;
        }
      }
      totalDaysInLastMonth--;
    }

    // fill current month
    col = 0;
    defValue = '';
    for (row = 0; row <= 5; row++) {
      if (row === 0) col = startDayInCurrentMonth;
      else col = 0;

      if (row === 0)
        for (col + 1; col <= 7; col++) {
          switch (col) {
            case 0: {
              tableData[row].D = this.makeQueryAndBuildLink(y, month, day);
              tableData[row].DL = tableData[row].D.toString().includes('a')
                ? 'V'
                : '';
              tableData[row].DLdef = defValue;
              break;
            }
            case 1: {
              tableData[row].L = this.makeQueryAndBuildLink(y, month, day);
              tableData[row].LL = tableData[row].L.toString().includes('a')
                ? 'V'
                : '';
              tableData[row].LLdef = defValue;
              break;
            }
            case 2: {
              tableData[row].M = this.makeQueryAndBuildLink(y, month, day);
              tableData[row].ML = tableData[row].M.toString().includes('a')
                ? 'V'
                : '';
              tableData[row].MLdef = defValue;
              break;
            }
            case 3: {
              tableData[row].MI = this.makeQueryAndBuildLink(y, month, day);
              tableData[row].MIL = tableData[row].MI.toString().includes('a')
                ? 'V'
                : '';
              tableData[row].MILdef = defValue;
              break;
            }
            case 4: {
              tableData[row].J = this.makeQueryAndBuildLink(y, month, day);
              tableData[row].JL = tableData[row].J.toString().includes('a')
                ? 'V'
                : '';
              tableData[row].JLdef = defValue;
              break;
            }
            case 5: {
              tableData[row].V = this.makeQueryAndBuildLink(y, month, day);
              tableData[row].VL = tableData[row].V.toString().includes('a')
                ? 'V'
                : '';
              tableData[row].VLdef = defValue;
              break;
            }
            case 6: {
              tableData[row].S = this.makeQueryAndBuildLink(y, month, day);
              tableData[row].SL = tableData[row].S.toString().includes('a')
                ? 'V'
                : '';
              tableData[row].SLdef = defValue;
              break;
            }
            case 7: {
              tableData[row].D = this.makeQueryAndBuildLink(y, month, day);
              tableData[row].DL = tableData[row].D.toString().includes('a')
                ? 'V'
                : '';
              tableData[row].DLdef = defValue;
              break;
            }
          }
          day++;
          if (startDayInCurrentMonth === 0) break;
        }
      else
        for (col = 0; col <= 6; col++) {
          switch (col) {
            // case 0: {tableData[row].D = day.toString(); tableData[row].DLdef = defValue; break;}
            case 0: {
              tableData[row].L = this.makeQueryAndBuildLink(y, month, day);
              tableData[row].LL = tableData[row].L.toString().includes('a')
                ? 'V'
                : '';
              tableData[row].LLdef = defValue;
              break;
            }
            case 1: {
              tableData[row].M = this.makeQueryAndBuildLink(y, month, day);
              tableData[row].ML = tableData[row].M.toString().includes('a')
                ? 'V'
                : '';
              tableData[row].MLdef = defValue;
              break;
            }
            case 2: {
              tableData[row].MI = this.makeQueryAndBuildLink(y, month, day);
              tableData[row].MIL = tableData[row].MI.toString().includes('a')
                ? 'V'
                : '';
              tableData[row].MILdef = defValue;
              break;
            }
            case 3: {
              tableData[row].J = this.makeQueryAndBuildLink(y, month, day);
              tableData[row].JL = tableData[row].J.toString().includes('a')
                ? 'V'
                : '';
              tableData[row].JLdef = defValue;
              break;
            }
            case 4: {
              tableData[row].V = this.makeQueryAndBuildLink(y, month, day);
              tableData[row].VL = tableData[row].V.toString().includes('a')
                ? 'V'
                : '';
              tableData[row].VLdef = defValue;
              break;
            }
            case 5: {
              tableData[row].S = this.makeQueryAndBuildLink(y, month, day);
              tableData[row].SL = tableData[row].S.toString().includes('a')
                ? 'V'
                : '';
              tableData[row].SLdef = defValue;
              break;
            }
            case 6: {
              tableData[row].D = this.makeQueryAndBuildLink(y, month, day);
              tableData[row].DL = tableData[row].D.toString().includes('a')
                ? 'V'
                : '';
              tableData[row].DLdef = defValue;
              break;
            }
          }

          if (day === totalDaysInCurrentMonth) {
            // breakRow = true;
            // break;
            // restart the day to fill the next month
            day = 1;
            defValue = '*';
          } else day++;
        }
      // if(breakRow)
      //   break;
    }
  }

  makeQueryAndBuildLink(y, month, day): any {
    // tslint:disable-next-line: no-shadowed-variable
    const query = _.filter(this.leyesDOFFiltered, function (d) {
      return (
        new Date(
          new Date(d.Fecha).getFullYear(),
          new Date(d.Fecha).getMonth(),
          new Date(d.Fecha).getDate()
        ).setHours(0, 0, 0, 0) ===
        new Date(y, month - 1, day).setHours(0, 0, 0, 0)
      );
    });
    if (query !== undefined && query.length > 0) {
      return (
        '<a target="_blank" href="' +
        _.first(query).Link +
        '">' +
        day.toString() +
        '</a>'
      );
    } else {
      return day.toString();
    }
  }

  buildLink(month, y, day): any {
    return (
      '<a target="_blank" href="dof/' +
      y.toString() +
      '/' +
      this.DisplayMonth(month).toLowerCase().substring(0, 3) +
      '/DOF_' +
      day.toString().padStart(2, '0') +
      this.DisplayMonth(month).toLowerCase().substring(0, 3) +
      y.toString().substring(0, 2) +
      '.pdf">' +
      day.toString() +
      '</a>'
    );
  }

  dayofStartMonthInt(month, year): any {
    return new Date(year, month).getDay();
  }

  daysInMonth(month, year): any {
    return new Date(year, month, 0).getDate();
  }

  DisplayMonth(numberOfMonth: number): any {
    let month = '';
    switch (numberOfMonth) {
      case 1: {
        month = 'Enero';
        break;
      }
      case 2: {
        month = 'Febrero';
        break;
      }
      case 3: {
        month = 'Marzo';
        break;
      }
      case 4: {
        month = 'Abril';
        break;
      }
      case 5: {
        month = 'Mayo';
        break;
      }
      case 6: {
        month = 'Junio';
        break;
      }
      case 7: {
        month = 'Julio';
        break;
      }
      case 8: {
        month = 'Agosto';
        break;
      }
      case 9: {
        month = 'Septiembre';
        break;
      }
      case 10: {
        month = 'Octubre';
        break;
      }
      case 11: {
        month = 'Noviembre';
        break;
      }
      case 12: {
        month = 'Diciembre';
        break;
      }
    }
    return month;
  }

  openModalNormas(TipoNorma: number): any {
    console.log('Tipo de Norma: ', TipoNorma);
    const dialogRef = this.dialog.open(ModalNormasComponent, {
      data: { tipo: TipoNorma },
    });
  }

  openModalCompilaciones(TipoCompilacion: number): any {
    console.log('Tipo de Compilación: ', TipoCompilacion);
    const dialogRef = this.dialog.open(ModalCompilacionesComponent, {
      data: { tipo: TipoCompilacion },
    });
  }

  openModalCronologico(): any {
    const dialogRef = this.dialog.open(ModalCronologico, {});
  }

  openModalArticulo(): any {
    const dialogRef = this.dialog.open(ModalArticuloComponent, {});
  }

  openModalLegislatura(): any {
    const dialogRef = this.dialog.open(ModalLegislaturaComponent, {});
  }

  openModalPeriodo(): any {
    const dialogRef = this.dialog.open(ModalPeriodoComponent, {});
  }

  openModalLXIV(noleg: any): void {
    console.log('Legis: ', noleg);
    const dialogRef = this.dialog.open(ModalLXIVComponent, {
      data: { legis: noleg, otroDAto: noleg },
    });
    console.log('Valor recibidoy', noleg);
    // console.log(':::::::::Legislaturas:', this.legis);
  }

  openModalReglamentosVigentes(): any {
    const dialogRef = this.dialog.open(ModalReglamentosVigentesComponent, {});
  }

  openModalReglamentosAbrogadas(): any {
    const dialogRef = this.dialog.open(ModalReglamentosAbrogadasComponent, {});
  }

  openModalMarcoCodigo(): any {
    const dialogRef = this.dialog.open(ModalMarcoCodigo, {});
  }

  openR(noleg: any): void {
    console.log('Legis: ', noleg);
    const dialogRef = this.dialog.open(ModalLXIVComponent, {
      data: { legis: noleg, otroDAto: noleg },
    });
    console.log('Valor recibidoy', noleg);
    // console.log(':::::::::Legislaturas:', this.legis);
  }

  verifyVisitantSession(): any {
    this.loggedIn = !!localStorage.getItem('HCD_activeSessionVisitant');
    if (this.loggedIn) {
      this.emailVisitante = localStorage.getItem('HCD_visitantEmail');
      // traer los eventos favoritos de el visitante
      this.subFavoriteLaws = this.apollo
        .watchQuery<ResponseFavoriteLaws>({
          query: gql`
          {
            allFavoriteLaws(EmailVisitante: "${this.emailVisitante}") {
              Oid
            }
          }
        `,
        })
        .valueChanges.subscribe((result) => {
          this.favoriteLaws = result.data && result.data.allFavoriteLaws;
          this.loading = result.loading;
          this.error = result.errors;

          console.log(
            'llegaron estas leyes favoritas',
            result.data.allFavoriteLaws
          );
        });
    }
  }
  isFavorite(oid): any {
    return this.favoriteLaws.find((law) => law.Oid === oid);
  }

  addViewToPage(Oid: any): any {
    this.api.addViewToPage(Oid).subscribe(
      ({ data }: any) => {
        this.visitas = data.addView.Visitas;
      },
      (error) => {
        console.log(
          '::::::::::Error al aumentar las visualizaciones de la infografía: ',
          error
        );
      }
    );
  }

  pageChange(newPage: number): any {
    this.router.navigate([''], { queryParams: { page: newPage } });
  }

  ngOnDestroy(): any {
    if (this.subTypes) {
      this.subTypes.unsubscribe();
    }
    if (this.subLaws) {
      this.subLaws.unsubscribe();
    }
  }

  // mapLeyes(oidTipo): any {
  //   const filteredLeyes: any[] = this.leyes.map(l => l.TipoLey === oidTipo);
  //   return filteredLeyes;
  // }

  linkLey(url): any {
    this.router.navigateByUrl(url);
  }

  addFavoriteLaw(OidLaw): any {
    // console.log(`Llego oid ${OidLaw}`);
    this.apollo
      .mutate({
        mutation: mutationAddFavoriteLaw(OidLaw, this.emailVisitante),
      })
      .subscribe(
        () => {
          // const newFavEvent: Event = {Oid: OidEvento};
          const newFavLaw: Law2 = { Oid: OidLaw };
          this.favoriteLaws.push(newFavLaw);
          alert('se ha guardado el evento en favoritos de manera exitosa!');
        },
        (error) => {
          alert('hubo un error al guardar en favoritos, intente de nuevo');
          console.log(error);
          // console.log(data);
        }
      );
  }
  buscar(texto: string) {
    window.open(
      `https://busqueda.diputados.gob.mx:5000/results?texto=${texto}`,
      '_blank'
    );
  }
  deleteFavoriteLaw(OidLaw): any {
    // console.log(`Llego oid ${OidLaw}`);
    this.apollo
      .mutate({
        mutation: mutationDeleteFavoriteLaw(OidLaw, this.emailVisitante),
      })
      .subscribe(
        () => {
          this.favoriteLaws = this.favoriteLaws.filter(
            (law) => law.Oid !== OidLaw
          );
          alert('se ha borrado el evento en favoritos de manera exitosa!');
        },
        (error) => {
          alert('hubo un error al borrar de favoritos, intente de nuevo');
          console.log(error);
        }
      );
  }
}
