import { Component, OnInit, Input } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { MatTableDataSource } from '@angular/material/table';
import gql from 'graphql-tag';
import { Acuerdo } from 'src/app/interfaces/acuerdo';
import { ActivatedRoute } from '@angular/router';
import { AcuerdosService } from '../../services/acuerdos.service';
import { Reunion } from '../../interfaces/reunion';
import { GraphqlService } from '../../services/graphql.service';

@Component({
  selector: 'app-reuniones-comision',
  templateUrl: './reuniones-comision.component.html',
  styleUrls: ['./reuniones-comision.component.scss'],
})
export class ReunionesComisionComponent implements OnInit {
  @Input() data: any = {};

  public _reuniones: Reunion[] = [];
  public reunionesFiltradas: Reunion[] = [];
  public reunion: Reunion[] = [];
  public anio: string = 'Todos';
  public periodo: string = 'Todos';
  public subAcuerdos: any;
  public subReuniones: any;
  public loading = false;
  public error: any;

  tipoYear: any = 0;
  tipoPeriodo: any = 0;
  tipoReunion: any = 0;

  public keyWord: string = '';

  public listaYears: any[] = [
    { nombre: 'Todos', value: 0 },
    { nombre: 'Primer Año', value: 'Primero' },
    { nombre: 'Segundo Año', value: 'Segundo' },
    { nombre: 'Tercer Año', value: 'Tercero' },
  ];

  public listaPeriodos: any[] = [
    { nombre: 'Todos', value: 0 },
    { nombre: 'Primer Perido', value: 'Primero' },
    { nombre: 'Segundo Periodo', value: 'Segundo' },
    { nombre: '1er Perido Extraordinario', value: 'PrimeroExtraordinario' },
    { nombre: '2do Perido Extraordinario', value: 'SegundoExtraordinario' },
  ];

  public listaReuniones: any[] = [
    { nombre: 'Todos', value: 0 },
    { nombre: 'Ordinaria', value: '24e3f9fa-77a9-4d86-973a-961f00295e82' },
    { nombre: 'Extraordinaria', value: 'a01929cd-5eb9-4514-920b-744a44bca816' },
  ];

  public source: any;
  public opcionYear = 0;
  public opcionPeriodo = 0;
  public opcionTipo = 0;
  public reunionesCom: any[] = [];
  public reunionesComision: any[] = [];
  private _oid: string;

  constructor(
    private apollo: Apollo,
    private route: ActivatedRoute,
    private _acuerdosService: AcuerdosService,
    private _gqlService: GraphqlService
  ) {}

  ngOnInit(): void {
    this._oid = this.data.Oid;
    this.source = new MatTableDataSource<any>();

    this.route.queryParams.subscribe((params) => {
      console.log({ params }); // { tipo: "#" }
      this.tipoYear = params.tipo;
      console.log('tipo', this.tipoYear); //
    });

    this._gqlService
      .getReunionesComision(this._oid)
      .valueChanges.subscribe((result) => {
        this._reuniones = result.data.getReunionesComision;
        this.reunionesFiltradas = [...this._reuniones];
        console.log('REUNIONESfiltradas!!! ', this.reunionesFiltradas);
        this.loading = result.loading;
        this.error = result.errors;
      });
  }

  filtrarTipo(year: any) {
    this.tipoYear = year;
    this.filtrar();
  }

  filtrar() {
    this.reunionesFiltradas = this._reuniones.filter((reunion) => {
      console.log('REUNION 0', reunion );
      console.log('this.periodo', this.periodo);

      return (
        (this.tipoYear !== 0
          ? reunion.AnioLegislativo === this.tipoYear
          : true) &&
        (this.tipoPeriodo !== 0
          ? reunion.PeriodoLegislativo === this.tipoPeriodo
          : true) &&
        (this.tipoReunion !== 0
          ? reunion.TipoReunion === this.tipoReunion
          : true) &&
        (this.keyWord
          ? Object.keys(reunion).some((key) => {
              if (key !== 'Oid') {
                return reunion[key]
                  ?.toString()
                  ?.toLowerCase()
                  .includes(this.keyWord.toLowerCase());
              }
            })
          : true)
      );
    }); console.log('reunionesFILTRADAS YYY', this.reunionesFiltradas );
 
  }
}
