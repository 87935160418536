import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Infografico } from 'src/app/interfaces/infografico';
import { InfograficoService } from 'src/app/services/infografico.service';
import { Apollo } from 'apollo-angular';
import { GraphqlService } from 'src/app/services/graphql.service';
import { InfograficoRespuesta } from 'src/app/interfaces/infografico-respuesta';
import gql from 'graphql-tag';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-mis-infograficos',
  templateUrl: './mis-infograficos.component.html',
  styleUrls: ['./mis-infograficos.component.scss'],
})
export class MisInfograficosComponent implements OnInit, OnDestroy {
  @Input() data: any = {};

  public infograficos: any;
  public favoriteInfograficos: Infografico[];
  private _subInfograficos: any;

  constructor(
    private _infograficoService: InfograficoService,
    private apollo: Apollo,
    private _graphqlService: GraphqlService
  ) {}

  ngOnInit(): void {
    this.setSubInfograficos();
  }

  ngOnDestroy(): any {
    this._subInfograficos.unsubscribe();
  }

  setSubInfograficos(): void {
    this._subInfograficos = this._infograficoService
      .getSubInfographics(this.data.visitanteEmail)
      .valueChanges.subscribe((result) => {
        this.favoriteInfograficos =
          result.data && result.data.allFavoriteInfographics;
        this.infograficos = this.chunkArray([...this.favoriteInfograficos], 4);
      });
  }

  deleteFavoriteInfographic(Oid): any {
    this._infograficoService
      .deleteFavoriteInfographic(Oid, this.data.visitanteEmail)
      .subscribe(
        () => {
          this.infograficos = this.infograficos.filter(
            (law) => law.Oid !== Oid
          );
          Swal.fire(
            'Confirmación',
            'Se ha borrado de favoritos de manera exitosa!',
            'success'
          );
        },
        (error) => {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text:
              'Hubo un error al borrar de favoritos, intente de nuevo mas tarde.',
            footer: '<a href>Continuas con problemas?</a>',
          });
          console.log(error);
        }
      );
  }

  chunkArray(arr: Array<any>, n: number) {
    return arr
      .slice(0, ((arr.length + n - 1) / n) | 0)
      .map((c, i) => arr.slice(n * i, n * i + n));
  }
}
