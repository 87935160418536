import { Component, OnInit, Input, ElementRef, Renderer2 } from '@angular/core';

@Component({
  selector: 'app-seccion',
  templateUrl: './seccion.component.html',
  styleUrls: ['./seccion.component.scss'],
})
export class SeccionComponent implements OnInit {
  @Input() title: any;
  @Input() data: any;

  constructor() { }

  ngOnInit(): void {
  }

}
