import { Component, OnInit } from '@angular/core';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import Swal from 'sweetalert2';
import { GraphqlService } from '../../../services/graphql.service';
interface Publicacion {
  Oid: string;
  Titulo: string;
  Fecha: Date;
  Autor: string;
  Categoria: string;
  Orden: number;
}
interface Declaratoria {
  Nombre: string;
  ImageBase64: string;
  Declaratoria: Date;
  LinkDeclaratoria: string;
  LinkExpediente: string;
  DOF: Date;
  LinkDof: string;
  InicioDevigencia: Date;
}
interface Response {
  allPublicaciones: Publicacion[];
  allDeclaratorias: Declaratoria[];
}
interface Publicacion2 {
  Oid: string;
}
interface ResponseFavoritePublications {
  allFavoritePublications: Publicacion2[];
}
const mutationAddFavoritePublication = (
  OidPublication,
  emailVisitante
): any => gql`
mutation {
  addFavoritePublication(OidPublication: "${OidPublication}", EmailVisitante: "${emailVisitante}"){
    Oid
  }
}`;

const mutationDeleteFavoritePublication = (
  OidPublication,
  emailVisitante
): any => gql`
mutation {
  deleteFavoritePublication(OidPublication: "${OidPublication}", EmailVisitante: "${emailVisitante}"){
    Oid

  }
}`;
@Component({
  selector: 'app-informacion-parlamentaria',
  templateUrl: './informacion-parlamentaria.component.html',
  styleUrls: ['./informacion-parlamentaria.component.scss'],
})
export class InformacionParlamentariaComponent implements OnInit {
  publicaciones: Publicacion[];
  Declaratorias: Declaratoria[];
  bloquesPublicaciones: any[] = [];
  sub: any;
  subDeclaratorias: any;
  error: any;
  loading = true;
  loggedIn = false;
  emailVisitante = '';
  subFavoritePublications: any;
  favoritePublications: Publicacion2[];

  public loadingPosts = false;
  titulo5: any;
  repositoriodigitaltext1: any;
  fullText: any;
  keyWord: any;
  files: any;
  catalog: any;
  fullTextString: any;

  constructor(private apollo: Apollo, private api: GraphqlService) {}

  ngOnInit(): void {
    this.fetchAllPublicaciones();
    this.verifyVisitantSession();
    this.fetchallDeclaratorias();
  }

  searchPosts() {
    const keyWordFilter = this.fullTextString ? null : this.keyWord;
    this.loadingPosts = true;
    this.api
      .searchPosts(
        this.fullTextString ? '' : keyWordFilter,
        this.fullTextString ? this.fullTextString : '',
        this.files ? this.files : false,
        this.catalog ? this.catalog : false
      )
      .refetch()
      .then((result) => {
        this.loadingPosts = false;
        this.publicaciones =
          result.data && result.data.filterPublicacionesPortal;
        this.bloquesPublicaciones = this.chunkArray([...this.publicaciones], 4);
        this.loading = result.loading;
        this.error = result['error'];
      });
  }
  fetchAllPublicaciones() {
    this.sub = this.apollo
      .watchQuery<Response>({
        query: gql`
          {
            allPublicaciones {
              Oid
              Titulo
              Fecha
              Autor
              Categoria
              Orden
              ImagenPortadaB64
              Vistas
            }
          }
        `,
      })
      .valueChanges.subscribe((result) => {
        // console.log('Publicaciones', result);
        this.publicaciones = result.data && result.data.allPublicaciones;
        // console.log(this.publicaciones.length);
        this.bloquesPublicaciones = this.chunkArray([...this.publicaciones], 4);
        // console.log('this.publicaciones trae', this.publicaciones);
        this.loading = result.loading;
        this.error = result.errors;
      });
  }
  fetchallDeclaratorias() {
    this.subDeclaratorias = this.apollo
      .watchQuery<Response>({
        query: gql`
          {
            allDeclaratorias {
              Nombre
              ImageBase64
              Declaratoria
              LinkDeclaratoria
              LinkExpediente
              DOF
              LinkDof
              InicioDevigencia
            }
          }
        `,
      })
      .valueChanges.subscribe((result) => {
        this.Declaratorias = result.data && result.data.allDeclaratorias;
        // console.log('Declaratorias trae', this.Declaratorias);
        this.loading = result.loading;
        this.error = result.errors;
      });
  }

  verifyVisitantSession(): any {
    this.loggedIn = !!localStorage.getItem('HCD_activeSessionVisitant');

    if (this.loggedIn) {
      this.emailVisitante = localStorage.getItem('HCD_visitantEmail');

      // traer las publicaciones favoritas de el visitante
      this.subFavoritePublications = this.apollo
        .watchQuery<ResponseFavoritePublications>({
          query: gql`
            {
              allFavoritePublications(EmailVisitante: "${this.emailVisitante}") {
                Oid
              }
            }
          `,
        })
        .valueChanges.subscribe((result) => {
          this.favoritePublications =
            result.data && result.data.allFavoritePublications;
          this.loading = result.loading;
          this.error = result.errors;
        });
    }
  }

  chunkArray(arr: Array<any>, n: number) {
    return (
      arr
        // tslint:disable-next-line: no-bitwise
        .slice(0, (arr.length + n - 1) / n || 0)
        .map((c, i) => arr.slice(n * i, n * i + n))
    );
  }
  isFavorite(oid): any {
    return this.favoritePublications.find((law) => law.Oid === oid);
  }
  addFavoritePublication(OidPublication): any {
    this.apollo
      .mutate({
        mutation: mutationAddFavoritePublication(
          OidPublication,
          this.emailVisitante
        ),
      })
      .subscribe(
        () => {
          const newFavPublication: Publicacion2 = { Oid: OidPublication };
          this.favoritePublications.push(newFavPublication);
          Swal.fire(
            'Bien!',
            '<h4>se ha guardado la publicacion en favoritos de manera exitosa!</h4>',
            'success'
          );
        },
        (error) => {
          Swal.fire(
            'Lo sentimos',
            '<h4>hubo un error al guardar en favoritos, intente de nuevo</h4>',
            'error'
          );
          //  console.log(error);
        }
      );
  }

  deleteFavoritePublication(OidPublication): any {
    console.log(`Llego oid ${OidPublication}`);
    this.apollo
      .mutate({
        mutation: mutationDeleteFavoritePublication(
          OidPublication,
          this.emailVisitante
        ),
      })
      .subscribe(
        () => {
          this.favoritePublications = this.favoritePublications.filter(
            (publication) => publication.Oid !== OidPublication
          );
          alert('se ha borrado la publicacion en favoritos de manera exitosa!');
        },
        (error) => {
          alert('hubo un error al borrar de favoritos, intente de nuevo');
          //  console.log(error);
        }
      );
  }
}
