import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { GraphqlService } from '../../../../services/graphql.service';
import { MatTableDataSource } from '@angular/material/table';
export interface PeriodicElement {
  nombre: string;
  entidad: string;
  distrito: number;
  orden: number;
  hora: string;
}
const ELEMENT_DATA: PeriodicElement[] = [
  {
    orden: 1,
    nombre: 'José Hipolito Hernández Flores',
    entidad: 'Tabasco',
    distrito: 1,
    hora: '11:50',
  },
  {
    orden: 2,
    nombre: 'Eugenia María Sandoval Martínez',
    entidad: 'Tabasco',
    distrito: 2,
    hora: '12:30',
  },
  {
    orden: 3,
    nombre: 'José Sánchez Pérez',
    entidad: 'Campeche',
    distrito: 3,
    hora: '12:50',
  },
  {
    orden: 4,
    nombre: 'Ricardo Antonio Larusso',
    entidad: 'Chiapas',
    distrito: 9,
    hora: '13:45',
  },
  { orden: 5, nombre: 'Luis Raúl Boron', entidad: 'Chiapas', distrito: 5, hora: '16:50' },
  {
    orden: 6,
    nombre: 'Carla Fuentes Figueroa',
    entidad: 'Chiapas',
    distrito: 6,
    hora: '14:50',
  },
  {
    orden: 7,
    nombre: 'Nigenda Orantes Treviño',
    entidad: 'Chiapas',
    distrito: 7,
    hora: '16:25',
  },
  {
    orden: 8,
    nombre: 'Ericka Ortega Espinoza',
    entidad: 'Chiapas',
    distrito: 9,
    hora: '16:05',
  },
  {
    orden: 9,
    nombre: 'Flor Villegas Hernández',
    entidad: 'Oaxaca',
    distrito: 8,
    hora: '16:15',
  },
  { orden: 10, nombre: 'Pablo Nuñez López', entidad: 'Chiapas', distrito: 4, hora: '18:50' },
];
@Component({
  selector: 'app-renovacion',
  templateUrl: './renovacion.component.html',
  styleUrls: ['./renovacion.component.scss'],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false },
    },
  ],
})
export class RenovacionComponent implements OnInit {
  displayedColumns: string[] = ['orden', 'nombre','entidad', 'distrito',  'hora'];
  dataSource = new MatTableDataSource(ELEMENT_DATA);

  isLinear = false;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  dia1: any[] = [];
  dia2: any[] = [];
  dia3: any[] = [];
  dia4: any[] = [];
  dia5: any[] = [];
  data = [
    {
      url: 'http://www.diputados.gob.mx/eleccion-2021/docs/CONVENIO_INE.pdf',
      urlImagen:
        'https://portalhcd.diputados.gob.mx/EleccionConsecutiva/Documentos/acuerdo_diputados.png',
      titulo: 'Calendario de recepción de consejeros',
      descripcion:
        'Convenio de Colaboración entre el INE y la Cámara de Diputados del H. Congreso de la Unión.',
      extension: 'pdf',
    },
    {
      url:
        'https://portalhcd.diputados.gob.mx/EleccionConsecutiva/Documentos/Acuerdo_Diputados.pdf',
      urlImagen:
        'https://portalhcd.diputados.gob.mx/EleccionConsecutiva/Documentos/acuerdo_diputados.png',
      titulo: 'Lista de diputados federales electos',
      descripcion: 'Recepción de constancas de mayoria y validez',
      extension: 'pdf',
    },
    {
      url: 'http://www.diputados.gob.mx/eleccion-2021/docs/CONVENIO_INE.pdf',
      urlImagen:
        'https://portalhcd.diputados.gob.mx/EleccionConsecutiva/Documentos/acuerdo_diputados.png',
      titulo: 'Estadístico de constancas por sexo y partido',
      descripcion:
        'Convenio de Colaboración entre el INE y la Cámara de Diputados del H. Congreso de la Unión.',
      extension: 'pdf',
    },
    {
      url:
        'https://portalhcd.diputados.gob.mx/EleccionConsecutiva/Documentos/Acuerdo_Diputados.pdf',
      urlImagen:
        'https://portalhcd.diputados.gob.mx/EleccionConsecutiva/Documentos/acuerdo_diputados.png',
      titulo: 'Estadístico de constancias por distrito y partido',
      descripcion: 'Acuerdo de Órganos de Gobierno',
      extension: 'pdf',
    },
    {
      url: 'http://www.diputados.gob.mx/eleccion-2021/docs/CONVENIO_INE.pdf',
      urlImagen:
        'https://portalhcd.diputados.gob.mx/EleccionConsecutiva/Documentos/acuerdo_diputados.png',
      titulo: 'Lista de diputados federales electos de mayoría relativa',
      descripcion:
        'Convenio de Colaboración entre el INE y la Cámara de Diputados del H. Congreso de la Unión.',
      extension: 'pdf',
    },
    {
      url:
        'https://portalhcd.diputados.gob.mx/EleccionConsecutiva/Documentos/Acuerdo_Diputados.pdf',
      urlImagen:
        'https://portalhcd.diputados.gob.mx/EleccionConsecutiva/Documentos/acuerdo_diputados.png',
      titulo: 'Ubicación de expedientes en bóveda por día',
      descripcion: 'Acuerdo de Órganos de Gobierno',
      extension: 'pdf',
    },
  ];
  private _oid: string = '9cf4996b-b33f-4353-810c-04c7eaac4c4b';
  private _reuniones: any;
  reunionesComision: any[][];
  reunionesFiltradas: any[];
  loading: any;
  error: any;
  constructor(
    private _formBuilder: FormBuilder,
    private _gqlService: GraphqlService
  ) {}

  ngOnInit(): void {
    this.dia1 = this.data;
    this.dia2 = this.data;
    this.firstFormGroup = this._formBuilder.group({
      firstCtrl: ['', Validators.required],
    });
    this.secondFormGroup = this._formBuilder.group({
      secondCtrl: ['', Validators.required],
    });
    this.fetchReunionesComision();
  }
  fetchReunionesComision() {
    this._gqlService
      .getReunionesComision(this._oid)
      .valueChanges.subscribe((result) => {
        this._reuniones = result.data.getReunionesComision;
        this.reunionesComision = this._gqlService.chunkArray(
          [...this._reuniones],
          3
        );
        this.reunionesFiltradas = [...this._reuniones];
        console.log('reunionesComision!!! ', this.reunionesComision);
        this.loading = result.loading;
        this.error = result.errors;
      });
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
}
