import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mis-busquedas',
  templateUrl: './mis-busquedas.component.html',
  styleUrls: ['./mis-busquedas.component.scss'],
})
export class MisBusquedasComponent implements OnInit {
  public data: any;
  busquedas = false;

  constructor() {}

  ngOnInit(): void {}
}
