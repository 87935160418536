import { Component, OnInit, ViewRef, ViewChild, Input } from '@angular/core';
import { Router } from '@angular/router';
import { DynamicComponent } from '../dynamic/dynamic.component';

@Component({
  selector: 'app-lista-dinamica',
  templateUrl: './lista-dinamica.component.html',
  styleUrls: ['./lista-dinamica.component.scss'],
})
export class ListaDinamicaComponent implements OnInit {
  public elements: Array<{ view: ViewRef; component: any; data: any }> = [];
  public editar = false;
  public visitanteEmail: string;
  public loggedIn: boolean;
  public configuracionUsuario: any;
  public userComponentList: any[] = [];

  @Input() title: string;
  @Input() configurationList: string;
  @Input() componentList: any[];
  @ViewChild(DynamicComponent, { read: null, static: true })
  dynamicComponent: DynamicComponent;

  constructor(private _router: Router) {}

  ngOnInit(): void {
    this.visitanteEmail = localStorage.getItem('HCD_visitantEmail');
    this.verifyVisitantSession();
    this.setUserConfiguration();
    this.addComponents();
  }

  setUserConfiguration(): void {
    this.configuracionUsuario = JSON.parse(
      localStorage.getItem(this.visitanteEmail)
    );
    if (
      !this.configuracionUsuario ||
      !this.configuracionUsuario[this.configurationList]
    ) {
      this.userComponentList = [...this.componentList];
      this.userComponentList.forEach(
        (section) => (section.data.visitanteEmail = this.visitanteEmail)
      );
      this.configuracionUsuario = this.configuracionUsuario
        ? this.configuracionUsuario
        : {};
      this.configuracionUsuario[
        this.configurationList
      ] = this.userComponentList.map((seccion) => seccion.data);
      this.saveSectionsList(null);
    } else {
      this.configuracionUsuario[this.configurationList].forEach(
        (dataSection) => {
          const dataSectionFound = this.componentList.find(
            (section) => section.data.id === dataSection.id
          );
          if (dataSectionFound) {
            dataSectionFound.data.visible = dataSection.visible;
            dataSectionFound.data.visitanteEmail = this.visitanteEmail;
            this.userComponentList.push(dataSectionFound);
          }
        }
      );
      this.componentList.forEach((section) => {
        const sectionFound = this.configuracionUsuario[
          this.configurationList
        ].find((dataSection) => dataSection.id === section.data.id);
        if (!sectionFound) {
          section.data.visitanteEmail = this.visitanteEmail;
          this.userComponentList.push(section);
        }
      });
    }
  }

  verifyVisitantSession(): any {
    // this.loggedIn = !!localStorage.getItem('HCD_activeSessionVisitant');
    this.loggedIn = !!localStorage.getItem('HCD_activeSessionVisitant');
  }

  saveSectionsList(data: any): any {
    if (data) {
      this.configuracionUsuario[this.configurationList] = data.map(
        (seccion) => seccion.data
      );
    }
    localStorage.setItem(
      `${this.visitanteEmail}`,
      JSON.stringify(this.configuracionUsuario)
    );
  }

  logoutMiCamaraVisitant(): any {
    localStorage.removeItem('HCD_activeSessionVisitant');
    localStorage.removeItem('HCD_visitantEmail');
    console.log('borradolocalStorage');
    this._router.navigate(['/inicio/tuCamara']);
    // setTimeout(() => {
    //   window.location.reload();
    // }, 500);
  }

  truncate(aux: number) {
    const out = Math.trunc(aux);
    return out;
  }

  move(type: string, item: { view: ViewRef; component: any; data: any }): void {
    const index = this.elements.findIndex((element) => element === item);
    if (type === 'up' && index !== 0) {
      [this.elements[index], this.elements[index - 1]] = [
        this.elements[index - 1],
        this.elements[index],
      ];
    } else if (type === 'down' && index !== this.elements.length - 1) {
      [this.elements[index], this.elements[index + 1]] = [
        this.elements[index + 1],
        this.elements[index],
      ];
    }
    this.saveSectionsList(this.elements);
  }

  addComponents(): void {
    this.userComponentList.forEach((item) => {
      const component = this.dynamicComponent.addComponent(item);
      const view: ViewRef = this.dynamicComponent.container.detach(0);
      const data = item.data;
      this.elements.push({ view, component, data });
    });
  }

  editSecciones(): void {
    this.editar = !this.editar;
  }

  toggleVisible(item: { view: ViewRef; component: any; data: any }): void {
    item.data.visible = !item.data.visible;
    this.saveSectionsList(this.elements);
  }
}
