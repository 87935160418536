import { Pipe, PipeTransform } from '@angular/core';

// GFVS.https://victorroblesweb.es/2019/04/15/como-incrustar-un-video-de-youtube-en-angular/
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'sanitizeVideoUrl',
})
export class SanitizeVideoUrlPipe implements PipeTransform {
  constructor(private _sanitizer: DomSanitizer) {}

  transform(url: any): any {
    // console.log('::::::::::URL Original: \n', url); // KYBC.Borrar.
    // console.log('::::::::::URL Sanitizada: \n', this._sanitizer.bypassSecurityTrustResourceUrl(url)); // KYBC.Borrar.
    const results = url.match('[\\?&]v=([^&#]*)');
    const video: any = results === null ? url : results[1];

    if (url === null) {
      return '';
    }

    //return this._sanitizer.bypassSecurityTrustResourceUrl(url);
    return this._sanitizer.bypassSecurityTrustResourceUrl(
      'https://www.youtube.com/embed/' + video
    );
  }
}
