import { subscribe } from 'graphql';
import {
  FullCalendarModule,
  FullCalendarComponent,
} from '@fullcalendar/angular';
import { CalendarOptions, Calendar } from '@fullcalendar/angular'; // useful for typechecking

import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  Input,
  Inject,
} from '@angular/core';
import { Acuerdo } from 'src/app/interfaces/acuerdo';
import { TipoAcuerdo } from 'src/app/enums/tipo-acuerdo.enum';
import { AcuerdosService } from '../../../services/acuerdos.service';
import { formatDate } from '@fullcalendar/angular';
import { style } from '@angular/animations';
import { ActivatedRoute, Router } from '@angular/router';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Fecha } from '../transparencia/transparencia.component.interface';
import { Observable } from 'rxjs';
interface ResponseAcuerdo {
  allAcuerdos: Acuerdo[];
}

@Component({
  selector: 'app-acuerdos',
  templateUrl: './acuerdos.component.html',
  styleUrls: ['./acuerdos.component.scss'],
})
export class AcuerdosComponent implements OnInit, OnDestroy {
  @ViewChild('calendar') calendarComponent: FullCalendarComponent;
  private _acuerdos: Acuerdo[] = [];
  public acuerdosFiltrados: Acuerdo[] = [];
  // @Input() tipoAcuerdo: number = 0;
  // @Input() vistaJucopo: boolean = false;
  public acuerdo: Acuerdo[] = [];
  public anio: string = 'Todos';
  public subAcuerdos: any;
  public loading = false;
  public error: any;
  public calendarOptions: CalendarOptions;
  public keyWord: string = '';
  public calendarApi: Calendar;
  public initialDate: any;
  public acuerdosCalendario: any[];

  public lista: string[] = ['Todos', '2021', '2020', '2019', '2018', '2017'];
  public listaTipos: any[] = [
    { nombre: 'Todos', value: 0 },
    { nombre: 'Mesa Directiva', value: 2 },
    { nombre: 'Junta de Cordinación Política', value: 3 },
    { nombre: 'Comité de Administración', value: 4 },
    {
      nombre:
        'Conferencia para la Dirección y Programación de los Trabajos Legislativos',
      value: 1,
    },
    { nombre: 'Consejo Editorial', value: 5 },
    { nombre: 'Otros', value: 6 },
  ];
  // Seleccionamos o iniciamos el valor '0' del <select>
  public opcionSeleccionado: string = 'Todos';
  public verSeleccion: string = '';
  querySub: any;
  opcion: any;
  tipoAcuerdo: any = 0;
  subAcuerdos2: any;

  constructor(
    private route: ActivatedRoute,
    private _acuerdosService: AcuerdosService // @Inject(MAT_DIALOG_DATA) public data: { tipo: number; verJucopo: boolean }
  ) {
    this.getAllAcuerdos();

    // this.tipoAcuerdo = data?.tipo || 0;
    // this.vistaJucopo = data?.verJucopo || false;
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      console.log({ params }); // { tipo: "#" }
      this.tipoAcuerdo = params.tipo|0;
      console.log('tipo', this.tipoAcuerdo); // tipoAcuerdo
      this.toggleWeekends();
      // this.filtrar();
      // setTimeout(() => this.filtrar(), 5000);
    });
  }
  someMethod() {
    const calendarApi = this.calendarComponent.getApi();
    calendarApi.next();
  }
  goTo(dia: string) {
    const calendarApi = this.calendarComponent.getApi();
    calendarApi.gotoDate(dia);
  }
  capturar() {
    // Pasamos el valor seleccionado a la variable verSeleccion
    this.verSeleccion = this.opcionSeleccionado;
    console.log('Ver selección: ', this.verSeleccion);
  }
  filtrarTipo(organo: any) {
    this.tipoAcuerdo = organo;
    this.filtrar();
  }
  filtrar() {
    console.log('tipo',this.tipoAcuerdo);
    this.acuerdosFiltrados = this._acuerdos.filter((acuerdo) => {
      let anioAcuerdo: string;
      anioAcuerdo = acuerdo.FechaAcuerdo?.split('-')[2] || '2021';
      console.log('ANIO-REUNION2', anioAcuerdo );
      return (
        (this.tipoAcuerdo !== 0
          ? acuerdo.TipoAcuerdo === this.tipoAcuerdo
          : true) &&
        (this.anio !== 'Todos' ? anioAcuerdo === this.anio : true) &&
        (this.keyWord
          ? Object.keys(acuerdo).some((key) => {
            if (key !== 'Oid') {
              return acuerdo[key]
                ?.toString()
                ?.toLowerCase()
                .includes(this.keyWord.toLowerCase());
            }
          })
          : true)
      );
    });console.log('reunionesFILTRADAS YYY', this.acuerdosFiltrados );
    // this.setCalendar();
  }

  ngOnDestroy(): void {
    if (this.subAcuerdos) {
      this.subAcuerdos.unsubscribe();
    }
  }

  getAllAcuerdos() {
    this.subAcuerdos = this._acuerdosService
      .allAcuerdos()
      .valueChanges.subscribe(
        (result) => {
          this._acuerdos = result.data && result.data.allAcuerdos;
          // console.log('acuerdos??? ', this.acuerdo);
          this.acuerdosFiltrados = [...this._acuerdos];
          console.log('acuerdos!!! ', this.acuerdosFiltrados);
          this.loading = result.loading;
          this.error = result.errors;
          this.setCalendar();
          // this.filtrar();

          return this.acuerdosFiltrados;
        },
        (error) => {
          this.error = error;
        }
      );
  }

  setCalendar() {
    let acuerdosCalendario: any = {};
    let day: string = '1-1-2021';
    if (this._acuerdos.length > 0) {
      if (this._acuerdos[0].FechaAcuerdo) {
        const dia: string[] = this._acuerdos[0].FechaAcuerdo.split('-');
        day = `${dia[2]}-${dia[1]}-${dia[0]}`;
        // console.log({ day });
      }
      acuerdosCalendario = this._acuerdos.map((acuerdo) => {
        let arrayFecha: string[] = ['1', '1', '2021'];
        if (acuerdo.FechaAcuerdo) {
          arrayFecha = acuerdo.FechaAcuerdo.split('-');
        }
        return {
          title: acuerdo.NombreDocumento,
          date: `${arrayFecha[2] || 2000}-${arrayFecha[1] || 1}-${
            arrayFecha[0] || 1
          }`,
          color: TipoAcuerdo[acuerdo.TipoAcuerdo],
          url: acuerdo.Link,
        };
      });
      this.calendarOptions = {
        firstDay: 1,
        initialView: 'dayGridMonth',
        initialDate: day,
        dateClick: (dia) => this.handleDateClick(dia),
        events: acuerdosCalendario,
        locale: 'es',
        height: '550px',
        buttonText: {
          today: 'Hoy',
        },
        dayHeaders: true,
      };
      // console.log('Calendario:', this.calendarOptions);
      this.goTo(day);
    }
  }
  toggleWeekends() {
    this.calendarOptions.weekends = !this.calendarOptions.weekends; // toggle the boolean!
  }
  handleDateClick(dia) {
    console.log(dia);
  }
}
