import { Component, Inject, OnInit } from '@angular/core';
import gql from 'graphql-tag';
import { Apollo } from 'apollo-angular';
import { BuscarDiputadosService } from 'src/app/services/buscar-diputados.service';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-presidencias',
  template: `
    <main>
      <section>
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <h1 style="text-align: center;">
                Distribución de Presidencias por Grupo Parlamentario Comisiones
                y Comités
              </h1>
            </div>
            <hr
              style="border-top: 1 px; border-width: 2px; width: 100%; border-color: #38572d;"
            />

            <div class="col-md-12 table-responsive">
              <table class="table ">
                <thead class="table-success" style="background-color: #38572d;">
                  <tr>
                    <th scope="col" style="text-align: center;">
                      Grupo Parlamentario
                    </th>
                    <th scope="col" style="text-align: center;">Ordinario</th>
                    <th scope="col" style="text-align: center;">%</th>
                    <th scope="col" style="text-align: center;">Bicamaral</th>
                    <th scope="col" style="text-align: center;">%</th>
                    <th scope="col" style="text-align: center;">Comité</th>
                    <th scope="col" style="text-align: center;">%</th>
                    <th scope="col" style="text-align: center;">Total</th>
                    <th scope="col" style="text-align: center;">%</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of dipDist" style="text-align: center;">
                    <th scope="row" style="text-align: center;">
                      {{ item.NombrePartido }}
                    </th>
                    <td style="text-align: center;">
                      {{ item.PresiOrdinaria }}
                    </td>
                    <td style="text-align: center;">
                      {{ item.PorcOrdinaria }}
                    </td>
                    <td style="text-align: center;">
                      {{ item.PresiBicamaral }}
                    </td>
                    <td style="text-align: center;">
                      {{ item.PorcBicamaral }}
                    </td>
                    <td style="text-align: center;">{{ item.PresiComite }}</td>
                    <td style="text-align: center;">{{ item.PorcComite }}</td>
                    <td style="text-align: center;">{{ item.Total }}</td>
                    <td style="text-align: center;">{{ item.PorcTotal }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>
    </main>
  `,
  styles: [],
})
export class PresidenciasComponent implements OnInit {
  dipDist: any[] = [];
  loading = true;
  table: number;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private apollo: Apollo,
    public dialog: MatDialog,

    private service: BuscarDiputadosService
  ) {
    this.loading = true;
  }

  ngOnInit(): void {
    this.service
      .getDistribucionPresidencias()
      .valueChanges.subscribe((res: any) => {
        // console.log('res', res.data.allDistribucionPresidencias);
        this.dipDist = res.data.allDistribucionPresidencias;
        this.loading = false;
      });
  }
}
