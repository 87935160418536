import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-legislacion-federal',
  templateUrl: './legislacion-federal.component.html',
  styleUrls: ['./legislacion-federal.component.scss']
})
export class LegislacionFederalComponent implements OnInit {
  links: any = [
    { nombre: 'Ley Federal de Transparencia y Acceso a la Información Pública', url: 'http://www.diputados.gob.mx/LeyesBiblio/pdf/LFTAIP.pdf' },
    { nombre: 'Ley General de Archivos', url: 'http://www.diputados.gob.mx/LeyesBiblio/pdf/LGA.pdf' },
    { nombre: 'Ley General de Protección de Datos Personales en Posesión de Sujetos Obligados', url: 'http://www.diputados.gob.mx/LeyesBiblio/pdf/LGPDPPSO.pdf' },
    { nombre: 'Ley General de Transparencia y Acceso a la Información Pública', url: 'http://www.diputados.gob.mx/LeyesBiblio/pdf/LGTAIP.pdf' },
    { nombre: 'Lineamientos Generales de Protección de Datos Personales en el Sector Público', url: 'http://www.dof.gob.mx/nota_detalle.php?codigo=5511540&fecha=26/01/2018' },
    { nombre: 'Lineamientos Para la Elaboración de Versiones Públicas, Por Parte de las Dependencias y Entidades de la Administración Pública', url: 'https://www.inali.gob.mx/pdf/LINEAMIENTOS_para_elaboracion_versiones_publicas-VI.pdf' },
    { nombre: 'Reglamento de Transparencia, Acceso a la Información Pública y Protección de Datos Personales de la Cámara de Diputados del Congreso de la Unión', url: 'http://www.diputados.gob.mx/LeyesBiblio/marjur/marco/Reg_TAIPPDPCDCU.pdf' },
  ];

  constructor() { }

  ngOnInit(): void {
  }



}
