import { Component, OnInit, Inject } from '@angular/core';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import * as _ from 'underscore';
import {
  ReformasLeg,
  LegislaturaLeyes,
  ResponseLxivReformasLeg,
  ResponseallLegislaturasLeyes,
} from '../leyes-vigentes.interface';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'app-modal-lxiv',
  templateUrl: './modal-lxiv.component.html',
  styleUrls: ['./modal-lxiv.component.scss'],
})
// tslint:disable-next-line: component-class-suffix
export class ModalLXIVComponent implements OnInit {
  loading = false;
  item = {};
  error: any;
  ReformasLeg: ReformasLeg[];
  Legislatura: LegislaturaLeyes[];
  suballLegislaturas: any;
  leg: any;
  legis: any;
  noleg: any;
  subLxivReformasLeg: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private apollo: Apollo,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<ModalLXIVComponent>
  ) {}
  ngOnInit(): void {
    console.log('los datos del modal son x: ', this.data);
    this.subLxivReformasLeg = this.apollo

      .watchQuery<ResponseLxivReformasLeg>({
        query: gql`
            {
              LxivReformasLeg(Nolegislatura: ${this.data.legis})  {
                NumeroLeg
                Titulo
                Leyes
                PublicacionDOF
                PubDOF
                Nota
                Numero
                Nuevo
                TextoWORD
              }
            }
          `,
      })
      .valueChanges.subscribe((result) => {
        this.ReformasLeg = result.data && result.data.LxivReformasLeg;
        this.loading = result.loading;
        this.error = result.errors;
      });
    this.suballLegislaturas = this.apollo
      .watchQuery<ResponseallLegislaturasLeyes>({
        query: gql`
          {
            allLegislaturasLeyes {
              Oid
              Numero
              FechaInicio
              FechaFin
              Descripcion
              Link
              Romano
            }
          }
        `,
      })
      .valueChanges.subscribe((result) => {
        this.Legislatura = result.data && result.data.allLegislaturasLeyes;
        this.loading = result.loading;
        this.error = result.errors;
      });
  }
  closeDialog(): any {
    this.dialogRef.close();
  }
}
