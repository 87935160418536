import { DOCUMENT } from '@angular/common';
import { Component, OnInit, ElementRef, Inject } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { Apollo } from 'apollo-angular';
import { queryAllVideos } from 'src/app/operations/query';
import { ApiHomeService } from 'src/app/services/api-home.service';
import { Video } from '../home/homepage/homepage.interface';

export type Response = {
  allVideosFromDB: Video[];
};

@Component({
  selector: 'app-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.scss'],
})
export class VideoComponent implements OnInit {
  /* videosFromDB: any[]; */

  /*   public canales: any[] = [
      {
        id: '1',
        nombreCanal: "Canal del congreso",
        direccion: "ATKZYa05b_k"
      },
      {
        id: '2',
        nombreCanal: "Cámara de Diputados",
        direccion: "g9KGGnCQneY"
      },
      {
        id: '3',
        nombreCanal: "CNN",
        direccion: "dOzu-kyQm2g"
      }

    ]; */
  sub: any;
  loadingVideos: boolean;
  error: any;
  querySub: any;
  id: any;

  constructor(
    private _sanitizer: DomSanitizer,
    private elementRef: ElementRef,
    private api: ApiHomeService,
    private apollo: Apollo,
    private route: ActivatedRoute,
    @Inject(DOCUMENT) document
  ) {
    /* console.log('canales: ', this.canales); */
  }

  ngOnInit(): void {
    this.querySub = this.route.queryParams.subscribe((params) => {
      console.log('llegó Params!!! ', params.id);
      this.id = params.id || '';
      console.log('llegó Id!!! ', this.id);
    });

    const tag = document.createElement('script');
    tag.src = 'https://www.youtube.com/iframe_api';
    document.body.appendChild(tag);

    /* this.fetchAllVideos(); */

    /*  const scriptKalturaPlayer = document.createElement('script');
     scriptKalturaPlayer.type = 'text/javascript';

     scriptKalturaPlayer.text = `
       kWidget.embed({
         "targetId": "kaltura_player_1565720477",
         "wid": "_1808371",
         "uiconf_id": 44425072,
         "flashvars": {
           "autoPlay": true
         },
         "cache_st": 1565720477,
         "entry_id": "1_3ekobsj8"
       });

     `;
     this.elementRef.nativeElement.appendChild(scriptKalturaPlayer); */
  }

  // GFVS.Fetch all video links from DB.
  /*  fetchAllVideos() {
     this.sub = this.apollo
       .watchQuery<Response>({
         query: queryAllVideos,
       })
       .valueChanges.subscribe((result) => {
         // console.log('::::::::::allVideosFromDB Result from DB: \n', result); // KYBC.Borrar.
         this.videosFromDB = result.data && result.data.allVideosFromDB;
//  console.log('::::::::::allVideosFromDB from DB: \n', this.videosFromDB); // KYBC.Borrar.
         this.loadingVideos = result.loading;
         this.error = result['error'];
       });
   } */
}
