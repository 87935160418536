import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-parlamento-abierto',
  templateUrl: './parlamento-abierto.component.html',
  styleUrls: ['./parlamento-abierto.component.scss'],
})
export class ParlamentoAbiertoComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
