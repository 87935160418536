import { Injectable, OnInit } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { Apollo } from 'apollo-angular';
import { GraphqlService } from './graphql.service';
import gql from 'graphql-tag';
import { Acuerdo } from '../interfaces/acuerdo';
import { Acta } from '../interfaces/acta';

interface ResponseAcuerdo {
  allAcuerdos: Acuerdo[];
  allAcuerdosJucopo: Acuerdo[];
}

interface ResponseActa {
  allActasJucopo: Acta[];
}

@Injectable({
  providedIn: 'root',
})
export class AcuerdosService implements OnInit {
  acuerdos: Acuerdo[];
  acuerdosJucopo: Acuerdo[];
  actas: Acta[];
  subAcuerdos: any;
  subAcuerdoJucopo: any;
  subActa: any;
  loading = false;
  error: any;

  constructor(
    private _httpClient: HttpClient,
    private apollo: Apollo,
    private _graphqlService: GraphqlService
  ) {
    console.log('Acuerdos servicio');
  }

  // tslint:disable-next-line: contextual-lifecycle
  ngOnInit(): void {
    this.allAcuerdos().valueChanges.subscribe((result) => {
      this.acuerdos = result.data && result.data.allAcuerdos;
      // console.log('Acuerdos!!!: ', this.acuerdos);
      this.loading = result.loading;
      this.error = result['error'];
      return this.acuerdos;
    });

    this.allActasJucopo().valueChanges.subscribe((result) => {
      this.actas = result.data && result.data.allActasJucopo;
      console.log('Acuerdos!!!: ', this.acuerdos);
      this.loading = result.loading;
      this.error = result['error'];
      return this.actas;
    });
  }

  allAcuerdos() {
    return (this.subAcuerdos = this.apollo.watchQuery<ResponseAcuerdo>({
      query: gql`
        {
          allAcuerdos {
            Oid
            TipoAcuerdo
            NombreDocumento
            FechaAcuerdo
            Link
            LinkEsteneo
            NombreCorto
            VersionEsteneografica
          }
        }
      `,
    }));
  }

  getAcuerdos() {
    return this.acuerdos;
  }

  allActasJucopo() {
    return (this.subActa = this.apollo.watchQuery<ResponseActa>({
      query: gql`
        {
          allActasJucopo {
            Oid
            TipoAcuerdo
            NombreDocumento
            FechaAcuerdo
            Link
            NombreCorto
            LinkEsteneo
            VersionEsteneografica
          }
        }
      `,
    }));
  }

  getActa() {
    return this.actas;
  }

  allAcuerdosJucopo() {
    return (this.subAcuerdoJucopo = this.apollo.watchQuery<ResponseAcuerdo>({
      query: gql`
        {
          allAcuerdosJucopo {
            Oid
            TipoAcuerdo
            NombreDocumento
            FechaAcuerdo
            Link
            NombreCorto
            LinkEsteneo
            VersionEsteneografica
          }
        }
      `,
    }));
  }

  getAcuerdosJucopo() {
    return this.acuerdosJucopo;
  }
}
