import { Injectable } from '@angular/core';
import gql from 'graphql-tag';
import { Apollo } from 'apollo-angular';
import { AddViewToPage } from '../operations/query';

@Injectable({
  providedIn: 'root',
})
export class GraphqlService {
  constructor(public apollo: Apollo) {}
  searchPosts(
    keyWord: string,
    fullText: string = '',
    files: boolean = false,
    catalog: boolean = false
  ) {
    console.log({ keyWord });
    console.log({ fullText });
    console.log({ files });
    console.log({ catalog });
    var result = this.apollo.watchQuery<any>({
      query: gql`
          {
            filterPublicacionesPortal(KeyWord: "${keyWord}", FullText: "${fullText}", Files: ${files}, Catalog: ${catalog}) {
              Oid,
              Titulo,
              Autor,
              Categoria,
              Sintesis,
              Url,
              ImagenPortadaB64,
              Vistas
              Objetivo
              IdObjetivo
            }
          }
        `,
    });
    console.log({ result });
    return result;
  }
  public mutationDeleteFavoriteLaw(OidLaw, emailVisitante): any {
    return gql`
    mutation {
      deleteFavoriteLaw(OidLaw: "${OidLaw}", EmailVisitante: "${emailVisitante}"){
        Oid
      }
    }`;
  }
  // DLG revisar y corregir return type : anys
  public mutationDeleteFavoriteEvent(OidEvento, emailVisitante): any {
    return gql`
    mutation {
      deleteFavoriteEvent(OidEvent: "${OidEvento}", EmailVisitante: "${emailVisitante}"){
        Oid
      }
    }`;
  }

  public mutationDeleteFavoriteInfographic(OidInfografia, emailVisitante): any {
    return gql`
    mutation {
      deleteFavoriteInfographic(OidInfographic: "${OidInfografia}", EmailVisitante: "${emailVisitante}"){
        Oid
      }
    }`;
  }

  public mutationDeleteFavoriteInfovideo(OidInfovideo, emailVisitante): any {
    return gql`
    mutation {
      deleteFavoriteInfovideo(OidInfoVideo: "${OidInfovideo}", EmailVisitante: "${emailVisitante}"){
        Oid
      }
    }`;
  }

  public mutationDeleteFavoritePublication(
    OidPublication,
    emailVisitante
  ): any {
    return gql`
    mutation {
      deleteFavoritePublication(OidPublication: "${OidPublication}", EmailVisitante: "${emailVisitante}"){
        Oid
      }
    }`;
  }
  public mutationDeleteFavoriteDiputado(idDiputado, emailVisitante) {
    return gql`
    mutation {
      deleteFavoriteDiputado(IdDiputado: "${idDiputado}", EmailVisitante: "${emailVisitante}"){
        Oid
      }
    }`;
  }
  public mutationAddFavoriteDiputado(idDiputado, emailVisitante) {
    console.log('MutSerIdDip:', idDiputado, '-', emailVisitante);
    return gql`
    mutation {
      addFavoriteDiputado(IdDiputado: "${idDiputado}", EmailVisitante: "${emailVisitante}"){
        Oid
      }
    }`;
  }
  public mutationDeleteFavoriteAviso(OidAviso, emailVisitante): any {
    return gql`
    mutation {
      deleteFavoriteAviso(OidAviso: "${OidAviso}", EmailVisitante: "${emailVisitante}"){
        Oid
      }
    }`;
  }
  public fillDiputadoTransparencia(OidDiputado: string): any {
    return gql`
    {
      allDiputadoTransparencia(Oid: "${OidDiputado}") {
        Oid
        PrimerApellido
        SegundoApellido
        Nombre
        NombreCompleto
        TipoEleccion
        EntEstado
        Distrito
        Correo
        Suplente
        FotoBase64
        OidPartido
        NombrePartido
        NombreCompletoPartido
        CredencialBase64Partido
        dTwitter
        dFacebook
        dLinkedIn
        dInstagram
        pTwitter
        pFacebook
        pLinkedIn
        pInstagram
        IdDiputado
      }
    }`;
  }
  public fillDiputadoTransparenciaFichaCurricular(OidDiputado): any {
    return gql`
          {
            allDiputadoTransparenciaFichaCurricular(Oid: "${OidDiputado}") {
              Oid
              Descripcion
              Detalle
              FeInicio
              FeFin
              TipoExperiencia
            }
          }
    ;`;
  }
  getComisiones(): any {
    return this.apollo.watchQuery<any>({
      query: gql`
        {
          allComisionesPortal {
            Oid
            Nombre
            NombreComision
            NombreCorto
            AcronimoComision
            Mision
            orden
            Extencion
            Objeptivo
            Valores
            Ubicacion
            EnlaceTecnico
            TipoComision
            Nombre
            GCRecord
            UrlMicrositio
          }
        }
      `,
    });
  }
  getReunionesComision(Oid: string): any {
    return this.apollo.watchQuery<any>({
      query: gql`
        {
          getReunionesComision(Oid: "${Oid}") {
            Oid
            Hora
            HoraFin
            Fecha
            FechaFin
            NombreReunion
            Descripcion
            Lugar
            TipoReunion
            Pais
            Actividad
            Comunicado
            Video
            Interlocutor
            Presidente
            Legislatura
            UnidadResponsable
            Programa
            Fotografia
            ImagenB64
            AnioLegislativo
            PeriodoLegislativo
            TipoPeriodo
            Numero
            Archivos {
              Nombre
              TipoArchivo
              TipoDocumento
              Nombre
              URLArchivo
              FechaActualizacion
              Sesion
              Area
              Asunto
              Unidad
              Eventos
              AsuntoTurnado
              Reunion
            }
          }
        }
      `,
    });
  }
  addViewToPage(Oid: any): any {
    return this.apollo
      .mutate({
        mutation: AddViewToPage,
        variables: {
          Oid,
        },
      })
  }
  chunkArray(arr: Array<any>, n: number) {
    return arr
      .slice(0, ((arr.length + n - 1) / n) || 0)
      .map((c, i) => arr.slice(n * i, n * i + n));
  }
}
