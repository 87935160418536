import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-relaciones-laborales',
  templateUrl: './relaciones-laborales.component.html',
  styleUrls: ['./relaciones-laborales.component.scss'],
})
export class RelacionesLaboralesComponent implements OnInit {
  constructor() {}

  ngOnInit(): any {}
}
