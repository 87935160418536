import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { OrganizacionComisionComponent } from 'src/app/components/organizacion-comision/organizacion-comision.component';
import { AdItem } from 'src/app/components/ad-item/ad-item';
import { ReunionesComisionComponent } from 'src/app/components/reuniones-comision/reuniones-comision.component';
import { GaleriaComisionComponent } from 'src/app/components/galeria-comision/galeria-comision.component';
import { EventosComisionComponent } from 'src/app/components/eventos-comision/eventos-comision.component';
import { EnlacesComisionComponent } from 'src/app/components/enlaces-comision/enlaces-comision.component';
import { Reunion } from 'src/app/interfaces/reunion';

interface ImagenGaleria {
  Oid: string;
  Nombre: string;
  Descripcion: string;
  Comision: string;
  ArchivoBase64: string;
  ArchivoBase642: string;
  Reunion: string;
}
interface EnlaceInteres {
  Oid: string;
  Nombre: string;
  Descripcion: string;
  Comision: string;
  ArchivoBase64: string;
  ArchivoBase642: string;
  Reunion: string;
}

interface Evento {
  Oid: string;
  Nombre: string;
  Descripcion: string;
  TipoEvento: string;
  Hora: Float32Array;
  HoraFin: DoubleRange;
  Comision: string;
  UrlForo: string;
  FechaHoraInicio: string;
  FechaHoraFin: string;
  Lugar: string;
  Fotografia: string;
  FotoBase64: string;
  GrupoTrabajo: string;
}

interface ArchivoComision {
  OID: string;
  Nombre: string;
  Archivo: string;
  URLArchivo: string;
  TipoDocumento: string;
  Area: string;
  Asunto: string;
  TipoArchivo: string;
  AsuntoTurnado: string;
}

interface Response {
  getGaleriaComision: ImagenGaleria[];
  getEnlacesComision: EnlaceInteres[];
  getReunionesComision: Reunion[];
  getEventosComision: Evento[];
}

@Component({
  selector: 'app-comision',
  templateUrl: './comision.component.html',
  styleUrls: ['./comision.component.scss'],
})
export class ComisionComponent implements OnInit {
  public Oid = 0;
  public loading = true;
  public AsuntosTurnados: boolean;
  public querySub: any;
  public otros: any[] = [];
  public seccionesPredeterminadas: any[] = [];

  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.AsuntosTurnados = true;

    this.querySub = this.route.queryParams.subscribe((params) => {
      this.Oid = params.Oid || 0;
      console.log('llegó oid', this.Oid);
    });

    this.seccionesPredeterminadas = [
      new AdItem(OrganizacionComisionComponent, {
        id: 'Organizacion',
        title: 'Organización',
        visible: true,
        Oid: this.Oid,
      }),
      new AdItem(ReunionesComisionComponent, {
        id: 'Reuniones',
        title: 'Reuniones',
        visible: true,
        Oid: this.Oid,
      }),
      new AdItem(GaleriaComisionComponent, {
        id: 'Galeria',
        title: 'Galeria',
        visible: true,
        Oid: this.Oid,
      }),
      new AdItem(EventosComisionComponent, {
        id: 'Eventos',
        title: 'Eventos',
        visible: true,
        Oid: this.Oid,
      }),
      new AdItem(EnlacesComisionComponent, {
        id: 'Enlaces',
        title: 'Enlaces',
        visible: true,
        Oid: this.Oid,
      }),
    ];
  }
}
