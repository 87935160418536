import { Component, OnInit, Input } from '@angular/core';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { Rel_Integrantes_Comisiones } from 'src/app/interfaces/rel_integrantes_comisiones';
import { Notice } from '../../pages/home/homepage/homepage.interface';

@Component({
  selector: 'app-organizacion-comision',
  templateUrl: './organizacion-comision.component.html',
  styleUrls: ['./organizacion-comision.component.scss']
})
export class OrganizacionComisionComponent implements OnInit {
  @Input() data: any = {};

  public galeriaComision: any;
  public noticiaComision: any;
  public galeria: any[] = [];
  public noticia: any[] = [];
  public listadoIntegrantesComisionArray: Rel_Integrantes_Comisiones[];
  public integrantes: any[] = [];
  public presidente: any[] = [];
  public secretario: any[] = [];
  public integrante: any[] = [];
  public otros: any[] = [];
  public AsuntosTurnados: boolean = true;
  public comision: any = {};
  private _oid: string;

  opcionVista = '0';

  opcionVistaBanner = '0';

  constructor(private apollo: Apollo) {

  }

  ngOnInit(): void {
    this._oid = this.data.Oid;
    this.apollo
      .watchQuery<any>({
        query: gql`
        {
          getIntegrantesComision(Oid: "${this._oid}") {
            Oid
            Diputado
            Extencion
            Comision
            CabeceraMunicipal
            Partido
            Estado
            Distrito
            Rol
            Activo
            FActualizacion
            Ubicacion
          }
        }
      `,
      })
      .valueChanges.subscribe((resultIntegrantes) => {
        this.listadoIntegrantesComisionArray = resultIntegrantes.data.getIntegrantesComision;
        this.integrantes = this.listadoIntegrantesComisionArray;
        this.presidente = this.integrantes.filter(diputado => diputado.Rol === 'Presidente');
        this.secretario = this.integrantes.filter(diputado => diputado.Rol === 'Secretaría');
        this.integrante = this.integrantes.filter(diputado => diputado.Rol === 'Integrante');
        this.otros = this.integrantes.filter(diputado =>
          diputado.Rol !== 'Integrante' && diputado.Rol !== 'Secretaría' && diputado.Rol !== 'Presidencia'
        );
      });

    this.comision = this.apollo
      .use('endpoint3')
      .watchQuery<any>({
        query: gql`
          {
            getComision(Oid: "${this._oid}") {
              Oid
              Nombre
              NombreCorto
              AcronimoComision
              Ubicacion
              Extencion
              Mision
              Vision
              Objeptivo
              Valores
              UrlSesion
              TipoComision
              EnlaceTecnico
              CorreoEnlaceTecnico
              TelEnlaceTecnico
              IconoB64

            }
          }
        `,
      })
      .valueChanges.subscribe((result) => {
        this.comision = result.data.getComision[0];
      });

    this.galeriaComision = this.apollo
      .use('endpoint3')
      .watchQuery<any>({
        query: gql`
        {
          getGaleriaComision(Oid: "${this._oid}") {
            Oid
            Nombre
            Descripcion
            Comision
            ArchivoBase64
            ArchivoBase642
            Reunion
          }
        }
      `,
      })
      .valueChanges.subscribe((result) => {
        this.galeriaComision = result.data.getGaleriaComision;
        this.galeria = this.galeriaComision;
      });
 
    this.noticiaComision = this.apollo
      .watchQuery<any>({
        query: gql`
        {
          getNociciasComision(Oid: "${this._oid}") {
            Oid
            Titulo
            SFTPFotografia
            Tipo
            ContadorNoticias
            FechaInicio
            ImageBase64
            FechaFin
            Url
          }
        }
      `,
      })
      .valueChanges.subscribe((result) => {
        this.noticiaComision = result.data.getNociciasComision;
        this.noticia = this.noticiaComision;
        console.log('NOTICIAS IMAGENES: ', this.noticia);
      }); 
  }

  obtener_localsotrage() {
    this.opcionVista = localStorage.getItem('opcionVista');
    console.log('Vista Integrantes', this.opcionVista);
  }

  obtener_localsotrageBanner() {
    this.opcionVistaBanner = localStorage.getItem('opcionVistaBanner');
    console.log('Vista Banner: ', this.opcionVistaBanner);
  }

  cambiarVista() {
    console.log('CAMBIAR VISTA', this.opcionVista);

    localStorage.setItem('opcionVista', this.opcionVista);

    this.obtener_localsotrage();

  }

  cambiarVistaBanner() {
    console.log('CAMBIAR VISTA Banner', this.opcionVistaBanner);

    localStorage.setItem('opcionVistaBanner', this.opcionVistaBanner);

    this.obtener_localsotrageBanner();

  }

}
