import { UniquePipe } from './../../pipes/unique.pipe';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HomeRoutingModule } from './home-routing.module';
import { TimeCustomPipeComponent } from './../../pipes/time-custom-pipe/time-custom-pipe.component';
import { BreadcrumbModule } from 'angular-crumbs';
import { NgxGalleryModule } from 'ngx-gallery-9';
import { NgxPaginationModule } from 'ngx-pagination';
import { MatTabsModule } from '@angular/material/tabs';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { HomepageComponent } from './homepage/homepage.component';
import { InformacionParlamentariaComponent } from './informacion-parlamentaria/informacion-parlamentaria.component';
import { ListaEventosComponent } from './lista-eventos/lista-eventos.component';
import { ListaEventosPasadosComponent } from './lista-eventos-pasados/lista-eventos-pasados.component';
import { MemoriaParlamentariaComponent } from './memoria-parlamentaria/memoria-parlamentaria.component';
import { MatButtonModule } from '@angular/material/button';
import { ModalInfografia } from './transparencia/transparencia.component';
// import {
//   Imagen,
// } from './transparencia/transparencia.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { CustompipesModule } from 'src/app/components/custompipes/custompipes.module';
import { BalanceActividadesComponent } from './balance-actividades/balance-actividades.component';
import { DatosRelevantesComponent } from './datos-relevantes/datos-relevantes.component';
import {
  InfopalComponent,
  ModalMovimientos,
} from './infopal/infopal.component';
import { SecretariaGeneralComponent } from './secretaria-general/secretaria-general.component';
import { PopupComponent } from './popup/popup.component';
import { MatDialogModule } from '@angular/material/dialog';
import { Beneficios, Mapa } from './tucamara/tucamara/tucamara.component';
import { AcuerdosComponent } from './acuerdos/acuerdos.component';
import { ArtDosComponent } from './transparencia/art-dos/art-dos.component';
import { DiarioDebatesComponent } from './diario-debates/diario-debates.component';
import { SesionComponent } from './diario-debates/sesion/sesion.component';
import { IntervencionesComponent } from './diario-debates/intervenciones/intervenciones.component';
import { AprobacionesConstitucionalesComponent } from './aprobaciones-constitucionales/aprobaciones-constitucionales.component';
import { MinutasComponent } from './minutas/minutas.component';
import { IntervencionComponent } from './diario-debates/intervenciones/intervencion/intervencion.component';
import { DiarioBusquedaComponent } from './diario-debates/diario-busqueda/diario-busqueda.component';
import { InfoFinancieraComponent } from './parlamentarias/info-financiera/info-financiera.component';
import { InfoLegislativaComponent } from './parlamentarias/info-legislativa/info-legislativa.component';
import { RelacionesLaboralesComponent } from './parlamentarias/relaciones-laborales/relaciones-laborales.component';
import { GacetaComponent } from './parlamentarias/gaceta/gaceta.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';
import { MatRadioModule } from '@angular/material/radio';
import { GacetaAltComponent } from './parlamentarias/gaceta-alt/gaceta-alt.component';
import { GacetaFrameComponent } from './parlamentarias/gaceta-frame/gaceta-frame.component';
import { TransparenciaModule } from './transparencia/transparencia.module';
import { ResultadoComponent } from './transparencia/historial-consultas/historial-consultas.component';
import { ComisionesComponent } from './comisiones/comisiones.component';
import { ComisionComponent } from './comisiones/comision/comision.component';
import { ParlamentoAbiertoComponent } from './parlamentarias/parlamento-abierto/parlamento-abierto.component';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { MicamaraTempComponent } from './micamara-temp/micamara-temp.component';
import { ComponentsModule } from 'src/app/components/components.module';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { MatNativeDateModule } from '@angular/material/core';
import { OrdenComponent } from './diario-debates/orden/orden.component';
import {
  EntrevistasComponent,
  EntrevistasDialog,
} from './entrevistas/entrevistas.component';
import { IndicesComponent } from './diario-debates/indices/indices.component';
import { VotacionesComponent } from './diario-debates/votaciones/votaciones.component';
import { FullCalendarModule } from '@fullcalendar/angular'; // the main connector. must go first
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin
import interactionPlugin from '@fullcalendar/interaction'; // a plugin
import { NoimagePipe } from 'src/app/pipes/noimage.pipe';
import { NgxOrgChartModule } from 'ngx-org-chart';
import { AsistenciasComponent } from './parlamentarias/asistencias/asistencias.component';
import { HistorialVotacionesComponent } from './parlamentarias/historial-votaciones/historial-votaciones.component';
import { ServicioSocialComponent } from './servicios/servicio-social/servicio-social.component';
import { ModalComponent } from './servicios/modal/modal.component';
import { DiputadosGpComponent } from './infopal/modal/diputados-gp/diputados-gp.component';
import { GeneroComponent } from './infopal/modal/genero/genero.component';
import { TipoEleccionComponent } from './infopal/modal/tipo-eleccion/tipo-eleccion.component';
import { EndidadFederativaComponent } from './infopal/modal/endidad-federativa/endidad-federativa.component';
import { CompocisionPlenoComponent } from './infopal/modal/compocision-pleno/compocision-pleno.component';
import { PresidenciasComponent } from './infopal/modal/presidencias/presidencias.component';
import { VideosDialogComponent } from './homepage/videosModal/videosModal.component';
import { DirectorioComponent } from './directorio/directorio.component';
import { ComentariosModalComponent } from './homepage/comentarios-modal/comentarios-modal.component';
import { NgxCaptchaModule } from 'ngx-captcha';
import { GraphQLModule } from 'src/app/graphql.module';
import { MaterialModule } from '../../shared/material/material.module';
import { RenovacionComponent } from './secretaria-general/renovacion/renovacion.component';
import { FilterArrayPipe } from '../../pipes/filter-array.pipe';
import { LeyesModule } from './leyes/leyes.module';
import { ModalLegislaturaComponent } from './leyes/leyes-vigentes/modal-legislatura/modal-legislatura.component';
import { AvisosComponent } from './homepage/avisos/avisos.component';
import { DireccionEventoComponent } from './homepage/modal/direccion-evento/direccion-evento.component';
import { VotosComponent } from './aprobaciones-constitucionales/modal/votos/votos.component';
import { LegislaturasAntComponent } from './infopal/legislaturas-ant/legislaturas-ant.component';
import { DialogElementsAnterioresDialog } from './tusdiputados/tusdiputados/tusdiputados.component';
FullCalendarModule.registerPlugins([
  // register FullCalendar plugins
  dayGridPlugin,
  interactionPlugin,
]);

@NgModule({
  declarations: [
    AcuerdosComponent,
    AprobacionesConstitucionalesComponent,
    ArtDosComponent,
    AsistenciasComponent,
    AvisosComponent,
    BalanceActividadesComponent,
    ComisionesComponent,
    ComisionComponent,
    CompocisionPlenoComponent,
    ComentariosModalComponent,
    DatosRelevantesComponent,
    DialogElementsAnterioresDialog,
    DirectorioComponent,
    DiputadosGpComponent,
    DireccionEventoComponent,
    Beneficios,
    DiarioBusquedaComponent,
    DiarioDebatesComponent,
    EntrevistasDialog,
    EntrevistasComponent,
    EndidadFederativaComponent,
    FilterArrayPipe,
    GacetaComponent,
    GacetaAltComponent,
    GacetaFrameComponent,
    GeneroComponent,
    HistorialVotacionesComponent,
    HomepageComponent,
    IndicesComponent,
    // Imagen,
    InfopalComponent,
    InformacionParlamentariaComponent,
    InfoFinancieraComponent,
    InfoLegislativaComponent,
    IntervencionesComponent,
    IntervencionComponent,
    LegislaturasAntComponent,
    ListaEventosComponent,
    ListaEventosPasadosComponent,
    Mapa,
    MinutasComponent,
    MicamaraTempComponent,
    MemoriaParlamentariaComponent,
    ModalMovimientos,
    ModalInfografia,
    ModalComponent,
    NoimagePipe,
    OrdenComponent,
    ParlamentoAbiertoComponent,
    PopupComponent,
    PresidenciasComponent,
    RelacionesLaboralesComponent,
    RenovacionComponent,
    ResultadoComponent,
    ServicioSocialComponent,
    SesionComponent,
    SecretariaGeneralComponent,
    TimeCustomPipeComponent,
    TipoEleccionComponent,
    TipoEleccionComponent,
    UniquePipe,
    VideosDialogComponent,
    VotacionesComponent,
    VotosComponent,
    // AvisoPrivacidadComponent,
    // HistorialConsultasComponent,
    // ModalTipoEleccion,
    // ModalEntidadFederativa,
    // ModalGenero,
    // ModalPresidencias,
    // ModalGenero,
  ],
  imports: [
    CommonModule,
    HomeRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    BreadcrumbModule,
    NgxGalleryModule,
    NgxPaginationModule,
    MatButtonModule,
    MatTabsModule,
    MatExpansionModule,
    MatIconModule,
    MatPaginatorModule,
    MatDialogModule,
    CustompipesModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressBarModule,
    MatSelectModule,
    MatRadioModule,
    TransparenciaModule,
    AutocompleteLibModule,
    ComponentsModule,
    LazyLoadImageModule,
    MatNativeDateModule,
    FullCalendarModule,
    NgxOrgChartModule,
    NgxCaptchaModule,
    GraphQLModule,
    MaterialModule,
    LeyesModule,
  ],
  entryComponents: [
    Beneficios,
    // Imagen,
    ResultadoComponent,
    EntrevistasDialog,
    ModalLegislaturaComponent,
  ],
})
export class HomeModule {}
