// KYBC.Imports for Angular components.
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss'],
})
export class NotFoundComponent implements OnInit {
  constructor() {}

  ngOnInit() {
    async function printDelayed(elements: string[]) {
      for (const element of elements) {
        await delay(1000);
        console.log(element);
      }
    }

    async function delay(milliseconds: number) {
      return new Promise<void>((resolve) => {
        setTimeout(resolve, milliseconds);
      });
    }

    printDelayed(['Hello', 'beautiful', 'asynchronous', 'world']).then(() => {
      console.log('Printed every element!');
    });
  }
}
