import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';

import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {
  ArticuloNom,
  Decreto,
  Erratas,
  Reformas,
  ReformasConstArtDes,
  ResponseDecreArtitransidddref,
  ResponseFedeErratas,
  ResponseNombreArt,
  ResponseReformasArt,
} from '../leyes-vigentes.interface';
export interface PeriodicElement {
  name: string;
  position: number;
}

//  PeriodicElement[] = [
//   { position: 1, name: 'Hydrogen' },
//   { position: 2, name: 'Helium' },
//   { position: 3, name: 'Lithium' },
//   { position: 4, name: 'Beryllium' },
//   { position: 5, name: 'Boron' },
//   { position: 6, name: 'Carbon' },
//   { position: 7, name: 'Nitrogen' },
//   { position: 8, name: 'Oxygen' },
//   { position: 9, name: 'Fluorine' },
//   { position: 10, name: 'Neon' },
// ];

@Component({
  selector: 'app-modal-articulo',
  templateUrl: './modal-articulo.component.html',
  styleUrls: ['./modal-articulo.component.scss'],
})
export class ModalArticuloComponent implements OnInit {
  loading = false;
  loadingRef = true;
  error: any;
  panelOpenState = 0;
  panelOpenStateB = 999999;
  subTypes: any;
  subNombreArt: any;
  subReformasArt: any;
  subFeErrata: any;
  subDecreArtitransidddref: any;
  ReformasConstArtDesc: ReformasConstArtDes[];
  ReformasArt: Reformas[];
  NombreArt0: ArticuloNom[];
  NombreArt1: ArticuloNom[];
  FeErratas: Erratas[];
  DecretoArtTransi: Decreto[] = [{ Titulo: 'Este es un decreto' }];
  displayedColumns: string[] = ['Titulo'];
  constructor(
    private apollo: Apollo,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<ModalArticuloComponent>
  ) {}
  ngOnInit(): any {
    this.subTypes = this.apollo
      .watchQuery<any>({
        query: gql`
          {
            ReformasConstArtDesc {
              Link
              Publicacionstr
              FerrataLinkTxt
              Ferratastr
            }
          }
        `,
      })
      .valueChanges.subscribe((result) => {
        this.ReformasConstArtDesc =
          result.data && result.data.ReformasConstArtDesc;
        this.loading = result.loading;
        this.loadingRef = false;
        this.error = result.errors;
      });
    this.subNombreArt = this.apollo
      .watchQuery<ResponseNombreArt>({
        query: gql`
          {
            NombreArticulo {
              nombre
              nombrecorto
              Numero
              TipoArticulo
              NombreArtTransitorio
            }
          }
        `,
      })
      .valueChanges.subscribe((result) => {
        const NombreArt = result.data && result.data.NombreArticulo;
        this.NombreArt0 = [];
        this.NombreArt1 = [];
        NombreArt.forEach((item) => {
          if (item.TipoArticulo === 0) {
            this.NombreArt0.push(item);
          } else if (item.TipoArticulo === 1) {
            this.NombreArt1.push(item);
          }
        });
        this.NombreArt0.filter((data) => data.nombre !== 'Artículo 0');
        this.loading = result.loading;
        this.loadingRef = false;
        this.error = result.errors;
      });
    this.subFeErrata = this.apollo
      .watchQuery<ResponseFedeErratas>({
        query: gql`
          {
            FedeErratas {
              Nombre
              Link
            }
          }
        `,
      })
      .valueChanges.subscribe((result) => {
        this.FeErratas = result.data && result.data.FedeErratas;
        this.loading = result.loading;
        this.loadingRef = false;
        this.error = result.errors;
      });
    this.subDecreArtitransidddref = this.apollo
      .watchQuery<ResponseDecreArtitransidddref>({
        query: gql`
          {
            DecreArtitransidddref {
              Oid
              Titulo
              PublicacionDOfstr
              Link
              TextoWORD
              TextoPDF
              TDSFTPArchivoPDF
              Nombre
              Tipo
              FPublicacionstr
              TextoImagen
              NombreTipo
            }
          }
        `,
      })
      .valueChanges.subscribe((result) => {
        this.DecretoArtTransi =
          result.data && result.data.DecreArtitransidddref;
        this.loading = result.loading;
        this.loadingRef = false;
        this.error = result.errors;
      });
  }

  closeDialog(): any {
    this.dialogRef.close();
  }
  getReformas(articulo: ArticuloNom) {
    console.log('getReformas recibió param', articulo.Numero);
    const query = this.apollo
      .watchQuery<ResponseReformasArt>({
        query: gql`
            {
              ReformasArticulos(NombreArticulo: ${articulo.Numero}) {
                NombreArtTransitorio
                NombreAr
                Tipo
                NReforma
                Publicaciondofstr
                TextoWORD
                TextoPDF
                TextoImagen
                Nuevo
                Nombre
                Link
              }
            }
          `,
      })
      .valueChanges.subscribe((result) => {
        articulo.reformas = result.data && result.data.ReformasArticulos;
        let noTransitorios: Reformas[]=[];
        articulo.reformas.forEach((element, index) => {
          if(element.NombreArtTransitorio===null)
          {
            noTransitorios.push(element);
          }
        });
        articulo.reformas=noTransitorios;
        console.log('articulo.reformas trae', articulo.reformas);
        query.unsubscribe();
      });
  }
  getReformasTransitorio(articulo: ArticuloNom) {
    console.log('getReformas recibió: ', articulo.Numero);
    const query = this.apollo
      .watchQuery<ResponseReformasArt>({
        query: gql`
            {
              ReformasArticulos(NombreArticulo: ${articulo.Numero}) {
                NombreArtTransitorio
                NombreAr
                Tipo
                NReforma
                Publicaciondofstr
                TextoWORD
                TextoPDF
                TextoImagen
                Nuevo
                Nombre
                Link
              }
            }
          `,
      })
      .valueChanges.subscribe((result) => {
        articulo.reformas = result.data && result.data.ReformasArticulos;
        let transitorios: Reformas[]=[];
        articulo.reformas.forEach((element, index) => {
          if(element.NombreArtTransitorio!==null)
          {
            transitorios.push(element);
          }
        });
        articulo.reformas=transitorios;
        // console.log('articulo.reformas trae', articulo.reformas);
        query.unsubscribe();
      });
  }
  setStep(index: number) {
    this.panelOpenState = index;
  }

  nextStep() {
    this.panelOpenState++;
  }

  prevStep() {
    this.panelOpenState--;
  }
}
