import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
@Component({
  selector: 'app-micamara-temp',
  templateUrl: './micamara-temp.component.html',
  styleUrls: ['./micamara-temp.component.scss'],
})
export class MicamaraTempComponent implements OnInit {
  public editar = false;

  constructor(private _router: Router) {}

  ngOnInit(): void {}

  editMiCamara(): void {
    this.editar = !this.editar;
  }
  logoutMiCamaraVisitant(): any {
    console.log('::::::::::Visitant logged out!');
    localStorage.removeItem('HCD_activeSessionVisitant');
    localStorage.removeItem('HCD_visitantEmail');
    console.log('::::::::::_router antes', this._router);
    this._router.navigate(['/inicio/tuCamara']);
    // setTimeout(() => {
    //   window.location.reload();
    // }, 500);
    console.log('::::::::::_router despues', this._router);
  }
  cerrarSesion(): void {
    localStorage.setItem('HCD_activeSessionVisitant', 'false');
    localStorage.setItem('HCD_visitantEmail', '');
    this._router.navigateByUrl('/inicio/tuCamara');
    setTimeout(() => {
      window.location.reload();
    }, 500);
  }
}
