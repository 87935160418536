import { Component, OnInit, Input } from '@angular/core';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { New } from '../../pages/home/homepage/homepage.interface';
// JACH.https://www.npmjs.com/package/ngx-gallery
import {
    NgxGalleryOptions,
    NgxGalleryImage,
    NgxGalleryAnimation,
} from 'ngx-gallery-9';

export type Response = {
    allNews: New[];
    getGaleriaComision: New[];
    //allVeryImportantNotices: Notice[];
    //allImportantNotices: Notice[];
    //allNotices: Notice[];
};

@Component({
    selector: 'app-galeria-comision',
    templateUrl: './galeria-comision.component.html',
    styleUrls: ['./galeria-comision.component.scss'],
})
export class GaleriaComisionComponent implements OnInit {
    @Input() data: any = {};

    galleryOptions: NgxGalleryOptions[] = [];
    galleryImages: NgxGalleryImage[] = [];
    news: any[] = [];
    sub: any;

    private _oid: string;
    galeriaComision: any;

    constructor(private apollo: Apollo) { }

    ngOnInit(): void {
        //this.fetchAllNews();
        this.fetchAllNewsB();
        this._oid = this.data.Oid;
        this.apollo
            .watchQuery<any>({
                query: gql`
        {
          getGaleriaComision(Oid: "${this._oid}") {
            Oid
            Nombre
            Descripcion
            Comision
            ArchivoBase64
            ArchivoBase642
            Reunion
          }
        }
      `,
            })
            .valueChanges.subscribe((result) => {
                this.galeriaComision = result.data.getGaleriaComision;
            });
    }

    // // KYBC.Fetch all News from DB.
    /*  fetchAllNews() {
      const UrlNoticias =
        'https://portalhcd.diputados.gob.mx/PortalWeb/PaginaPrincipal/Noticias/2021/';
      this.sub = this.apollo
        .watchQuery<Response>({
          query: gql`
            {
              allNews {
                Oid
                Titulo
                #ImageBase64
                linkSitio
                Descripcion
                Numero
                SFTPFotografia
                Url
              }
            }
          `,
        })
        .valueChanges.subscribe((result) => {
          // console.log('::::::::::allNews Result from DB: \n', result); // KYBC.Borrar.
          this.news = result.data && result.data.allNews;
          // console.log('::::::::::allNews from DB: \n', this.news); // KYBC.Borrar.
          for (let i = 0; i < this.news.length; i++) {
            this.galleryImages.push({
              small:
                this.news[i].SFTPFotografia != null
                  ? UrlNoticias + this.news[i].SFTPFotografia
                  : '/assets/images/preloader.webp', // this.news[i].ImageBase64,
              medium:
                this.news[i].SFTPFotografia != null
                  ? UrlNoticias + this.news[i].SFTPFotografia
                  : '/assets/images/preloader.webp',
              big:
                this.news[i].SFTPFotografia != null
                  ? UrlNoticias + this.news[i].SFTPFotografia
                  : '/assets/images/preloader.webp',
              description: this.news[i].Descripcion,
              url: this.news[i].linkSitio,
            });
          }
          // console.log('::::::::::this.galleryImages: \n', this.galleryImages); // KYBC.Borrar.
          this.formatNewsSlider();
        });
    } */

    // // KYBC.Fetch all News from DB.
    fetchAllNewsB() {
        this._oid = this.data.Oid;
        //console.log('_oidxxxx', this._oid);
        this.sub = this.apollo
            .watchQuery<Response>({
                query: gql`
        {
          getGaleriaComision(Oid: "${this._oid}") {
            Oid
            Nombre
            Descripcion
            Comision
            ArchivoBase64
            ArchivoBase642
            Reunion
            SFTPFotografia
          }
        }
        `,
            })
            .valueChanges.subscribe((result) => {
                //console.log('::::::::::allNews Result from DB: \n', result); // KYBC.Borrar.
                this.news = result.data && result.data.getGaleriaComision;
               // console.log('::::::::::allNews from DB: \n', this.news); // KYBC.Borrar.
                for (let i = 0; i < this.news.length; i++) {
                    this.galleryImages.push({
                        small:
                            //this.news[i].SFTPFotografia != null
                            this.news[i].SFTPFotografia != null
                                ? this.news[i].SFTPFotografia
                                : '/assets/images/preloader.webp', // this.news[i].ImageBase64,
                        medium:
                            this.news[i].SFTPFotografia != null
                                ? this.news[i].SFTPFotografia
                                : '/assets/images/preloader.webp',
                        big:
                            this.news[i].SFTPFotografia != null
                                ? this.news[i].SFTPFotografia
                                : '/assets/images/preloader.webp',
                        description: this.news[i].Descripcion,
                        //url: this.news[i].linkSitio,
                    });
                }
                //console.log('::::::::::this.galleryImages1: \n', this._oid); // KYBC.Borrar.
               // console.log('::::::::::this.galleryImages2: \n', this.galleryImages); // KYBC.Borrar.
                this.formatNewsSlider();
            });
    }

    formatNewsSlider() {
        // JACH.Options for Ngx slider.
        this.galleryOptions = [
            {
                imageAutoPlay: true,
                imageAutoPlayPauseOnHover: true,
                previewAutoPlay: true,
                previewAutoPlayPauseOnHover: true,
                imageAutoPlayInterval: 5000,
                width: '100%',
                height: '600px',
                thumbnailsColumns: 4,
                imageAnimation: NgxGalleryAnimation.Fade,
                imageDescription: true,
                preview: true,
                linkTarget: '_blank',
                thumbnailsAsLinks: false,
                previewCloseOnEsc: true,
                previewCloseOnClick: true,
                lazyLoading: true,
            },
            // max-width 800
            {
                breakpoint: 800,
                width: '100%',
                height: '400px',
                imagePercent: 80,
                thumbnailsPercent: 20,
                thumbnailsMargin: 20,
                thumbnailMargin: 20,
            },
            // max-width 400
            {
                breakpoint: 400,
                preview: false,
            },
        ];
    }
}