import { Component, OnInit, Input } from '@angular/core';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';

@Component({
  selector: 'app-enlaces-comision',
  templateUrl: './enlaces-comision.component.html',
  styleUrls: ['./enlaces-comision.component.scss']
})
export class EnlacesComisionComponent implements OnInit {
  @Input() data: any = {};

  public enlacesComision: any;
  public enlacesCom: any[] = [];
  private _oid: string;

  constructor(private apollo: Apollo) { }

  ngOnInit(): void {
    console.log(this.data);

    this._oid = this.data.Oid;
    this.apollo
      .use('endpoint3')
      .watchQuery<any>({
        query: gql`
        {
          getEnlacesComision(Oid: "${this._oid}") {
            Oid
            Nombre
            linkSitio
            Descripcion
            ImageBase64
          }
        }
      `,
      })
      .valueChanges.subscribe((result) => {
        this.enlacesComision = result.data.getEnlacesComision;
        this.enlacesCom = this.enlacesComision;
        console.log('EnlacesComision!!!: ', this.enlacesCom);
      });
  }

}
