import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-historial-votaciones',
  templateUrl: './historial-votaciones.component.html',
  styleUrls: ['./historial-votaciones.component.scss']
})
export class HistorialVotacionesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
