import {
  Component,
  OnInit,
  Input,
  ElementRef,
  Output,
  EventEmitter,
} from '@angular/core';
import { EstadoEnum } from 'src/app/enums/estado.enum';
import { ShareService } from '../../services/share.service';

@Component({
  selector: 'app-map-chart',
  templateUrl: './map-chart.component.html',
  styleUrls: ['./map-chart.component.scss'],
})
export class MapChartComponent implements OnInit {
  @Input() diputadosEntidad: any;
  @Output() loaded = new EventEmitter<boolean>();

  private data: any[];

  constructor(
    private _shareService: ShareService,
    private _elementRef: ElementRef
  ) {}

  ngOnInit(): void {
    this.addMainScripts();
  }

  setData() {
    this.data = Object.keys(this.diputadosEntidad).map((estadoKey) => {
      let value = '';

      this.diputadosEntidad[estadoKey].forEach((diputadoEntidad) => {
        value += ` (${diputadoEntidad.Partido}: ${diputadoEntidad.Conteo})`;
      });
      return [EstadoEnum[estadoKey], value];
    });
  }

  addMainScripts() {
    const scripts = [
      {
        name: 'highmapsExporting',
        src: 'https://code.highcharts.com/maps/modules/exporting.js',
      },
      {
        name: 'highmapsMxAll',
        src: 'https://code.highcharts.com/mapdata/countries/mx/mx-all.js',
      },
    ];
    this._shareService
      .addMultiplesLinkScripts(this._elementRef, [
        {
          name: 'highmaps',
          src: 'https://code.highcharts.com/maps/highmaps.js',
        },
      ])
      .then(() => {
        this._shareService
          .addMultiplesLinkScripts(this._elementRef, scripts)
          .then(() => {
            this.setData();
            this.crearGrafica();
          });
      });
  }

  crearGrafica() {
    this._shareService
      .addScript(
        this._elementRef,
        `
    // Create the chart
    Highcharts.mapChart('chartdiv', {
      chart: {
        map: 'countries/mx/mx-all'
      },

      title: {
        text: ''
      },

      mapNavigation: {
        enabled: false,
        buttonOptions: {
          verticalAlign: 'bottom'
        }
      },

      plotOptions: {
        series: {
          events: {
            legendItemClick: function(e) {
              e.preventDefault();
            }
          }
        }
      },

      series: [{
        data: ${JSON.stringify(this.data)},
        color: "#3e5f59",
        name: 'Diputados',
        states: {

          fill: {
            color: '#BADA55'
          },
         

          hover: {
            color: '#BADA55'
          }
        },
        dataLabels: {
          enabled: true,
          format: '{point.name}'
        }
      }]
    });
  `
      )
      .then((data) => {
        this.loaded.emit(true);
      });
  }
}
