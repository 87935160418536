import { Component, OnInit } from '@angular/core';
import { CalendarOptions } from '@fullcalendar/core';
import { LeyService } from 'src/app/services/ley.service';
import { Legislatura } from 'src/app/interfaces/legislatura';

@Component({
  selector: 'app-datos-relevantes',
  templateUrl: './datos-relevantes.component.html',
  styleUrls: ['./datos-relevantes.component.scss'],
})
export class DatosRelevantesComponent implements OnInit {

  public legislaturas: Legislatura[];

  constructor(private _leyService: LeyService) { }

  ngOnInit(): void {
    this.getAllLegislaturas();
  }

  getAllLegislaturas() {
    this._leyService.getAllLegislaturas()
      .valueChanges.subscribe((result) => {
        this.legislaturas = result.data && result.data.allLegis;
        this.setCalendar();
      });
  }

  setCalendar() {
    this.legislaturas.forEach(legislatura => {
      const datosFormateados = legislatura.DatosRelevantes.map(dato => {
        return {
          title: dato.Titulo ? dato.Titulo : 'Sin titulo',
          date: dato.Fecha,
          url: dato.URLDocumento
        };
      });
      legislatura.calendarOptions = {
        initialView: 'dayGridMonth',
        events: datosFormateados,
        locale: 'es',
        initialDate: new Date(legislatura.Inicia)
      };
    });
  }
}
