import { Component, OnInit, OnDestroy } from '@angular/core';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { GraphqlService } from '../../../services/graphql.service';
interface Minuta {
  Oid: string;
  ClaveUnica: string;
  Minuta: string;
  Link: string;
  Dictamen: string;
  Legislatura: string;
  CLegislatura: string;
  Observaciones: string;
  TipoAnexo: string;
  Periodo: string;
  NombrePeriodo: string;
  FechaInicioPeriodo: string;
}
interface TitMinutaLegis {
  minutas: Minuta[];
  Oid: string;
  Numlegislatura: string;
  Titulo: string;
  Subtitulo: string;
}
interface ResponseMinutas {
  allMinutasLegis: Minuta[];
  allMinutasLegisActual: Minuta[];
}
interface ResonseTitMinutas {
  allTitulosLegislaturasMinutas: TitMinutaLegis[];
  titulolegilaturaActualMinutas: TitMinutaLegis[];
}

@Component({
  selector: 'app-minutas',
  templateUrl: './minutas.component.html',
  styleUrls: ['./minutas.component.scss'],
})
export class MinutasComponent implements OnInit, OnDestroy {
  minutasTitLegisArr: TitMinutaLegis[];
  ultimaLegisTituloarr: TitMinutaLegis[];
  ultimaLegisMinutasArr: Minuta[];
  subTitLegislaturasMinutas: any;
  subLegislaturaActualMinutas: any;
  subLegislaturaActualTitulo: any;
  loading = false;
  error: any;
  visitas: any;

  constructor(private apollo: Apollo, private api: GraphqlService) {}

  ngOnInit(): void {
    this.addViewToPage('8ce9465d-55ba-400a-9f4c-7a57e7bce230');
    this.subLegislaturaActualMinutas = this.apollo
      .watchQuery<ResponseMinutas>({
        query: gql`
          {
            allMinutasLegisActual {
              Oid
              ClaveUnica
              Minuta
              Link
              Dictamen
              Legislatura
              Observaciones
              TipoAnexo
              CLegislatura
              Periodo
              NombrePeriodo
              FechaInicioPeriodo
            }
          }
        `,
      })
      .valueChanges.subscribe((result) => {
        this.ultimaLegisMinutasArr =
          result.data && result.data.allMinutasLegisActual;
        this.loading = result.loading;
        this.error = result.errors;
      });
    this.subTitLegislaturasMinutas = this.apollo
      .watchQuery<ResonseTitMinutas>({
        query: gql`
          {
            allTitulosLegislaturasMinutas {
              Oid
              Numlegislatura
              Titulo
              Subtitulo
            }
          }
        `,
      })
      .valueChanges.subscribe((result) => {
        this.minutasTitLegisArr =
          result.data && result.data.allTitulosLegislaturasMinutas;
        this.loading = result.loading;
        this.error = result.errors;
      });
    this.subLegislaturaActualTitulo = this.apollo
      .watchQuery<ResonseTitMinutas>({
        query: gql`
          {
            titulolegilaturaActualMinutas {
              Titulo
              Subtitulo
            }
          }
        `,
      })
      .valueChanges.subscribe((result) => {
        this.ultimaLegisTituloarr =
          result.data && result.data.titulolegilaturaActualMinutas;
        this.loading = result.loading;
        this.error = result.errors;
      });
  }

  ngOnDestroy(): void {}

  addViewToPage(Oid: any): any {
    this.api.addViewToPage(Oid).subscribe(
      ({ data }: any) => {
        this.visitas = data.addView.Visitas;
        console.log('visitas', this.visitas);
      },
      (error) => {
        console.log(
          '::::::::::Error al aumentar las visualizaciones de la infografía: ',
          error
        );
      }
    );
  }
  getMinutas(legislatura: TitMinutaLegis) {
    console.log('el oid que paso es:', legislatura.Oid);
    const query = this.apollo
      .watchQuery<ResponseMinutas>({
        query: gql`
          {
            allMinutasLegis(NombreOid: "${legislatura.Oid}") {
              Oid
              ClaveUnica
              Minuta
              Link
              Dictamen
              Legislatura
              CLegislatura
              Observaciones
              TipoAnexo
            }
          }
        `,
      })
      .valueChanges.subscribe((result) => {
        legislatura.minutas = result.data && result.data.allMinutasLegis;
        // console.log('oid.minutas trae', legislatura.minutas);
        query.unsubscribe();
      });
  }
}
