import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// KYBC.
import { LeyesVigentesComponent } from './leyes-vigentes/leyes-vigentes.component';
import { LeyComponent } from './ley/ley.component';

const routes: Routes = [
  {
    path: '',
    component: LeyesVigentesComponent,
    data: { breadcrumb: 'Leyes' },
  },
  { path: 'ley', component: LeyComponent, data: { breadcrumb: 'Ley' } },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class LeyesRoutingModule {}
