import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { Apollo } from 'apollo-angular';
import { GraphqlService } from './graphql.service';
import { DiputadoIdRespuesta } from '../interfaces/diputado-id-respuesta';
import gql from 'graphql-tag';

@Injectable({
  providedIn: 'root',
})
export class BuscarDiputadosService {
  constructor(
    private _httpClient: HttpClient,
    private apollo: Apollo,
    private _graphqlService: GraphqlService
  ) {}

  obtenerDiputados(diputadoJSONData: JSON): any {
    // console.log('::::::::::diputadoJSONData:'); // KYBC.Borrar
    console.log(diputadoJSONData); // KYBC.Borrar.
    return this._httpClient
      .get(
        'https://chats.diputados.gob.mx/ords/sitl/dip/?ROWNUM=' +
          diputadoJSONData['rownum'] +
          '&NOMBRE=' +
          diputadoJSONData['nombre'] +
          '&ESTADO=' +
          diputadoJSONData['estado'] +
          '&PARTIDO=' +
          diputadoJSONData['grupoParlamentario'] +
          '&CABECERA=' +
          diputadoJSONData['cabeceraMunicipal']
      )
      .toPromise();
  }
  getDiputados(): any {
    return this.apollo.watchQuery<any>({
      query: gql`
        {
          allDiputados {
            Nombre
            PrimerApellido
            SegundoApellido
            NombreCompleto
            Estado
            Partido
            Distrito
            Legislacion
            PrimerApellido
            CabeceraMunicipal
            Suplente
            id_dip
            IdDiputado
            Correo
            Telefono
            TipoEleccion
          }
        }
      `,
    });
  }
  getAsientosDiputados(): any {
    return this.apollo.watchQuery<any>({
      query: gql`
        {
          allAsientoDiputados {
            NombreCompleto
            Partido
            NombreCompletoPartido
            ColorHexadecimal
          }
        }
      `,
    });
  }

  getDiputadosEntidadPartido(): any {
    return this.apollo.watchQuery<any>({
      query: gql`
        {
          allDiputadosEntidadPartido {
            NombreCompleto
            Estado
            Partido
          }
        }
      `,
    });
  }

  getRedesSocialesDiputado(diputadoOid: string): any {
    return this.apollo.watchQuery<any>({
      query: gql`
        {
          allRedSocialDiputado(DiputadoOid: "${diputadoOid}") {
            Nombre
            Url
          }
        }
        `,
    });
  }

  getDiputadosMesaDirectiva(): any {
    return this.apollo.watchQuery<any>({
      query: gql`
        {
          allDiputadosMesaDirectiva {
            Oid
            Rol
            NombreCompleto
            Comision
            ImageBase64
            LogoBase64
            Estado
            Distrito
            Correo
            Suplente
            Onomastico
            TipoEleccion
          }
        }
      `,
    });
  }
  getDiputadosJucopo(): any {
    return this.apollo.watchQuery<any>({
      query: gql`
        {
          allDiputadosJucopo {
            Oid
            NombreCompleto
            Rol
            Comision
            ImageBase64
            LogoBase64
            Estado
            Distrito
            Correo
            Suplente
            Onomastico
            TipoEleccion
          }
        }
      `,
    });
  }
  getDiputadosConferencia(): any {
    return this.apollo.watchQuery<any>({
      query: gql`
        {
          allDiputadosConferencia {
            Oid
            Rol
            NombreCompleto
            Comision
            ImageBase64
            LogoBase64
            Estado
            Distrito
            Correo
            Suplente
            Onomastico
            TipoEleccion
          }
        }
      `,
    });
  }
  getFavoriteDeputies(visitanteEmail: string): any {
    return this.apollo.watchQuery<DiputadoIdRespuesta>({
      query: gql`
        {
          allFavoriteDiputados(EmailVisitante: "${visitanteEmail}") {
            IdDiputado
            NombreCompleto
            FotoBase64
          }
        }
        `,
    });
  }

  addFavoriteDeputies(idDiputado: string, emailVisitante: string): any {
    console.log('ids: ', idDiputado, emailVisitante);
    return this.apollo.mutate({
      mutation: this._graphqlService.mutationAddFavoriteDiputado(
        idDiputado,
        emailVisitante
      ),
    });
  }

  deleteFavoriteDeputies(idDiputado: string, emailVisitante: string): any {
    console.log('ids: ', idDiputado, emailVisitante);
    return this.apollo.mutate({
      mutation: this._graphqlService.mutationDeleteFavoriteDiputado(
        idDiputado,
        emailVisitante
      ),
    });
  }
  getDiputadosGenero(): any {
    return this.apollo.watchQuery<any>({
      query: gql`
        {
          allDiputadosGenero {
            NombrePartido
            PorcentajeMujeres
            TotalMujeres
            PorcentajeHombres
            TotalHombres
            Total
            Totalp
            MRh
            RPh
            MRm
            RPm
            TotalGP
          }
        }
      `,
    });
  }
  getDiputadosEntidad(): any {
    return this.apollo.watchQuery<any>({
      query: gql`
        {
          allDiputadosEntidad {
            Entidad
            MRmorena
            RPmorena
            MRpan
            RPpan
            MRpri
            RPpri
            MRpes
            RPpes
            MRpt
            RPpt
            MRmc
            RPmc
            MRprd
            RPprd
            MRpvem
            RPpvem
            MRsp
            RPsp
            MRtotal
            RPtotal
          }
        }
      `,
    });
  }

  getComisionTipoEleccion(): any {
    return this.apollo.watchQuery<any>({
      query: gql`
        {
          allComisionTipoEleccion {
            Nombre
            MR
            pMR
            Primera
            Segunda
            Tercera
            Cuarta
            Quinta
            RP
            pRP
            Total
            pTotal
          }
        }
      `,
    });
  }

  getDistribucionPresidencias(): any {
    return this.apollo.watchQuery<any>({
      query: gql`
        {
          allDistribucionPresidencias {
            NombrePartido
            PresiOrdinaria
            PorcOrdinaria
            PresiBicamaral
            PorcBicamaral
            PresiComite
            PorcComite
            Total
            PorcTotal
          }
        }
      `,
    });
  }

  getPartidos(): any {
    return this.apollo.watchQuery<any>({
      query: gql`
        # Write your query or mutation here
        {
          allPartidos {
            Oid
            OID
            Nombre
            IntegrantesActivos
            LogoBase64
            ColorHexadecimal
            Twitter
            Facebook
            ColorHexadecimal
            Instagram
            NombreCompleto
            Diputados {
              NombreCompleto
              NombreCompletoPartido
              Estado
              Distrito
              CabeceraMunicipal
              Correo
              Ubicacion
              Extension
            }
          }
        }
      `,
    });
  }
}
