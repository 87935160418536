import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';

interface Respuesta {
  getTransparenciaIFAD: IFADTransparencia
}


interface IFADTransparencia {
  Oid: string;
  Titulo: string;
  Descripcion: string;
  Fecha: string;
  UrlArchivo: string;
  Anexos: AnexosIFAD[];
}

interface AnexosIFAD {
  Oid: string;
  Titulo: string;
  Descripcion: string;
  Fecha:string;
  UrlArchivo:string;
}

@Component({
  selector: 'app-ifad-detalle',
  templateUrl: './ifad-detalle.component.html',
  styleUrls: ['./ifad-detalle.component.scss']
})

export class IfadDetalleComponent implements OnInit {

  ifad:IFADTransparencia;
  constructor(
    private apollo: Apollo,
    private route: ActivatedRoute,) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
    let oid = params.Oid;
      this.getTransparenciaIFAD(oid);
    });
  }

  getTransparenciaIFAD(oid:string){
    this.apollo
      .watchQuery<Respuesta>({
        query: gql`
          {
            getTransparenciaIFAD(Oid:"${oid}") {
              Oid
              Titulo
              Descripcion
              Fecha
              UrlArchivo
              Anexos{
                Oid
                Titulo
                Descripcion
                Fecha
                UrlArchivo
              }
            }
          }
        `,
      })
      .valueChanges.subscribe((result) => {
        this.ifad = result.data && result.data.getTransparenciaIFAD;
      });
  }
}
