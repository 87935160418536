import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';

@Injectable({
  providedIn: 'root'
})
export class TrabajadorService {

  constructor(private apollo: Apollo, private http: HttpClient) { }

  getLeyendaSuperior() {
    const url =
      'https://micrositios.diputados.gob.mx:4001/graphql?query={getLeyendaSuperior{LeyendaSuperior}}';
    return this.http.get<any>(url);
  }
  getFooter() {
    const url =
      'https://micrositios.diputados.gob.mx:4001/graphql?query={allFooter{Orden,Nombre,Columna,Detalle{Oid,Orden,Titulo,TipoDestino,UrlDestino,Parametros}}}';
    return this.http.get<any>(url);
  }
  allDirectorio(): any {
    return this.apollo.watchQuery<any>({
      query: gql`
        {
          allDirectorio {
            OidArea
            OrdenArea
            Area
            OidCargo
            Cargo
            Extension
            Titulo
            NombreCompleto
            Correo
            Edificio
            Piso
            Fotografia
          }
        }`,
    });
  }

  allAreas(): any {
    return this.apollo.watchQuery<any>({
      query: gql`
        {
          allAreas {
            Oid
            Area
          }
        }`,
    });
  }

  getAllDirectorio(oid: string): any {
    return this.apollo.watchQuery<any>({
      query: gql`
      {
        selectTrabajadoresArea(Oid: "${oid}") {
          OidArea
          NombreArea
          PuestoTitular
          PuestoEmpleado
          NombrePuesto
          Extension
          NombreCompleto
          Fotografia
          TituloDirectorio
        }
      }`,
    });
  }

  selectTrabajadoresArea(oid: string = null): any {
    return this.apollo.watchQuery<any>({
      query: oid ? gql`
        {
          selectTrabajadoresArea(Oid: "${oid}") {
            OidArea
            NombreArea
            PuestoTitular
            PuestoEmpleado
            NombrePuesto
            Extension
            NombreCompleto
            Fotografia
            TituloDirectorio
          }
        }` : gql`
        {
          selectTrabajadoresArea(Oid: null) {
            OidArea
            NombreArea
            PuestoTitular
            PuestoEmpleado
            NombrePuesto
            Extension
            NombreCompleto
            Fotografia
            TituloDirectorio
          }
        }`,
    });
  }
}
