import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mis-obligaciones-transparencia',
  templateUrl: './mis-obligaciones-transparencia.component.html',
  styleUrls: ['./mis-obligaciones-transparencia.component.scss'],
})
export class MisObligacionesTransparenciaComponent implements OnInit {
  public data: any;
  obligaciones = false;

  constructor() { }

  ngOnInit(): void { }
}
