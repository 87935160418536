import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-info-preguntas-frec',
  templateUrl: './info-preguntas-frec.component.html',
  styleUrls: ['./info-preguntas-frec.component.scss']
})
export class InfoPreguntasFrecComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
