import { Component, Inject, OnInit } from '@angular/core';
import gql from 'graphql-tag';
import { Apollo } from 'apollo-angular';
import { BuscarDiputadosService } from 'src/app/services/buscar-diputados.service';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
@Component({
  selector: 'app-endidad-federativa',
  templateUrl: './entidad-federativa.component.html',
  styles: [],
})
export class EndidadFederativaComponent implements OnInit {
  dipEntidad: any[] = [];
  loading = true;
  table: Number;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private apollo: Apollo,
    public dialog: MatDialog,

    private service: BuscarDiputadosService
  ) {
    this.loading = true;
  }

  ngOnInit(): void {
    this.service.getDiputadosEntidad().valueChanges.subscribe((res: any) => {
      // console.log('res', res.data.allDiputadosEntidad);
      this.dipEntidad = res.data.allDiputadosEntidad;
      this.loading = false;
    });
  }
}
