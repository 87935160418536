import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Apollo } from 'apollo-angular';
import { FormGroup, FormControl } from '@angular/forms';
import { ConsultasTransparenciaService } from '../../../../services/consultas-transparencia.service';
import { ActivatedRoute, Router } from '@angular/router';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { PageEvent } from '@angular/material/paginator';
@Component({
  selector: 'app-resultado',
  templateUrl: 'resultado/resultado.html',
})
export class ResultadoComponent {
  page_zise = 10;
  page_number = 1;
  consultasDatares: any;
  item = {};
  buscaConsultasFormGroup = new FormGroup({
    palabraClaveForm: new FormControl(''),
    anioForm: new FormControl(''),
    mesForm: new FormControl(''),
  });
  itemNid: any;
  itemEst: any;
  itemTipoSol: any;
  itemCardText: any;
  itemCardlink: any;
  itemIngreso: any;
  itemFrespuesta: any;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ResultadoComponent>,
    public dialog: MatDialog,
    // private apollo: Apollo,
    // private _rutaActiva: ActivatedRoute,
    private _buscarConsultasService: ConsultasTransparenciaService,
    // private _snackBar: MatSnackBar
  ) {}
  // tslint:disable-next-line: use-lifecycle-interface
  ngOnInit(): void {
    // console.log('los datos del modal son', this.data);
    this.item = this.data.item;
    // eslint-disable-next-line @typescript-eslint/camelcase
    this.itemNid = this.data.item.folio;
    this.itemEst = this.data.item.estado;
    this.itemTipoSol = this.data.item.tipo_solicitud;
    this.itemCardText = this.data.item.card_text;
    this.itemCardlink = this.data.item.card_link;
    this.itemIngreso = this.data.item.ingreso;
    this.itemFrespuesta = this.data.item.frespuesta;
    // const consultaJSONData: any = {};
    this.buscarConsultas();
    // this.buscarConsultas();
    // this.buscaConsultasFormGroup.patchValue({
    //   anioForm: this._rutaActiva.snapshot.params.anio
    //     ? this._rutaActiva.snapshot.params.anio
    //     : '',
    //   mesForm: this._rutaActiva.snapshot.params.mes
    //     ? this._rutaActiva.snapshot.params.mes
    //     : '',
    //   palabraClaveForm: this._rutaActiva.snapshot.params.palabraClave
    //     ? this._rutaActiva.snapshot.params.palabraClave
    //     : '',
    // });
  }
  buscarConsultas(): any {
    // console.log(this.buscaConsultasFormGroup.value); // KYBC.Borrar.
    const consultaJSONData: any = {
      url:
        'https://hist-transparencia.diputados.gob.mx/ords/transparencia/transparencia/sol/0/0/-',
      //anio: this.buscaConsultasFormGroup.value.anioForm,
      // anio: localStorage.getItem('anio'),
      // mes: this.buscaConsultasFormGroup.value.mesForm,
      // mes: localStorage.getItem('mes'),
      // palabraClave: this.buscaConsultasFormGroup.value.palabraClaveForm,
      // palabraClave: localStorage.getItem('PalabraClave'),
    };
    // console.log(consultaJSONData); // KYBC.Borrar.

    this._buscarConsultasService
      .obtenerConsultas(consultaJSONData)
      .then((response) => {
        this.consultasDatares = response;
        // console.log('::::::::::consultadatares from WS:');
        // console.log(this.consultasDatares);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  closeDialog(): any {
    this.dialogRef.close();
  }
}
interface ConsultasAtendidas {
  Pregunta: string;
  Respuesta: string;
  Folio: string;
  FechaAtencion: Date;
  Estado: string;
  TipoSolicitud: string;
  FechaSolicitud: Date;
}
interface ResponseConsultasAtendidas {
  allConsultasAtendidas: ConsultasAtendidas[];
}
@Component({
  selector: 'app-historial-consultas',
  templateUrl: './historial-consultas.component.html',
  styleUrls: ['./historial-consultas.component.scss'],
})
export class HistorialConsultasComponent implements OnInit {
  page_size = 5;
  page_number = 1;
  pageSizeOptions = [5, 10, 15];
  page: any;
  pagePrev: any;
  consultasData: any;
  buscaConsultasFormGroup = new FormGroup({
    palabraClaveForm: new FormControl(''),
    anioForm: new FormControl(''),
    mesForm: new FormControl(''),
  });
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  constructor(
    public dialog: MatDialog,
    private apollo: Apollo,
    private _rutaActiva: ActivatedRoute,
    private _buscarConsultasService: ConsultasTransparenciaService,
    private _snackBar: MatSnackBar,
    private router: Router,
    private activateRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    // this.buscarConsultas();

    this.buscaConsultasFormGroup.patchValue({
      anioForm: this._rutaActiva.snapshot.params.anio
        ? this._rutaActiva.snapshot.params.anio
        : '',
      mesForm: this._rutaActiva.snapshot.params.mes
        ? this._rutaActiva.snapshot.params.mes
        : '',
      palabraClaveForm: this._rutaActiva.snapshot.params.palabraClave
        ? this._rutaActiva.snapshot.params.palabraClave
        : '',
    });
    if (
      this.buscaConsultasFormGroup.value.anioForm !== '' ||
      this.buscaConsultasFormGroup.value.mesForm !== '' ||
      this.buscaConsultasFormGroup.value.palabraClaveForm !== ''
    ) {
      const anio = this.buscaConsultasFormGroup.value.anioForm;
      const mes = this.buscaConsultasFormGroup.value.mesForm;
      const pala = this.buscaConsultasFormGroup.value.palabraClaveForm;

      this.buscarConsultas(
        //'https://hist-transparencia.diputados.gob.mx/ords/transparencia/transparencia/sol/'+this.buscaConsultasFormGroup.value.anioForm+'/'+this.buscaConsultasFormGroup.value.mesForm+'/'+this.buscaConsultasFormGroup.value.palabraClaveForm
        'https://hist-transparencia.diputados.gob.mx/ords/transparencia/transparencia/sol/' +
          (anio == '' ? '0' : anio) +
          '/' +
          (mes == '' ? '0' : mes) +
          '/' +
          (pala == '' ? '-' : pala)
      );
    }
  }

  handlePage(e: PageEvent) {
    this.page_size = e.pageSize;
    this.page_number = e.pageIndex + 1;
  }
  buscarConsultas(parentUrl: string): any {
    if (
      this.buscaConsultasFormGroup.value.anioForm !== '' ||
      this.buscaConsultasFormGroup.value.mesForm !== '' ||
      this.buscaConsultasFormGroup.value.palabraClaveForm !== ''
    ) {
      const anio = this.buscaConsultasFormGroup.value.anioForm;
      const mes = this.buscaConsultasFormGroup.value.mesForm;
      const pala = this.buscaConsultasFormGroup.value.palabraClaveForm;
      if (parentUrl == null) {
        parentUrl =
          'https://hist-transparencia.diputados.gob.mx/ords/transparencia/transparencia/sol/' +
          (anio == '' ? '0' : anio) +
          '/' +
          (mes == '' ? '0' : mes) +
          '/' +
          (pala == '' ? '-' : pala);
      }

      const consultaJSONData: any = {
        url: parentUrl, //'https://hist-transparencia.diputados.gob.mx/ords/transparencia/transparencia/sol/'+this.buscaConsultasFormGroup.value.anioForm+'/'+this.buscaConsultasFormGroup.value.mesForm+'/'+this.buscaConsultasFormGroup.value.palabraClaveForm,
        anio: this.buscaConsultasFormGroup.value.anioForm,
        mes: this.buscaConsultasFormGroup.value.mesForm,
        palabraClave: this.buscaConsultasFormGroup.value.palabraClaveForm,
      };
      this._buscarConsultasService
        .obtenerConsultas(consultaJSONData)
        .then((response) => {
          //console.log({ response }); // KYBC.Borrar.
          this.consultasData = response;
          this.page = response?.next?.$ref;
          this.pagePrev = response?.prev?.$ref;
          // console.log(this.page, 'valor de page');
          //console.log(this.pagePrev, 'valor de previus');
          if (this.consultasData.items.length === 0) {
            // console.log('Hola mundo ');
            this.buscarConsultas(this.page);

            // this.siguiente();
          }
          if (response.error) {
            this._snackBar.open(response.mensaje, 'Aceptar', {
              duration: 5000,
              horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,
            });
          }
          // console.log('::::::::::consultasdatahist from WS:');
          // console.log(this.consultasData);
        })
        .catch((err: any) => {
          this._snackBar.open(err, 'Aceptar', {
            duration: 5000,
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
          });
        });
    } else {
      this._snackBar.open('Debe ingresar al menos un parámetro.', 'Aceptar', {
        duration: 5000,
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
      });
    }
  }
  modalOpen(item): void {
    this.dialog.open(ResultadoComponent, {
      data: {
        item,
        otroDato: 'miDato',
      },
    });
  }
  siguiente() {
    if (this.page != undefined) {
      this.buscarConsultas(this.page);
    }
  }
  regresar() {
    if (this.pagePrev != undefined) {
      this.buscarConsultas(this.pagePrev);
    }
  }
}
