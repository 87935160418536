import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LeyesRoutingModule } from './leyes-routing.module';
import { LeyesVigentesComponent } from './leyes-vigentes/leyes-vigentes.component';
import { LeyComponent } from './ley/ley.component';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { ModalCronologico } from './leyes-vigentes/modal-cronologico/modal-cronologico.component';
import { MaterialModule } from '../../../shared/material/material.module';
import { ComponentsModule } from '../../../components/components.module';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { ModalArticuloComponent } from './leyes-vigentes/modal-articulo/modal-articulo.component';
import { ModalPeriodoComponent } from './leyes-vigentes/modal-periodo/modal-periodo.component';
import { ModalLegislaturaComponent } from './leyes-vigentes/modal-legislatura/modal-legislatura.component';
import { ModalLXIVComponent } from './leyes-vigentes/modal-lxiv/modal-lxiv.component';
import { ModalReglamentosVigentesComponent } from './leyes-vigentes/modal-regvigentes/modal-regvigentes.component';
import { ModalReglamentosAbrogadasComponent } from './leyes-vigentes/modal-regabrogadas/modal-regabrogadas.component';
import { ModalMarcoCodigo } from './leyes-vigentes/modal-marcocodigo/modal-marcocodigo.component';
import { LeyesFederalesComponent } from './leyes-federales/leyes-federales.component';
import { ModalNormasComponent } from './leyes-vigentes/modal-normas/modal-normas.component';
import { ModalCompilacionesComponent } from './leyes-vigentes/modal-compilaciones/modal-compilaciones.component';

@NgModule({
  declarations: [
    LeyesVigentesComponent,
    LeyComponent,
    ModalCronologico,
    ModalArticuloComponent,
    ModalLegislaturaComponent,
    ModalPeriodoComponent,
    ModalLXIVComponent,
    ModalReglamentosVigentesComponent,
    ModalReglamentosAbrogadasComponent,
    ModalMarcoCodigo,
    LeyesFederalesComponent,
    ModalNormasComponent,
    ModalCompilacionesComponent,
  ],
  imports: [
    CommonModule,
    LeyesRoutingModule,
    MatIconModule,
    MatTabsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MaterialModule,
    MatProgressBarModule,
    ComponentsModule,
  ],
  exports: [ModalCronologico,ModalLegislaturaComponent],
})
export class LeyesModule {}
