import { Component, OnInit } from '@angular/core';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-lista-ifad',
  templateUrl: './lista-ifad.component.html',
  styleUrls: ['./lista-ifad.component.scss']
})
export class ListaIfadComponent implements OnInit {

  ifads:any[]=[];

  constructor(
    private apollo: Apollo,) { }

  ngOnInit(): void {
    this.allTransparenciaIFAD();
  }

  allTransparenciaIFAD(){
    this.apollo
      .watchQuery<any>({
        query: gql`
          {
            allTransparenciaTipoIFAD {
              Oid
              Nombre
              OidVariable
              NombreVariable
              Descripcion
              Documentos{
                Oid
                Titulo
                Descripcion
                FechaPublicacion
                FechaActualizacion
                UrlArchivo
                NAnexos
              }
            }
          }
        `,
      })
      .valueChanges.subscribe((result) => {
        this.ifads = result.data && result.data.allTransparenciaTipoIFAD;
      });
  }
  
  copiarLink(url: string) {
    // console.log('ABC: ', url);
    let aux = document.createElement('input');
    aux.setAttribute('value', url);
    document.body.appendChild(aux);
    aux.select();
    document.execCommand('copy');
    document.body.removeChild(aux);
    Swal.fire(
      'Confirmación',
      'La dirección URL se ha copiado exitosamente!',
      'success'
    );
  }
  
}
