import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { GraphqlService } from './graphql.service';
import { InfoVideoRespuesta } from '../interfaces/info-video-respuesta';
import gql from 'graphql-tag';

@Injectable({
  providedIn: 'root',
})
export class InfoVideoService {
  constructor(
    private apollo: Apollo,
    private _graphqlService: GraphqlService
  ) {}

  getSubInfoVideos(visitanteEmail: string): any {
    return this.apollo.watchQuery<InfoVideoRespuesta>({
      query: gql`
        {
          allFavoriteInfoVideos(EmailVisitante: "${visitanteEmail}") {
           Oid
           Titulo
           Fecha
           linkSitio
           Descripcion
           ImageBase64
          }
        }`,
    });
  }

  deleteFavoriteInfoVideo(Oid: string, emailVisitante: string): any {
    return this.apollo.mutate({
      mutation: this._graphqlService.mutationDeleteFavoriteInfovideo(
        Oid,
        emailVisitante
      ),
    });
  }
}
