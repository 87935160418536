import { Component, OnInit, Input } from '@angular/core';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';

@Component({
  selector: 'app-eventos-comision',
  templateUrl: './eventos-comision.component.html',
  styleUrls: ['./eventos-comision.component.scss']
})
export class EventosComisionComponent implements OnInit {
  @Input() data: any = {};

  public eventos: any;
  public eventosComision: any;
  public eventosC: any;
  private _oid: string;

  constructor(private apollo: Apollo) { }

  ngOnInit(): void {
    this._oid = this.data.Oid;
    this.apollo
      .use('endpoint3')
      .watchQuery<any>({
        query: gql`
          {
            getEventosComision(Oid: "${this._oid}") {
              Oid
              Nombre
              Descripcion
              TipoEvento
              Hora
              HoraFin
              Comision
              UrlForo
              FechaHoraInicio
              FechaHoraFin
              Lugar
              Fotografia
              FotoBase64
              GrupoTrabajo
            }
          }
        `,
      })
      .valueChanges.subscribe((result) => {
        this.eventosComision = result.data.getEventosComision;
        this.eventosC = this.chunkArray([...this.eventosComision], 3);
      });
  }

  chunkArray(arr: Array<any>, n: number) {
    return arr
      .slice(0, ((arr.length + n - 1) / n) | 0)
      .map((c, i) => arr.slice(n * i, n * i + n));
  }
}
