import gql from 'graphql-tag';
export const getQueryPaginaPrincipal = gql`
  query {
    getDatosPaginaPrincipal {
      OrdenDelDia
      NombreCompleto
      Citatorio
      FechaUltimaSesion
      UrlUltimaSesion
      UrlBaseResumenSesion
      UrlBaseSinopsis
      UrlBaseVotaciones
      UrlBaseMinutoMinuto
      UrlBaseIntervenciones
    }
  }
`;
export const queryVeryImportantNotices = gql`
  {
    allNotices {
      Oid
      Titulo
      SFTPFotografia
      Tipo
      Url
    }
  }
`;
export const queryRelevantes = gql`
  {
    allRelevantes {
      Oid
      Orden
      Titulo
      SFTPFotografia
      FechaInicio
      FechaFin
      Url
    }
  }
`;
export const queryAllVideos = gql`
  {
    allVideosFromDB {
      Oid
      Titulo
      linkSitio
      Tipo
      NombreCanal
    }
  }
`;
export const queryallEnlacesInteres = gql`
  {
    allEnlacesInteres {
      Oid
      Nombre
      linkSitio
      Descripcion
      SFTPFotografia
    }
  }
`;
export const queryallEnlacesInteres2 = gql`
  {
    allEnlacesInteres2 {
      Oid
      Nombre
      linkSitio
      Descripcion
      SFTPFotografia
    }
  }
`;
export const queryallCulturalEventBanners = gql`
  {
    allCulturalEventBanners {
      Oid
      Nombre
      linkSitio
      Tooltip
      BanBase64
    }
  }
`;

export const queryEventos = gql`
  {
    allEvents {
      Oid
      Nombre
      Descripcion
      Ubicacion
      FechaInicio
      FechaFin
      TipoEvento
      linkSitio
      SFTPFotografia
    }
  }
`;
export const queryInfographicBanner = gql`
  {
    allInfographicBanners {
      Oid
      Titulo
      Descripcion
      Tooltip
      Link
      BannerBase64
    }
  }
`;

export const queryEnviarComentario = gql`
query($email: String!, $Comentario: String!) {
  enviarComentario(email: $email Comentario: $Comentario) 
   }
`;

export const queryDireccionEventoDiario = gql`
query($Fecha: String!){
    getDireccionEventoDiario(Fecha: $Fecha){
      Oid
      Orden
      Nombre
      Fecha
      Observaciones
      SFTPArchivo
    }
  }
`;

export const queryDireccionEventoMes = gql`
  {
    getDireccionEventoMes{
      Oid
      Orden
      Nombre
      Fecha
      Observaciones
      SFTPArchivo
    }
  }
`;
export const AddViewToPage = gql`
  mutation addView($Oid: String!) {
    addView(Oid: $Oid) {
      Oid
      Visitas
      Url
    }
  }
`;