import { Component, OnInit, Inject } from '@angular/core';

// KYBC.https://material.angular.io/components/dialog/examples
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';

// KYBC.Imports for queries and mutations with GraphQL and Apollo.
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
// import Swal from 'sweetalert2';

export interface DialogData {
  modalInfographic: string;
  youtubeUrl: string;
}
interface InfovideoCategory {
  Oid: string;
  Titulo: string;
  ImageBase64: string;
  Categoria: string;
}
interface Infovideo {
  Oid: string;
  Titulo: string;
  Fecha: string;
  linkSitio: string;
  Descripcion: string;
}
interface Infovideo2 {
  Oid: string;
}
interface Response {
  allInfoVideosCategories: InfovideoCategory[];
  allInfovideosPortal: Infovideo[];
  allInfoVideosByCategory: Infovideo[];
}
// interface ResponseFavoriteInfovideos {
//   allFavoriteInfoVideos: Infovideo2[];
// }
// const mutationAddFavoriteInfoVideo = (
//   OidInfovideo: any,
//   emailVisitante: any
// ): any => gql`
// mutation {
//   addFavoriteInfoVideo(OidInfoVideo: "${OidInfovideo}", EmailVisitante: "${emailVisitante}"){
//     Oid
//   }
// }`;
// const mutationDeleteFavoriteInfovideo = (
//   OidInfovideo: any,
//   emailVisitante: any
// ): any => gql`
// mutation {
//   deleteFavoriteInfovideo(OidInfoVideo: "${OidInfovideo}", EmailVisitante: "${emailVisitante}"){
//     Oid
//   }
// }`;
//DLG: revisar no funciona correctamente Pide ImanegnB64 cuando la consulta devuele URL
const infoVideoPreviewQueryByCategory = gql`
  query allInfoVideosByCategory($CategoriaOid: ID!) {
    allInfoVideosByCategory(CategoriaOid: $CategoriaOid) {
      Oid
      Titulo
      linkSitio
      ImageBase64
      Categoria
      Fecha
    }
  }
`;
const infographicPreviewQuery = gql`
  query infovideoPreview($Oid: ID!) {
    infovideoPreview(Oid: $Oid) {
      Oid
      Titulo
      linkSitio
      Categoria
    }
  }
`;
// const AddViewToInfographicMutation = gql`
//   mutation addViewToInfographic($Oid: ID!) {
//     addViewToInfographic(Oid: $Oid) {
//       Oid
//     }
//   }
// `;
const AddViewToInfovideoMutation = gql`
  mutation addViewToInfovideo($Oid: ID!) {
    addViewToInfovideo(Oid: $Oid) {
      Oid
    }
  }
`;
@Component({
  selector: 'app-videoteca',
  templateUrl: './videoteca.component.html',
  styleUrls: ['./videoteca.component.scss'],
})
export class VideotecaComponent implements OnInit {
  // favoriteInfoVideos: Infovideo2[];
  // loggedIn = false;
  // emailVisitante = '';
  // loading = true;
  // errorInfovideos: any;
  // subFavoriteInfovideos: any;
  // categorySelected: any = 'todas';

  // infographics: any;
  // loadingInfovideos = true;

  //RMGT
  sub: any;
  infoVideoCategories: any[];
  infovideos: any[];
  private querySubscription: any;
  previewInfoVideo: any;
  loadingCategories = true;

  error: any;

  constructor(private apollo: Apollo, public dialog: MatDialog) {}

  ngOnInit(): void {
    // GFVS.Call function for fetch all infovideo links from DB.
    // this.verifyVisitantSession(); // te faltaba esta linea
    this.fetchAllallInfoVideosCategories();
  }

  //RMGT.Funcion para obtener la Categorias de Videos
  fetchAllallInfoVideosCategories(): any {
    this.sub = this.apollo

      .watchQuery<Response>({
        query: gql`
          {
            allInfoVideosCategories {
              Oid
              Titulo
              ImageBase64
            }
          }
        `,
      })
      .valueChanges.subscribe((result) => {
        this.infoVideoCategories =
          result.data && result.data.allInfoVideosCategories;
        this.loadingCategories = result.loading;
        this.error = result['error'];
      });
  }

  //RMGT.Traer los videos por oid de Categoria
  fetchInfoVideosThumbnailsByCategory(categoryOid: any): any {
    this.querySubscription = this.apollo
      .watchQuery<Response>({
        query: infoVideoPreviewQueryByCategory,
        variables: {
          CategoriaOid: categoryOid,
        },
      })
      .valueChanges.subscribe(({ data }) => {
        this.infovideos = data.allInfoVideosByCategory;
        this.setPreviewInfoVideo(this.infovideos[0].Oid);
      });
  }

  // DLG. revisar
  setPreviewInfoVideo(Oid: any): any {
    this.querySubscription = this.apollo

      .watchQuery({
        query: infographicPreviewQuery,
        variables: {
          Oid,
        },
      })
      .valueChanges.subscribe(({ data }) => {
        this.previewInfoVideo = data;
        this.previewInfoVideo = this.previewInfoVideo.infovideoPreview;
        this.addViewToInfovideo(this.previewInfoVideo.Oid);
      });
    window.scrollTo(0, 0);
  }

  addViewToInfovideo(Oid: any): any {
    this.apollo

      .mutate({
        mutation: AddViewToInfovideoMutation,
        variables: {
          Oid,
        },
      })
      .subscribe(
        ({ data }) => {},
        (error) => {
          console.log(
            '::::::::::Error al aumentar las visualizaciones de la infografía: ',
            error
          );
        }
      );
  }

  // KYBC.Opens modal with infographic.
  openDialog(youtubeUrl: any): void {
    const dialogRef = this.dialog.open(VideotecaDialog, {
      width: '75%',
      height: '70%',
      data: { youtubeUrl: youtubeUrl },
    });
    dialogRef.afterClosed().subscribe((result) => {
      // console.log('::::::::::Modal closed! :' + result);
    });
  }

  /*RMGT. Funciones que no se ocupan pero que no estaban comentadas */
  // myFunction(): any {
  //   const dots = document.getElementById('dots');
  //   const moreText = document.getElementById('more');
  //   const btnText = document.getElementById('myBtn');

  //   if (dots.style.display === 'none') {
  //     dots.style.display = 'inline';
  //     btnText.innerHTML = 'Leer más';
  //     moreText.style.display = 'none';
  //   } else {
  //     dots.style.display = 'none';
  //     btnText.innerHTML = 'Leer menos';
  //     moreText.style.display = 'inline';
  //   }
  // }

  // myFunction2(): any {
  //   const dots = document.getElementById('dots2');
  //   const moreText = document.getElementById('more2');
  //   const btnText = document.getElementById('myBtn2');

  //   if (dots.style.display === 'none') {
  //     dots.style.display = 'inline';
  //     btnText.innerHTML = 'Leer más';
  //     moreText.style.display = 'none';
  //   } else {
  //     dots.style.display = 'none';
  //     btnText.innerHTML = 'Leer menos';
  //     moreText.style.display = 'inline';
  //   }
  // }

  // myFunction4(): any {
  //   const dots = document.getElementById('dots4');
  //   const moreText = document.getElementById('more4');
  //   const btnText = document.getElementById('myBtn4');

  //   if (dots.style.display === 'none') {
  //     dots.style.display = 'inline';
  //     btnText.innerHTML = 'Leer más';
  //     moreText.style.display = 'none';
  //   } else {
  //     dots.style.display = 'none';
  //     btnText.innerHTML = 'Leer menos';
  //     moreText.style.display = 'inline';
  //   }
  // }

  // myFunction5(): any {
  //   const dots = document.getElementById('dots5');
  //   const moreText = document.getElementById('more5');
  //   const btnText = document.getElementById('myBtn5');

  //   if (dots.style.display === 'none') {
  //     dots.style.display = 'inline';
  //     btnText.innerHTML = 'Leer más';
  //     moreText.style.display = 'none';
  //   } else {
  //     dots.style.display = 'none';
  //     btnText.innerHTML = 'Leer menos';
  //     moreText.style.display = 'inline';
  //   }
  // }

  // myFunction6(): any {
  //   const dots = document.getElementById('dots6');
  //   const moreText = document.getElementById('more6');
  //   const btnText = document.getElementById('myBtn6');

  //   if (dots.style.display === 'none') {
  //     dots.style.display = 'inline';
  //     btnText.innerHTML = 'Leer más';
  //     moreText.style.display = 'none';
  //   } else {
  //     dots.style.display = 'none';
  //     btnText.innerHTML = 'Leer menos';
  //     moreText.style.display = 'inline';
  //   }
  // }

  /*Funciones que ya no se ocupan */
  // // GFVS.Fetch all infovideo links from DB.
  // fetchAllInfovideos(): any {
  //   this.sub = this.apollo
  //     .watchQuery<Response>({
  //       query: gql`
  //         {
  //           allInfovideosPortal {
  //             Oid
  //             Titulo
  //             Fecha
  //             linkSitio
  //             Descripcion
  //           }
  //         }
  //       `,
  //     })
  //     .valueChanges.subscribe((result) => {
  //       // console.log('::::::::::allInfovideos Result from DB: \n', result); // KYBC.Borrar.
  //       this.infovideos = result.data && result.data.allInfovideosPortal;
  //       // console.log('::::::::::allInfovideos from DB: \n', this.infovideos); // KYBC.Borrar.
  //       this.loadingInfovideos = result.loading;
  //       this.errorInfovideos = result['error'];
  //     });
  // }
  // verifyVisitantSession(): any {
  //   this.loggedIn = !!localStorage.getItem('HCD_activeSessionVisitant');
  //   if (this.loggedIn) {
  //     this.emailVisitante = localStorage.getItem('HCD_visitantEmail');

  //     // traer los eventos favoritos de el visitante
  //     this.subFavoriteInfovideos = this.apollo
  //       .watchQuery<ResponseFavoriteInfovideos>({
  //         query: gql`
  //         {
  //           allFavoriteInfoVideos(EmailVisitante: "${this.emailVisitante}") {
  //             Oid
  //           }
  //         }
  //       `,
  //       })
  //       .valueChanges.subscribe((result) => {
  //         this.favoriteInfoVideos =
  //           result.data && result.data.allFavoriteInfoVideos;
  //         this.loading = result.loading;
  //         this.error = result['error'];

  //         console.log(
  //           'llegaron estos infovideos favoritos',
  //           result.data.allFavoriteInfoVideos
  //         );
  //         this.fetchAllInfovideos();
  //       });
  //   }
  // }
  // addFavoriteInfovideo(OidInfovideo) {
  //   console.log(`Llego oid ${OidInfovideo}`);
  //   console.log(`tenemos correo: ${this.emailVisitante}`);

  //   this.apollo
  //     .mutate({
  //       mutation: mutationAddFavoriteInfoVideo(OidInfovideo, this.emailVisitante),
  //     })
  //     .subscribe(
  //       ({ data }) => {
  //         alert('se ha guardado el infovideo en favoritos de manera exitosa!');
  //       },
  //       error => {
  //         alert('hubo un error al guardar en favoritos, intente de nuevo');
  //       }
  //     );
  // }
  // isFavorite(oid: any): any {
  //   return this.favoriteInfoVideos.find((infovideo) => infovideo.Oid === oid);
  // }
  // // ngOnDestroy() {
  // //    console.log("onDestroy"); //KYBC. Borrar
  // //   this.sub.unsubscribe();
  // //   // GFVS este ngOnDestroy lo agregué para  traer infovideos por oid
  // //   this.querySubscription.unsubscribe();
  // // }
  // addFavoriteInfovideo(OidInfovideo: any): any {
  //   console.log(`Llego oid ${OidInfovideo}`);
  //   console.log(`Llego correo ${this.emailVisitante}`);
  //   this.apollo
  //     .mutate({
  //       mutation: mutationAddFavoriteInfoVideo(
  //         OidInfovideo,
  //         this.emailVisitante
  //       ),
  //     })
  //     .subscribe(
  //       ({ data }) => {
  //         // const newFavEvent: Event = {Oid: OidEvento};
  //         const newFavInfoVideo: Infovideo2 = { Oid: OidInfovideo };
  //         this.favoriteInfoVideos.push(newFavInfoVideo);
  //         Swal.fire(
  //           'Confirmación',
  //           'Se ha agregado a favoritos de manera exitosa!',
  //           'success'
  //         );
  //       },
  //       (error: string) => {
  //         Swal.fire({
  //           icon: 'error',
  //           title: 'Oops...',
  //           text:
  //             'Hubo un error al agregar a favoritos, intente de nuevo mas tarde.',
  //           footer: '<a href>Continuas con problemas?</a>',
  //         });
  //       }
  //     );
  // }
  // deleteFavoriteInfovideo(OidInfovideo: any): any {
  //   // console.log(`Llego oid ${OidInfovideo}`);
  //   this.apollo
  //     .mutate({
  //       mutation: mutationDeleteFavoriteInfovideo(
  //         OidInfovideo,
  //         this.emailVisitante
  //       ),
  //     })
  //     .subscribe(
  //       ({ data }) => {
  //         this.favoriteInfoVideos = this.favoriteInfoVideos.filter(
  //           (infovideo) => infovideo.Oid !== OidInfovideo
  //         );
  //         Swal.fire(
  //           'Confirmación',
  //           'Se ha borrado de favoritos de manera exitosa!',
  //           'success'
  //         );
  //       },
  //       (error: string) => {
  //         Swal.fire({
  //           icon: 'error',
  //           title: 'Oops...',
  //           text:
  //             'Hubo un error al borrar de favoritos, intente de nuevo mas tarde.',
  //           footer: '<a href>Continuas con problemas?</a>',
  //         });
  //       }
  //     );
  // }
}

@Component({
  selector: 'videotecaModal',
  templateUrl: 'videotecaModal.html',
})
export class VideotecaDialog {
  constructor(
    public dialogRef: MatDialogRef<VideotecaDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
    // console.log('::::::::::Modal closed!');
  }
}
