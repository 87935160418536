import { Component, OnInit } from '@angular/core';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import * as _ from 'underscore';
import {
  TurnadasLegis,
  ResponseReformasConstiTurnadas,
} from '../leyes-vigentes.interface';
@Component({
  selector: 'app-modal-legislatura',
  templateUrl: './modal-legislatura.component.html',
  styles: [
    `
      .leyes-action-buttons {
        padding-bottom: 20px;
      }

      .leyes-headers-align .mat-expansion-panel-header-title,
      .leyes-headers-align .mat-expansion-panel-header-description {
        flex-basis: 0;
      }

      .leyes-headers-align .mat-expansion-panel-header-description {
        justify-content: space-between;
        align-items: center;
      }

      .leyes-headers-align .mat-form-field + .mat-form-field {
        margin-left: 8px;
      }
      .organo-icon {
        color: lightgray;
      }
    `,
  ],
})
export class ModalLegislaturaComponent implements OnInit {
  loading = true;
  error: any;
  subReformasConstiTurnadas: any;
  ReformasConstTurnadas: TurnadasLegis[];
  constructor(
    private apollo: Apollo,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<ModalLegislaturaComponent>
  ) {}
  ngOnInit(): any {
    this.subReformasConstiTurnadas = this.apollo
      .watchQuery<ResponseReformasConstiTurnadas>({
        query: gql`
          {
            ReformasConstiTurnadas {
              Id
              Nombre
              LinkProyecto
              TurnoCDstr
              DeclaratoriaCDstr
              DeclaratoriaCSstr
              FechaDOFstr
              LinkDOF
              Aprobacion
              AprobacionF
            }
          }
        `,
      })
      .valueChanges.subscribe((result) => {
        this.ReformasConstTurnadas =
          result.data && result.data.ReformasConstiTurnadas;
        this.loading = result.loading;
        this.error = result.errors;
      });
  }
  closeDialog(): any {
    this.dialogRef.close();
  }
}
