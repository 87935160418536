import { DOCUMENT } from '@angular/common';
import { Component, ElementRef, Inject, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Apollo } from 'apollo-angular';
import { ApiHomeService } from 'src/app/services/api-home.service';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit {
  querySub: any;
  id: any;
  Id_Tipo: any;
  palabraClave: any;


  constructor(private apollo: Apollo,
    public dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute) {

  }

  ngOnInit(): void {

    this.querySub = this.route.queryParams.subscribe((params) => {
      this.Id_Tipo = params.Tipo || null;
      console.log({ Tipo: this.Id_Tipo });
      this.palabraClave = params.key || null;
    });


  }


}
