import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import {
  Aviso,
  AvisoPrivacidad,
  // AvisoPrivacidad2,
  ResponseAvisoPrivacidad,
  // ResponseFavoriteAvisoPrivacidad,
} from './aviso-privacidad.interface';
import Swal from 'sweetalert2';

// const mutationAddFavoriteAviso = (OidAviso, emailVisitante): any => gql`
// mutation {
//   addFavoriteAviso(OidAviso: "${OidAviso}", EmailVisitante: "${emailVisitante}"){
//     Oid
//   }
// }`;

// const mutationDeleteFavoriteAviso = (OidAviso, emailVisitante): any => gql`
// mutation {
//   deleteFavoriteAviso(OidAviso: "${OidAviso}", EmailVisitante: "${emailVisitante}"){
//     Oid
//   }
// }`;

@Component({
  selector: 'app-aviso-privacidad',
  templateUrl: './aviso-privacidad.component.html',
  styleUrls: ['./aviso-privacidad.component.scss'],
})
export class AvisoPrivacidadComponent implements OnInit, OnDestroy {
  // morena = false;
  // error: any;
  // p = 1;
  // subAvisoPrivacidad: any;
  // subFavoriteAvisoPrivacidad: any;
  // decodedString: any;
  // loggedIn = false;
  // emailVisitante = '';
  // favoriteAvisosPrivacidad: AvisoPrivacidad2[];

  //RMGT
  @Input() separador: boolean = true;
  loading = false;
  config: any;
  collection = [];

  subAvisoPrivacidad: any;
  avisosPriv: Aviso[];
  gruposParl: AvisoPrivacidad[] = [];
  comisiones: AvisoPrivacidad[] = [];
  mesaDirec: AvisoPrivacidad[] = [];
  jucopo: AvisoPrivacidad[] = [];
  organosA: AvisoPrivacidad[] = [];

  colap = '';
  ap = '';

  contador: number = 0;

  constructor(
    private apollo: Apollo,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.config = {
      currentPage: 1,
      itemsPerPage: 2,
    };

    this.route.queryParamMap
      .pipe(map((params) => params.get('page')))
      .subscribe((page) => (this.config.currentPage = page));

    for (let i = 1; i <= 101; i++) {
      this.collection.push(`item ${i}`);
    }
  }

  ngOnInit() {
    // this.verifyVisitantSession();
    this.route.queryParams.subscribe((params: any) => {
      this.colap = params.colap;
      this.ap = params.ap;
    });

    // this.allAvisosPrivacidad();
    this.getAvisosPrivacidadGruposParlamentarios();
    this.getAvisosPrivacidadComisiones();
    this.getAvisosPrivacidadOrganoGobierno();
    this.getAvisosPrivacidadOrganoAdministrativo();
  }

  // ngAfterContentInit(){
  //   if (
  //     this.LoadgruposParl &&
  //     this.Loadcomisiones &&
  //     this.LoadmesaDirec &&
  //     this.Loadjucopo &&
  //     this.LoadorganosA
  //   ) {
  //     document.getElementById('btnScrol')?.click();
  //   }
  // }

  // allAvisosPrivacidad(){

  //   this.subAvisoPrivacidad = this.apollo
  //     .watchQuery<ResponseAvisoPrivacidad>({
  //       query: gql`
  //         {
  //           allAvisosPrivacidad {
  //             Oid
  //             Nombre
  //             NombreCompleto
  //             NombreCorto
  //             URLDocumento
  //             FileName
  //             size
  //             Area
  //           }
  //         }
  //       `,
  //     })
  //     .valueChanges.subscribe((result) => {
  //       this.avisosPriv = result.data && result.data.allAvisosPrivacidad;
  //       // this.gruposParl.forEach((grupo) => {
  //       //   grupo.avisos = this.avisosPriv.filter(
  //       //     (dato) => dato.Area === grupo.oid
  //       //   );
  //       // });
  //       // this.comisiones.forEach((comision) => {
  //       //   comision.avisos = this.avisosPriv.filter(
  //       //     (dato) => dato.Area === comision.oid
  //       //   );
  //       // });
  //       // this.organosGob.forEach((organo) => {
  //       //   organo.avisos = this.avisosPriv.filter(
  //       //     (dato) => dato.Area === organo.oid
  //       //   );
  //       // });
  //       // this.jucopo.forEach((juc) => {
  //       //   juc.avisos = this.avisosPriv.filter((dato) => dato.Area === juc.oid);
  //       // });
  //       // this.organosA.forEach((organoA) => {
  //       //   organoA.avisos = this.avisosPriv.filter(
  //       //     (dato) => dato.Area === organoA.oid
  //       //   );
  //       // });
  //       // this.loading = result.loading;
  //     });
  // }
  getAvisosPrivacidadGruposParlamentarios() {
    this.apollo
      .watchQuery<ResponseAvisoPrivacidad>({
        query: gql`
          {
            getAvisosPrivacidadGruposParlamentarios {
              Oid
              Nombre
              NombreCompleto
              Orden
              Open
              Avisos {
                Oid
                Nombre
                Area
                NombreCompleto
                NombreCorto
                FileName
                size
                Content
                URLDocumento
                NombreArea
                Contenido
              }
            }
          }
        `,
      })
      .valueChanges.subscribe((result) => {
        this.gruposParl =
          result.data && result.data.getAvisosPrivacidadGruposParlamentarios;
        this.UrlConParametros(this.gruposParl);
      });
  }

  getAvisosPrivacidadComisiones() {
    this.apollo
      .watchQuery<ResponseAvisoPrivacidad>({
        query: gql`
          {
            getAvisosPrivacidadComisiones {
              Oid
              Nombre
              NombreCompleto
              Orden
              Open
              Avisos {
                Oid
                Nombre
                Area
                NombreCompleto
                NombreCorto
                FileName
                size
                Content
                URLDocumento
                NombreArea
                Contenido
              }
            }
          }
        `,
      })
      .valueChanges.subscribe((result) => {
        this.comisiones =
          result.data && result.data.getAvisosPrivacidadComisiones;
        this.UrlConParametros(this.comisiones);
      });
  }

  getAvisosPrivacidadOrganoGobierno() {
    this.apollo
      .watchQuery<ResponseAvisoPrivacidad>({
        query: gql`
          {
            getAvisosPrivacidadOrganoGobierno {
              Oid
              Nombre
              NombreCompleto
              AgruprarAvisoPrivacidad
              Orden
              Open
              Avisos {
                Oid
                Nombre
                Area
                NombreCompleto
                NombreCorto
                FileName
                size
                Content
                URLDocumento
                NombreArea
                Contenido
              }
            }
          }
        `,
      })
      .valueChanges.subscribe((result) => {
        let organoGobierno =
          result.data && result.data.getAvisosPrivacidadOrganoGobierno;

        this.mesaDirec = organoGobierno.filter(
          (og) =>
            og.AgruprarAvisoPrivacidad == '511c10d0-e9f2-4100-9399-19d41e8aa96d'
        );
        this.UrlConParametros(this.mesaDirec);
        this.jucopo = organoGobierno.filter(
          (og) =>
            og.AgruprarAvisoPrivacidad == 'afd35b41-ed37-4883-bddb-cf934908afbb'
        );
        this.UrlConParametros(this.jucopo);
      });
  }

  getAvisosPrivacidadOrganoAdministrativo() {
    this.apollo
      .watchQuery<ResponseAvisoPrivacidad>({
        query: gql`
          {
            getAvisosPrivacidadOrganoAdministrativo {
              Oid
              Nombre
              NombreCompleto
              Orden
              Open
              Avisos {
                Oid
                Nombre
                Area
                NombreCompleto
                NombreCorto
                FileName
                size
                Content
                URLDocumento
                NombreArea
                Contenido
              }
            }
          }
        `,
      })
      .valueChanges.subscribe((result) => {
        this.organosA =
          result.data && result.data.getAvisosPrivacidadOrganoAdministrativo;

        this.UrlConParametros(this.organosA);
      });
  }

  UrlConParametros(arreglo: any) {
    this.contador += 1;
    if (this.colap != '' && this.ap != '') {
      arreglo.filter((x) => {
        if (x.Oid === this.colap) x.Open = true;
      });
      if (this.contador == 5)
        setTimeout(() => {
          this.scrollHref();
        }, 2000);
    }
  }

  // verifyVisitantSession(): any {
  //   this.loggedIn = !!localStorage.getItem('HCD_activeSessionVisitant');

  //   if (this.loggedIn) {
  //     this.emailVisitante = localStorage.getItem('HCD_visitantEmail');

  //     // traer los avisos favoritos de el visitante
  //     this.subFavoriteAvisoPrivacidad = this.apollo
  //       .watchQuery<ResponseFavoriteAvisoPrivacidad>({
  //         query: gql`
  //         {
  //           allFavoriteAvisosPrivacidad(EmailVisitante: "${this.emailVisitante}") {
  //             Oid
  //           }
  //         }
  //       `,
  //       })
  //       .valueChanges.subscribe((result) => {
  //         this.favoriteAvisosPrivacidad =
  //           result.data && result.data.allFavoriteAvisosPrivacidad;
  //         this.loading = result.loading;
  //         this.error = result['error'];

  //         // console.log(
  //         //   'llegaron estos avisos favoritos',
  //         //   result.data.allFavoriteAvisosPrivacidad
  //         // );
  //       });
  //   }
  // }
  // isFavorite(oid): any {
  //   return this.favoriteAvisosPrivacidad.find((aviso) => aviso.Oid === oid);
  // }

  ngOnDestroy() {
    if (this.subAvisoPrivacidad) {
      this.subAvisoPrivacidad.unsubscribe();
    }
  }
  // addFavoriteAviso(OidAviso): any {
  //   // console.log(`Llego oid ${OidAviso}`);
  //   this.apollo
  //     .mutate({
  //       mutation: mutationAddFavoriteAviso(OidAviso, this.emailVisitante),
  //     })
  //     .subscribe(
  //       ({ data }) => {
  //         const newFavAviso: AvisoPrivacidad2 = { Oid: OidAviso };
  //         this.favoriteAvisosPrivacidad.push(newFavAviso);
  //         alert('se ha guardado el aviso en favoritos de manera exitosa!');
  //       },
  //       (error) => {
  //         alert('hubo un error al guardar en favoritos, intente de nuevo');
  //         console.log(error);
  //       }
  //     );
  // }

  // deleteFavoriteAviso(OidAviso): any {
  //   this.apollo
  //     .mutate({
  //       mutation: mutationDeleteFavoriteAviso(OidAviso, this.emailVisitante),
  //     })
  //     .subscribe(
  //       ({ data }) => {
  //         this.favoriteAvisosPrivacidad = this.favoriteAvisosPrivacidad.filter(
  //           (aviso) => aviso.Oid !== OidAviso
  //         );
  //         alert('se ha borrado el aviso en favoritos de manera exitosa!');
  //       },
  //       (error) => {
  //         alert('hubo un error al borrar de favoritos, intente de nuevo');
  //         console.log(error);
  //       }
  //     );
  // }

  copiarLink(url: string) {
    // console.log('ABC: ', url);
    let aux = document.createElement('input');
    aux.setAttribute('value', url);
    document.body.appendChild(aux);
    aux.select();
    document.execCommand('copy');
    document.body.removeChild(aux);
    Swal.fire(
      'Confirmación',
      'La dirección URL se ha copiado exitosamente!',
      'success'
    );
  }
  copiarLinkVinculo(
    seccion: String,
    oidColapsable,
    oidAvisoPrivacidad: string
  ) {
    const aux = document.createElement('input');
    let url = `https://transparencia.diputados.gob.mx/transparencia/datosPersonales?colap=${oidColapsable}&ap=${oidAvisoPrivacidad}`;
    aux.setAttribute('value', url);
    document.body.appendChild(aux);
    aux.select();
    document.execCommand('copy');
    document.body.removeChild(aux);
    Swal.fire(
      'Confirmación',
      'El link se ha copiado correctamente!',
      'success'
    );
  }

  scrollHref() {
    const element = document.getElementById(`${this.ap}`);
    element?.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'nearest',
    });
  }
}
