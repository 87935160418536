import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { IfadDetalleComponent } from './ifad-detalle/ifad-detalle.component';
import { ListaIfadComponent } from './lista-ifad/lista-ifad.component';

const routes: Routes = [
    {
      path: '',
      component: ListaIfadComponent,
    },
    {
        path: 'ifad-detalle',
        component: IfadDetalleComponent, // Ajuste para que el home sea otro, para Diputados el valor debe ser Ho
        data: { breadcrumb: 'Detalle' },
    },
  ];
  
  @NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
  })
  export class IFADRoutingModule { }
  