import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// KYBC.Pages components imports.
import { HomepageComponent } from './homepage/homepage.component';
import { ListaEventosComponent } from './lista-eventos/lista-eventos.component';
import { ListaEventosPasadosComponent } from './lista-eventos-pasados/lista-eventos-pasados.component';
import { TransparenciaComponent } from './transparencia/transparencia.component';
import { MemoriaParlamentariaComponent } from './memoria-parlamentaria/memoria-parlamentaria.component';
import { InformacionParlamentariaComponent } from './informacion-parlamentaria/informacion-parlamentaria.component';
import { BalanceActividadesComponent } from './balance-actividades/balance-actividades.component';
import { DatosRelevantesComponent } from './datos-relevantes/datos-relevantes.component';
import { InfopalComponent } from './infopal/infopal.component';
import { PopupComponent } from './popup/popup.component';
import { SecretariaGeneralComponent } from './secretaria-general/secretaria-general.component';
import { AcuerdosComponent } from './acuerdos/acuerdos.component';
import { ArtDosComponent } from './transparencia/art-dos/art-dos.component';
import { DiarioDebatesComponent } from './diario-debates/diario-debates.component';
import { SesionComponent } from './diario-debates/sesion/sesion.component';
import { VotacionesComponent } from './diario-debates/votaciones/votaciones.component';
import { DiarioBusquedaComponent } from './diario-debates/diario-busqueda/diario-busqueda.component';
import { IntervencionesComponent } from './diario-debates/intervenciones/intervenciones.component';
import { IntervencionComponent } from './diario-debates/intervenciones/intervencion/intervencion.component';
import { AprobacionesConstitucionalesComponent } from './aprobaciones-constitucionales/aprobaciones-constitucionales.component';

import { MinutasComponent } from './minutas/minutas.component';
import { InfoFinancieraComponent } from './parlamentarias/info-financiera/info-financiera.component';
import { InfoLegislativaComponent } from './parlamentarias/info-legislativa/info-legislativa.component';
import { RelacionesLaboralesComponent } from './parlamentarias/relaciones-laborales/relaciones-laborales.component';
import { AvisoPrivacidadComponent } from './transparencia/aviso-privacidad/aviso-privacidad.component';
import { GacetaComponent } from './parlamentarias/gaceta/gaceta.component';
import { GacetaAltComponent } from './parlamentarias/gaceta-alt/gaceta-alt.component';
import { GacetaFrameComponent } from './parlamentarias/gaceta-frame/gaceta-frame.component';
import { HistorialConsultasComponent } from './transparencia/historial-consultas/historial-consultas.component';
import { ComisionesComponent } from './comisiones/comisiones.component';
import { ComisionComponent } from './comisiones/comision/comision.component';
import { ArtIxComponent } from './transparencia/art-ix/art-ix.component';
import { ParlamentoAbiertoComponent } from './parlamentarias/parlamento-abierto/parlamento-abierto.component';
import { MicamaraTempComponent } from './micamara-temp/micamara-temp.component';
import { OrdenComponent } from './diario-debates/orden/orden.component';
import { EntrevistasComponent } from './entrevistas/entrevistas.component';
import { IndicesComponent } from './diario-debates/indices/indices.component';
import { TransparenciaModule } from './transparencia/transparencia.module';
import { RecursosHumanosComponent } from './parlamentarias/recursos-humanos/recursos-humanos.component';
import { ServicioSocialComponent } from './servicios/servicio-social/servicio-social.component';
import { AsistenciasComponent } from './parlamentarias/asistencias/asistencias.component';
import { HistorialVotacionesComponent } from './parlamentarias/historial-votaciones/historial-votaciones.component';
import { DirectorioComponent } from './directorio/directorio.component';
import { RenovacionComponent } from './secretaria-general/renovacion/renovacion.component';
import { VotosComponent } from './aprobaciones-constitucionales/modal/votos/votos.component';
import { DireccionEventoComponent } from './homepage/modal/direccion-evento/direccion-evento.component';

const routes: Routes = [
  { path: 'inicio', component: TransparenciaComponent },
  {
    path: '',
    component: TransparenciaComponent, // Ajuste para que el home sea otro, para Diputados el valor debe ser Ho
    data: { breadcrumb: 'Inicio' },
  },
  {
    path: 'tusDiputados',
    loadChildren: (): any =>
      import('../home/tusdiputados/tusdiputados.module').then(
        (m) => m.TusdiputadosModule
      ),
    data: { breadcrumb: 'Tus Diputados' },
  },
  {
    path: 'tuCamara',
    loadChildren: (): any =>
      import('../home/tucamara/tucamara.module').then((m) => m.TucamaraModule),
    data: { breadcrumb: 'Tu Cámara' },
  },
  {
    path: 'infocamara',
    loadChildren: (): any =>
      import('./infocamara/infocamara.module').then((m) => m.InfocamaraModule),
    data: { breadcrumb: 'Infocámara' },
  },

  {
    path: 'eventosProximos',
    component: ListaEventosComponent,
    data: { breadcrumb: 'Eventos Próximos' },
  },
  {
    path: 'eventosPasados',
    component: ListaEventosPasadosComponent,
    data: { breadcrumb: 'Eventos Pasados' },
  },
  {
    path: 'transparencia',
    component: TransparenciaComponent,
    data: { breadcrumb: 'Transparencia' },
  },
  {
    path: 'memoriaParlamentaria',
    component: MemoriaParlamentariaComponent,
    data: { breadcrumb: 'Memoria Parlamentaria' },
  },
  {
    path: 'informacionParlamentaria',
    component: InformacionParlamentariaComponent,
    data: { breadcrumb: 'Información Parlamentaria' },
  },
  {
    path: 'recursosHumanos',
    component: RecursosHumanosComponent,
    data: { breadcrumb: 'Recursos Humanos' },
  },
  {
    path: 'balanceActividades',
    component: BalanceActividadesComponent,
    data: { breadcrumb: 'Balance de Actividades' },
  },
  {
    path: 'datosRelevantes',
    component: DatosRelevantesComponent,
    data: { breadcrumb: 'Datos Relevantes' },
  },
  {
    path: 'infopal',
    component: InfopalComponent,
    data: { breadcrumb: 'Infopal' },
  },
  {
    path: 'servicioSocial',
    component: ServicioSocialComponent,
    data: { breadcrumb: 'Servicio Social' },
  },
  {
    path: 'secretariaGeneral',
    component: SecretariaGeneralComponent,
    data: { breadcrumb: 'Secretaría General' },
  },
  {
    path: 'renovacion',
    component: RenovacionComponent,
    data: { breadcrumb: 'Renovación' },
  },
  { path: 'popup', component: PopupComponent, data: { breadcrumb: 'Popup' } },
  {
    path: 'acuerdos',
    component: AcuerdosComponent,
    data: { breadcrumb: 'Acuerdos' },
  },
  {
    path: 'artDos',
    component: ArtDosComponent,
    data: { breadcrumb: 'Artículo 72' },
  },
  {
    path: 'diarioDebates',
    component: DiarioDebatesComponent,
    data: { breadcrumb: 'Diario de Debates' },
  },
  {
    path: 'sesion',
    component: SesionComponent,
    data: { breadcrumb: 'Sesiones' },
  },
  {
    path: 'votaciones',
    component: VotacionesComponent,
    data: { breadcrumb: 'Votaciones' },
  },
  {
    path: 'intervenciones',
    component: IntervencionesComponent,
    data: { breadcrumb: 'Intervenciones' },
  },
  {
    path: 'aprobacionesConstitucionales',
    component: AprobacionesConstitucionalesComponent,
    data: { breadcrumb: 'Aprobaciones Constitucionales' },
  },
  {
    path: 'votosConstitucionales',
    component: VotosComponent,
    data: { breadcrumb: 'Votos Constitucionales' },
  },
  {
    path: 'minutas',
    component: MinutasComponent,
    data: { breadcrumb: 'Minutas' },
  },
  {
    path: 'intervencion',
    component: IntervencionComponent,
    data: { breadcrumb: 'Intervención' },
  },
  {
    path: 'diarioBusqueda',
    component: DiarioBusquedaComponent,
    data: { breadcrumb: 'Búsqueda de Diario' },
  },
  {
    path: 'infoFinanciera',
    component: InfoFinancieraComponent,
    data: { breadcrumb: 'Información Financiera y Presupuestal' },
  },
  {
    path: 'infoLegislativa',
    component: InfoLegislativaComponent,
    data: { breadcrumb: 'Información Legislativa' },
  },
  {
    path: 'asistencias',
    component: AsistenciasComponent,
    data: { breadcrumb: 'Asistencias' },
  },
  {
    path: 'historial-votaciones',
    component: HistorialVotacionesComponent,
    data: { breadcrumb: 'Historial de Votaciones' },
  },
  {
    path: 'relacionesLaborales',
    component: RelacionesLaboralesComponent,
    data: { breadcrumb: 'Relaciones Laborales' },
  },
  {
    path: 'avisoPrivacidad',
    component: AvisoPrivacidadComponent,
    data: { breadcrumb: 'Aviso de Privacidad' },
  },
  {
    path: 'gaceta',
    component: GacetaComponent,
    data: { breadcrumb: 'Gaceta Parlamentaria' },
  },
  {
    path: 'gacetaAlt',
    component: GacetaAltComponent,
    data: { breadcrumb: 'Gaceta Parlamentaria' },
  },
  {
    path: 'gacetaFrame',
    component: GacetaFrameComponent,
    data: { breadcrumb: 'Gaceta Parlamentaria' },
  },
  {
    path: 'historialConsultas',
    component: HistorialConsultasComponent,
    data: { breadcrumb: 'Historial de Consultas' },
  },
  {
    path: 'comisiones',
    component: ComisionesComponent,
    data: { breadcrumb: 'Comisiones' },
  },
  {
    path: 'comision',
    component: ComisionComponent,
    data: { breadcrumb: 'Micrositio Comisión' },
  },
  {
    path: 'parlamentoAbierto',
    component: ParlamentoAbiertoComponent,
    data: { breadcrumb: 'Parlamento Abierto' },
  },
  {
    path: 'micamaraTemp',
    component: MicamaraTempComponent,
    data: { breadcrumb: 'Parlamento Abierto' },
  },
  {
    path: 'orden',
    component: OrdenComponent,
    data: { breadcrumb: 'Orden del Día' },
  },
  {
    path: 'entrevistas',
    component: EntrevistasComponent,
    data: { breadcrumb: 'Entrevistas' },
  },

  {
    path: 'indices',
    component: IndicesComponent,
    data: { breadcrumb: 'Índice del Diario Oficial' },
  },
  {
    path: 'directorio',
    component: DirectorioComponent,
    data: { breadcrumb: 'Directorio de la Cámara de Diputados' },
  },
  {
    path: 'artIx',
    component: ArtIxComponent,
    data: { breadcrumb: 'Archivos Transparencia' },
  },
  {
    path: 'direccionEventos',
    component: DireccionEventoComponent,
    data: { breadcrumb: 'Eventos' },
  },
  {
    path: '**',
    redirectTo: 'inicio',
    data: { breadcrumb: 'Inicio' },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class HomeRoutingModule {}
