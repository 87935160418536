export enum EstadoEnum {
  'Baja California' = 'mx-bc',
  'Baja California Sur' = 'mx-bs',
  'Sonora' = 'mx-so',
  'Colima' = 'mx-cl',
  'Nayarit' = 'mx-na',
  'Campeche' = 'mx-cm',
  'Quintana Roo' = 'mx-qr',
  'México' = 'mx-mx',
  'Morelos' = 'mx-mo',
  'Ciudad de México' = 'mx-df',
  'Querétaro' = 'mx-qt',
  'Tabasco' = 'mx-tb',
  'Chiapas' = 'mx-cs',
  'Nuevo León' = 'mx-nl',
  'Sinaloa' = 'mx-si',
  'Chihuahua' = 'mx-ch',
  'Veracruz' = 'mx-ve',
  'Zacatecas' = 'mx-za',
  'Aguascalientes' = 'mx-ag',
  'Jalisco' = 'mx-ja',
  'Michoacán' = 'mx-mi',
  'Oaxaca' = 'mx-oa',
  'Puebla' = 'mx-pu',
  'Guerrero' = 'mx-gr',
  'Tlaxcala' = 'mx-tl',
  'Tamaulipas' = 'mx-tm',
  'Coahuila' = 'mx-co',
  'Yucatán' = 'mx-yu',
  'Durango' = 'mx-dg',
  'Guanajuato' = 'mx-gj',
  'San Luis Potosí' = 'mx-sl',
  'Hidalgo' = 'mx-hg',
}
