import { Component, OnInit } from '@angular/core';
import { AvisoPrivacidad } from 'src/app/interfaces/avisoPrivacidad';
import { AvisosPrivacidadService } from 'src/app/services/avisos-privacidad.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-mis-avisos-privacidad',
  templateUrl: './mis-avisos-privacidad.component.html',
  styleUrls: ['./mis-avisos-privacidad.component.scss'],
})
export class MisAvisosPrivacidadComponent implements OnInit {
  favoriteAvisosPrivacidad: AvisoPrivacidad[];
  public data: any;
  private _subAvisosPrivacidad: any;
  constructor(private _AvisosPrivacidadService: AvisosPrivacidadService) {}

  ngOnInit() {
    this.setSubAvisosPrivacidad();
  }

  ngOnDestroy(): any {
    this._subAvisosPrivacidad.unsubscribe();
  }
  setSubAvisosPrivacidad() {
    this._subAvisosPrivacidad = this._AvisosPrivacidadService
      .getSubAvisosPrivacidad(this.data.visitanteEmail)
      .valueChanges.subscribe((result) => {
        this.favoriteAvisosPrivacidad =
          result.data && result.data.allFavoriteAvisosPrivacidad;
      });
  }

  deleteFavoriteAviso(OidAviso): any {
    this._AvisosPrivacidadService
      .deleteFavoriteAvisoPrivacidad(OidAviso, this.data.visitanteEmail)
      .subscribe(
        ({ data }) => {
          this.favoriteAvisosPrivacidad = this.favoriteAvisosPrivacidad.filter(
            (aviso) => aviso.Oid !== OidAviso
          );
          Swal.fire(
            'Confirmación',
            'Se ha borrado de favoritos de manera exitosa!',
            'success'
          );
        },
        (error) => {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text:
              'Hubo un error al borrar de favoritos, intente de nuevo mas tarde.',
            footer: '<a href>Continuas con problemas?</a>',
          });
          console.log(error);
        }
      );
  }
}
