import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { TransparenciaModule } from './transparencia.module';
import { TransparenciaComponent } from './transparencia.component';
import { AvisoPrivacidadComponent } from './aviso-privacidad/aviso-privacidad.component';
import { HistorialConsultasComponent } from './historial-consultas/historial-consultas.component';
import { ArtIxComponent } from './art-ix/art-ix.component';
import { DatosPersonalesComponent } from './datos-personales/datos-personales.component';

const routes: Routes = [
  {
    path: '',
    component: TransparenciaComponent, // Ajuste para que el home sea otro, para Diputados el valor debe ser Ho
    data: { breadcrumb: 'Transparencia' },
  },
  {
    path: 'avisoPrivacidad',
    component: AvisoPrivacidadComponent, // Ajuste para que el home sea otro, para Diputados el valor debe ser Ho
    data: { breadcrumb: 'Avisos de privacidad' },
  },
  {
    path: 'historialConsultas',
    component: HistorialConsultasComponent, // Ajuste para que el home sea otro, para Diputados el valor debe ser Ho
    data: { breadcrumb: 'Historial' }, // artIx?oid=8
  },
  {
    path: 'datosPersonales',
    component: DatosPersonalesComponent, // Ajuste para que el home sea otro, para Diputados el valor debe ser Ho
    data: { breadcrumb: 'Datos Personales' }, // artIx?oid=8
  },
  {
    path: 'obligaciones',
    component: ArtIxComponent, // Ajuste para que el home sea otro, para Diputados el valor debe ser Ho
    data: { breadcrumb: 'Obligaciones' },
  },
  {
    path: 'ifad',
    loadChildren: (): any =>
      import('../../home/transparencia/ifad/ifad.module').then(
        (m) => m.IfadModule
      ),
    data: { breadcrumb: 'IFAD' },
  },
  {
    path: '**',
    redirectTo: '', // Ajuste para que el home sea otro, para Diputados el valor debe ser Ho
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class TransparenciaRoutingModule { }
