import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-gaceta-alt',
  templateUrl: './gaceta-alt.component.html',
  styleUrls: ['./gaceta-alt.component.scss'],
})
export class GacetaAltComponent implements OnInit {
  constructor() {}

  legislatura = 'LXIV';

  ngOnInit(): void {}
}
