import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-gaceta-frame',
  templateUrl: './gaceta-frame.component.html',
  styleUrls: ['./gaceta-frame.component.scss'],
})
export class GacetaFrameComponent implements OnInit {
  legislatura = 'LXIV';
  constructor() {}

  ngOnInit(): void {}

  openCity(evt, cityName): void {
    let i;
    let tabcontent;
    let tablinks;
    tabcontent = document.getElementsByClassName('tabcontent');
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].style.display = 'none';
    }
    tablinks = document.getElementsByClassName('tablinks');
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace(' active', '');
    }
    document.getElementById(cityName).style.display = 'block';
    evt.currentTarget.className += ' active';
  }
}
