import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { SeccionComponent } from './seccion/seccion.component';
import { ViewDirective } from '../directives/view/view.directive';
import { MisDiputadosComponent } from './mis-diputados/mis-diputados.component';
import { ListaDinamicaComponent } from './lista-dinamica/lista-dinamica.component';
import { DynamicComponent } from './dynamic/dynamic.component';
import { WidgetComponent } from './widget/widget.component';
import { MisLeyesComponent } from './mis-leyes/mis-leyes.component';
import { MisEventosComponent } from './mis-eventos/mis-eventos.component';
import { MisInfograficosComponent } from './mis-infograficos/mis-infograficos.component';
import { MisVideosComponent } from './mis-videos/mis-videos.component';
import { MisBusquedasComponent } from './mis-busquedas/mis-busquedas.component';
import { MisObligacionesTransparenciaComponent } from './mis-obligaciones-transparencia/mis-obligaciones-transparencia.component';
import { MisAvisosPrivacidadComponent } from './mis-avisos-privacidad/mis-avisos-privacidad.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatIconModule } from '@angular/material/icon';
import { CarouselComponent } from './carousel/carousel.component';
import { ItemChartComponent } from './item-chart/item-chart.component';
import { MapChartComponent } from './map-chart/map-chart.component';
import { MisPublicacionesComponent } from './mis-publicaciones/mis-publicaciones.component';
import { OrgnamigramaComponent } from './orgnamigrama/orgnamigrama.component';
import { CustompipesModule } from './custompipes/custompipes.module';
import { OrganizacionComisionComponent } from './organizacion-comision/organizacion-comision.component';
import { MatNativeDateModule } from '@angular/material/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { ReunionesComisionComponent } from './reuniones-comision/reuniones-comision.component';
import { MatTabsModule } from '@angular/material/tabs';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';
import { MatRadioModule } from '@angular/material/radio';
import { GaleriaComisionComponent } from './galeria-comision/galeria-comision.component';
import { EventosComisionComponent } from './eventos-comision/eventos-comision.component';
import { EnlacesComisionComponent } from './enlaces-comision/enlaces-comision.component';
import { NgxGalleryModule } from 'ngx-gallery-9';
import {MatCardModule} from '@angular/material/card';
import { SeccionMenuComponent } from './seccion-menu/seccion-menu.component';
import {LazyLoadImageModule} from 'ng-lazyload-image';

@NgModule({
  declarations: [
    SeccionComponent,
    ViewDirective,
    ListaDinamicaComponent,
    DynamicComponent,
    WidgetComponent,
    MisDiputadosComponent,
    MisLeyesComponent,
    MisEventosComponent,
    MisInfograficosComponent,
    MisVideosComponent,
    MisBusquedasComponent,
    MisObligacionesTransparenciaComponent,
    MisAvisosPrivacidadComponent,
    CarouselComponent,
    ItemChartComponent,
    MapChartComponent,
    MisPublicacionesComponent,
    OrgnamigramaComponent,
    OrganizacionComisionComponent,
    ReunionesComisionComponent,
    GaleriaComisionComponent,
    EventosComisionComponent,
    EnlacesComisionComponent,
    SeccionMenuComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    MatPaginatorModule,
    MatButtonToggleModule,
    MatIconModule,
    CustompipesModule,
    MatNativeDateModule,
    MatExpansionModule,
    MatTabsModule,
    MatExpansionModule,
    MatIconModule,
    MatPaginatorModule,
    MatDialogModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressBarModule,
    MatSelectModule,
    MatRadioModule,
    FormsModule,
    NgxGalleryModule,
    LazyLoadImageModule,
  ],
  entryComponents: [
    MisDiputadosComponent,
    MisLeyesComponent,
    MisEventosComponent,
    MisInfograficosComponent,
    MisVideosComponent,
    MisBusquedasComponent,
    MisObligacionesTransparenciaComponent,
    MisAvisosPrivacidadComponent,
    MisPublicacionesComponent,
  ],
  exports: [
    ViewDirective,
    SeccionComponent,
    ListaDinamicaComponent,
    DynamicComponent,
    WidgetComponent,
    MisDiputadosComponent,
    MisLeyesComponent,
    MisEventosComponent,
    MisInfograficosComponent,
    MisVideosComponent,
    MisBusquedasComponent,
    MisObligacionesTransparenciaComponent,
    MisAvisosPrivacidadComponent,
    MisPublicacionesComponent,
    CarouselComponent,
    ItemChartComponent,
    MapChartComponent,
    OrgnamigramaComponent,
    OrganizacionComisionComponent,
    ReunionesComisionComponent,
    GaleriaComisionComponent,
    EventosComisionComponent,
    EnlacesComisionComponent,
    MatProgressBarModule,
    SeccionMenuComponent,
  ],
})
export class ComponentsModule {}
