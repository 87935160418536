// KYBC.Imports for Angular components.
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-memoria-parlamentaria',
  templateUrl: './memoria-parlamentaria.component.html',
  styleUrls: ['./memoria-parlamentaria.component.scss'],
})
export class MemoriaParlamentariaComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
