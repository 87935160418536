import { Injectable } from '@angular/core';
import { LeyRespuesta } from '../interfaces/ley-respuesta';
import gql from 'graphql-tag';
import { Apollo } from 'apollo-angular';
import { GraphqlService } from './graphql.service';

@Injectable({
  providedIn: 'root',
})
export class LeyService {
  constructor(
    private apollo: Apollo,
    private _graphqlService: GraphqlService
  ) {}

  getAllLegislaturas(): any {
    return this.apollo.watchQuery({
      query: gql`
        {
          allLegis {
            Numero
            Descripcion
            Legislatura
            Inicia
            DatosRelevantes {
              Fecha
              URLDocumento
              Titulo
            }
          }
        }
      `,
    });
  }

  getSubLaws(visitanteEmail: string): any {
    return this.apollo.watchQuery<LeyRespuesta>({
      query: gql`
          {
            allFavoriteLaws(EmailVisitante: "${visitanteEmail}") {
              Oid
              Id
              Nombre
              Version
              UltimaReforma
              Url
              UrlWord
              UrlPdf
              UrlAndroid
              TipoLey
            }
          }
        `,
    });
  }

  deleteFavoriteLaw(Oid: string, emailVisitante: string): any {
    return this.apollo.mutate({
      mutation: this._graphqlService.mutationDeleteFavoriteLaw(
        Oid,
        emailVisitante
      ),
    });
  }
}
