import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterArray',
})
export class FilterArrayPipe implements PipeTransform {
  transform(arrayValue: any[], arg: string = ''): any[] {
    if (arg !== undefined && arg !== null && arg === '' && !arrayValue) {
      return arrayValue;
    }
    const arregloFiltrado = [];
    if (arrayValue && arrayValue.length>0) {
      arrayValue.forEach((element) => {
        console.log(element);
        if (element.Legislatura?.includes(arg)) {
          arregloFiltrado.push(element);
        }
      });
      return arregloFiltrado;
    }
    else {
      return arrayValue;
    }
  }
}
