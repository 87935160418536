import { Component, OnInit } from '@angular/core';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import * as _ from 'underscore';
import { environment } from 'src/environments/environment';

interface ReformasConstDec {
  PublicacionDofstr: string;
  Numero: number;
  Nuevo: boolean;
  Titulo: string;
  MDecreto: string;
  PublicacionDOF: string;
  TextoWORD: string;
  TextoPDF: string;
  Nota: string;
  PLegislativo: string;
  TextoImagen: string;
  Nombre: string;
  TextovigenteImagen: string;
  TextoVigeneteWORD: string;
  ReformasConstPorDec: string;
  Link: string;
  FedeErratas: any[];
}
interface ResponseReformasConstPorDec {
  ReformasConstPorDec: ReformasConstDec[];
}
interface Titulos {
  Nombre: string;
  Descripcion: string;
}
interface ResponseDescriReformasConst {
  DescReformasConst: Titulos[];
}
@Component({
  selector: 'app-modal-cronologico',
  templateUrl: './modal-cronologico.component.html',
  styleUrls: ['./modal-cronologico.component.scss'],
})
export class ModalCronologico implements OnInit {
  loading = true;
  loadingDesc = true;
  error: any;
  ReformasConstDec: ReformasConstDec[];
  subTypes: any;
  subReformasConstPorDec: any;
  subDescReformasConst: any;
  DescReformasConst: Titulos[];
  produccion = false;
  urlSftp = 'https://portalhcd.diputados.gob.mx';
  constructor(
    private apollo: Apollo,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<ModalCronologico>
  ) {}
  ngOnInit(): void {
    this.produccion = environment.production;
    this.subTypes = this.apollo;
    this.loading = true;
    this.subReformasConstPorDec = this.apollo
      .watchQuery<ResponseReformasConstPorDec>({
        query: gql`
          {
            ReformasConstPorDec {
              PublicacionDofstr
              Nuevo
              Numero
              Titulo
              MDecreto
              PublicacionDOF
              TextoWORD
              TextoPDF
              Nota
              PLegislativo
              TextoImagen
              Nombre
              TextovigenteImagen
              TextoVigeneteWORD
              Link
              FedeErratas {
                Nombre
                Link
                LinkImagen
                LinkWord
                FechaPublicacion
              }
            }
          }
        `,
      })
      .valueChanges.subscribe((result) => {
        this.ReformasConstDec = result.data && result.data.ReformasConstPorDec;
        // console.log('REFORMAS_CONST_DEC', this.ReformasConstDec);

        this.loading = result.loading;
        console.log('load', result.loading);
        this.error = result.errors;
      });

    this.subDescReformasConst = this.apollo
      .watchQuery<ResponseDescriReformasConst>({
        query: gql`
          {
            DescReformasConst {
              Descripcion
            }
          }
        `,
      })
      .valueChanges.subscribe((result) => {
        this.DescReformasConst = result.data && result.data.DescReformasConst;
        this.loadingDesc = result.loading;
        this.error = result.errors;
      });
  }
  closeDialog(): any {
    this.dialogRef.close();
  }
}
