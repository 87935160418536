import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { CapacitacionTransparenciaRespuesta } from '../interfaces/capacitacion-transparencia-respuesta';
import { map } from 'rxjs/operators';
import { ArchivoTransparencia } from '../pages/home/transparencia/transparencia.component.interface';

@Injectable({
  providedIn: 'root',
})
export class CapacitacionTransparenciaService {
  constructor(private apollo: Apollo) {}
  getCapacitacionesTransparenciaTipo(type: number) {
    return this.apollo.watchQuery<CapacitacionTransparenciaRespuesta>({
      query: gql`
          {
            allCapacitacionesTransparenciaTipo(Tipo: ${type}) {
              Oid
              Nombre
              Descripcion
              URLDocumento
              Tipo
              NombreCompleto
            }
          }
        `,
    });
  }
}
