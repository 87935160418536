import {
  Component,
  OnInit,
  ElementRef,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { TrabajadorArea } from 'src/app/interfaces/trabajador-area';
import { ShareService } from '../../services/share.service';

@Component({
  selector: 'app-orgnamigrama',
  templateUrl: './orgnamigrama.component.html',
  styleUrls: ['./orgnamigrama.component.scss'],
})
export class OrgnamigramaComponent implements OnInit {
  private _equipos: any = {};
  private _nodoInicial: any;
  private _data: any[] = [];
  private _nodes: any[] = [];
  private _urlSFTP: string =
    'https://portalhcd.diputados.gob.mx/PortalWeb/SecretariaGeneral/Organigrama/2021/';

  @Input() trabajadores: TrabajadorArea[];
  @Output() loaded = new EventEmitter<boolean>();

  constructor(
    private _shareService: ShareService,
    private _elementRef: ElementRef
  ) {}

  ngOnInit(): void {
    this.trabajadores.forEach((trabajador) => {
      if (this._equipos[trabajador.PuestoTitular]) {
        if (trabajador.PuestoTitular != trabajador.PuestoEmpleado) {
          this._equipos[trabajador.PuestoTitular].equipo.push(trabajador);
        } else {
          this._equipos[trabajador.PuestoTitular].encargado = trabajador;
        }
      } else {
        let inicializadorArea: any = {
          encargado: trabajador
            ? trabajador.PuestoTitular == trabajador.PuestoEmpleado
            : null,
          equipo: [],
        };

        if (trabajador.PuestoTitular != trabajador.PuestoEmpleado) {
          inicializadorArea.equipo.push(trabajador);
        }

        this._equipos[trabajador.PuestoTitular] = inicializadorArea;
      }

      if (trabajador.PuestoTitular == trabajador.PuestoEmpleado) {
        this._nodoInicial = trabajador.PuestoEmpleado;
      }
    });
    if (this.trabajadores.length > 0) {
      this.crearNivel(
        this._equipos[this._nodoInicial].encargado,
        this._equipos[this._nodoInicial].equipo
      );
      this.addMainScripts();
    }
  }

  addMainScripts() {
    const scripts = [
      {
        name: 'highchartsOrganization',
        src: 'https://code.highcharts.com/modules/organization.js',
      },
      {
        name: 'highchartsExporting',
        src: 'https://code.highcharts.com/modules/exporting.js',
      },
      {
        name: 'highchartsAccessibility',
        src: 'https://code.highcharts.com/modules/accessibility.js',
      },
    ];
    this._shareService
      .addMultiplesLinkScripts(this._elementRef, [
        {
          name: 'highcharts2  ',
          src: 'https://code.highcharts.com/highcharts.js',
        },
      ])
      .then(() => {
        this._shareService
          .addMultiplesLinkScripts(this._elementRef, [
            {
              name: 'highchartsSankey',
              src: 'https://code.highcharts.com/modules/sankey.js',
            },
          ])
          .then(() => {
            this._shareService
              .addMultiplesLinkScripts(this._elementRef, scripts)
              .then(() => this.crearGrafica());
          });
      });
  }

  crearGrafica() {
    this._shareService.addScript(
      this._elementRef,
      `
    Highcharts.chart('orgChartContainer', {
      chart: {
          height: 600,
          inverted: true
      },
  
      title: {
          text: 'Organigrama'
      },
  
      accessibility: {
          point: {
              descriptionFormatter: function (point) {
                  var nodeName = point.toNode.name,
                      nodeId = point.toNode.id,
                      nodeDesc = nodeName === nodeId ? nodeName : nodeName + ', ' + nodeId,
                      parentDesc = point.fromNode.id;
                  return point.index + '. ' + nodeDesc + ', reports to ' + parentDesc + '.';
              }
          }
      },
  
      series: [{
          type: 'organization',
          name: 'Highsoft',
          keys: ['from', 'to'],
          data: ${JSON.stringify(this._data)}, 
          levels: [{
            level: 0,
            color: 'silver',
            dataLabels: {
                color: 'black'
            },
            height: 25
        }],
          nodes: ${JSON.stringify(this._nodes)},
          colorByPoint: false,
          color: '#E0E0E0',
          dataLabels: {
              color: 'white'
          },
          borderColor: 'white',
          nodeWidth: 65
      }],
      tooltip: {
          outside: true
      },
      exporting: {
          allowHTML: true,
          sourceWidth: 800,
          sourceHeight: 600
      }
  
  })`
    );
  }

  crearNivel(encargado: TrabajadorArea, equipo: TrabajadorArea[]) {
    if (this._data.length > 0) {
      this._data.push([encargado.PuestoTitular, encargado.PuestoEmpleado]);
      this._nodes.push({
        id: encargado.PuestoEmpleado,
        title: encargado.NombrePuesto,
        name: encargado.NombreCompleto,
        image:
          encargado.Fotografia != null
            ? this._urlSFTP + encargado.Fotografia
            : null,
      });
      //console.log(this._nodes)
    } else {
      this._data = [[encargado.PuestoEmpleado, equipo[0].PuestoEmpleado]];
      this._nodes = [
        {
          id: encargado.PuestoEmpleado,
          title: encargado.NombrePuesto,
          name: encargado.NombreCompleto,
          image:
            encargado.Fotografia != null
              ? this._urlSFTP + encargado.Fotografia
              : null,
        },
      ];
    }

    equipo?.forEach((trabajador) => {
      const equipo = this._equipos[trabajador.PuestoEmpleado]?.equipo;
      this.crearNivel(trabajador, equipo);
    });

    //console.log(this._data);
  }
}
