import { Component } from '@angular/core';
import * as AOS from 'aos';
import { Location } from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  public ruta = '';

  constructor(location: Location) {
    this.ruta = location.path();
  }

  ngOnInit(): void {
    AOS.init();
  }

  onActivate(): void {
    window.scrollTo(0, 0);
  }
}
