import { Component, OnInit } from '@angular/core';
import {
  Law,
  Law2,
  LawType,
  LegislaturaLeyes,
  Link,
  Linkb,
  ResponseallLegislaturasLeyes,
  ResponseallLinks,
  ResponsecaracReformasConst,
  ResponseDescTitLeyesFederales,
  ResponseDescTitPieUno,
  ResponseFavoriteLaws,
  ResponseLaws,
  ResponseTituloLeyesFederales,
  ResponseTypes,
  Titulos,
} from '../leyes-vigentes/leyes-vigentes.interface';
import gql from 'graphql-tag';
import { Apollo } from 'apollo-angular';
import { ResponseTituloPieUno } from '../leyes-vigentes/leyes-vigentes.interface';
import { Router } from '@angular/router';

@Component({
  selector: 'app-leyes-federales',
  templateUrl: './leyes-federales.component.html',
  styleUrls: ['./leyes-federales.component.scss'],
})
export class LeyesFederalesComponent implements OnInit {
  TitLeyesFederalesar: Titulos[];
  leyes: Law[];
  loggedIn = false;
  subLaws: any;
  subTypes: any;
  loading: boolean;
  error: any;
  suballLegislaturas: any;
  suballLinks: any;
  suballLinksb: any;
  loadingLv = true;
  favoriteLaws: any;
  DescTitLeyesFederalesAr: Titulos[];
  tipoLeyes: LawType[];
  Link: Link[];
  Linkb: Linkb[];
  Legislatura: LegislaturaLeyes[];
  emailVisitante: any;
  TitPieUnoar: Titulos[];
  DescTitPieUnoAr: Titulos[];
  subTituloPieUno: any;
  subTituloLeyesFederales: any;
  subDescTitLeyesFederales: any;
  subFavoriteLaws: any;
  subDescTitpieUno: any;
  CarReformasConst: any;
  constructor(private apollo: Apollo,private router: Router) {}

  ngOnInit(): void {
    this.verifyVisitantSession();
    this.subTituloPieUno = this.apollo
      .watchQuery<ResponseTituloPieUno>({
        query: gql`
          {
            TitPieUno {
              Titulo
              Descripcion
            }
          }
        `,
      })
      .valueChanges.subscribe((result) => {
        this.TitPieUnoar = result.data && result.data.TitPieUno;
         console.log('**fer TitPieUnoar trae', this.TitPieUnoar);
        this.loading = result.loading;
        this.error = result.errors;
      });
    this.subDescTitLeyesFederales = this.apollo
      .watchQuery<ResponseDescTitLeyesFederales>({
        query: gql`
          {
            DescTitLeyesFederales {
              Descripcion
            }
          }
        `,
      })
      .valueChanges.subscribe((result) => {
        this.DescTitLeyesFederalesAr =
          result.data && result.data.DescTitLeyesFederales;
        // console.log('DescTitLeyesFederalesAr', this.DescTitLeyesFederalesAr);

        this.loading = result.loading;
        this.error = result.errors;
      });
    this.subDescTitpieUno = this.apollo
      .watchQuery<ResponseDescTitPieUno>({
        query: gql`
          {
            DescTitpieUno {
              Descripcion
            }
          }
        `,
      })
      .valueChanges.subscribe((result) => {
        this.DescTitPieUnoAr = result.data && result.data.DescTitpieUno;
        this.loading = result.loading;
        this.error = result.errors;
      });
    this.subTypes = this.apollo
      .watchQuery<ResponseTypes>({
        query: gql`
          {
            allLawTypes {
              Oid
              Titulo
            }
          }
        `,
      })
      .valueChanges.subscribe((result) => {
        this.tipoLeyes = result.data && result.data.allLawTypes;
        this.loading = result.loading;
        this.error = result.errors;
      });

    this.subTituloLeyesFederales = this.apollo
      .watchQuery<ResponseTituloLeyesFederales>({
        query: gql`
          {
            TitLeyesFederales {
              Descripcion
            }
          }
        `,
      })
      .valueChanges.subscribe((result) => {
        this.TitLeyesFederalesar = result.data && result.data.TitLeyesFederales;
        // // console.log('**fer TitLeyesFederalesar trae', this.TitLeyesFederalesar);
        this.loading = result.loading;
        this.error = result.errors;
      });
    this.suballLinks = this.apollo
      .watchQuery<ResponseallLinks>({
        query: gql`
          {
            allLinks {
              Oid
              Titulo
              LinkTitulo
              Tipo
              Descripcion
              Nota
            }
          }
        `,
      })
      .valueChanges.subscribe((result) => {
        this.Link = result.data && result.data.allLinks;
        this.loading = result.loading;
        this.error = result.errors;
      });
    this.suballLinksb = this.apollo
      .watchQuery<ResponseallLinks>({
        query: gql`
          {
            allLinksb {
              Oid
              Titulo
              LinkTitulo
              Tipo
              Descripcion
              Nota
            }
          }
        `,
      })
      .valueChanges.subscribe((result) => {
        this.Linkb = result.data && result.data.allLinksb;
        this.loading = result.loading;
        this.error = result.errors;
      });
    this.subLaws = this.apollo
      .watchQuery<ResponseLaws>({
        query: gql`
          {
            allLaws {
              Oid
              Id
              No
              Nombre
              Version
              UltimaReforma
              MarcoJuridico
              Nuevo
              UltimaReforma
              Url
              UrlWord
              Notas
              UrlPdf
              UrlAndroid
              TipoLey
              PublicacionOriginal
              Estatus
              FAbrogacion
              NuevasReformas
              EnActualizacion
            }
          }
        `,
      })
      .valueChanges.subscribe((result) => {
        this.leyes = result.data && result.data.allLaws;
        this.loadingLv = result.loading;
        this.error = result.errors;
      });
  }
  isFavorite(oid): any {
    return this.favoriteLaws.find((law) => law.Oid === oid);
  }
  abrirWord(url: string): void {
    console.log({ url });
    window.open(url, '_blank');
  }

  addFavoriteLaw(OidLaw): any {
    // console.log(`Llego oid ${OidLaw}`);
    this.apollo
      .mutate({
        mutation: mutationAddFavoriteLaw(OidLaw, this.emailVisitante),
      })
      .subscribe(
        () => {
          // const newFavEvent: Event = {Oid: OidEvento};
          const newFavLaw: Law2 = { Oid: OidLaw };
          this.favoriteLaws.push(newFavLaw);
          alert('se ha guardado el evento en favoritos de manera exitosa!');
        },
        (error) => {
          alert('hubo un error al guardar en favoritos, intente de nuevo');
          console.log(error);
          // console.log(data);
        }
      );
  }

  deleteFavoriteLaw(OidLaw): any {
    // console.log(`Llego oid ${OidLaw}`);
    this.apollo
      .mutate({
        mutation: mutationDeleteFavoriteLaw(OidLaw, this.emailVisitante),
      })
      .subscribe(
        () => {
          this.favoriteLaws = this.favoriteLaws.filter(
            (law) => law.Oid !== OidLaw
          );
          alert('se ha borrado el evento en favoritos de manera exitosa!');
        },
        (error) => {
          alert('hubo un error al borrar de favoritos, intente de nuevo');
          console.log(error);
        }
      );
  }
  linkLey(url): any {
    this.router.navigateByUrl(url);
  }
  verifyVisitantSession(): any {
    this.loggedIn = !!localStorage.getItem('HCD_activeSessionVisitant');

    if (this.loggedIn) {
      this.emailVisitante = localStorage.getItem('HCD_visitantEmail');

      // traer los eventos favoritos de el visitante
      this.subFavoriteLaws = this.apollo
        .watchQuery<ResponseFavoriteLaws>({
          query: gql`
          {
            allFavoriteLaws(EmailVisitante: "${this.emailVisitante}") {
              Oid
            }
          }
        `,
        })
        .valueChanges.subscribe((result) => {
          this.favoriteLaws = result.data && result.data.allFavoriteLaws;
          this.loading = result.loading;
          this.error = result.errors;

          console.log(
            'llegaron estas leyes favoritas',
            result.data.allFavoriteLaws
          );
        });
    }
  }
}
export const mutationDeleteFavoriteLaw = (OidLaw, emailVisitante): any => gql`
mutation {
  deleteFavoriteLaw(OidLaw: "${OidLaw}", EmailVisitante: "${emailVisitante}"){
    Oid
  }
}`;
export const mutationAddFavoriteLaw = (OidLaw, emailVisitante): any => gql`
mutation {
  addFavoriteLaw(OidLaw: "${OidLaw}", EmailVisitante: "${emailVisitante}"){
    Oid
  }
}`;
