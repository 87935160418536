import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CarruselHomeComponent } from './pages/carrusel-home/carrusel-home.component';

import { NotFoundComponent } from './pages/not-found/not-found.component';
import { TransmisionComponent } from './pages/transmision/transmision.component';
import { VideoComponent } from './pages/video/video.component';


const routes: Routes = [
  // DLG: Ajuste para routerlink a Transparencia
  { path: '', redirectTo: 'transparencia', pathMatch: 'full' },
  {
    path: 'inicio',
     loadChildren: (): any =>
       import('./pages/home/home.module').then((m) => m.HomeModule),
     data: { breadcrumb: 'Inicio' },
   },
  {
    path: 'transparencia',
    loadChildren: (): any =>
      import('./pages/home/transparencia/transparencia.module').then(
        (m) => m.TransparenciaModule
      ),
    data: { breadcrumb: 'Transparencia' },
  },
  {
    path: 'home',
    redirectTo: '/transparencia/avisoPrivacidad',
    data: { breadcrumb: 'Avisos de privacidad' },
  },
  // {
  //   path: 'leyes',
  //   loadChildren: (): any =>
  //     import('./pages/home/leyes/leyes.module').then((m) => m.LeyesModule),
  //   data: { breadcrumb: 'Leyes' },
  // },
  // {
  //   path: 'transmision',
  //   component: TransmisionComponent,
  //   data: { breadcrumb: 'Transmision en vivo' },
  // },
  // {
  //   path: 'video',
  //   component: VideoComponent,
  //   data: { breadcrumb: 'Transmision en vivo' },
  // },
  // {
  //   path: 'carrusel',
  //   component: CarruselHomeComponent,
  //   data: { breadcrumb: 'Carrusel Home' },
  // },
  {
    path: '**',
    component: NotFoundComponent,
    data: { breadcrumb: 'Página no encontrada' },
  },
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
