import { Component, OnInit } from '@angular/core';
import { TrabajadorArea } from 'src/app/interfaces/trabajador-area';
import { TrabajadorService } from 'src/app/services/trabajador.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-secretaria-general',
  templateUrl: './secretaria-general.component.html',
  styleUrls: ['./secretaria-general.component.scss'],
})
export class SecretariaGeneralComponent implements OnInit {
  public trabajadoresArea: TrabajadorArea[] = [];
  public organigramaCargado: boolean = false;

  constructor(
    private _trabajadorService: TrabajadorService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.selectTrabajadoresArea();
  }

  selectTrabajadoresArea() {
    this._trabajadorService
      .selectTrabajadoresArea('1988f17c-f425-4b75-b777-0d64904a58a1')
      .refetch()
      .then((data) => {
        this.trabajadoresArea = data.data.selectTrabajadoresArea;
        this.organigramaCargado = true;
      });
  }

  goOrdinaria() {
    this.router.navigate(['/inicio/comisiones'], {
      queryParams: { Tipo: 'Ordinaria' },
    });
  }

  goEspecial() {
    this.router.navigate(['/inicio/comisiones'], {
      queryParams: { Tipo: 'Especial' },
    });
  }

  goBicamaral() {
    this.router.navigate(['/inicio/comisiones'], {
      queryParams: { Tipo: 'Bicamaral' },
    });
  }

  goInvestigacion() {
    this.router.navigate(['/inicio/comisiones'], {
      queryParams: { Tipo: 'Investigación' },
    });
  }

  goGAmistad() {
    this.router.navigate(['/inicio/comisiones'], {
      queryParams: { Tipo: 'Grupo de Amistad' },
    });
  }

  goPInternacionales() {
    this.router.navigate(['/inicio/comisiones'], {
      queryParams: { Tipo: 'Parlamentos Internacionales' },
    });
  }

  goGTrabajo() {
    this.router.navigate(['/inicio/comisiones'], {
      queryParams: { Tipo: 'Trabajo' },
    });
  }
}
