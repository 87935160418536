// KYBC.Imports for Angular components.
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { map } from 'rxjs/operators';

// KYBC.Imports for queries and mutations with GraphQL and Apollo.
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';

import { subscribe } from 'graphql';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';

interface Event {
  Oid: string;
  Nombre: string;
  Descripcion: string;
  Ubicacion: string;
  FechaInicio: string;
  FechaFin: string;
  ImageData: string;
  TipoEvento: string;
  linkSitio: string;
  LinkRegistro: string;
  HoraInicio: string;
  HoraFin: string;
}
interface Event2 {
  Oid: string;
}

interface Response {
  allEvents: Event[];
}

interface ResponseEvents {
  allFavoriteEvents: Event2[];
}

// const mutationAddFavoriteEvent = (oidEvent, emailVisitant) => gql`
// mutation {
//   addFavoriteEvent(OidEvent: "${oidEvent}", EmailVisitante: "${emailVisitant}"){
//     Oid
//   }
// }`;
const mutationAddFavoriteEvent = (OidEvento, emailVisitante): any => gql`
mutation {
  addFavoriteEvent(OidEvent: "${OidEvento}", EmailVisitante: "${emailVisitante}"){
    Oid
  }
}`;
const mutationDeleteFavoriteEvent = (OidEvento, emailVisitante): any => gql`
mutation {
  deleteFavoriteEvent(OidEvent: "${OidEvento}", EmailVisitante: "${emailVisitante}"){
    Oid
  }
}`;

@Component({
  selector: 'app-lista-eventos',
  templateUrl: './lista-eventos.component.html',
  styleUrls: ['./lista-eventos.component.scss'],
})
export class ListaEventosComponent implements OnInit, OnDestroy {
  eventos: Event[];
  favoriteEvents: Event2[];
  loading = true;
  error: any;
  p = 1;
  collection = [];
  config: any;
  sub: any;
  subFavoriteEvents: any;
  loggedIn = false;
  emailVisitante = '';
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  constructor(
    private apollo: Apollo,
    private route: ActivatedRoute,
    private _snackBar: MatSnackBar,
    private router: Router
  ) {
    this.config = {
      currentPage: 1,
      itemsPerPage: 2,
    };

    this.route.queryParamMap
      .pipe(map((params) => params.get('page')))
      .subscribe((page) => (this.config.currentPage = page));

    for (let i = 1; i <= 101; i++) {
      this.collection.push(`item ${i}`);
    }
  }

  ngOnInit(): any {
    this.verifyVisitantSession();
    this.sub = this.apollo
      .watchQuery<Response>({
        query: gql`
          {
            allEvents {
              Oid
              Nombre
              Descripcion
              Ubicacion
              FechaInicio
              FechaFin
              TipoEvento
              imagedata
              linkSitio
              LinkRegistro
              HoraInicio
              HoraFin
              Url
            }
          }
        `,
      })
      .valueChanges.subscribe((result) => {
        this.eventos = result.data && result.data.allEvents;
        this.loading = result.loading;
        this.error = result['error'];
        this.generateGoogleInvitation();
      });
  }

  verifyVisitantSession(): any {
    this.loggedIn = !!localStorage.getItem('HCD_activeSessionVisitant');
    if (this.loggedIn) {
      this.emailVisitante = localStorage.getItem('HCD_visitantEmail');

      // traer los eventos favoritos de el visitante
      this.subFavoriteEvents = this.apollo
        .watchQuery<ResponseEvents>({
          query: gql`
          {
            allFavoriteEvents(EmailVisitante: "${this.emailVisitante}") {
              Oid
            }
          }
        `,
        })
        .valueChanges.subscribe((result) => {
          this.favoriteEvents = result.data && result.data.allFavoriteEvents;
          this.loading = result.loading;
          this.error = result['error'];

          // console.log(
          //   'llegaron estos eventos favoritos',
          //   result.data.allFavoriteEvents
          // );
        });
    }
  }

  isFavorite(oid): any {
    return this.favoriteEvents.find((event) => event.Oid === oid);
  }

  pageChange(newPage: number): any {
    this.router.navigate([''], { queryParams: { page: newPage } });
  }

  ngOnDestroy(): any {
    // console.log('onDestroy');
    this.sub.unsubscribe();
  }

  generateGoogleInvitation(): any {
    this.eventos.forEach((e) => {
      const linkNombre = e['Nombre']
        ? '&text=' + e['Nombre'].split(' ').join('%20')
        : '';
      const linkUbicacion = e['Ubicacion']
        ? '&location=' + e['Ubicacion'].split(' ').join('%20')
        : '';
      const linkDescripcion = e['Descripcion']
        ? '&details=' + e['Descripcion'].split(' ').join('%20')
        : '';
      // console.log(linkNombre);
      // console.log(linkUbicacion);
      // console.log(linkDescripcion);
      e['gNombre'] = linkNombre;
      e['gUbicacion'] = linkUbicacion;
      e['gDescripcion'] = linkDescripcion;
    });
  }

  addFavoriteEvent(OidEvento): any {
    // console.log(`Llego oid ${OidEvento}`);
    this.apollo
      .mutate({
        mutation: mutationAddFavoriteEvent(OidEvento, this.emailVisitante),
      })
      .subscribe(
        () => {
          // const newFavEvent: Event = {Oid: OidEvento};
          const newFavEvent: Event2 = { Oid: OidEvento };
          this.favoriteEvents.push(newFavEvent);
          this.openSnackBar('se ha guardado el evento en favoritos de manera exitosa!');
        },
        (error) => {
          this.openSnackBar('hubo un error al guardar en favoritos, intente de nuevo');
          //  console.log(error);
        }
      );
  }
  openSnackBar(texto:string) {
    this._snackBar.open(texto, 'Aceptar', {
      duration: 5000,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
    });
  } 
  deleteFavoriteEvent(OidEvento): any {
    console.log(`Llego oid ${OidEvento}`);
    this.apollo
      .mutate({
        mutation: mutationDeleteFavoriteEvent(OidEvento, this.emailVisitante),
      })
      .subscribe(
        () => {
          this.favoriteEvents = this.favoriteEvents.filter(
            (event) => event.Oid !== OidEvento
          );
          this.openSnackBar('se ha borrado el evento en favoritos de manera exitosa!');
        },
        (error) => {
          this.openSnackBar('hubo un error al borrar de favoritos, intente de nuevo');
          //  console.log(error);
        }
      );
  }
}
