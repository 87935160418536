import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';

@Injectable({
  providedIn: 'root'
})
export class PresupuestoService {

  constructor(private apollo: Apollo) { }

  selectBienMaterial(oid: string): any {
    return this.apollo.watchQuery<any>({
      query: gql`
        {
          selectBienMaterial(Oid: "${oid}") {
            Oid
            Nombre
            Anio
            Descripcion
            Orden
            UltimaActualizacion
            Tipo
            Titulo
            ArchivoB64
          }
        }`,
    });
  }

  selectBienesMateriales(): any {
    return this.apollo.watchQuery<any>({
      query: gql`
        {
          selectBienesMateriales {
            Oid
            Nombre
            Anio
            Descripcion
            Orden
            UltimaActualizacion
            Tipo
            Titulo
          }
        }`,
    });
  }

  allPresupuestos(): any {
    return this.apollo.watchQuery<any>({
      query: gql`
        {
          allPresupuestos {
            Oid
            Anio
            Nombre
            Titulo
          }
        }`,
    });
  }

  selectPresupuestoAsignado(presupuestoOid: string): any {
    return this.apollo.watchQuery<any>({
      query: gql`
        {
          selectPresupuestoAsignado(PresupuestoOid: "${presupuestoOid}") {
            Oid
            Anio
            Nombre
            Descripcion
            ArchivoB64
            Url
          }
        }`,
    });
  }

  selectGastoAsignado(presupuestoOid: string): any {
    return this.apollo.watchQuery<any>({
      query: gql`
        {
          selectGastosAsignado(PresupuestoOid: "${presupuestoOid}") {
            Oid
            Anio
            Nombre
            Monto
            Titulo
            Numero
            Descripcion
          }
        }`,
    });
  }

  selectCapituloGasto(gastoOid: string): any {
    return this.apollo.watchQuery<any>({
      query: gql`
        {
          selectCapituloGasto(GastoOid: "${gastoOid}") {
            Oid
            Nombre
            Monto
            Numero
            Descripcion
            capituloGastos
          }
        }`,
    });
  }
}
