import { Component, OnInit } from '@angular/core';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { Infopal, SitioInteresInfopal } from '../infopal.component';
interface Response {
  allInfopal: Infopal[];
  SitioInteresInfopal: SitioInteresInfopal[];
}
@Component({
  selector: 'app-legislaturas-ant',
  templateUrl: './legislaturas-ant.component.html',
  styleUrls: ['./legislaturas-ant.component.scss']
})
export class LegislaturasAntComponent implements OnInit {
  infopal: any;
  loading: boolean;

  constructor(    private apollo: Apollo,
    ) { }

  ngOnInit(): void {
    
    this.infopal = this.apollo
      .watchQuery<Response>({
        query: gql`
          {
            allInfopal {
              Oid
              Nombre
              Link
              FechaInicio
              FechaFin
              Legislatura
              ImagenLBase64
            }
          }
        `,
      })
      .valueChanges.subscribe((result) => {
        // console.log('result');
        // console.log(result);
        this.infopal = result.data.allInfopal;
        // console.log('Infopal!!!', this.infopal);
        this.loading = result.loading;
      });
  }

}
