import { Component, OnInit } from '@angular/core';
import { GraphqlService } from '../../../services/graphql.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Comision } from 'src/app/interfaces/comision';

@Component({
  selector: 'app-comisiones',
  templateUrl: './comisiones.component.html',
  styleUrls: ['./comisiones.component.scss'],
})
export class ComisionesComponent implements OnInit {
  private _allComisiones: Comision[] = [];
  public comisiones: Comision[];
  public querySub: any;
  public Id_Tipo: any;
  public palabraClave = '';
  opcionSeleccionado = 0;
  Ordinarias: Comision[] = [];

  Especiales: Comision[] = [];
  Trabajo: Comision[] = [];
  Amistad: Comision[] = [];
  Bicamaral: Comision[] = [];
  Investigacion: Comision[] = [];
  Internacionales: Comision[] = [];
  Permanente: Comision[] = [];
  Comite: Comision[] = [];
  GrupoTrabajo: Comision[] = [];
  Otros: Comision[] = [];

  constructor(
    public graphql: GraphqlService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.querySub = this.route.queryParams.subscribe((params) => {
      this.Id_Tipo = params.Tipo || 'Ordinaria';
      //console.log({ Tipo: this.Id_Tipo });
      this.palabraClave = params.key || null;
      console.log('PALABRA CLAVE: !!!',{ palabraClave: this.palabraClave });
    });
    this.actualizarComisiones();
    if (this.palabraClave !== null && this.palabraClave !== undefined) {
      this.buscar();
    }
  }
  filtrarTipo(tipo?: string) {
    this.router.navigate(['/inicio/comisiones'], {
      queryParams: { Tipo: tipo },
    });
  }
  traerTodas() {
    this.Id_Tipo = null;
    this.router.navigate(['/inicio/comisiones']);
  }
  actualizarComisiones() {
    this.graphql.getComisiones().valueChanges.subscribe((result) => {
      this._allComisiones = result.data.allComisionesPortal;
      this.comisiones = this._allComisiones;
      console.log({ comisiones: this.comisiones });

      this.Ordinarias = this.comisiones.filter(
        (comi) => comi.TipoComision === 'Ordinaria'
      );
      this.Especiales = this.comisiones.filter(
        (comi) => comi.TipoComision === 'Especial'
      );
      this.Amistad = this.comisiones.filter(
        (comi) => comi.TipoComision === 'Grupo de Amistad'
      );
      this.Trabajo = this.comisiones.filter(
        (comi) => comi.TipoComision === 'Grupo de Trabajo'
      );
      this.Bicamaral = this.comisiones.filter(
        (comi) => comi.TipoComision === 'Bicamaral'
      );
      this.Investigacion = this.comisiones.filter(
        (comi) => comi.TipoComision === 'Investigación'
      );
      this.Internacionales = this.comisiones.filter(
        (comi) => comi.TipoComision === 'Parlamentos internacionales'
      );
      this.Permanente = this.comisiones.filter(
        (comi) => comi.TipoComision === 'Permanente'
      );
      this.Comite = this.comisiones.filter(
        (comi) => comi.TipoComision === 'Comité'
      );
      this.GrupoTrabajo = this.comisiones.filter(
        (comi) => comi.TipoComision === 'Grupos de Trabajo'
      );
      this.Otros = this.comisiones.filter(
        (comi) => comi.TipoComision === 'Otros'
      );
    });
  }

  buscar() {
    if (this.Id_Tipo !== null && this.Id_Tipo !== undefined) {
      this.router.navigate(['/inicio/comisiones'], {
        queryParams: { key: this.palabraClave },
      });
    }
    const comisionesFiltradas = this._allComisiones.filter((obj) =>
      Object.keys(obj).some((key) => {
        if (key !== 'Oid') {
          return obj[key]
            ?.toLowerCase()
            .includes(this.palabraClave.toLowerCase());
        }
      })
    );
    this.comisiones =
      this.palabraClave.length === 0
        ? this._allComisiones
        : comisionesFiltradas;
    console.log({ comisiones: this.comisiones });
  }
}
