import { Component, OnInit, NgModule } from '@angular/core';

@Component({
  selector: 'app-gaceta',
  templateUrl: './gaceta.component.html',
  styleUrls: ['./gaceta.component.scss'],
})
export class GacetaComponent implements OnInit {
  constructor() {}

  legislatura = 'LXIV';

  ngOnInit() {}
}
