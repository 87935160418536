// KYBC.Imports for Angular components.
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { map } from 'rxjs/operators';

// KYBC.Imports for queries and mutations with GraphQL and Apollo.
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';

import { subscribe } from 'graphql';

type Event = {
  Oid: string;
  Nombre: string;
  Descripcion: string;
  Ubicacion: string;
  FechaInicio: string;
  FechaFin: string;
  ImageData: string;
  TipoEvento: string;
  linkSitio: string;
};

type Response = {
  pastEvents: Event[];
};

@Component({
  selector: 'app-lista-eventos-pasados',
  templateUrl: './lista-eventos-pasados.component.html',
  styleUrls: ['./lista-eventos-pasados.component.scss'],
})
export class ListaEventosPasadosComponent implements OnInit, OnDestroy {
  eventos: any[];
  loading = true;
  error: any;
  p = 1;
  collection = [];
  config: any;
  sub: any;

  constructor(
    private apollo: Apollo,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.config = {
      currentPage: 1,
      itemsPerPage: 2,
    };

    this.route.queryParamMap
      .pipe(map((params) => params.get('page')))
      .subscribe((page) => (this.config.currentPage = page));

    for (let i = 1; i <= 101; i++) {
      this.collection.push(`item ${i}`);
    }
  }

  ngOnInit(): any {
    // console.log('oninit');
    this.sub = this.apollo
      .watchQuery<Response>({
        query: gql`
          {
            pastEvents {
              Oid
              Nombre
              Descripcion
              Ubicacion
              FechaInicio
              FechaFin
              TipoEvento
              imagedata
              linkSitio
              HoraInicio
              HoraFin
              Url
            }
          }
        `,
      })
      .valueChanges.subscribe((result) => {
        // console.log(result);
        this.eventos = result.data && result.data.pastEvents;
        //console.log('Eventos Pasados: ', this.eventos);
        this.loading = result.loading;
        this.error = result['error'];
      });
  }

  pageChange(newPage: number): any {
    this.router.navigate([''], { queryParams: { page: newPage } });
  }

  ngOnDestroy(): any {
    // console.log('onDestroy');
    this.sub.unsubscribe();
  }
}
