import { Component, Inject, OnInit } from '@angular/core';
import { BuscarDiputadosService } from 'src/app/services/buscar-diputados.service';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-diputados-gp',
  templateUrl: `./diputados-gp.component.html`,
  styleUrls: [`./diputados-gp.component.scss`],
})
export class DiputadosGpComponent implements OnInit {
  panelOpenState1: any;
  dipGrupo: any[] = [];
  loading = true;
  table: number;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    private service: BuscarDiputadosService
  ) {
    this.loading = true;
    // console.log({ data });
  }

  ngOnInit(): void {
    if (this.data?.obj?.Diputados?.length >= 0) {
      this.dipGrupo = [this.data.obj];
      this.loading = false;
    } else {
      this.service.getPartidos().valueChanges.subscribe((res: any) => {
        this.dipGrupo = res.data.allPartidos;
        this.loading = false;
      });
    }
  }
}
