import { Component, OnInit } from '@angular/core';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import {
  Nombre,
  Contador,
  Anio,
  ArticulosPresi,
  ResponsePresidentesNombre,
  ResponseAniosPeriodoPresi,
  ResponseArticulosporPresi,
  ResponseContadorArticulosporPresi,
} from '../leyes-vigentes.interface';

import { MatDialog, MatDialogRef } from '@angular/material/dialog';
@Component({
  selector: 'app-modal-periodo',
  templateUrl: './modal-periodo.component.html',
  styleUrls: ['./modal-periodo.component.scss'],
})
export class ModalPeriodoComponent implements OnInit {
  private querySubscription: any;
  // anios: any;
  loading1 = true;
  loading2 = true;
  loading3 = true;
  loading4 = true;
  loading5 = true;
  loading = this.loading1 || this.loading2 || this.loading3 || this.loading4;
  error: any;
  subAniosPeriodoPresi: any;
  subpresidentesNombre: any;
  subArticulosPorPresi: any;
  subContador: any;
  NombrePresidentes: Nombre[];
  contadorarticulospresi: Contador[];
  anios: Anio[];
  articulospresi: ArticulosPresi[];
  articulospresifiltrado: ArticulosPresi;
  presi: any;
  constructor(
    private apollo: Apollo,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<ModalPeriodoComponent>
  ) {}

  ngOnInit(): void {
    // this.AniosPeriodoPresidencial(this.presi);
    this.subpresidentesNombre = this.apollo
      .watchQuery<ResponsePresidentesNombre>({
        query: gql`
          {
            PresidentesNombre {
              Oid
              NombreCompleto
              FechaInicioPeriodoPresi
              FechaFinPeriodoPresi
            }
          }
        `,
      })
      .valueChanges.subscribe((result) => {
        this.NombrePresidentes = result.data && result.data.PresidentesNombre;
        this.loading5 = result.loading;
        this.error = result.errors;
      });
    this.subAniosPeriodoPresi = this.apollo
      .watchQuery<ResponseAniosPeriodoPresi>({
        query: gql`
          {
            AniosPeriodoPresi {
              PublicacionDofstr
              NombreCompleto
            }
          }
        `,
      })
      .valueChanges.subscribe((result) => {
        this.anios = result.data && result.data.AniosPeriodoPresi;
        this.loading2 = result.loading;
        this.error = result.errors;
      });
    this.subArticulosPorPresi = this.apollo
      .watchQuery<ResponseArticulosporPresi>({
        query: gql`
          {
            ArticulosPorPresidente {
              NReforma
              Numero
              nombre
              Oid
              PublicacionDofstr
              Link
              TextoWORD
              TextoPDF
              TextoImagen
            }
          }
        `,
      })
      .valueChanges.subscribe((result) => {
        this.articulospresi = result.data && result.data.ArticulosPorPresidente;
        console.log(
          'result.data.ArticulosPorPresidente. trae',
          result.data.ArticulosPorPresidente
        );
        this.loading1 = result.loading;
      });

    this.subContador = this.apollo
      .watchQuery<ResponseContadorArticulosporPresi>({
        query: gql`
          {
            NumeroArtreformadosPorPresidente {
              contador
              NombreCompleto
            }
          }
        `,
      })
      .valueChanges.subscribe((result) => {
        this.contadorarticulospresi =
          result.data && result.data.NumeroArtreformadosPorPresidente;

        this.loading3 = result.loading;
      });
  }

  getReformasPresidente(presidente: Nombre) {
    console.log('el presidente que paso :', presidente.NombreCompleto);
    const query = this.apollo
      .watchQuery<ResponseArticulosporPresi>({
        query: gql`
            {
              ReformasPorPresidente(NombrePresidente: "${presidente.NombreCompleto}") {
                NReforma
                Numero
                nombre
                Oid
                PublicacionDofstr
                Link
                TextoWORD
                TextoPDF
                TextoImagen
              }
            }
          `,
      })
      .valueChanges.subscribe((result) => {
        presidente.reformas = result.data && result.data.ReformasPorPresidente;
        this.loading4 = result.loading;
        // console.log('articulo.reformas trae', presidente.reformas);
        query.unsubscribe();
      });
  }
  closeDialog(): any {
    this.dialogRef.close();
  }
}
