import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-diario-debates',
  templateUrl: './diario-debates.component.html',
  styleUrls: ['./diario-debates.component.scss'],
})
export class DiarioDebatesComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}

export class DatepickerValueExample {
  date = new FormControl(new Date());
  serializedDate = new FormControl(new Date().toISOString());
}
