import { NgModule } from '@angular/core';

import { InfoPreguntasFrecComponent } from './modales/info-preguntas-frec/info-preguntas-frec.component';
import { MaterialModule } from '../../../shared/material/material.module';
import { DirectorioTranspComponent } from './directorio-transp/directorio-transp.component';
import { AvisoPrivacidadComponent } from './aviso-privacidad/aviso-privacidad.component';
import { ObligacionTransparenciaModalComponent, TransparenciaComponent } from './transparencia.component';
import { HistorialConsultasComponent } from './historial-consultas/historial-consultas.component';
import { RouterModule } from '@angular/router';
import { TransparenciaRoutingModule } from './transparencia-routing.module';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { ComponentsModule } from '../../../components/components.module';
import { ArtIxComponent } from './art-ix/art-ix.component';
import { LegislacionFederalComponent } from './modales/legislacion-federal/legislacion-federal.component';
import { CorpusComponent } from './modales/corpus/corpus.component';
import { DatosPersonalesComponent } from './datos-personales/datos-personales.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { PaginatePipe } from './paginate.pipe';
import { IfadModule } from './ifad/ifad.module';
import { SafeHTML } from '../../../pipes/safeHTML.pipe';


@NgModule({
  declarations: [
    InfoPreguntasFrecComponent,
    DirectorioTranspComponent,
    AvisoPrivacidadComponent,
    TransparenciaComponent,
    HistorialConsultasComponent,
    ArtIxComponent,
    LegislacionFederalComponent,
    CorpusComponent,
    DatosPersonalesComponent,
    PaginatePipe,
    SafeHTML,
    ObligacionTransparenciaModalComponent,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    RouterModule,
    TransparenciaRoutingModule,
    IfadModule,
    ReactiveFormsModule,
    FormsModule,
    LazyLoadImageModule,
    ComponentsModule,
    MatTooltipModule,
  ],
  // se utiliza para angular material paginado
  // providers: [
  //   {
  //     provide: MatPaginatorIntl,
  //     useClass: CustomMatPaginatorIntl,
  //   },
  // ],
  exports: [DirectorioTranspComponent],
})
export class TransparenciaModule {}
