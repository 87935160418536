import { Component, Inject, OnInit } from '@angular/core';
import gql from 'graphql-tag';
import { Apollo } from 'apollo-angular';
import { BuscarDiputadosService } from 'src/app/services/buscar-diputados.service';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-genero',
  templateUrl: './genero.component.html',
  styles: [],
})
export class GeneroComponent implements OnInit {
  dipuGenero1: any[] = [];
  loading = true;
  table: Number;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private apollo: Apollo,
    public dialog: MatDialog,

    private service: BuscarDiputadosService
  ) {
    this.loading = true;
  }

  ngOnInit(): void {
    this.service.getDiputadosGenero().valueChanges.subscribe((res: any) => {
      // console.log('res', res.data.allDiputadosGenero);
      this.dipuGenero1 = res.data.allDiputadosGenero;
      this.loading = false;
    });
  }
}
