
import { Component, OnInit } from '@angular/core';
import { MatDialog} from '@angular/material/dialog';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';

interface Balance {
  Oid: string;
  Legislatura: string;
  PeriodoLegis: string;
  Nombre: string;
  UrlDocumento: string;
}

interface Response {
  getBalances: Balance[];
}

@Component({
  selector: 'app-balance-actividades',
  templateUrl: './balance-actividades.component.html',
  styleUrls: ['./balance-actividades.component.scss'],
})
export class BalanceActividadesComponent implements OnInit {
  
  balanceActividades: Balance[];
  subBalances: any;
  loading = false;
  error: any;
  tipo: number;


  // constructor() { }
  constructor(private apollo: Apollo, 
              public dialog: MatDialog) {}
  ngOnInit(): void {

    this.subBalances = this.apollo
    .watchQuery<Response>({
      query: gql`
        {
          getBalances  {
            Oid
            Legislatura
            Nombre
            UrlDocumento
          }
        }
      `,
    })
    .valueChanges.subscribe((result) => {
      this.balanceActividades = result.data && result.data.getBalances;
      //console.log('Balances!!!: ', this.balanceActividades);
      this.loading = result.loading;
      this.error = result.errors;
    });



   
  }
 
}
