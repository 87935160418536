import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { InfoVideo } from 'src/app/interfaces/info-video';
import { InfoVideoService } from 'src/app/services/info-video.service';
import Swal from 'sweetalert2';
import { MatDialog } from '@angular/material/dialog';
import { VideotecaDialog } from '../../pages/home/infocamara/videoteca/videoteca.component';

@Component({
  selector: 'app-mis-videos',
  templateUrl: './mis-videos.component.html',
  styleUrls: ['./mis-videos.component.scss'],
})
export class MisVideosComponent implements OnInit, OnDestroy {
  @Input() data: any = {};

  public infoVideos: any;
  public favoriteInfoVideos: InfoVideo[];
  private _subInfoVideos: any;

  constructor(
    private _infoVideoService: InfoVideoService,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.setSubInfoVideos();
  }

  ngOnDestroy(): any {
    this._subInfoVideos.unsubscribe();
  }

  setSubInfoVideos(): void {
    this._subInfoVideos = this._infoVideoService
      .getSubInfoVideos(this.data.visitanteEmail)
      .valueChanges.subscribe((result) => {
        this.favoriteInfoVideos =
          result.data && result.data.allFavoriteInfoVideos;
        this.infoVideos = this.chunkArray([...this.favoriteInfoVideos], 4);
      });
  }

  deleteFavoriteInfoVideo(Oid): any {
    this._infoVideoService
      .deleteFavoriteInfoVideo(Oid, this.data.visitanteEmail)
      .subscribe(
        () => {
          this.infoVideos = this.infoVideos.filter((law) => law.Oid !== Oid);
          Swal.fire(
            'Confirmación',
            'Se ha borrado de favoritos de manera exitosa!',
            'success'
          );
        },
        (error) => {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text:
              'Hubo un error al borrar de favoritos, intente de nuevo mas tarde.',
            footer: '<a href>Continuas con problemas?</a>',
          });
          console.log(error);
        }
      );
  }
  openDialog(youtubeUrl: any): void {
    // console.log(youtubeUrl);
    const dialogRef = this.dialog.open(VideotecaDialog, {
      width: '75%',
      height: '70%',
      data: { youtubeUrl },
    });
    dialogRef.afterClosed().subscribe((result) => {
      console.log('::::::::::Modal closed! :' + result);
    });
  }
  chunkArray(arr: Array<any>, n: number) {
    return arr
      .slice(0, (arr.length + n - 1) / n || 0)
      .map((c, i) => arr.slice(n * i, n * i + n));
  }
}
