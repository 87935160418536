import { stringify } from '@angular/compiler/src/util';
import { Component, OnInit, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { ApiHomeService } from 'src/app/services/api-home.service';
import Swal from 'sweetalert2';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogData } from '../../infocamara/infografias/infografias.component';

@Component({
  selector: 'app-comentarios-modal',
  templateUrl: './comentarios-modal.component.html',
  styleUrls: ['./comentarios-modal.component.scss'],
})
export class ComentariosModalComponent implements OnInit {
  visitante: any = {
    email: '',
    Comentario: '',
  };
  operation: number;
  message: string;
  captcha: string;
  vandera = false;

  constructor(
    private api: ApiHomeService,
    private router: Router,
    public dialogRef: MatDialogRef<ComentariosModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    this.captcha = '6Lei6EwaAAAAAHvxJGzUaUPbnIqKMe1rSDyUbGwj';
  }

  ngOnInit(): void {}

  handleSuccess(tokenCaptcha: any): void {
    this.vandera = true;
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
  save(): void {
    // console.log(this.visitante);
    if (this.vandera === true) {
      this.api
        .enviarComentario(this.visitante.email, this.visitante.Comentario)
        .refetch()
        .then(
          (result) => {
            // console.log('UNO', result);
            // console.log('DOS', this.visitante);
            if (result) {
              this.operation = 1;
              // console.log({ userResult });
            } else {
              this.operation = 2;
            }
            Swal.fire({
              icon: 'success',
              title: 'Sus comentarios se han enviado correctamente. Gracias',
              showConfirmButton: false,
              timer: 1500,
            });
            this.message =
              'Para cerrar la ventana de click fuera del formulario o presione la tecla Esc';
          },
          (error) => {
            //console.log('error enviando el query: ', error);
            this.operation = 3;
            this.message = 'Error inesperado';
          }
        );
    } else {
      this.operation = 3;
      this.message = 'Valide que no es un Robot';
    }
  }
}
