import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { GraphqlService } from './graphql.service';
import { InfograficoRespuesta } from '../interfaces/infografico-respuesta';
import gql from 'graphql-tag';

@Injectable({
  providedIn: 'root',
})
export class InfograficoService {
  constructor(
    private apollo: Apollo,
    private _graphqlService: GraphqlService
  ) {}

  getSubInfographics(visitanteEmail: string): any {
    return this.apollo.watchQuery<InfograficoRespuesta>({
      query: gql`
        {
          allFavoriteInfographics(EmailVisitante: "${visitanteEmail}") {
            Oid
            Titulo
            FechaSubida
            ThumbnailBase64
            Categoria
            Visualizaciones
          }
        }`,
    });
  }

  deleteFavoriteInfographic(Oid: string, emailVisitante: string): any {
    return this.apollo.mutate({
      mutation: this._graphqlService.mutationDeleteFavoriteInfographic(
        Oid,
        emailVisitante
      ),
    });
  }
}
