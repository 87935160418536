import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { Norma, ResponseNormas } from '../leyes-vigentes.interface';

@Component({
  selector: 'app-modal-normas',
  templateUrl: './modal-normas.component.html',
  styleUrls: ['./modal-normas.component.scss']
})
export class ModalNormasComponent implements OnInit {

  normasReglamentarias: Norma[];
  subNormas: any;
  loading = false;
  error: any;
  tipo: number;

  constructor( public dialog: MatDialog,
    private apollo: Apollo,
    private route: ActivatedRoute,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router, public dialogRef: MatDialogRef<ModalNormasComponent>) { }

  ngOnInit(): void {

    this.subNormas = this.apollo
      .watchQuery<ResponseNormas>({
        query: gql`
          {
            allNormas(TipoNorma: ${this.data.tipo})  {
              Oid
              Nombre
              Version
              Word
              Orden
              TipoNorma
            }
          }
        `,
      })
      .valueChanges.subscribe((result) => {
        this.normasReglamentarias = result.data && result.data.allNormas;

        console.log('normas!!!: ', this.normasReglamentarias);
        console.log('normasxxxx: ', this.data.tipo);
        this.loading = result.loading;
        this.error = result.errors;
      });
  }

  closeDialog(): any {
    this.dialogRef.close();
  }

}
