import { Component, OnInit } from '@angular/core';
import { LegislacionFederalComponent } from '../modales/legislacion-federal/legislacion-federal.component';
// import { CorpusComponent } from '../modales/corpus/corpus.component';
import {
  MatDialog,
  // MatDialogRef,
  // MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { ModalInfografia } from '../transparencia.component';
import { Router, ActivatedRoute } from '@angular/router';
import { ConsultasTransparenciaService } from '../../../../services/consultas-transparencia.service';
import {
  // ResponseFavoriteAvisoPrivacidad,
  AvisoPrivacidad2,
} from '../aviso-privacidad/aviso-privacidad.interface';
import { TrabajadorService } from '../../../../services/trabajador.service';
import { TrabajadorArea } from '../../../../interfaces/trabajador-area';
import {
  TransparenciaProactiva,
  ResponseTransparenciaProactiva,
} from '../transparencia.component.interface';
import { GraphqlService } from '../../../../services/graphql.service';
import Swal from 'sweetalert2';

// interface Category {
//   Nombre: string;
// }

interface Infographic {
  Oid: string;
  Titulo: string;
  UrlImagenCompleta: string;
  UrlPortada: string;
  Usuario: string;
  Nombre: string;
  PrimerApellido: string;
  SegundoApellido: string;
  Categoria: string;
  FechaSubida: string;
  Visualizaciones: number;
}
interface InformacionRelevanteTransparencia {
  Oid: string;
  Orden: number;
  Nombre: string;
  UrlArchivo: string;
  SFTPIcono: string;
}

interface Response {
  // allInfographicCategories: Category[];
  allInfographicsThumbnails: Infographic[];
  // allInfographicsByViews: Infographic[];
  // allInfographicsByVotes: Infographic[];
  // allInfographicsByDate: Infographic[];
  // infographicPreview: Infographic;
  allInformacionRelevanteTransparencia: InformacionRelevanteTransparencia[];
}

// const mutationAddFavoriteAviso = (OidAviso, emailVisitante): any => gql`
// mutation {
//   addFavoriteAviso(OidAviso: "${OidAviso}", EmailVisitante: "${emailVisitante}"){
//     Oid
//   }
// }`;

// const mutationDeleteFavoriteAviso = (OidAviso, emailVisitante): any => gql`
// mutation {
//   deleteFavoriteAviso(OidAviso: "${OidAviso}", EmailVisitante: "${emailVisitante}"){
//     Oid
//   }
// }`;

// // KYBC.GraphQL Queries definitions.
// const infographicPreviewQuery = gql`
//   query infographicPreview($Oid: ID!) {
//     infographicPreview(Oid: $Oid) {
//       Oid
//       Titulo
//       Descripcion
//       Votos

//       PreviewBase64
//       Usuario
//       Nombre
//       PrimerApellido
//       SegundoApellido
//       FechaSubida
//       Categoria
//       Visualizaciones
//     }
//   }
// `;
// const modalInfographicQuery = gql`
//   query modalInfographic($Oid: ID!) {
//     modalInfographic(Oid: $Oid) {
//       ImageBase64
//     }
//   }
// `;

// const AddViewToInfographicMutation = gql`
//   mutation addViewToInfographic($Oid: ID!) {
//     addViewToInfographic(Oid: $Oid) {
//       Oid
//     }
//   }
// `;

// const mutationAddFavoriteInfographic = (
//   OidInfografia,
//   emailVisitante
// ): any => gql`
// mutation {
//   addFavoriteInfographic(OidInfographic: "${OidInfografia}", EmailVisitante: "${emailVisitante}"){
//     Oid
//   }
// }`;
// const mutationDeleteFavoriteInfographic = (
//   OidInfografia: any,
//   emailVisitante: string
// ): any => gql`
// mutation {
//   deleteFavoriteInfographic(OidInfographic: "${OidInfografia}", EmailVisitante: "${emailVisitante}"){
//     Oid
//   }
// }`;

// const infographicPreviewQueryByCategory = gql`
//   query allInfographicsByCategory($CategoriaOid: ID!) {
//     allInfographicsByCategory(CategoriaOid: $CategoriaOid) {
//       Oid
//       Titulo
//       Descripcion
//       Votos
//       Estrellas
//       ThumbnailBase64
//       Usuario
//       Nombre
//       PrimerApellido
//       SegundoApellido
//       FechaSubida
//       Categoria
//       Visualizaciones
//     }
//   }
// `;

@Component({
  selector: 'app-datos-personales',
  templateUrl: './datos-personales.component.html',
  styleUrls: ['./datos-personales.component.scss'],
})
export class DatosPersonalesComponent implements OnInit {
  // infographicCategories: any[];
  // favoriteAvisosPrivacidad: AvisoPrivacidad2[];
  // private querySubscription: any;
  // previewInfographic: any;
  // loadingCategories = true;
  // private querySub: any;
  // emailVisitante = '';

  // loggedIn = false;

  // subFavoriteAvisoPrivacidad: any;

  // public organigramaArea: TrabajadorArea[] = [];

  // public organigramaCargado = false;

  // informacionRelevanteC: any[]=[];

  //RMGT
  subInformacionRelevante: any;
  informacionRelevante: InformacionRelevanteTransparencia[] = [];
  subTransparenciaProactivaa: any;
  subTransparenciaProactivac: any;
  subTransparenciaProactivab: any;
  traproa: TransparenciaProactiva[] = [];
  traprob: TransparenciaProactiva[] = [];
  traproc: TransparenciaProactiva[] = [];
  infographics: any;
  infoTransparencia: any;
  loadingInfographics = true;
  datosCon:any = {};

  sub: any;
  public categoria: string;
  private querySub: any;
  error: any;
  loading = false;
  catego: any[];

  visitas: any;

  
  ifads:any[]=[];

  constructor(
    public dialog: MatDialog,
    private apollo: Apollo,
    private _router: Router,
    private api: GraphqlService,
    private route: ActivatedRoute,
    private _buscarConsultasService: ConsultasTransparenciaService,
    private router: Router,
    private _trabajadorServiceOrganigrama: TrabajadorService
  ) {}

  ngOnInit(): void {
    this.addViewToPage('8a9a965c-0f25-4eba-992c-03c54063aeaf');
    this.querySub = this.route.queryParams.subscribe((params) => {
      // Defaults to 0 if no query param provided.

      this.categoria =
        params.categoria || '6ed40eb8-b48d-45ae-bfc3-69cecc9a4bfd';
      // this.oidpadre = this.oid;
      // console.log('llegó oidCategoria', this.categoria);
    });

    this.DatosContacto();
    this.InformacionRelevante();

    if (this.categoria !== '6ed40eb8-b48d-45ae-bfc3-69cecc9a4bfd') {
      // this.fetchInfographicsThumbnailsByCategory(this.categoria);
      // this.verifyVisitantSession();
      // console.log('entro al if  que trae infografias transparencia');
    } else {
      // this.verifyVisitantSession();
      // this.fetchAllInfographicCategories();
      this.fetchAllInfographicsThumbnailsFromDB();
    }

    // this.verifyVisitantSession();
    
    this.allTransparenciaIFAD();
  }

  addViewToPage(Oid: any): any {
    this.api.addViewToPage(Oid).subscribe(
      ({ data }: any) => {
        this.visitas = data.addView.Visitas;
      },
      (error) => {
        console.log(
          '::::::::::Error al aumentar las visualizaciones de la infografía: ',
          error
        );
      }
    );
  }

  //Información Relevante
  InformacionRelevante() {
    //Conceptos
    this.subInformacionRelevante = this.apollo
      .watchQuery<Response>({
        query: gql`
          {
            allInformacionRelevanteTransparencia {
              Oid
              Orden
              Nombre
              SFTPIcono
              UrlArchivo
            }
          }
        `,
      })
      .valueChanges.subscribe((result) => {
        this.informacionRelevante =
          result.data && result.data.allInformacionRelevanteTransparencia;
        // this.informacionRelevanteC = this.chunkArray(this.informacionRelevante , 3);
        this.loading = result.loading;
      });

    //Información de Interés
    this.subTransparenciaProactivaa = this.apollo
      .watchQuery<ResponseTransparenciaProactiva>({
        query: gql`
          {
            allTransparenciaProactivaa {
              Nombre
              URL
              Video
              ImageBase64
              Pagina
            }
          }
        `,
      })
      .valueChanges.subscribe((result) => {
        result.data.allTransparenciaProactivaa.forEach((element) => {
          this.traproa.push(element);
        });
        this.loading = result.loading;
      });

    this.subTransparenciaProactivab = this.apollo
      .watchQuery<ResponseTransparenciaProactiva>({
        query: gql`
          {
            allTransparenciaProactivab {
              Nombre
              URL
              Video
              ImageBase64
              Pagina
            }
          }
        `,
      })
      .valueChanges.subscribe((result) => {
        this.traprob = result.data && result.data.allTransparenciaProactivab;
        this.traprob.forEach((element) => {
          this.traproa.push(element);
        });
        this.loading = result.loading;
      });
    this.subTransparenciaProactivac = this.apollo
      .watchQuery<ResponseTransparenciaProactiva>({
        query: gql`
          {
            allTransparenciaProactivac {
              Nombre
              URL
              Video
              ImageBase64
              Pagina
            }
          }
        `,
      })
      .valueChanges.subscribe((result) => {
        this.traproc = result.data && result.data.allTransparenciaProactivac;
        this.traproc.forEach((element) => {
          this.traproa.push(element);
        });
        this.loading = result.loading;
      });
  }

  openLegislacionFederal(): any {
    const dialogRef = this.dialog.open(LegislacionFederalComponent, {});
  }
  DatosContacto(): any {
    this.sub = this.apollo
      .watchQuery<any>({
        query: gql`
          {
            allDatosPersonales {
              Oid
              UrlDirectorio
              Contacto
            }
          }
        `,
      })
      .valueChanges.subscribe((result) => {
        let datosContacto = result.data && result.data.allDatosPersonales;
        if (datosContacto.length > 0) {
          this.datosCon = datosContacto[0];
        }
      });
  }

  //Infografías
  //KYBC.Function for fetch all infographic previews from DB.
  fetchAllInfographicsThumbnailsFromDB(): any {
    this.sub = this.apollo
      .watchQuery<Response>({
        query: gql`
          {
            allInfographicsThumbnails {
              Oid
              Titulo
              Descripcion
              Votos
              Estrellas
              UrlImagenCompleta
              UrlPortada
              Usuario
              Nombre
              PrimerApellido
              SegundoApellido
              FechaSubida
              Categoria
              Visualizaciones
            }
          }
        `,
      })
      .valueChanges.subscribe((result) => {
        this.infographics =
          result.data && result.data.allInfographicsThumbnails;

        this.catego = this.infographics.filter((aviso) =>
          aviso.Categoria.includes('Datos Personales')
        );
        this.infoTransparencia = this.chunkArray([...this.catego], 3);
        // this.setPreviewInfographic(this.catego[0].Oid);
        // console.log('info: ', this.infoTransparencia);
        this.loadingInfographics = result.loading;
        this.error = result.errors;
      });
  }

  allTransparenciaIFAD(){
    this.apollo
      .watchQuery<any>({
        query: gql`
          {
            allTransparenciaTipoIFAD {
              Oid
              Nombre
              OidVariable
              NombreVariable
              Ejercicio
              FechaPublicacion
              FechaActualizacion
              Descripcion
              Documentos{
                Oid
                Titulo
                Descripcion
                # FechaPublicacion
                # FechaActualizacion
                UrlArchivo
                NAnexos
              }
            }
          }
        `,
      })
      .valueChanges.subscribe((result) => {
        this.ifads = result.data && result.data.allTransparenciaTipoIFAD;
      });
  }
  
  copiarLink(url: string) {
    // console.log('ABC: ', url);
    let aux = document.createElement('input');
    aux.setAttribute('value', url);
    document.body.appendChild(aux);
    aux.select();
    document.execCommand('copy');
    document.body.removeChild(aux);
    Swal.fire(
      'Confirmación',
      'La dirección URL se ha copiado exitosamente!',
      'success'
    );
  }

  openInfografia(Oid: any): void {
    // console.log('OdiInfografia trae: ', Oid);

    const dialogRef = this.dialog.open(ModalInfografia, {
      width: '70%',
      height: '99%',
      data: { legis: Oid },
    });
  }

  chunkArray(arr: Array<any>, n: number) {
    return arr
      .slice(0, (arr.length + n - 1) / n || 0)
      .map((c, i) => arr.slice(n * i, n * i + n));
  }

  /*RMGT. Funciones que ya no se ocupan */
  // openCorpus(): any {
  //   const dialogRef = this.dialog.open(CorpusComponent, {});
  // }

  // verifyVisitantSession(): any {
  //   this.loggedIn = !!localStorage.getItem('HCD_activeSessionVisitant');

  //   if (this.loggedIn) {
  //     this.emailVisitante = localStorage.getItem('HCD_visitantEmail');

  //     // traer los avisos favoritos de el visitante
  //     this.subFavoriteAvisoPrivacidad = this.apollo
  //       .watchQuery<ResponseFavoriteAvisoPrivacidad>({
  //         query: gql`
  //         {
  //           allFavoriteAvisosPrivacidad(EmailVisitante: "${this.emailVisitante}") {
  //             Oid
  //           }
  //         }
  //       `,
  //       })
  //       .valueChanges.subscribe((result) => {
  //         this.favoriteAvisosPrivacidad =
  //           result.data && result.data.allFavoriteAvisosPrivacidad;
  //         this.loading = result.loading;
  //         this.error = result.errors;

  //         // console.log(
  //         // 'llegaron estos avisos favoritos',
  //         //  result.data.allFavoriteAvisosPrivacidad
  //         // );
  //       });
  //   }
  //   this.organigramaTransparencia();
  // }
  // organigramaTransparencia() {
  //   this._trabajadorServiceOrganigrama
  //     .selectTrabajadoresArea('562df02c-6eea-490a-b9ac-87f371e5e87e')
  //     .refetch()
  //     .then((data) => {
  //       this.organigramaArea = data.data.selectTrabajadoresArea;
  //       this.organigramaCargado = true;
  //     });
  // }
  // isFavorite(oid): any {
  //   return this.favoriteAvisosPrivacidad.find((aviso) => aviso.Oid === oid);
  // }

  // addFavoriteAviso(OidAviso): any {
  //   // .log(`Llego oid ${OidAviso}`);
  //   this.apollo
  //     .mutate({
  //       mutation: mutationAddFavoriteAviso(OidAviso, this.emailVisitante),
  //     })
  //     .subscribe(
  //       ({ data }) => {
  //         const newFavAviso: AvisoPrivacidad2 = { Oid: OidAviso };
  //         this.favoriteAvisosPrivacidad.push(newFavAviso);
  //         alert('se ha guardado el aviso en favoritos de manera exitosa!');
  //       },
  //       (error) => {
  //         alert('hubo un error al guardar en favoritos, intente de nuevo');
  //         console.log(error);
  //       }
  //     );
  // }

  // deleteFavoriteAviso(OidAviso): any {
  //   this.apollo
  //     .mutate({
  //       mutation: mutationDeleteFavoriteAviso(OidAviso, this.emailVisitante),
  //     })
  //     .subscribe(
  //       ({ data }) => {
  //         this.favoriteAvisosPrivacidad = this.favoriteAvisosPrivacidad.filter(
  //           (aviso) => aviso.Oid !== OidAviso
  //         );
  //         alert('se ha borrado el aviso en favoritos de manera exitosa!');
  //       },
  //       (error) => {
  //         alert('hubo un error al borrar de favoritos, intente de nuevo');
  //         console.log(error);
  //       }
  //     );
  // }

  // // KYBC.Function for fetch all infographic categories from DB.
  // fetchAllInfographicCategories(): any {
  //   this.sub = this.apollo
  //     .watchQuery<Response>({
  //       query: gql`
  //         {
  //           allInfographicCategories {
  //             Oid
  //             Nombre
  //             ImageBase64
  //           }
  //         }
  //       `,
  //     })
  //     .valueChanges.subscribe((result) => {
  //       // console.log('::::::::::allInfographicCategories Result \n', result); // KYBC.Borrar.
  //       this.infographicCategories =
  //         result.data && result.data.allInfographicCategories;
  //       // console.log(
  //       // '::::::::::allInfographicCategories from DBasdf: \n',
  //       // this.infographicCategories
  //       // ); // KYBC.Borrar.
  //       this.loadingCategories = result.loading;
  //       this.error = result.errors;
  //     });
  // }
  // setPreviewInfographic(Oid: string): any {
  //   this.querySubscription = this.apollo
  //     .watchQuery({
  //       query: infographicPreviewQuery,
  //       variables: {
  //         Oid,
  //       },
  //     })
  //     .valueChanges.subscribe(({ data }) => {
  //       this.previewInfographic = data;
  //       this.previewInfographic = this.previewInfographic.infographicPreview;
  //       this.addViewToInfographic(this.previewInfographic.Oid);
  //     });
  //   window.scrollTo(0, 0);
  // }

  // addViewToInfographic(Oid: any): any {
  //   this.apollo
  //     .mutate({
  //       mutation: AddViewToInfographicMutation,
  //       variables: {
  //         Oid,
  //       },
  //     })
  //     .subscribe(
  //       ({ data }) => {
  //         console.log(
  //           '::::::::::AddViewToInfographicMutation Data from DB: ',
  //           data
  //         ); // KYBC.Borrar.
  //       },
  //       (error) => {
  //         console.log(
  //           '::::::::::Error al aumentar las visualizaciones de la infografía: ',
  //           error
  //         );
  //       }
  //     );
  // }

  // // KYBC.Function for fetch all infographic categories from DB.
  // fetchInfographicsThumbnailsByCategory(categoryOid: any): any {
  //   console.log('::::::::::Category Oid Selected: \n', categoryOid); // KYBC.Borrar.
  //   this.querySubscription = this.apollo
  //     .watchQuery({
  //       query: infographicPreviewQueryByCategory,
  //       variables: {
  //         CategoriaOid: categoryOid,
  //       },
  //     })
  //     .valueChanges.subscribe(({ data }) => {
  //       this.infographics = data;
  //       this.infographics = this.infographics.allInfographicsByCategory;
  //       // console.log(':::: info trae', this.infographics);
  //       this.loadingInfographics = this.infographics.loading;
  //       // this.setPreviewInfographic(this.infographics[0].Oid);
  //     });
  // }
}
