import { Component, OnInit, ElementRef } from '@angular/core';

@Component({
  selector: 'app-transmision',
  templateUrl: './transmision.component.html',
  styleUrls: ['./transmision.component.scss']
})
export class TransmisionComponent implements OnInit {

  constructor(private elementRef: ElementRef) {
  }

  ngOnInit(): void {
    const scriptKalturaPlayer = document.createElement('script');
    scriptKalturaPlayer.type = 'text/javascript';
    scriptKalturaPlayer.text = `
      kWidget.embed({
        "targetId": "kaltura_player_1565720477",
        "wid": "_1808371",
        "uiconf_id": 44425072,
        "flashvars": {
          "autoPlay": true
        },
        "cache_st": 1565720477,
        "entry_id": "1_3ekobsj8"
      });

    `;
    this.elementRef.nativeElement.appendChild(scriptKalturaPlayer);
  }

}
