import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { CapacitacionTransparenciaRespuesta } from '../interfaces/capacitacion-transparencia-respuesta';

@Injectable({
  providedIn: 'root',
})
export class DisposicionesMateriaService {
  constructor(private apollo: Apollo) {}

  allDisposicionMateria() {
    return this.apollo.watchQuery<any>({
      query: gql`
          {
            allDisposicionMateria{
              Oid
              Descripcion
              Archivos{
                Oid
                Titulo
                SubTitulo
                UrlArchivo
              }
            }
          }
        `,
    });
  }
}
