import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Publicacion } from 'src/app/interfaces/publicacion';
import Swal from 'sweetalert2';
import { PublicacionesService } from '../../services/publicaciones.service';

@Component({
  selector: 'app-mis-publicaciones',
  templateUrl: './mis-publicaciones.component.html',
  styleUrls: ['./mis-publicaciones.component.scss'],
})
export class MisPublicacionesComponent implements OnInit, OnDestroy {
  @Input() data: any = {};
  // public data: any;

  favoritePublicaciones: Publicacion[];
  bloquesPublicaciones: any = [];
  error: any;
  loading = true;
  private _subPublicaciones: any;
  public loadingPosts = false;
  constructor(private _PublicacionesService: PublicacionesService) {}

  ngOnInit(): void {
    this.setSubPublications();
  }
  ngOnDestroy(): any {
    this._subPublicaciones.unsubscribe();
  }
  setSubPublications(): void {
    // console.log('setSubPublications visitanteEmail', this.data.visitanteEmail);
    this._subPublicaciones = this._PublicacionesService
      .getSubPublications(this.data.visitanteEmail)
      .valueChanges.subscribe((result) => {
        // console.log('resulktPub', result);
        this.favoritePublicaciones =
          result.data && result.data.allFavoritePublications;
        // console.log('favoritePublicaciones', this.favoritePublicaciones);
        this.bloquesPublicaciones = this.chunkArray(
          [...this.favoritePublicaciones],
          4
        );
        this.loading = result.loading;
        this.error = result['error'];
        console.log('error', this.error);
      });
  }
  deleteFavoritePublication(OidPublication): any {
    this._PublicacionesService
      .deleteFavoritePublication(OidPublication, this.data.visitanteEmail)
      .subscribe(
        () => {
          this.favoritePublicaciones = this.favoritePublicaciones.filter(
            (publication) => publication.Oid !== OidPublication
          );
          Swal.fire(
            'Confirmación',
            'Se ha borrado de favoritos de manera exitosa!',
            'success'
          );
        },
        (error) => {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text:
              'Hubo un error al borrar de favoritos, intente de nuevo mas tarde.',
            footer: '<a href>Continuas con problemas?</a>',
          });
          //  console.log(error);
        }
      );
  }
  chunkArray(arr: Array<any>, n: number) {
    return arr
      .slice(0, ((arr.length + n - 1) / n) | 0)
      .map((c, i) => arr.slice(n * i, n * i + n));
  }
}
