import { Injectable, ElementRef } from '@angular/core';
// import * as scriptjs from "scriptjs";

@Injectable({
  providedIn: 'root',
})
export class ShareService {
  private scripts: any = {};

  constructor() { }

  addScript(elementRef: ElementRef, script: string) {
    return new Promise((resolve, reject) => {
      var newScript = document.createElement('script');
      newScript.type = 'text/javascript';
      newScript.innerHTML = script; //inline script
      elementRef.nativeElement.appendChild(newScript);
      resolve({ loaded: true, status: 'Loaded' });
    });
  }

  addMultiplesLinkScripts(elementRef: ElementRef, scripts: any[]) {
    return Promise.all(scripts.map((script) =>
      this.loadScript(elementRef, script)
    ));
  }

  loadScript(elementRef: ElementRef, script: any) {
    return new Promise((resolve, reject) => {
      //Resolver promesa si el script ya fue cargado
      if (this.scripts[script.name]?.loaded) {
        resolve({
          script: script.name,
          loaded: true,
          status: 'Already Loaded',
        });
      } else {
        let newScript = document.createElement('script');
        newScript.type = 'text/javascript';
        newScript.src = script.src;
        //Script incluido a la lista local actual
        this.scripts[script.name] = {
          loaded: false,
          src: script.src,
        };
        newScript.onload = () => {
          this.scripts[script.name].loaded = true;
          resolve({ script: script.name, loaded: true, status: 'Loaded' });
        };
        newScript.onerror = (error: any) => reject({ script: script.name, loaded: false, status: error });
        elementRef.nativeElement.appendChild(newScript);
      }
    });
  }

  downloadPdf(base64String, fileName) {
    const source = `data:application/pdf;base64,${base64String}`;
    const link = document.createElement("a");
    link.href = source;
    link.download = `${fileName}.pdf`
    link.click();
  }
}
