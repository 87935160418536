import { Component, OnChanges, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { NgForm, FormControl, FormsModule, Form } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { ComentariosModalComponent } from 'src/app/pages/home/homepage/comentarios-modal/comentarios-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { VideosDialogComponent } from '../../pages/home/homepage/videosModal/videosModal.component';
import { TrabajadorService } from 'src/app/services/trabajador.service';
@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit, OnChanges {
  public leyendaSuperior: string;
  public today: any;
  public year: number;
  loggedIn = false;
  public menuoriginal: boolean;
  public menufalso = true;
  public ocultarmenu: boolean;
  private textoBuscar = '';

  constructor(private http: HttpClient, public dialog: MatDialog,
    private trabajadorService: TrabajadorService,) {}

  ngOnInit(): void {
    this.year = new Date().getFullYear();
    this.verifyVisitantSession();
    this.today = this.formatAMPM();
    this.getLeyendaSuperior();
  }

  getLeyendaSuperior() {
    this.trabajadorService.getLeyendaSuperior().subscribe((rest)=>{
      let leySuperior = rest.data.getLeyendaSuperior;
      this.leyendaSuperior = leySuperior.LeyendaSuperior;
    });
  }
  verifyVisitantSession(): void {
    this.loggedIn = !!localStorage.getItem('HCD_activeSessionVisitant');
  }
  ngOnChanges() {
    this.loggedIn = !!localStorage.getItem('HCD_activeSessionVisitant');
  }
  formatAMPM(): string {
    const d = new Date();
    const months = [
      'de enero',
      'de febrero',
      'de marzo',
      'de abril',
      'de mayo',
      'de junio',
      'de julio',
      'de agosto',
      'de septiembre',
      'de octubre',
      'de noviembre',
      'de diciembre',
    ];
    const days = [
      'Domingo',
      'Lunes',
      'Martes',
      'Miércoles',
      'Jueves',
      'Viernes',
      'Sábado',
    ];
    return (
      days[d.getDay()] +
      ' ' +
      d.getDate() +
      ' ' +
      months[d.getMonth()] +
      ' , ' +
      d.getFullYear()
    );
  }
  buscar(texto: string) {
    window.open(`https://busqueda.diputados.gob.mx:5000/results?texto=${texto}`, '_blank');
  }
  // KYBC.Change the Channel in 'Sesion en Vivo' functionality.
  changeActiveMenuDecoration(menuNumber: string): void {
    // tslint:disable-next-line: one-variable-per-declaration
    const menues: HTMLCollectionOf<Element> = document.getElementsByClassName(
      'menu'
    );
    const menuSelected: HTMLCollectionOf<Element> = document.getElementsByClassName(
      menuNumber
    );
    // tslint:disable-next-line: triple-equals
    if (menuNumber != 'number0') {
      menuSelected[0].className = menuSelected[0].className.replace(
        ' menu',
        ' menu menuDecoration'
      );
    } else {
      this.clearMenuesDecoration(menues);
    }
    this.menuoriginal = true;
    this.menufalso = false;
  }

  // KYBC.Change the Channel in 'Sesion en Vivo' functionality.
  changeActiveMenuDecoration2(menuNumber: string): void {
    // tslint:disable-next-line: one-variable-per-declaration
    const menues: HTMLCollectionOf<Element> = document.getElementsByClassName(
      'menu'
    );
    const menuSelected: HTMLCollectionOf<Element> = document.getElementsByClassName(
      menuNumber
    );

    // menues = document.getElementsByClassName('menu');
    // this.clearMenuesDecoration(menues);
    // menuSelected = document.getElementsByClassName(menuNumber);
    // tslint:disable-next-line: triple-equals
    if (menuNumber != 'number0') {
      menuSelected[0].className = menuSelected[0].className.replace(
        ' menu',
        ' menu menuDecoration'
      );
    } else {
      this.clearMenuesDecoration(menues);
    }
    this.menuoriginal = false;
    this.menufalso = true;
  }

  // JG. Modal de Comentarios
  openComentarios(): void {
    const dialogRef = this.dialog.open(ComentariosModalComponent, {
      width: '70%',
      height: '68%',
    });
    dialogRef.afterClosed().subscribe((result) => {
      // console.log('::::::::::Modal closed! :' + result);
    });
  }

  // KYBC.Clear the menuDecoration class for the 'changeActiveMenu(menuNumber: string)' function above.
  clearMenuesDecoration(menues: HTMLCollectionOf<Element>): void {
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < menues.length; i++) {
      menues[i].className = menues[i].className.replace(
        ' menu menuDecoration',
        ' menu'
      );
    }
  }

  openSocial(): void {
    document.getElementById('iconbar').style.left = '0px';
    document.getElementById('cerrar-barra').style.left = '32px';
    document.getElementById('cerrar-barra').style.display = 'none';
    document.getElementById('abrir-barra').style.display = 'none';
  }
  openDialog(youtubeUrl?: any): void {
    // console.log('YOUTUBE MODAL PARAMETRO', youtubeUrl);
    let dialogRef;
    if (youtubeUrl) {
      dialogRef = this.dialog.open(VideosDialogComponent, {
        width: '75%',
        height: '70%',
        data: { youtubeUrl },
      });
    } else {
      dialogRef = this.dialog.open(VideosDialogComponent, {
        width: '65%',
        data: { youtubeUrl },
      });
    }
    dialogRef.afterClosed().subscribe((result) => {
      // console.log('::::::::::Modal closed! :' + result);
    });
  }

  closeSocial(): void {
    document.getElementById('iconbar').style.left = '-32px';
    document.getElementById('cerrar-barra').style.left = '0px';
    document.getElementById('cerrar-barra').style.display = 'none';
    document.getElementById('abrir-barra').style.display = 'block';
  }

  openAccess(): void {
    document.getElementById('access').style.right = '0px';
    document.getElementById('cerrar-access').style.right = '74px';
    document.getElementById('cerrar-access').style.display = 'block';
    document.getElementById('abrir-access').style.display = 'none';
  }

  closeAccess(): void {
    document.getElementById('access').style.right = '-74px';
    document.getElementById('cerrar-access').style.right = '0px';
    document.getElementById('cerrar-access').style.display = 'none';
    document.getElementById('abrir-access').style.display = 'block';
  }
  handleSubmit(e, texto?: string) {
    e.preventDefault();
    window.open(
      `http://201.147.98.69:5000/results?texto=${this.textoBuscar}`,
      '_blank'
    );
  }
  handleKeyUp(e, texto?: string) {
    if (e.keyCode === 13) {
      this.handleSubmit(e);
    } else {
      console.log(e);
      console.log(texto);
      this.textoBuscar = e;
    }
  }
}
