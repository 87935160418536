import { ActivatedRoute, Router } from '@angular/router';
import { DialogData } from '../homepage.component';
import {
  Component,
  OnInit,
  ViewChildren,
  Inject,
  ElementRef,
  OnDestroy,
} from '@angular/core';
import Swiper from 'swiper';
import { DOCUMENT } from '@angular/common';
import { Apollo } from 'apollo-angular';
import { queryAllVideos } from '../../../../operations/query';
import { Video } from '../homepage.interface';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
export type Response = {
  // allEnlacesParlamentarios: EnlaceParlamentario[];
  allVideosFromDB: Video[];

  // allLinks: Link[];
};
@Component({
  selector: 'app-videos-dialog',
  templateUrl: './videosModal.component.html',
  styleUrls: ['./videosModal.component.scss'],
})
export class VideosDialogComponent {
  @ViewChildren('sliderVideosChildren') videosSliderViewChildren: any;
  public transmision = false;
  proximosEventosSwiper: any;
  enlacesInteresSwiper: any;
  enlacesInteresSwiper2: any;
  videosSwiper: any;
  videosFromDB: any[] = [];
  loadingVideos = true;
  sub: any;
  videosDB: any;
  error: any;
  constructor(
    private elementRef: ElementRef,
    private apollo: Apollo,
    public dialogRef: MatDialogRef<VideosDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    if (!data.youtubeUrl) {
      this.transmision = true;
    }
    this.fetchAllVideos();
  }
  ngAfterViewInit(): void {
    this.videosSliderViewChildren.changes.subscribe(() => {
      this.videosSwiper = new Swiper('.swiperVideos', {
        updateOnWindowResize: true, // KYBC.Swiper will recalculate slides position on window resize (orientationchange).
        initialSlide: 0, // KYBC.	Index number of initial slide.
        direction: 'horizontal', // KYBC.Could be 'horizontal' or 'vertical' (for vertical slider).
        speed: 300, // KYBC.Duration of transition between slides (in ms).
        effect: 'slide', // KYBC.Tranisition effect. Could be "slide", "fade", "cube", "coverflow" or "flip".
        spaceBetween: 10, // KYBC.Distance between slides in px.
        slidesPerView: 4, // KYBC.Number of slides per view (slides visible at the same time on slider's container).
        loop: false, // KYBC.Set to true to enable continuous loop mode.
      });
      //console.log("VIDEOS SWIPER", this.videosSwiper);
    });
    // Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    // Add 'implements AfterViewInit' to the class.
    // KYBC.Applies script tag for KalturaPlayer for 'Sesión en Vivo' functionality.
    const scriptKalturaPlayer = document.createElement('script');
    scriptKalturaPlayer.type = 'text/javascript';
    scriptKalturaPlayer.text = `
    kWidget.embed({
      "targetId": "kaltura_player_1565720477",
      "wid": "_1808371",
      "uiconf_id": 44425072,
      "flashvars": {
        "autoPlay": true,
        "thumbnailUrl": "/assets/images/thumbnail-home.jpg"
      },
      "cache_st": 1565720477,
      "entry_id": "1_3ekobsj8"
    });
    `;
    this.elementRef.nativeElement.appendChild(scriptKalturaPlayer);
  }

  fetchAllVideos() {
    this.sub = this.apollo
      .watchQuery<Response>({
        query: queryAllVideos,
      })
      .valueChanges.subscribe((result) => {
        // console.log('::::::::::allVideosFromDB Result from DB: \n', result); // KYBC.Borrar.
        this.videosFromDB = result.data && result.data.allVideosFromDB;
        // console.log('::::::::::allVideosFromDB from DB: \n', this.videosFromDB); // KYBC.Borrar.
        this.loadingVideos = result.loading;
        this.error = result['error'];
      });
  }

  changeChannel(channelName: string, tablinkNumber: string) {
    let divs;
    let tablinks;

    divs = document.getElementsByClassName('channel');
    for (let i = 0; i < divs.length; i++) {
      divs[i].style.display = 'none';
    }

    tablinks = document.getElementsByClassName('tablink');
    for (let i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace(
        ' sesionEnVivoButtonActive',
        ''
      );
    }

    document.getElementById(channelName).style.display = 'block';
    tablinks = document.getElementsByClassName(tablinkNumber);
    tablinks[0].className = tablinks[0].className.replace(
      ' tablink',
      ' tablink sesionEnVivoButtonActive'
    );
  }

  sliderPrevNext(swiper: string, navigationOption: string) {
    switch (swiper) {
      case 'swiperProximosEventos':
        if (navigationOption === 'prev') {
          this.proximosEventosSwiper.slidePrev();
        }
        if (navigationOption === 'next') {
          this.proximosEventosSwiper.slideNext();
        }
        break;
      case 'swiperVideos':
        if (navigationOption === 'prev') {
          this.videosSwiper.slidePrev();
        }
        if (navigationOption === 'next') {
          this.videosSwiper.slideNext();
        }
        break;
      case 'swiperEnlacesInteres':
        if (navigationOption === 'prev') {
          this.enlacesInteresSwiper.slidePrev();
        }
        if (navigationOption === 'next') {
          this.enlacesInteresSwiper.slideNext();
        }
        break;
      case 'swiperEnlacesInteres2':
        if (navigationOption === 'prev') {
          this.enlacesInteresSwiper.slidePrev();
        }
        if (navigationOption === 'next') {
          this.enlacesInteresSwiper.slideNext();
        }
        break;
      default:
      // code block
    }
  }
}
