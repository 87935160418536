import {
  Component,
  OnInit,
  ViewChildren,
  OnDestroy,
  Inject,
} from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';

import * as PhotoSphereViewer from 'photo-sphere-viewer';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';

import { MatSnackBar } from '@angular/material/snack-bar';
import Swiper from 'swiper';
import { throwToolbarMixedModesError } from '@angular/material/toolbar';
const loginMiCamaraVisitantQuery = gql`
  query loginMiCamaraVisitant(
    $Correo: String
    $Password: String
    $Celular: String
  ) {
    loginMiCamaraVisitant(
      Correo: $Correo
      Password: $Password
      Celular: $Celular
    ) {
      Oid
      Correo
      Celular
      FechaRegistro
      CorreoConfirmado
      CelularConfirmado
      VerEventosFavoritos
      VerDiputadosFavoritos
      VerLeyesFavoritas
      VerReformasFavoritas
    }
  }
`;

const addEmailMutation = gql`
  mutation addEmail($Correo: String!) {
    addEmail(Correo: $Correo) {
      Correo
    }
  }
`;

type Visitant = {
  Oid: string;
  Usuario: string;
  Correo: string;
  Celular: string;
  FechaRegistro: string;
  VerEventosFavoritos: boolean;
  VerDiputadosFavoritos: boolean;
};

type Event = {
  Oid: string;
  Nombre: string;
  Descripcion: string;
  Ubicacion: string;
  FechaInicio: string;
  FechaFin: string;
  ImageData: string;
  TipoEvento: string;
  linkSitio: string;
};

type SitioInteres = {
  Oid: string;
  Nombre: string;
  linkSitio: string;
  Descripcion: string;
  ImageBase64: string;
};

type Legislatura = {
  Oid: string;
  Numero: string;
  Legislatura: string;
  ImagenLBase64: string;
  Descripcion: string;
};

type Response = {
  loginMiCamaraVisitant: Visitant[];
  allEvents: Event[];
  allSitiosInteres: SitioInteres[];
  allLegislaturas: Legislatura[];
};

export interface DialogData {
  modalInfographic: string;
}

@Component({
  selector: 'app-tucamara',
  templateUrl: './tucamara.component.html',
  styleUrls: ['./tucamara.component.scss'],
})
export class TucamaraComponent implements OnInit, OnDestroy {
  visitantDataFromDB: any;
  visitantActiveSession = 'false';

  cargando: boolean;

  @ViewChildren('sliderProximosEventosChildren')
  proximosEventosSliderViewChildren: any;
  @ViewChildren('sliderTuCamaraTiempoThumbsChildren')
  tuCamaraTiempoThumbsSliderViewChildren: any;
  @ViewChildren('sliderTuCamaraTiempoChildren')
  tuCamaraTiempoSliderViewChildren: any;

  proximosEventosSwiper: any;
  tuCamaraTiempoThumbsSwiper: any;
  tuCamaraTiempoSwiper: any;

  LoginMiCamaraFormGroup: FormGroup;
  correoVisitante: any = '';
  snackBarRef: any;

  eventos: any[];
  sitiosInteres: any[];
  legislaturas: any[];

  loading = true;
  sitiosInteresLoading = true;
  legislaturasLoading = true;

  error: any;
  sitiosInteresError: any;
  legislaturasError: any;

  sub: any;
  querySubscription: any;

  constructor(
    private apollo: Apollo,
    private _router: Router,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar
  ) { }

  ngOnInit(): any {
    this.cargando = true;
    // KYBC.Call function for verify visitant session.
    this.verifyVisitantSession();

    this.openDialog();

    // KYBC.Functionality 'Recorrido 360'
    const PSV = new PhotoSphereViewer({
      container: document.querySelector('#viewer'),
      panorama: '../../../assets/images/tuCamara/Recorrido_360/1.jpg',
      caption: 'Recorrido 360 H. Cámara de Diputados',
      default_fov: 179,
      default_long: 0.6571265480503274,
      default_lat: -0.21516637184742038,
      markers: [
        {
          id: 'esculturaGranTintero',
          image:
            '../../../assets/images/tuCamara/Recorrido_360/flechaRecorrido.png',
          longitude: 3.2475552347083037,
          latitude: 0.09094487662134876,
          width: 35,
          height: 35,
          anchor: 'bottom center',
          tooltip: 'Escultura "El gran Tintero"',
          // content: document.getElementById('lorem-content').innerHTML
        },
        {
          id: 'muralConstituyentes',
          image:
            '../../../assets/images/tuCamara/Recorrido_360/flechaRecorrido.png',
          longitude: 1.5223912058039737,
          latitude: -0.027386768226888902,
          width: 35,
          height: 35,
          anchor: 'bottom center',
          tooltip: 'Mural Constituyentes',
          // content: document.getElementById('lorem-content').innerHTML
        },
        {
          id: 'rotativaConstituyente',
          image:
            '../../../assets/images/tuCamara/Recorrido_360/flechaRecorrido.png',
          longitude: 0.059393329101476315,
          latitude: 0.044843025056090724,
          width: 35,
          height: 35,
          anchor: 'bottom center',
          tooltip: 'Rotativa "Constituyente"',
          // content: document.getElementById('lorem-content').innerHTML
        },
        {
          id: 'muralCongresosConstituyentes',
          image:
            '../../../assets/images/tuCamara/Recorrido_360/flechaRecorrido.png',
          longitude: 4.946472542842333,
          latitude: 0.000773878978242104,
          width: 35,
          height: 35,
          anchor: 'bottom center',
          tooltip: 'Mural "Los Congresos Constituyentes"',
          // content: document.getElementById('lorem-content').innerHTML
        },
        {
          id: 'museo',
          image:
            '../../../assets/images/tuCamara/Recorrido_360/flechaRecorrido.png',
          longitude: 6.026478104911444,
          latitude: -0.010626900807502171,
          width: 35,
          height: 35,
          anchor: 'bottom center',
          tooltip: 'Museo',
          // content: document.getElementById('lorem-content').innerHTML
        },
        {
          id: 'muralSintoniaEcotropica',
          image:
            '../../../assets/images/tuCamara/Recorrido_360/flechaRecorrido.png',
          longitude: 0.6918115810780606,
          latitude: -0.00614518048456425,
          width: 35,
          height: 35,
          anchor: 'bottom center',
          tooltip: 'Mural "Sintonía Ecotrópica"',
          // content: document.getElementById('lorem-content').innerHTML
        },
      ],
    });

    PSV.on('select-marker', function (marker) {
      const PSVPosition: any = {
        longitude: 0,
        latitude: 0,
      };

      switch (marker.id) {
        case 'explanada':
          PSV.clearMarkers(true);
          PSV.setPanorama(
            '../../../assets/images/tuCamara/Recorrido_360/1.jpg',
            null,
            false
          );
          PSVPosition.longitude = 0.6571265480503274;
          PSVPosition.latitude = -0.21516637184742038;
          PSV.rotate(PSVPosition, false);
          PSV.zoom(0);
          PSV.hud.addMarker({
            id: 'esculturaGranTintero',
            longitude: 3.2342207449194604,
            latitude: 0.08875877000198251,
            width: 35,
            height: 35,
            image:
              '../../../assets/images/tuCamara/Recorrido_360/flechaRecorrido.png',
            anchor: 'bottom center',
            tooltip: 'Escultura "El gran Tintero"',
          });
          PSV.hud.addMarker({
            id: 'muralConstituyentes',
            longitude: 1.5223912058039737,
            latitude: -0.027386768226888902,
            width: 35,
            height: 35,
            image:
              '../../../assets/images/tuCamara/Recorrido_360/flechaRecorrido.png',
            anchor: 'bottom center',
            tooltip: 'Mural Constituyentes',
          });
          PSV.hud.addMarker({
            id: 'rotativaConstituyente',
            longitude: 0.058972,
            latitude: 0.046959,
            width: 35,
            height: 35,
            image:
              '../../../assets/images/tuCamara/Recorrido_360/flechaRecorrido.png',
            anchor: 'bottom center',
            tooltip: 'Rotativa "Constituyente"',
          });
          PSV.hud.addMarker({
            id: 'muralCongresosConstituyentes',
            longitude: 4.948734,
            latitude: 0.054189,
            width: 35,
            height: 35,
            image:
              '../../../assets/images/tuCamara/Recorrido_360/flechaRecorrido.png',
            anchor: 'bottom center',
            tooltip: 'Mural "Los Congresos Constituyentes"',
          });
          PSV.hud.addMarker({
            id: 'museo',
            longitude: 6.026478104911444,
            latitude: -0.010626900807502171,
            width: 35,
            height: 35,
            image:
              '../../../assets/images/tuCamara/Recorrido_360/flechaRecorrido.png',
            anchor: 'bottom center',
            tooltip: 'Museo',
          });
          PSV.hud.addMarker({
            id: 'muralSintoniaEcotropica',
            longitude: 0.6918115810780606,
            latitude: -0.00614518048456425,
            width: 35,
            height: 35,
            image:
              '../../../assets/images/tuCamara/Recorrido_360/flechaRecorrido.png',
            anchor: 'bottom center',
            tooltip: 'Mural "Sintonía Ecotrópica"',
          });
          break;

        case 'esculturaGranTintero':
          PSV.clearMarkers(true);
          PSV.setPanorama(
            '../../../assets/images/tuCamara/Recorrido_360/2.jpg',
            null,
            false
          );
          PSVPosition.longitude = 0.13222660896515154;
          PSVPosition.latitude = 0.012599095537853877;
          PSV.rotate(PSVPosition, false);
          PSV.zoom(0);
          PSV.hud.addMarker({
            id: 'explanada',
            longitude: 3.2188688877984792,
            latitude: -0.09537755607078768,
            width: 35,
            height: 35,
            image:
              '../../../assets/images/tuCamara/Recorrido_360/flechaRecorrido.png',
            anchor: 'bottom center',
            tooltip: 'Explanada H. Cámara de Diputados',
          });
          PSV.hud.addMarker({
            id: 'muralConstituyentes',
            longitude: 4.209890860350809,
            latitude: 0.05820170155032334,
            width: 35,
            height: 35,
            image:
              '../../../assets/images/tuCamara/Recorrido_360/flechaRecorrido.png',
            anchor: 'bottom center',
            tooltip: 'Mural Constituyentes',
          });
          PSV.hud.addMarker({
            id: 'rotativaConstituyente',
            longitude: 3.237896675724882,
            latitude: 0.08500046595916633,
            width: 35,
            height: 35,
            image:
              '../../../assets/images/tuCamara/Recorrido_360/flechaRecorrido.png',
            anchor: 'bottom center',
            tooltip: 'Rotativa "Constituyente"',
          });
          PSV.hud.addMarker({
            id: 'muralCongresosConstituyentes',
            longitude: 2.270946243714126,
            latitude: 0.02677443127399548,
            width: 35,
            height: 35,
            image:
              '../../../assets/images/tuCamara/Recorrido_360/flechaRecorrido.png',
            anchor: 'bottom center',
            tooltip: 'Mural "Los Congresos Constituyentes"',
          });
          PSV.hud.addMarker({
            id: 'museo',
            longitude: 3.0393513999975976,
            latitude: 0.025438365261818863,
            width: 35,
            height: 35,
            image:
              '../../../assets/images/tuCamara/Recorrido_360/flechaRecorrido.png',
            anchor: 'bottom center',
            tooltip: 'Museo',
          });
          PSV.hud.addMarker({
            id: 'muralSintoniaEcotropica',
            longitude: 3.7115683576771694,
            latitude: 0.063396732888646,
            width: 35,
            height: 35,
            image:
              '../../../assets/images/tuCamara/Recorrido_360/flechaRecorrido.png',
            anchor: 'bottom center',
            tooltip: 'Mural "Sintonía Ecotrópica"',
          });
          break;

        case 'muralConstituyentes':
          PSV.clearMarkers(true);
          PSV.setPanorama(
            '../../../assets/images/tuCamara/Recorrido_360/3.jpg',
            null,
            false
          );
          PSVPosition.longitude = 0.15106475704622846;
          PSVPosition.latitude = -0.03059780344901153;
          PSV.rotate(PSVPosition, false);
          PSV.zoom(0);
          PSV.hud.addMarker({
            id: 'explanada',
            longitude: 3.186732353594237,
            latitude: -0.02175694142931084,
            width: 35,
            height: 35,
            image:
              '../../../assets/images/tuCamara/Recorrido_360/flechaRecorrido.png',
            anchor: 'bottom center',
            tooltip: 'Explanada H. Cámara de Diputados',
          });
          PSV.hud.addMarker({
            id: 'esculturaGranTintero',
            longitude: 2.478895447806331,
            latitude: 0.03982308051143346,
            width: 35,
            height: 35,
            image:
              '../../../assets/images/tuCamara/Recorrido_360/flechaRecorrido.png',
            anchor: 'bottom center',
            tooltip: 'Escultura "El gran Tintero"',
          });
          PSV.hud.addMarker({
            id: 'rotativaConstituyente',
            longitude: 4.143914082416901,
            latitude: 0.1456428570701258,
            width: 35,
            height: 35,
            image:
              '../../../assets/images/tuCamara/Recorrido_360/flechaRecorrido.png',
            anchor: 'bottom center',
            tooltip: 'Rotativa "Constituyente"',
          });
          PSV.hud.addMarker({
            id: 'muralCongresosConstituyentes',
            longitude: 3.2994179227221085,
            latitude: 0.07820184726797019,
            width: 35,
            height: 35,
            image:
              '../../../assets/images/tuCamara/Recorrido_360/flechaRecorrido.png',
            anchor: 'bottom center',
            tooltip: 'Mural "Los Congresos Constituyentes"',
          });
          PSV.hud.addMarker({
            id: 'recinto',
            longitude: 0.23717787051468542,
            latitude: 0.4949996871744251,
            width: 35,
            height: 35,
            image:
              '../../../assets/images/tuCamara/Recorrido_360/flechaRecorrido.png',
            anchor: 'bottom center',
            tooltip: 'Recinto',
          });
          PSV.hud.addMarker({
            id: 'museo',
            longitude: 4.035967231894444,
            latitude: 0.08837372890740358,
            width: 35,
            height: 35,
            image:
              '../../../assets/images/tuCamara/Recorrido_360/flechaRecorrido.png',
            anchor: 'bottom center',
            tooltip: 'Museo',
          });
          PSV.hud.addMarker({
            id: 'muralSintoniaEcotropica',
            longitude: 4.95324783022405,
            latitude: 0.05426761568994842,
            width: 35,
            height: 35,
            image:
              '../../../assets/images/tuCamara/Recorrido_360/flechaRecorrido.png',
            anchor: 'bottom center',
            tooltip: 'Mural "Sintonía Ecotrópica"',
          });
          break;

        case 'rotativaConstituyente':
          PSV.clearMarkers(true);
          PSV.setPanorama(
            '../../../assets/images/tuCamara/Recorrido_360/4.jpg',
            null,
            false
          );
          PSVPosition.longitude = 3.190450522552322;
          PSVPosition.latitude = -0.09342965652080659;
          PSV.rotate(PSVPosition, false);
          PSV.zoom(0);
          PSV.hud.addMarker({
            id: 'explanada',
            longitude: 1.8448575340726117,
            latitude: -0.010167014152426157,
            width: 35,
            height: 35,
            image:
              '../../../assets/images/tuCamara/Recorrido_360/flechaRecorrido.png',
            anchor: 'bottom center',
            tooltip: 'Explanada H. Cámara de Diputados',
          });
          PSV.hud.addMarker({
            id: 'esculturaGranTintero',
            longitude: 1.7553710929296713,
            latitude: 0.021865697944960072,
            width: 35,
            height: 35,
            image:
              '../../../assets/images/tuCamara/Recorrido_360/flechaRecorrido.png',
            anchor: 'bottom center',
            tooltip: 'Escultura "El gran Tintero"',
          });
          PSV.hud.addMarker({
            id: 'muralConstituyentes',
            longitude: 0.9192898409985267,
            latitude: -0.01570370995068071,
            width: 35,
            height: 35,
            image:
              '../../../assets/images/tuCamara/Recorrido_360/flechaRecorrido.png',
            anchor: 'bottom center',
            tooltip: 'Mural Constituyentes',
          });
          PSV.hud.addMarker({
            id: 'muralCongresosConstituyentes',
            longitude: 2.54988861946939,
            latitude: 0.014945390850763518,
            width: 35,
            height: 35,
            image:
              '../../../assets/images/tuCamara/Recorrido_360/flechaRecorrido.png',
            anchor: 'bottom center',
            tooltip: 'Mural "Los Congresos Constituyentes"',
          });
          PSV.hud.addMarker({
            id: 'museo',
            longitude: 3.7609559693444936,
            latitude: -0.020723121365915587,
            width: 35,
            height: 35,
            image:
              '../../../assets/images/tuCamara/Recorrido_360/flechaRecorrido.png',
            anchor: 'bottom center',
            tooltip: 'Museo',
          });
          PSV.hud.addMarker({
            id: 'muralSintoniaEcotropica',
            longitude: 6.077362324917804,
            latitude: -0.025124424475843066,
            width: 35,
            height: 35,
            image:
              '../../../assets/images/tuCamara/Recorrido_360/flechaRecorrido.png',
            anchor: 'bottom center',
            tooltip: 'Mural "Sintonía Ecotrópica"',
          });
          break;

        case 'muralCongresosConstituyentes':
          PSV.clearMarkers(true);
          PSV.setPanorama(
            '../../../assets/images/tuCamara/Recorrido_360/5.jpg',
            null,
            false
          );
          PSVPosition.longitude = 6.273206496140601;
          PSVPosition.latitude = 0.36144677978022477;
          PSV.rotate(PSVPosition, false);
          PSV.zoom(0);
          PSV.hud.addMarker({
            id: 'explanada',
            longitude: 6.271121286808195,
            latitude: -0.07310931807779664,
            width: 35,
            height: 35,
            image:
              '../../../assets/images/tuCamara/Recorrido_360/flechaRecorrido.png',
            anchor: 'bottom center',
            tooltip: 'Explanada H. Cámara de Diputados',
          });
          break;

        case 'muralConstitucionesMexico':
          PSV.clearMarkers(true);
          PSV.setPanorama(
            '../../../assets/images/tuCamara/Recorrido_360/6.jpg',
            null,
            false
          );
          PSVPosition.longitude = 6.248296234451496;
          PSVPosition.latitude = 0.2599995170080316;
          PSV.rotate(PSVPosition, false);
          PSV.zoom(0);
          PSV.hud.addMarker({
            id: 'recinto',
            longitude: 6.157307609347045,
            latitude: -0.2243318190238368,
            width: 35,
            height: 35,
            image:
              '../../../assets/images/tuCamara/Recorrido_360/flechaRecorrido.png',
            anchor: 'bottom center',
            tooltip: 'Recinto',
          });
          PSV.hud.addMarker({
            id: 'muralPluralismoPolitico',
            longitude: 3.2387643772892596,
            latitude: -0.1483193376842733,
            width: 35,
            height: 35,
            image:
              '../../../assets/images/tuCamara/Recorrido_360/flechaRecorrido.png',
            anchor: 'bottom center',
            tooltip: 'Mural "El Pluralismo Político',
          });
          break;

        case 'muralPluralismoPolitico':
          PSV.clearMarkers(true);
          PSV.setPanorama(
            '../../../assets/images/tuCamara/Recorrido_360/7.jpg',
            null,
            false
          );
          PSVPosition.longitude = 3.186340285586057;
          PSVPosition.latitude = 0.3738822507006683;
          PSV.rotate(PSVPosition, false);
          PSV.zoom(0);
          PSV.hud.addMarker({
            id: 'muralConstitucionesMexico',
            longitude: 3.199615693530096,
            latitude: -0.06600647368803214,
            width: 35,
            height: 35,
            image:
              '../../../assets/images/tuCamara/Recorrido_360/flechaRecorrido.png',
            anchor: 'bottom center',
            tooltip: 'Mural "Las Constituciones de México"',
          });
          break;

        case 'recinto':
          PSV.clearMarkers(true);
          PSV.setPanorama(
            '../../../assets/images/tuCamara/Recorrido_360/8.jpg',
            null,
            false
          );
          PSVPosition.longitude = 3.072688040081021;
          PSVPosition.latitude = 0.19716766393623963;
          PSV.rotate(PSVPosition, false);
          PSV.zoom(0);
          PSV.hud.addMarker({
            id: 'explanada',
            longitude: 2.7467367261339692,
            latitude: -0.03317654886638355,
            width: 35,
            height: 35,
            image:
              '../../../assets/images/tuCamara/Recorrido_360/flechaRecorrido.png',
            anchor: 'bottom center',
            tooltip: 'Explanada H. Cámara de Diputados',
          });
          PSV.hud.addMarker({
            id: 'muralConstitucionesMexico',
            longitude: 6.268668519454272,
            latitude: 0.08473995575345517,
            width: 35,
            height: 35,
            image:
              '../../../assets/images/tuCamara/Recorrido_360/flechaRecorrido.png',
            anchor: 'bottom center',
            tooltip: 'Mural "Las Constituciones de México"',
          });
          PSV.hud.addMarker({
            id: 'banderas',
            longitude: 3.136349642841649,
            latitude: -0.04013446085788086,
            width: 35,
            height: 35,
            image:
              '../../../assets/images/tuCamara/Recorrido_360/flechaRecorrido.png',
            anchor: 'bottom center',
            tooltip: 'Banderas',
          });
          break;

        case 'banderas':
          PSV.clearMarkers(true);
          PSV.setPanorama(
            '../../../assets/images/tuCamara/Recorrido_360/9.jpg',
            null,
            false
          );
          PSVPosition.longitude = 0.06300793492980063;
          PSVPosition.latitude = 0.0479420128907333;
          PSV.rotate(PSVPosition, false);
          PSV.zoom(0);
          PSV.hud.addMarker({
            id: 'explanada',
            longitude: 1.9236521871417473,
            latitude: -0.12839270230838684,
            width: 35,
            height: 35,
            image:
              '../../../assets/images/tuCamara/Recorrido_360/flechaRecorrido.png',
            anchor: 'bottom center',
            tooltip: 'Explanada H. Cámara de Diputados',
          });
          PSV.hud.addMarker({
            id: 'muralConstitucionesMexico',
            longitude: 0.06006958088883016,
            latitude: 0.012967367983013567,
            width: 35,
            height: 35,
            image:
              '../../../assets/images/tuCamara/Recorrido_360/flechaRecorrido.png',
            anchor: 'bottom center',
            tooltip: 'Mural "Las Constituciones de México"',
          });
          PSV.hud.addMarker({
            id: 'recinto',
            longitude: 0.06924688698137146,
            latitude: -0.2018337575807656,
            width: 35,
            height: 35,
            image:
              '../../../assets/images/tuCamara/Recorrido_360/flechaRecorrido.png',
            anchor: 'bottom center',
            tooltip: 'Recinto',
          });
          break;

        case 'museo':
          PSV.clearMarkers(true);
          PSV.setPanorama(
            '../../../assets/images/tuCamara/Recorrido_360/10.jpg',
            null,
            false
          );
          PSVPosition.longitude = 6.0252622461493415;
          PSVPosition.latitude = -0.07379470243587782;
          PSV.rotate(PSVPosition, false);
          PSV.zoom(0);
          PSV.hud.addMarker({
            id: 'explanada',
            longitude: 5.585107615920572,
            latitude: 0.13811163065842735,
            width: 35,
            height: 35,
            image:
              '../../../assets/images/tuCamara/Recorrido_360/flechaRecorrido.png',
            anchor: 'bottom center',
            tooltip: 'Explanada H. Cámara de Diputados',
          });
          PSV.hud.addMarker({
            id: 'muralSintoniaEcotropica',
            longitude: 4.051822143967372,
            latitude: 0.04676658315933224,
            width: 35,
            height: 35,
            image:
              '../../../assets/images/tuCamara/Recorrido_360/flechaRecorrido.png',
            anchor: 'bottom center',
            tooltip: 'Mural "Sintonía Ecotrópica"',
          });
          break;

        case 'muralSintoniaEcotropica':
          PSV.clearMarkers(true);
          PSV.setPanorama(
            '../../../assets/images/tuCamara/Recorrido_360/11.jpg',
            null,
            false
          );
          PSVPosition.longitude = 3.0226850876896503;
          PSVPosition.latitude = 0.04401502207374541;
          PSV.rotate(PSVPosition, false);
          PSV.zoom(0);
          PSV.hud.addMarker({
            id: 'explanada',
            longitude: 4.850872301377285,
            latitude: -0.032892690163282,
            width: 35,
            height: 35,
            image:
              '../../../assets/images/tuCamara/Recorrido_360/flechaRecorrido.png',
            anchor: 'bottom center',
            tooltip: 'Explanada H. Cámara de Diputados',
          });
          PSV.hud.addMarker({
            id: 'muralConstituyentes',
            longitude: 4.570666352670047,
            latitude: -0.027642601478083728,
            width: 35,
            height: 35,
            image:
              '../../../assets/images/tuCamara/Recorrido_360/flechaRecorrido.png',
            anchor: 'bottom center',
            tooltip: 'Mural Constituyentes',
          });
          PSV.hud.addMarker({
            id: 'museo',
            longitude: 0.16540966491162695,
            latitude: 0.18846616573636932,
            width: 35,
            height: 35,
            image:
              '../../../assets/images/tuCamara/Recorrido_360/flechaRecorrido.png',
            anchor: 'bottom center',
            tooltip: 'Museo',
          });
          break;
        default:
        // console.log('Marcador seleccionado: ' + marker.id); // KYBC.Borrar.
      }
    });

    //  KYBC.Login 'Mi Cámara' FormGroup.
    this.LoginMiCamaraFormGroup = new FormGroup({
      loginForm: new FormControl('', [
        Validators.required,
        Validators.pattern(
          /^([a-zA-Z0-9_\-.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/
          ///^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/ DLG.FIX
        ),
      ]),
      passwordForm: new FormControl('', [Validators.required]),
    });

    // KYBC.Call function for fetch all events from DB.
    this.fetchAllEventsFromDB();

    // KYBC.Call function for fetch all sitios de interes from DB.
    this.fetchAllSitiosInteresFromDB();

    // KYBC.Call function for fetch all legislaturas from DB.
    this.fetchAllLegislaturas();
  }

  openDialog(): any {
    const dialogRef = this.dialog.open(Beneficios, {});
  }

  openMapa(): any {
    const dialogRef = this.dialog.open(Mapa, {});
  }

  ngAfterViewInit(): any {
    this.proximosEventosSliderViewChildren.changes.subscribe((t: any) => {
      this.proximosEventosSwiper = new Swiper('.swiperProximosEventos', {
        updateOnWindowResize: true, // KYBC.Swiper will recalculate slides position on window resize (orientationchange).
        initialSlide: 0, // KYBC.	Index number of initial slide.
        direction: 'horizontal', // KYBC.Could be 'horizontal' or 'vertical' (for vertical slider).
        speed: 300, // KYBC.Duration of transition between slides (in ms).
        effect: 'slide', // KYBC.Tranisition effect. Could be "slide", "fade", "cube", "coverflow" or "flip".
        spaceBetween: 10, // KYBC.Distance between slides in px.
        slidesPerView: 5, // KYBC.Number of slides per view (slides visible at the same time on slider's container).
        loop: true, // KYBC.Set to true to enable continuous loop mode.
        autoplay: {
          delay: 2500,
          disableOnInteraction: true,
        },
      });
    });

    this.tuCamaraTiempoThumbsSliderViewChildren.changes.subscribe((t: any) => {
      this.tuCamaraTiempoThumbsSwiper = new Swiper(
        '.swiperTuCamaraTiempoThumbs',
        {
          spaceBetween: 5,
          slidesPerView: 15,
          loop: true,
          freeMode: true,
          loopedSlides: 5, //looped slides should be the same
          watchSlidesVisibility: true,
          watchSlidesProgress: true,
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          },
          breakpoints: {
            // when window width is >= 10px
            10: {
              slidesPerView: 5,
            },
            // when window width is >= 480px
            480: {
              slidesPerView: 8,
            },
            // when window width is >= 640px
            640: {
              slidesPerView: 10,
            },
            // when window width is >= 1000px
            1000: {
              slidesPerView: 15,
            },
          },
        }
      );
    });

    this.tuCamaraTiempoSliderViewChildren.changes.subscribe((t: any) => {
      this.tuCamaraTiempoSwiper = new Swiper('.swiperTuCamaraTiempo', {
        spaceBetween: 10,
        loop: true,
        loopedSlides: 5, //looped slides should be the same
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        thumbs: {
          swiper: this.tuCamaraTiempoThumbsSwiper,
        },
      });
    });
  }

  ngOnDestroy(): any {
    console.log('onDestroy'); //KYBC. Borrar
    this.sub.unsubscribe();
  }

  // KYBC.Function for verify the visitant session.
  verifyVisitantSession(): any {
    this.correoVisitante = localStorage.getItem('HCD_visitantEmail');
    this.visitantActiveSession = localStorage.getItem(
      'HCD_activeSessionVisitant'
    );
    console.log(this.visitantActiveSession);
  }

  // KYBC.Function for login a visitant in 'Mi Cámara'.
  loginMiCamaraVisitant(): any {
    this.querySubscription = this.apollo
      .watchQuery({
        query: loginMiCamaraVisitantQuery,
        variables: {
          Correo: this.LoginMiCamaraFormGroup.value.loginForm,
          Password: this.LoginMiCamaraFormGroup.value.passwordForm,
          Celular: '',
        },
      })
      .valueChanges.subscribe(({ data }) => {
        // console.log('::::::::::loginMiCamaraVisitant Data from DB: \n', data); // KYBC.Borrar.
        this.visitantDataFromDB = data;
        this.visitantDataFromDB = this.visitantDataFromDB.loginMiCamaraVisitant;
        // console.log(
        //   '::::::::::loginMiCamaraVisitant from DB: \n',
        //   this.visitantDataFromDB
        // ); // KYBC.Borrar.
        if (this.visitantDataFromDB == null) {
          this.openSnackBar(
            'El correo o la contraseña son incorrectos. Favor de validar sus datos',
            'Acceptar'
          );
          this.clearLoginMiCamaraFormGroup();
          // } else if (this.visitantDataFromDB == null) {
          //   this.openSnackBar(
          //     'El correo no existe en la BD por favor registrese.',
          //     'Registrarme'
          //   );
          //   this.snackBarRef.afterDismissed().subscribe(() => {
          //     // console.log('::::::::::The snack-bar was dismissed'); // KYBC.Borrar.
          //     this.openRegisterModal();
          //   });
          //   this.clearLoginMiCamaraFormGroup();
        } else {
          //  console.log('se va a settear', this.visitantDataFromDB.Correo);
          localStorage.setItem('HCD_activeSessionVisitant', 'true');
          localStorage.setItem(
            'HCD_visitantEmail',
            this.visitantDataFromDB.Correo
          );
          this._router.navigateByUrl('/inicio/tuCamara/micamara');
          // setTimeout(() => {
          //   // window.location.reload();
          // }, 500);
        }
      });
  }

  // KYBC.Logout Mi Cámara Visitant
  logoutMiCamaraVisitant(): any {
    console.log('::::::::::Visitant logged out!');
    localStorage.removeItem('HCD_activeSessionVisitant');
    localStorage.removeItem('HCD_visitantEmail');
    this.visitantActiveSession = 'false';
    this.clearLoginMiCamaraFormGroup();
    this._router.navigateByUrl('/inicio/tuCamara/');
  }

  // KYBC.Clear the loginForm.
  clearLoginMiCamaraFormGroup(): any {
    this.LoginMiCamaraFormGroup.patchValue({
      loginForm: '',
      passwordForm: '',
    });
  }

  // KYBC.Opens register modal for register a new visitant in 'Mi Cámara'.
  openRegisterModal(): void {
    const dialogRef = this.dialog.open(MiCamaraRegisterDialog, {
      width: '50%',
      height: '60%',
      data: { modalInfographicBase64: 'hola' },
    });
    dialogRef.afterClosed().subscribe(() => {
      // console.log('::::::::::Modal closed!');
      this.clearLoginMiCamaraFormGroup();
      this.verifyVisitantSession();
    });
  }

  // KYBC.Opens register modal for register a new visitant in 'Mi Cámara'.
  openCambiarContraseniaModal(): void {
    const dialogRef = this.dialog.open(MiCamaraCambiarContraseniaDialog, {
      width: '50%',
      height: '60%',
      data: { modalInfographicBase64: 'hola' },
    });
    dialogRef.afterClosed().subscribe(() => {
      // console.log('::::::::::Modal closed!');
      this.clearLoginMiCamaraFormGroup();
      this.verifyVisitantSession();
    });
  }
  // KYBC.Opens register modal for register a new visitant in 'Mi Cámara'.
  openConfigModal(): void {
    const dialogRef = this.dialog.open(MiCamaraConfigDialog, {
      width: '50%',
      height: '40%',
      data: { modalInfographicBase64: 'hola' },
    });
    dialogRef.afterClosed().subscribe(() => {
      // console.log('::::::::::Modal closed!');
    });
  }

  // KYBC.Function for show a SnackBar with a message to the user.
  openSnackBar(mensaje: string, accion: string): any {
    this.snackBarRef = this._snackBar.open(mensaje, accion, {
      // duration: 5000, // KYBC.Time in milliseconds.
      verticalPosition: 'top', // KYBC.Posible values: 'top' | 'bottom'.
      horizontalPosition: 'center', // KYBC.Posible values: 'start' | 'center' | 'end' | 'left' | 'right'.
    });
  }

  // KYBC.Fetch all events from DB.
  fetchAllEventsFromDB(): any {
    this.sub = this.apollo
      .watchQuery<Response>({
        query: gql`
          {
            allEvents {
              Oid
              Nombre
              Descripcion
              Ubicacion
              FechaInicio
              FechaFin
              TipoEvento
              imagedata
              linkSitio
            }
          }
        `,
      })
      .valueChanges.subscribe((result) => {
        // console.log('::::::::::Result from DB: \n', result); // KYBC.Borrar.
        this.eventos = result.data && result.data.allEvents;
        //  console.log('::::::::::allEvents from DB: \n', this.eventos); // KYBC.Borrar.
        this.loading = result.loading;
        this.error = result['error'];
      });
  }

  // KYBC.Fetch all sitios de interes from DB.
  fetchAllSitiosInteresFromDB(): any {
    this.sub = this.apollo
      .watchQuery<Response>({
        query: gql`
          {
            allSitiosInteres {
              Oid
              Nombre
              linkSitio
              Descripcion
              ImageBase64
            }
          }
        `,
      })
      .valueChanges.subscribe((result) => {
        // console.log('::::::::::Result from DB: \n', result); // KYBC.Borrar.
        this.sitiosInteres = result.data && result.data.allSitiosInteres;
        this.sitiosInteresLoading = result.loading;
        this.sitiosInteresError = result['error'];
      });
  }

  // KYBC.Fetch all Legislaturas from DB.
  fetchAllLegislaturas(): any {
    this.sub = this.apollo
      .watchQuery<Response>({
        query: gql`
          {
            allLegislaturas {
              Oid
              Numero
              Legislatura
              ImagenLBase64
              Descripcion
            }
          }
        `,
      })
      .valueChanges.subscribe((result) => {
        // console.log(::::::::::result: \n, result); // KYBC.Borrar.
        this.legislaturas = result.data && result.data.allLegislaturas;
        //   console.log('::::::::::allLegislaturas from DB: \n', this.legislaturas); // KYBC.Borrar.
        this.cargando = false;
        this.loading = result.loading;
        this.error = result['error'];
      });
  }

  // KYBC.Functionality 'Suscribir Boletín Informátivo'.
  // suscribirBoletin() {
  //   this.apollo.mutate({
  //     mutation: addEmailMutation,
  //     variables: {
  //       Correo: this.LoginMiCamaraFormGroup.value.emailForm
  //     }
  //   }).subscribe(({ data }) => {
  //     console.log('Got data: ', data); // KYBC.Borrar.
  //     alert('El correo: ' + this.LoginMiCamaraFormGroup.value.emailForm +
  //       ' fue agregado exitosamente a la lista de distribución del Boletín Informativo de la H. Càmara de Diputados.');
  //   }, (error) => {
  //     console.log('Error al agregar el correo a la lista de distribución: ', error);
  //   });
  // }

  sliderPrevNext(swiper: string, navigationOption: string): any {
    switch (swiper) {
      case 'swiperProximosEventos':
        if (navigationOption == 'prev') {
          this.proximosEventosSwiper.slidePrev();
        }
        if (navigationOption == 'next') {
          this.proximosEventosSwiper.slideNext();
        }
        break;
      case 'swiperTuCamaraTiempoThumbs':
        if (navigationOption == 'prev') {
          this.tuCamaraTiempoThumbsSwiper.slidePrev();
          this.tuCamaraTiempoSwiper.slidePrev();
        }
        if (navigationOption == 'next') {
          this.tuCamaraTiempoThumbsSwiper.slideNext();
          this.tuCamaraTiempoSwiper.slideNext();
        }
        break;
      case 'swiperTuCamaraTiempo':
        if (navigationOption == 'prev') {
          this.tuCamaraTiempoThumbsSwiper.slidePrev();
          this.tuCamaraTiempoSwiper.slidePrev();
        }
        if (navigationOption == 'next') {
          this.tuCamaraTiempoThumbsSwiper.slideNext();
          this.tuCamaraTiempoSwiper.slideNext();
        }
        break;
      default:
      // code block
    }
  }

  goToCredencialesLegislatura(Oid: any): any {
    this._router
      .navigate([
        '/inicio/tuCamara/credencialesLegislatura',
        {
          Oid: Oid,
        },
      ])
      .then((e) => {
        if (e) {
          //  console.log('Navegación Fallida', e);
        } else {
          //  console.log('Navegación Exitosa', e);
        }
      });
  }
}

const registerMiCamaraVisitantMutation = gql`
  mutation registerMiCamaraVisitant(
    $Correo: String!
    $Password: String!
    $Celular: String
  ) {
    registerMiCamaraVisitant(
      Correo: $Correo
      Password: $Password
      Celular: $Celular
    ) {
      Correo
    }
  }
`;

@Component({
  selector: 'miCamaraRegisterForm',
  templateUrl: 'miCamaraRegisterForm.html',
  styles: [
    `
      .loginButton {
        background-color: #183c34;
        color: white;
      }
    `,
  ],
})
export class MiCamaraRegisterDialog {
  registerMiCamaraFormGroup: FormGroup;
  snackBarRef: any;

  captcha: string;

  operation: number;
  message: string;

  vandera = false;

  constructor(
    private apollo: Apollo,
    //private api: ApiHomeService,
    //private router: Router,
    public dialogRef: MatDialogRef<MiCamaraRegisterDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private _snackBar: MatSnackBar
  ) {
    this.captcha = '6Lei6EwaAAAAAHvxJGzUaUPbnIqKMe1rSDyUbGwj';
    // this.url = "http://172.16.50.125:8003/api/EnvioMail";

   
    
  }

  ngOnInit(): any {
    //  KYBC.Call function for Register 'Mi Cámara' FormGroup.
    this.loadRegisterFormGroup();
    
  }

  handleSuccess(tokenCaptcha: any): void {
    this.vandera = true;
  }

  //  KYBC.Register 'Mi Cámara' FormGroup.
  loadRegisterFormGroup(): any {
    this.registerMiCamaraFormGroup = new FormGroup({
      registerEmailForm: new FormControl('', [
        Validators.required,
        Validators.pattern(
          /^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/
        ),
      ]),
      registerPasswordForm: new FormControl('', [Validators.required]),
      registerMobileForm: new FormControl('', [Validators.pattern('[0-9 ]*')]),
    });
  }
  private _router2: Router;

  // KYBC.Function for register a new visitant in 'Mi Cámara'.
  registerMiCamaraVisitant(): any {
    console.log('query: ', registerMiCamaraVisitantMutation);

    if (this.vandera === true) {
      this.apollo
        .mutate({
          mutation: registerMiCamaraVisitantMutation,
          variables: {
            Correo: this.registerMiCamaraFormGroup.value.registerEmailForm,
            Password: this.registerMiCamaraFormGroup.value.registerPasswordForm,
            Celular: this.registerMiCamaraFormGroup.value.registerMobileForm,
          },
        })
        .subscribe(
          ({ data }) => {
            localStorage.setItem('HCD_activeSessionVisitant', 'true');
            localStorage.setItem(
              'HCD_visitantEmail',
              this.registerMiCamaraFormGroup.value.registerEmailForm
            );
            this.openSnackBar(
              'El correo: ' +
              this.registerMiCamaraFormGroup.value.registerEmailForm +
              ' fue agregado exitosamente a la BD de la H. Càmara de Diputados.',
              'Aceptar'
            );
            this.onNoClick();
            this._router2.navigate(['/inicio/tuCamara']);
          },
          (error) => {
            this.openSnackBar(error, 'Aceptar');
          }
        );
    } else {
      this.operation = 3;
      this.message = 'Valide que no es un Robot';
    }
  }

  // KYBC.Function for show a SnackBar with a message to the user.
  openSnackBar(mensaje: string, accion: string): any {
    this.snackBarRef = this._snackBar.open(mensaje, accion, {
      duration: 5000, // KYBC.Time in milliseconds.
      verticalPosition: 'top', // KYBC.Posible values: 'top' | 'bottom'.
      horizontalPosition: 'center', // KYBC.Posible values: 'start' | 'center' | 'end' | 'left' | 'right'.
    });
  }

  // KYBC.Function for close modal.
  onNoClick(): void {
    this.dialogRef.close();
    // console.log('::::::::::Modal closed!'); // KYBC.Borrar.
  }
}

const CambiarContraseniaMiCamaraVisitantMutation = gql`
  mutation olvideContraseniaMiCamaraVisitant(
    $Correo: String!
    $Password: String!
  ) {
    olvideContraseniaMiCamaraVisitant(Correo: $Correo, Password: $Password) {
      Correo
      Password
    }
  }
`;

@Component({
  selector: 'miCamaraCambiarContraseniaForm',
  templateUrl: 'miCamaraCambiarContraseniaForm.html',
})
export class MiCamaraCambiarContraseniaDialog {
  CambiarContraseniaMiCamaraFormGroup: FormGroup;
  snackBarRef: any;

  constructor(
    private apollo: Apollo,
    public dialogRef: MatDialogRef<MiCamaraCambiarContraseniaDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private _snackBar: MatSnackBar,
    public dialog: MatDialog
  ) { }

  ngOnInit(): any {
    //  KYBC.Call function for CambiarContrasenia 'Mi Cámara' FormGroup.
    this.loadCambiarContraseniaFormGroup();
  }

  //  KYBC.CambiarContrasenia 'Mi Cámara' FormGroup.
  loadCambiarContraseniaFormGroup(): any {
    this.CambiarContraseniaMiCamaraFormGroup = new FormGroup({
      CambiarContraseniaEmailForm: new FormControl('', [
        Validators.required,
        Validators.pattern(
          /^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/
        ),
      ]),
      CambiarContraseniaPasswordForm: new FormControl('', [
        Validators.required,
      ]),
    });
  }
  private _router2: Router;
  // KYBC.Function for CambiarContrasenia a new visitant in 'Mi Cámara'.
  CambiarContraseniaMiCamaraVisitant(): any {
    console.log('query: ', CambiarContraseniaMiCamaraVisitantMutation);
    this.apollo
      .mutate({
        mutation: CambiarContraseniaMiCamaraVisitantMutation,
        variables: {
          Correo: this.CambiarContraseniaMiCamaraFormGroup.value
            .CambiarContraseniaEmailForm,
          Password: this.CambiarContraseniaMiCamaraFormGroup.value
            .CambiarContraseniaPasswordForm,
        },
      })
      .subscribe(
        ({ data }) => {
          localStorage.setItem('HCD_activeSessionVisitant', 'true');
          localStorage.setItem(
            'HCD_visitantEmail',
            this.CambiarContraseniaMiCamaraFormGroup.value
              .CambiarContraseniaEmailForm
          );
          this.openSnackBar(
            'El Nuevo NIP se envio al correo: ' +
            this.CambiarContraseniaMiCamaraFormGroup.value
              .CambiarContraseniaEmailForm,
            'Aceptar'
          );
          this.onNoClick();
          this._router2.navigate(['/inicio/tuCamara']);
        },
        (error) => {
          this.openSnackBar(
            'El correo no existe en la BD por favor registrese.',
            'Registrarme'
          );
          this.snackBarRef.afterDismissed().subscribe(() => {
            // console.log('::::::::::The snack-bar was dismissed'); // KYBC.Borrar.
            this.onNoClick();
            this.openRegisterModal();
          });
          this._router2.navigate(['/inicio/tuCamara']);
        }
      );
  }

  // KYBC.Opens register modal for register a new visitant in 'Mi Cámara'.
  openRegisterModal(): void {
    const dialogRef = this.dialog.open(MiCamaraRegisterDialog, {
      width: '70%',
      height: '150%',
    });
    dialogRef.afterClosed().subscribe(() => {
      // console.log('::::::::::Modal closed!');
    });
  }

  // KYBC.Function for show a SnackBar with a message to the user.
  openSnackBar(mensaje: string, accion: string): any {
    this.snackBarRef = this._snackBar.open(mensaje, accion, {
      // duration: 5000, // KYBC.Time in milliseconds.
      verticalPosition: 'top', // KYBC.Posible values: 'top' | 'bottom'.
      horizontalPosition: 'center', // KYBC.Posible values: 'start' | 'center' | 'end' | 'left' | 'right'.
    });
  }

  // KYBC.Function for close modal.
  onNoClick(): void {
    this.dialogRef.close();
    // console.log('::::::::::Modal closed!'); // KYBC.Borrar.
  }
}
@Component({
  selector: 'app-beneficios',
  templateUrl: 'beneficios.html',
  styles: [``],
})
export class Beneficios {
  constructor(public dialogRef: MatDialogRef<Beneficios>) { }

  closeDialog(): any {
    this.dialogRef.close();
  }
}

@Component({
  selector: 'app-mapa',
  templateUrl: 'mapa.html',
  styles: [``],
})
export class Mapa {
  constructor(public dialogRef: MatDialogRef<Mapa>) { }

  closeDialog(): any {
    this.dialogRef.close();
  }
}

@Component({
  selector: 'app-mi-camara-config',
  templateUrl: 'miCamaraConfig.html',
  styles: [``],
})
export class MiCamaraConfigDialog implements OnInit {
  registerMiCamaraFormGroup: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<MiCamaraRegisterDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) { }

  ngOnInit(): any {
    console.log('::::::::::Ready');
  }

  // KYBC.Function for close modal.
  onNoClick(): void {
    this.dialogRef.close();
    // console.log('::::::::::Modal closed!'); // KYBC.Borrar.
  }
}
