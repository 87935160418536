import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListaIfadComponent } from './lista-ifad/lista-ifad.component';
import { IFADRoutingModule } from './ifad-routing.module';
import { IfadDetalleComponent } from './ifad-detalle/ifad-detalle.component';
import { MaterialModule } from 'src/app/shared/material/material.module';



@NgModule({
  declarations: [ListaIfadComponent, IfadDetalleComponent],
  imports: [
    CommonModule,
    IFADRoutingModule,
    MaterialModule,
  ]
})
export class IfadModule { }
