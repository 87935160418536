import { Component, OnInit, OnDestroy } from '@angular/core';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { ResponseReformasConstiTurnadas, TurnadasLegis } from '../leyes/leyes-vigentes/leyes-vigentes.interface';
import { GraphqlService } from '../../../services/graphql.service';
interface Aprobacion {
  Oid: string;
  NumeroDeProyecto: number;
  ProyectoDecreto: string;
  LinkProyectoDec: string;
  DiarioOficialFederacion: string;
  LinkDOF: string;
  Titulo: string;
  FechaTurnoLegisEstat: string;
  FechaDeclaratoriaCamDip: string;
  FechaDeclaratoriaCamSen: string;
  AFavor: number;
  EnContra: number;
  Legislacion: string;
  Legis: string;
}

interface TitMinutaLegis {
  aprobacion: Aprobacion[];
  Oid: string;
  Numlegislatura: string;
  Titulo: string;
  Subtitulo: string;
}
interface ResponseAprobacion {
  allAprobacionesConst: Aprobacion[];
  allAprobacionesLegisActual: Aprobacion[];
}
interface ResonseTitMinutas {
  allTitulosLegislaturasMinutas: TitMinutaLegis[];
  titulolegilaturaActualMinutas: TitMinutaLegis[];
}

@Component({
  selector: 'app-aprobaciones-constitucionales',
  templateUrl: './aprobaciones-constitucionales.component.html',
  styleUrls: ['./aprobaciones-constitucionales.component.scss'],
})
export class AprobacionesConstitucionalesComponent
  implements OnInit, OnDestroy {
  minutasTitLegisArr: TitMinutaLegis[];
  ultimaLegisTituloarr: TitMinutaLegis[];
  ultimaLegisMinutasArr: Aprobacion[];

  subLegislaturaActualMinutas: any;
  subLegislaturaActualTitulo: any;
  subTitLegislaturasMinutas: any;

  loading = false;
  error: any;
  loading2 = true;
  subReformasConstiTurnadas: any;
  ReformasConstTurnadas: TurnadasLegis[];
  visitas: any;
  constructor(private apollo: Apollo, private api: GraphqlService) {}
  ngOnInit(): void {
    this.addViewToPage('a1227d23-c68c-4881-aa42-bd94ecac9177');
    this.subReformasConstiTurnadas = this.apollo
      .watchQuery<ResponseReformasConstiTurnadas>({
        query: gql`
          {
            ReformasConstiTurnadas {
              Id
              Nombre
              LinkProyecto
              TurnoCDstr
              DeclaratoriaCDstr
              DeclaratoriaCSstr
              FechaDOFstr
              LinkDOF
              Aprobacion
              AprobacionF
            }
          }
        `,
      })
      .valueChanges.subscribe((result) => {
        this.ReformasConstTurnadas =
          result.data && result.data.ReformasConstiTurnadas;
        this.loading2 = result.loading;
        this.error = result.errors;
      });
    this.subLegislaturaActualMinutas = this.apollo
      .watchQuery<ResponseAprobacion>({
        query: gql`
          {
            allAprobacionesLegisActual {
              Oid
              NumeroDeProyecto
              ProyectoDecreto
              LinkProyectoDec
              DiarioOficialFederacion
              LinkDOF
              Titulo
              FechaTurnoLegisEstat
              FechaDeclaratoriaCamDip
              FechaDeclaratoriaCamSen
              AFavor
              EnContra
            }
          }
        `,
      })
      .valueChanges.subscribe((result) => {
        this.ultimaLegisMinutasArr =
          result.data && result.data.allAprobacionesLegisActual;
        this.loading = result.loading;
        // console.log('xxx', this.ultimaLegisMinutasArr)
        this.error = result.errors;
      });
    this.subTitLegislaturasMinutas = this.apollo
      .watchQuery<ResonseTitMinutas>({
        query: gql`
          {
            allTitulosLegislaturasMinutas {
              Oid
              Numlegislatura
              Titulo
              Subtitulo
            }
          }
        `,
      })
      .valueChanges.subscribe((result) => {
        this.minutasTitLegisArr =
          result.data && result.data.allTitulosLegislaturasMinutas;
        console.log('legislaturas', this.minutasTitLegisArr);
        this.loading = result.loading;
        this.error = result.errors;
      });
    this.subLegislaturaActualTitulo = this.apollo
      .watchQuery<ResonseTitMinutas>({
        query: gql`
          {
            titulolegilaturaActualMinutas {
              Titulo
              Subtitulo
            }
          }
        `,
      })
      .valueChanges.subscribe((result) => {
        this.ultimaLegisTituloarr =
          result.data && result.data.titulolegilaturaActualMinutas;
        this.loading = result.loading;
        // console.log('yyy', this.ultimaLegisTituloarr)
        this.error = result['error'];
      });
  }

  ngOnDestroy(): void {}
  addViewToPage(Oid: any): any {
    this.api.addViewToPage(Oid)
      .subscribe(
        ({ data }: any) => {
          this.visitas = data.addView.Visitas;
        },
        (error) => {
          console.log( ':Error addView: ',  error);
        }
      );
  }
  getAprobaciones(legislatura: TitMinutaLegis) {
    console.log('el oid que paso es:', legislatura.Oid);
    const query = this.apollo
      .watchQuery<ResponseAprobacion>({
        query: gql`
          {
            allAprobacionesConst (NombreOid: "${legislatura.Oid}") {
              Oid
              NumeroDeProyecto
              ProyectoDecreto
              LinkProyectoDec
              DiarioOficialFederacion
              LinkDOF
              Titulo
              FechaTurnoLegisEstat
              FechaDeclaratoriaCamDip
              FechaDeclaratoriaCamSen
              AFavor
              EnContra
            }
          }
        `,
      })
      .valueChanges.subscribe((result) => {
        legislatura.aprobacion =
          result.data && result.data.allAprobacionesConst;
        console.log('oid.aprobaciones trae', legislatura.aprobacion);
        query.unsubscribe();
      });
  }

 
}
