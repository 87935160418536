import { MatIconModule } from '@angular/material/icon';
import { Component, Inject, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { BuscarDiputadosService } from 'src/app/services/buscar-diputados.service';
import { DiputadosGpComponent } from './modal/diputados-gp/diputados-gp.component';
import { CompocisionPlenoComponent } from './modal/compocision-pleno/compocision-pleno.component';
import { GeneroComponent } from './modal/genero/genero.component';
import { EndidadFederativaComponent } from './modal/endidad-federativa/endidad-federativa.component';
import { PresidenciasComponent } from './modal/presidencias/presidencias.component';
import { TipoEleccionComponent } from './modal/tipo-eleccion/tipo-eleccion.component';
import { filter } from 'rxjs/operators';

export interface Infopal {
  Oid: string;
  Nombre: string;
  Link: string;
  FechaInicio: string;
  FechaFin: string;
  Legislatura: string;
  ImagenLBase64: string;
}

export interface SitioInteresInfopal {
  Oid: string;
  Nombre: string;
  Icono: string;
  IconoBase64: string;
}

interface Response {
  allInfopal: Infopal[];
  SitioInteresInfopal: SitioInteresInfopal[];
}
@Component({
  selector: 'app-infopal',
  templateUrl: './infopal.component.html',
  styleUrls: ['./infopal.component.scss'],
})
export class InfopalComponent implements OnInit {
  Oid = 0;
  loading = true;
  infopals: Infopal[];
  infopal: any;
  sitioInteres: any;
  querySub: any;
  actividadPleno: any;

  constructor(
    private apollo: Apollo,
    public dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  openModalPleno(): any {
    // tslint:disable-next-line: no-use-before-declare
    const dialogRef = this.dialog.open(CompocisionPlenoComponent, {});
  }

  openModalGrupo(): any {
    // tslint:disable-next-line: no-use-before-declare
    const dialogRef = this.dialog.open(DiputadosGpComponent, {});
  }

  openModalTipoEleccion(): any {
    // tslint:disable-next-line: no-use-before-declare
    const dialogRef = this.dialog.open(TipoEleccionComponent, {});
  }

  openModalEntidadFederativa(): any {
    // tslint:disable-next-line: no-use-before-declare
    const dialogRef = this.dialog.open(EndidadFederativaComponent, {});
  }

  openModalGenero(): any {
    // tslint:disable-next-line: no-use-before-declare
    const dialogRef = this.dialog.open(GeneroComponent, {});
  }

  openModalMovimientos(): any {
    // tslint:disable-next-line: no-use-before-declare
    const dialogRef = this.dialog.open(ModalMovimientos, {});
  }

  openModalPresidencias(): any {
    // tslint:disable-next-line: no-use-before-declare
    const dialogRef = this.dialog.open(PresidenciasComponent, {});
  }

  openModalMovIntegrantes(): any {
    // tslint:disable-next-line: no-use-before-declare
    const dialogRef = this.dialog.open(ModalIntegrantes, {});
  }

  ngOnInit(): void {
    this.querySub = this.route.queryParams.subscribe((params) => {
      this.Oid = params.Oid || 0;
      console.log('llegó oid', this.Oid);
    });


    this.sitioInteres = this.apollo
      .watchQuery<Response>({
        query: gql`
          {
            SitioInteresInfopal {
              Oid
              Nombre
              Icono
              IconoBase64
              Link
            }
          }
        `,
      })
      .valueChanges.subscribe((result) => {
        // console.log('result');
        this.sitioInteres = result.data.SitioInteresInfopal;
        this.actividadPleno = this.sitioInteres.filter(
          (opcion) => opcion.Tipo === 3 || opcion.Nombre.includes('AL-')
        );
        this.sitioInteres = this.sitioInteres.filter(
          (enlace) => enlace.Tipo === 2 || enlace.Nombre.includes('SI-')
        );
        // console.log('SitioInteres!!!', this.sitioInteres);
        this.loading = result.loading;
      });
  }
}

// @Component({
//   // tslint:disable-next-line: component-selector
//   selector: 'legislacion',
//   templateUrl: 'modal/modal-tipo-eleccion.html',
// })
// // tslint:disable-next-line: component-class-suffix
// export class ModalTipoEleccion implements OnInit {
//   loading = false;
//   item = {};
//   error: any;

//   sub: any;
//   leg: any;
//   legis: any;
//   noleg: any;
//   subLxivReformasLeg: any;
//   subLegislacion: any;
//   tipoEleccion: any[] = [];

//   constructor(
//     @Inject(MAT_DIALOG_DATA) public data: any,
//     private apollo: Apollo,
//     public dialog: MatDialog,
//     public dialogRef: MatDialogRef<ModalTipoEleccion>,
//     private service: BuscarDiputadosService
//   ) {
//     this.loading = true;
//   }
//   ngOnInit(): void {
//     this.service
//       .getComisionTipoEleccion()
//       .valueChanges.subscribe((data: any) => {
////  console.log('data', data.data.allComisionTipoEleccion);
//         this.tipoEleccion = data.data.allComisionTipoEleccion;
////  console.log('Tipo Eleccion', this.tipoEleccion);
//         this.loading = false;
//       });
//     console.log('los datos del modal son xYY: ', this.data);
//   }
//   closeDialog(): any {
//     this.dialogRef.close();
//   }
// }

// @Component({
//   // tslint:disable-next-line: component-selector
//   selector: 'legislacion',
//   templateUrl: 'modal/modal-entidad-federativa.html',
// })
// // tslint:disable-next-line: component-class-suffix
// export class ModalEntidadFederativa implements OnInit {
//   loading = false;
//   item = {};
//   error: any;

//   sub: any;
//   leg: any;
//   legis: any;
//   noleg: any;
//   subLxivReformasLeg: any;
//   subLegislacion: any;
//   dipEntidad: any[] = [];

//   constructor(
//     @Inject(MAT_DIALOG_DATA) public data: any,
//     private apollo: Apollo,
//     public dialog: MatDialog,
//     public dialogRef: MatDialogRef<ModalEntidadFederativa>,
//     private service: BuscarDiputadosService
//   ) {
//     this.loading = true;
//   }
//   ngOnInit(): void {
//     this.service.getDiputadosEntidad().valueChanges.subscribe((data: any) => {
//       console.log('data', data.data.allDiputadosEntidad);
//       this.dipEntidad = data.data.allDiputadosEntidad;
//       console.log('Entidad Federativa', this.dipEntidad);
//       this.loading = false;
//     });
//     console.log('los datos del modal son xYY: ', this.data);
//   }
//   closeDialog(): any {
//     this.dialogRef.close();
//   }
// }

// @Component({
//   // tslint:disable-next-line: component-selector
//   selector: 'modal-genero',
//   templateUrl: 'modal/modal-genero.html',
// })
// // tslint:disable-next-line: component-class-suffix
// export class ModalGenero implements OnInit {
//   loading = false;
//   item = {};
//   error: any;

//   sub: any;
//   leg: any;
//   legis: any;
//   noleg: any;
//   subLxivReformasLeg: any;
//   subLegislacion: any;
//   dipGenero: any[] = [];

//   constructor(
//     @Inject(MAT_DIALOG_DATA) public data: any,
//     private apollo: Apollo,
//     public dialog: MatDialog,
//     public dialogRef: MatDialogRef<ModalGenero>,
//     private service: BuscarDiputadosService
//   ) {
//     this.loading = true;
//   }
//   ngOnInit(): void {
//     this.service.getDiputadosGenero().valueChanges.subscribe((data: any) => {
//       console.log('data', data.data.allDiputadosGenero);
//       this.dipGenero = data.data.allDiputadosGenero;
//       this.loading = false;
//     });
//   }
//   closeDialog(): any {
//     this.dialogRef.close();
//   }
// }

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'legislacion',
  templateUrl: 'modal/modal-movimientos.html',
})
// tslint:disable-next-line: component-class-suffix
export class ModalMovimientos implements OnInit {
  loading = false;
  item = {};
  error: any;

  sub: any;
  leg: any;
  legis: any;
  noleg: any;
  subLxivReformasLeg: any;
  subLegislacion: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private apollo: Apollo,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<ModalMovimientos>
  ) {}
  ngOnInit(): void {
    console.log('los datos del modal son xYY: ', this.data);
  }
  closeDialog(): any {
    this.dialogRef.close();
  }
}

// @Component({
//   // tslint:disable-next-line: component-selector
//   selector: 'legislacion',
//   templateUrl: 'modal/modal-presidencias.html',
// })
// // tslint:disable-next-line: component-class-suffix
// export class ModalPresidencias implements OnInit {
//   loading = false;
//   item = {};
//   error: any;

//   sub: any;
//   leg: any;
//   legis: any;
//   noleg: any;
//   subLxivReformasLeg: any;
//   subLegislacion: any;
//   dipDist: [] = [];

//   constructor(
//     @Inject(MAT_DIALOG_DATA) public data: any,
//     private apollo: Apollo,
//     public dialog: MatDialog,
//     public dialogRef: MatDialogRef<ModalPresidencias>,
//     private service: BuscarDiputadosService
//   ) {}
//   ngOnInit(): void {
//     this.service
//       .getDistribucionPresidencias()
//       .valueChanges.subscribe((data: any) => {
////  console.log('data', data.data.allDistribucionPresidencias);
//         this.dipDist = data.data.allDistribucionPresidencias;
//         this.loading = false;
//       });
//   }
//   closeDialog(): any {
//     this.dialogRef.close();
//   }
// }

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'legislacion',
  templateUrl: 'modal/modal-integrantes.html',
})
// tslint:disable-next-line: component-class-suffix
export class ModalIntegrantes implements OnInit {
  loading = false;
  item = {};
  error: any;

  sub: any;
  leg: any;
  legis: any;
  noleg: any;
  subLxivReformasLeg: any;
  subLegislacion: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private apollo: Apollo,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<ModalIntegrantes>,
    private service: BuscarDiputadosService
  ) {}
  ngOnInit(): void {
    console.log('los datos del modal son xYY: ismael', this.data);
  }
  closeDialog(): any {
    this.dialogRef.close();
  }
}
