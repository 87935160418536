import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
//Apollo GraphQL
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { Router, ActivatedRoute } from '@angular/router';

export interface DialogData {
  modalInfographic: string;
  youtubeUrl: string;
}
interface InfovideoCategory {
  Oid: string;
  Titulo: string;
  ImageBase64: string;
  Categoria: string;
}
interface Infovideo {
  Oid: string;
  Titulo: string;
  Fecha: string;
  linkSitio: string;
  Descripcion: string;
  Categoria: string;
}
interface Infovideo2 {
  Oid: string;
}
interface Response {
  allInfoVideosCategories: InfovideoCategory[];
  allInfovideos: Infovideo[];
  allInfovideosEntrevista: Infovideo[];
}
interface ResponseFavoriteInfovideos {
  allFavoriteInfoVideos: Infovideo2[];
}
const mutationAddFavoriteInfoVideo = (
  OidInfovideo: any,
  emailVisitante: any
): any => gql`
mutation {
  addFavoriteInfoVideo(OidInfoVideo: "${OidInfovideo}", EmailVisitante: "${emailVisitante}"){
    Oid
  }
}`;
const mutationDeleteFavoriteInfovideo = (
  OidInfovideo: any,
  emailVisitante: any
): any => gql`
mutation {
  deleteFavoriteInfovideo(OidInfoVideo: "${OidInfovideo}", EmailVisitante: "${emailVisitante}"){
    Oid
  }
}`;

const infoVideoPreviewQueryByCategory = gql`
  query allInfoVideosByCategory($CategoriaOid: ID!) {
    allInfoVideosByCategory(CategoriaOid: $CategoriaOid) {
      Oid
      Titulo
      ImageBase64
      Categoria
    }
  }
`;
const AddViewToInfographicMutation = gql`
  mutation addViewToInfographic($Oid: ID!) {
    addViewToInfographic(Oid: $Oid) {
      Oid
    }
  }
`;
@Component({
  selector: 'app-entrevistas',
  templateUrl: './entrevistas.component.html',
  styleUrls: ['./entrevistas.component.scss'],
})
export class EntrevistasComponent implements OnInit {
  private querySub: any;
  infoVideoCategories: any[];
  favoriteInfoVideos: Infovideo2[];
  infovideos: any[];
  loadingInfovideos = true;
  loadingCategories = true;
  error: any;
  loggedIn = false;
  emailVisitante = '';
  loading = true;
  errorInfovideos: any;
  subFavoriteInfovideos: any;
  public categoria = 0;
  sub: any;
  categorySelected: any = 'todas';

  private querySubscription: any;
  infographics: any;
  previewInfographic: any;

  constructor(
    private apollo: Apollo,
    public dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.querySub = this.route.queryParams.subscribe((params) => {
      // Defaults to 0 if no query param provided.
      this.categoria = params['categoria'] || 0;
      // this.oidpadre = this.oid;
    });
    // GFVS.Call function for fetch all infovideo links from DB.
    this.fetchAllInfovideosEntrevista();
    this.fetchAllallInfoVideosCategories();
    this.verifyVisitantSession(); // te faltaba esta linea
  }
  verifyVisitantSession(): any {
    this.loggedIn = !!localStorage.getItem('HCD_activeSessionVisitant');
    if (this.loggedIn) {
      this.emailVisitante = localStorage.getItem('HCD_visitantEmail');

      // traer los eventos favoritos de el visitante
      this.subFavoriteInfovideos = this.apollo
        .watchQuery<ResponseFavoriteInfovideos>({
          query: gql`
          {
            allFavoriteInfoVideos(EmailVisitante: "${this.emailVisitante}") {
              Oid
            }
          }
        `,
        })
        .valueChanges.subscribe((result) => {
          this.favoriteInfoVideos =
            result.data && result.data.allFavoriteInfoVideos;
          this.loading = result.loading;
          this.error = result['error'];

          // console.log(
          //   'llegaron estos infovideos favoritos',
          //   result.data.allFavoriteInfoVideos
          // );
        });
    }
  }
  isFavorite(oid: string): any {
    return this.favoriteInfoVideos.find((infovideo) => infovideo.Oid === oid);
  }
  // ngOnDestroy() {
  //    console.log("onDestroy"); //KYBC. Borrar
  //   this.sub.unsubscribe();
  //   // GFVS este ngOnDestroy lo agregué para  traer infovideos por oid
  //   this.querySubscription.unsubscribe();
  // }
  //GFVS para traer las categorías de videos por oid
  fetchInfoVideosThumbnailsByCategory(categoryOid: string): any {
    // console.log('::::::::::Category Oid Selected: \n', categoryOid); // KYBC.Borrar.
    this.querySubscription = this.apollo
      .watchQuery({
        query: infoVideoPreviewQueryByCategory,
        variables: {
          CategoriaOid: categoryOid,
        },
      })
      .valueChanges.subscribe(({ data }) => {
        // console.log(
        //   '::::::::::infographicPreviewQueryByCategory Data from DB: \n',
        //   data
        // ); // KYBC.Borrar.
        this.infographics = data;
        this.infographics = this.infographics.allInfoVideosByCategory;
        this.setPreviewInfographic(this.infographics[0].Oid);
        // console.log(
        //   '::::::::::All infoVideos by categories from DB: \n',
        //   this.infographics
        // ); // KYBC.Borrar.
      });
  }

  setPreviewInfographic(Oid: string): any {
    // console.log('::::::::::Oid of Preview Infographic set: \n', Oid); // KYBC.Borrar.
    this.querySubscription = this.apollo
      .watchQuery({
        query: infoVideoPreviewQueryByCategory,
        variables: {
          Oid: Oid,
        },
      })
      .valueChanges.subscribe(({ data }) => {
        // console.log('::::::::::infographicPreviewQuery Data from DB: \n', data); // KYBC.Borrar.
        this.previewInfographic = data;
        this.previewInfographic = this.previewInfographic.infographicPreview;
        this.addViewToInfographic(this.previewInfographic.Oid);
        // console.log(
        //   '::::::::::infographicPreviewQuery from DB: \n',
        //   this.previewInfographic
        // ); // KYBC.Borrar.
      });
    window.scrollTo(0, 0);
  }
  addViewToInfographic(Oid: string): any {
    // console.log('::::::::::Inographic Viewed Oid: \n', Oid); // KYBC.Borrar.
    this.apollo
      .mutate({
        mutation: AddViewToInfographicMutation,
        variables: {
          Oid: Oid,
        },
      })
      .subscribe(
        ({ data }) => {
          // console.log(
          //   '::::::::::AddViewToInfographicMutation Data from DB: ',
          //   data
          // ); // KYBC.Borrar.
        },
        (error) => {
          console.log(':::::Error : ', error);
        }
      );
  }

  // GFVS.Fetch all infovideo links from DB.
  fetchAllInfovideosEntrevista(): any {
    this.sub = this.apollo
      .watchQuery<Response>({
        query: gql`
          {
            allInfovideosEntrevista {
              Oid
              Titulo
              Fecha
              linkSitio
              Descripcion
              Categoria
            }
          }
        `,
      })
      .valueChanges.subscribe((result) => {
        // console.log('::::::::::allInfovideos Result from DB: \n', result); // KYBC.Borrar.
        this.infovideos = result.data && result.data.allInfovideosEntrevista;
        //  console.log('::::::::::allInfovideos from DB: \n', this.infovideos); // KYBC.Borrar.
        this.loadingInfovideos = result.loading;
        this.errorInfovideos = result['error'];
      });
  }

  fetchAllallInfoVideosCategories(): any {
    this.sub = this.apollo
      .watchQuery<Response>({
        query: gql`
          {
            allInfoVideosCategories {
              Oid
              Titulo
              ImageBase64
            }
          }
        `,
      })
      .valueChanges.subscribe((result) => {
        // console.log('::::::::::allallInfoVideosCategories Result \n', result); // KYBC.Borrar.
        this.infoVideoCategories =
          result.data && result.data.allInfoVideosCategories;
        // console.log(
        //   '::::::::::allInfoVideosCategories from DB: \n',
        //   this.infoVideoCategories
        // ); // KYBC.Borrar.
        this.loadingCategories = result.loading;
        this.error = result['error'];
      });
  }

  // KYBC.Opens modal with infographic.
  openDialog(youtubeUrl: any): void {
    //console.log(youtubeUrl);
    const dialogRef = this.dialog.open(EntrevistasDialog, {
      width: '75%',
      height: '80%',
      data: { youtubeUrl: youtubeUrl },
    });
    dialogRef.afterClosed().subscribe((result) => {
      //console.log('::::::::::Modal closed! :' + result);
    });
  }

  myFunction(): any {
    const dots = document.getElementById('dots');
    const moreText = document.getElementById('more');
    const btnText = document.getElementById('myBtn');

    if (dots.style.display === 'none') {
      dots.style.display = 'inline';
      btnText.innerHTML = 'Leer más';
      moreText.style.display = 'none';
    } else {
      dots.style.display = 'none';
      btnText.innerHTML = 'Leer menos';
      moreText.style.display = 'inline';
    }
  }

  myFunction2(): any {
    const dots = document.getElementById('dots2');
    const moreText = document.getElementById('more2');
    const btnText = document.getElementById('myBtn2');

    if (dots.style.display === 'none') {
      dots.style.display = 'inline';
      btnText.innerHTML = 'Leer más';
      moreText.style.display = 'none';
    } else {
      dots.style.display = 'none';
      btnText.innerHTML = 'Leer menos';
      moreText.style.display = 'inline';
    }
  }

  myFunction4(): any {
    const dots = document.getElementById('dots4');
    const moreText = document.getElementById('more4');
    const btnText = document.getElementById('myBtn4');

    if (dots.style.display === 'none') {
      dots.style.display = 'inline';
      btnText.innerHTML = 'Leer más';
      moreText.style.display = 'none';
    } else {
      dots.style.display = 'none';
      btnText.innerHTML = 'Leer menos';
      moreText.style.display = 'inline';
    }
  }

  myFunction5(): any {
    const dots = document.getElementById('dots5');
    const moreText = document.getElementById('more5');
    const btnText = document.getElementById('myBtn5');

    if (dots.style.display === 'none') {
      dots.style.display = 'inline';
      btnText.innerHTML = 'Leer más';
      moreText.style.display = 'none';
    } else {
      dots.style.display = 'none';
      btnText.innerHTML = 'Leer menos';
      moreText.style.display = 'inline';
    }
  }

  myFunction6(): any {
    const dots = document.getElementById('dots6');
    const moreText = document.getElementById('more6');
    const btnText = document.getElementById('myBtn6');

    if (dots.style.display === 'none') {
      dots.style.display = 'inline';
      btnText.innerHTML = 'Leer más';
      moreText.style.display = 'none';
    } else {
      dots.style.display = 'none';
      btnText.innerHTML = 'Leer menos';
      moreText.style.display = 'inline';
    }
  }

  addFavoriteInfovideo(OidInfovideo: any): any {
    // console.log(`Llego oid ${OidInfovideo}`);
    // console.log(`Llego correo ${this.emailVisitante}`);
    this.apollo
      .mutate({
        mutation: mutationAddFavoriteInfoVideo(
          OidInfovideo,
          this.emailVisitante
        ),
      })
      .subscribe(
        ({ data }) => {
          // const newFavEvent: Event = {Oid: OidEvento};
          const newFavInfoVideo: Infovideo2 = { Oid: OidInfovideo };
          this.favoriteInfoVideos.push(newFavInfoVideo);
          alert('se ha guardado el infovideo en favoritos de manera exitosa! ');
        },
        (error: string) => {
          alert(
            'hubo un error al guardar en favoritos, intente de nuevo, Error:' +
              error
          );
        }
      );
  }

  deleteFavoriteInfovideo(OidInfovideo: any): any {
    // console.log(`Llego oid ${OidInfovideo}`);
    this.apollo
      .mutate({
        mutation: mutationDeleteFavoriteInfovideo(
          OidInfovideo,
          this.emailVisitante
        ),
      })
      .subscribe(
        ({ data }) => {
          this.favoriteInfoVideos = this.favoriteInfoVideos.filter(
            (infovideo) => infovideo.Oid !== OidInfovideo
          );
          alert('se ha borrado el evento en favoritos de manera exitosa!');
        },
        (error: string) => {
          alert(
            'hubo un error al borrar de favoritos, intente de nuevo, Error:' +
              error
          );
        }
      );
  }
}
@Component({
  selector: 'entrevistaModal',
  templateUrl: 'entrevistaModal.html',
})
export class EntrevistasDialog {
  constructor(
    public dialogRef: MatDialogRef<EntrevistasDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
    // console.log('::::::::::Modal closed!');
  }
}
